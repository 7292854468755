import { clsx } from 'clsx';

import type { ReactElement } from 'react';
import type ISubtitle from './interfaces/ISubtitle';

import styles from './sass/Subtitle.module.scss';

function Subtitle({ text, className, icon }: ISubtitle): ReactElement {
  return (
    <h2 className={clsx(styles.title, className)}>
      {icon}
      {text}
    </h2>
  );
}

export default Subtitle;
