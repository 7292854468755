import { Link } from 'react-router-dom';
import { clsx } from 'clsx';

import type { ReactElement } from 'react';
import type ILinkButton from './interfaces/ILinkButton';

import styles from './sass/LinkButton.module.scss';

function LinkButton({
  to,
  title,
  onClick,
  startIcon,
  endIcon,
  className,
}: ILinkButton): ReactElement {
  return (
    <Link
      to={to}
      className={clsx(styles.linkButton, className)}
      onClick={onClick}
    >
      {startIcon ? <span>{startIcon}</span> : null}
      <span>{title}</span>
      {endIcon ? <span>{endIcon}</span> : null}
    </Link>
  );
}

export default LinkButton;
