import { generatePath, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { AUTHENTICATION_TYPE, SMS_CODE } from 'constants/formFields';
import { useAppSelector } from 'store/hooks/useApp';
import { authTokensSelector } from 'store/slices/auth/selectors';
import { useLoginInfoQuery } from 'store/slices/user/apis/user';

import useAuthenticationTypeForm from './hooks/useAuthenticationTypeForm';
import AuthenticationTypeView from './AuthenticationTypeView';

import type { ReactElement } from 'react';

function AuthenticationType(): ReactElement {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
  } = useAuthenticationTypeForm();

  const { verifyToken } = useAppSelector(authTokensSelector);

  const queryParams = verifyToken ? {} : skipToken;

  const { data } = useLoginInfoQuery(queryParams);

  const onAuthenticationTypeSubmit = handleSubmit((formData) => {
    const { mfaAppEnabled, mfaSmsEnabled } = data?.data.profile || {};

    const isUserMfaAppEnabled = mfaAppEnabled === 'enabled';
    const isUserMfaSmsEnabled = mfaSmsEnabled === 'enabled';

    const mfaAppPath = isUserMfaAppEnabled ? AppRoutes.verificationCode : generatePath(
      AppRoutes.authenticationSetup,
    );
    const mfaSmsPath = isUserMfaSmsEnabled ? AppRoutes.verificationSms : AppRoutes.verificationSmsSetup;
    const isSmsCodeNextStep = formData[AUTHENTICATION_TYPE] === SMS_CODE;
    const nextStep = isSmsCodeNextStep ? mfaSmsPath : mfaAppPath;
    navigate(nextStep);
  });

  return (
    <AuthenticationTypeView
      control={control}
      onAuthenticationTypeSubmit={onAuthenticationTypeSubmit}
    />
  );
}

export default AuthenticationType;
