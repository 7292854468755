import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { AUTHENTICATION_SETUP } from 'constants/formFields';
import { AUTHENTICATOR_SETUP_MODAL_STEPS } from 'constants/interfaces';

import useAuthenticationSetupForm from './hooks/useAuthenticationSetupForm';
import useAuthenticationSetupModal from './hooks/useAuthenticationSetupModal';
import AuthenticationSetupView from './AuthenticationSetupView';

import type { ReactElement } from 'react';

function AuthenticationSetup(): ReactElement {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
  } = useAuthenticationSetupForm();

  const onAuthenticationSetupSubmit = handleSubmit((values) => {
    const type = values[AUTHENTICATION_SETUP];

    if (type) {
      navigate(generatePath(
        AppRoutes.authenticationSetup,
        { step: AUTHENTICATOR_SETUP_MODAL_STEPS.downloadApp, type },
      ));
    }
  });

  const { steps, setIsSecretCodeVisible, isModalVisible } = useAuthenticationSetupModal({});

  return (
    <AuthenticationSetupView
      control={control}
      onAuthenticationSetupSubmit={onAuthenticationSetupSubmit}
      steps={steps}
      setIsSecretCodeVisible={setIsSecretCodeVisible}
      isModalVisible={isModalVisible}
    />
  );
}

export default AuthenticationSetup;
