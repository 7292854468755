import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';

import type { INavSidebar } from './interfaces/INavSidebar';

import styles from './scss/NavSidebar.module.scss';

function NavSidebar({
  navList, disabled, navState, activeClassName,
}: INavSidebar) {
  return (
    <nav>
      <ul className={styles.list}>
        {navList.map(({
          to, name, key, icon,
        }) => (
          <li key={key}>
            <NavLink
              to={to}
              className={({ isActive }) => clsx(styles.link, {
                [clsx(styles.active, activeClassName)]: isActive,
                [styles.disabled]: disabled,
              })}
              state={navState}
            >
              {icon}
              <p>{name}</p>
            </NavLink>
          </li>

        ))}
      </ul>
    </nav>
  );
}

export default NavSidebar;
