import i18n from '../i18n';
import { COMPANY_STATUS, PILLAR_ACCESS_TYPES, STATUS } from './interfaces';

export const COMPANY_NAME_MIN_LENGTH = 3;
export const COMPANY_NAME_MAX_LENGTH = 64;
export const NAME_MIN_LENGTH = 2;
export const DATA_SETS_NAME_MIN_LENGTH = 3;
export const DATA_SETS_NAME_MAX_LENGTH = 64;
export const NAME_MAX_LENGTH = 50;
export const DESCRIPTION_LENGTH = 256;
export const SELECT_ITEM_HEIGHT = 36;
export const SMS_TIME_LEFT = 90;
export const MAX_LOGO_SIZE = 5000000;
export const DATE_FORMAT = 'MM/DD/YY';
export const DATA_SETS_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const REPLACE_DOUBLE_SPACE_REGEX = /  +/g;
export const EXTEND_DATE_FORMAT = 'MMMM D, YYYY';
export const DEFAULT_OFFSET = 10;
export const ALPHANUMERIC_SPACES_SPECIAL_CHARTERS_REGEX = /^[A-Za-z0-9\s\-!@#$%^&*()_+={}[\]:;"'<>,.?/\\|]*$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z\s-]+$/;
export const ALPHANUMERIC_AND_NUMBER_REGEX = /^[a-zA-Z0-9-]+$/;

export const LOGO_MIME_TYPES_ACCEPT = {
  'image/jpg': [],
  'image/jpeg': [],
  'image/png': [],
};

export const DATA_SETS_MIME_TYPES_ACCEPT = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel': [],
};

export const PASSWORD_STRENGTH_METER = {
  weak: 15,
  medium: 66,
  strong: 100,
};

export const FILTER_STATUSES = [
  { value: '', label: i18n.t('common.all') },
  { value: COMPANY_STATUS.draft, label: i18n.t('common.draft') },
  { value: COMPANY_STATUS.active, label: i18n.t('common.active') },
  { value: COMPANY_STATUS.deactivated, label: i18n.t('common.deactivated') },
];

export const CLIENT_TYPES = [
  { value: 'direct', label: i18n.t('common.direct') },
  { value: 'partner', label: i18n.t('common.partner') },
];

export const STATUS_TYPES = [
  { value: STATUS.enabled, label: i18n.t('common.enabled') },
  { value: STATUS.disabled, label: i18n.t('common.disabled') },
];

export const ACCESS_TYPES = [
  { value: PILLAR_ACCESS_TYPES.visible_enabled, label: i18n.t('common.visibleEnabled') },
  { value: PILLAR_ACCESS_TYPES.dimmed_disabled, label: i18n.t('common.dimmedDisabled') },
  { value: PILLAR_ACCESS_TYPES.invisible_disabled, label: i18n.t('common.invisibleDisabled') },
];

export const AUTHENTICATOR_APP_LINKS = {
  google: {
    apple: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    google: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en',
  },
  microsoft: {
    apple: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
    google: 'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en',
  },
};

export const WEBLATE_LINKS = {
  superAdmin: 'https://onix-systems-weblate-attaima.dev.onix.team/admin/',
  translations: 'https://onix-systems-weblate-attaima.dev.onix.team/projects/attaima/',
};

export const EMPTY_SYMBOL = ' ';

export const BLOCKER_STATES = {
  blocked: 'blocked',
  unblocked: 'unblocked',
};

export const API_METHODS = {
  patch: 'PATCH',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const LAUNCHPAD_PILLARS_COUNT = 9;

export const LAUNCHPAD_EMPTY_PILLARS_COUNT = 6;

export const NEW_CLIENT_ID = 'create';

export const SHEET_EXAMPLE_FILES_NAME = {
  profitCenter: 'profit-center.xlsx',
  ownOperation: 'own-operation.xlsx',
  suppliers: 'suppliers.xlsx',
  customers: 'customers.xlsx',
  products: 'products.xlsx',
};

export const MAPPING_FIELDS_PREFIX = {
  target: 'target.',
  source: 'sources.',
} as const;
export const MINIMUM_FRACTION_DIGITS = 0;
export const MAXIMUM_FRACTION_DIGITS = 2;
export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_LOCALE = 'en';

export const THOUSAND_SEPARATOR = ',';
export const DECIMAL_SEPARATOR = '.';
export const DEFAULT_DECIMAL_SCALE = 2;
export const PERCENT_SUFFIX = '%';
