import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import usePillarModulePermission from 'hooks/usePillarModulePermission';

import Panel from '../Panel/Panel';

import type IProfileCard from './interfaces/IProfileCard';

import styles from './scss/ProfileCard.module.scss';

function ProfileCard({
  icon, title, description, className, navLink, pillarModuleKey, target,
}: IProfileCard) {
  const { isDisabled, isVisible, isLoading } = usePillarModulePermission({ pillarModuleKey });

  const link = isDisabled ? '#' : navLink;

  return (isVisible || isDisabled) && !isLoading ? (
    <div className={clsx(styles.root, className)}>
      <Link
        to={link}
        target={target}
        className={clsx(styles.navLink, {
          [styles.disabled]: isDisabled,
        })}
      >
        <Panel className={styles.panel}>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </Panel>
      </Link>
    </div>

  ) : null;
}

export default ProfileCard;
