import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type ICompaniesStore from './interfaces/ICompaniesStore';

export const initialState: ICompaniesStore = {
  createFlowCompanyId: undefined,
  clientsQueryParams: {
    offset: 0,
    search: '',
    status: undefined,
    orderBy: 'name',
  },
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanyState: (state, { payload }: PayloadAction<Partial<ICompaniesStore>>) => ({ ...state, ...payload }),
    resetState: () => initialState,
  },
  extraReducers: () => {},
});

export const {
  resetState, setCompanyState,
} = companiesSlice.actions;

export default companiesSlice.reducer;
