import { useTranslation } from 'react-i18next';
import {
  generatePath,
  matchPath, Outlet, useLocation, useMatch, useParams,
} from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import NavSidebar from 'components/NavSidebar/NavSidebar';
import Title from 'components/Title/Title';
import { AppRoutes } from 'constants/AppRoutes';
import { createNewUserNav } from 'constants/navigationLists';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useUserDetailsQuery } from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';

import type { TAppRoutes } from 'constants/AppRoutes';

import styles from './scss/UserManagementLayout.module.scss';

function UserManagementLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const { userId = '' } = useParams();

  const isNavMenuVisible = [
    AppRoutes.createNewUser,
    AppRoutes.createUserPermissions,
    AppRoutes.editUserInfo,
    AppRoutes.editUserPermissions,
  ].some((path) => matchPath(path, pathname));

  const userInfoPath = generatePath(AppRoutes.userInfo, { userId });

  const editUserInfoPath = generatePath(AppRoutes.editUserInfo, { userId });
  const editUserPermissionsPath = generatePath(AppRoutes.editUserPermissions, { userId });
  const createUserPermissionsPath = generatePath(AppRoutes.createUserPermissions, { userId });

  const fetchQuery = userId ? {
    userId: +userId,
  } : skipToken;

  const { data } = useUserDetailsQuery(fetchQuery);

  const { firstName, lastName } = data?.data || {};

  const { redirectPath } = location.state || {};

  const userLabel = userId ? t('userManagement.fullName', {
    firstName,
    lastName,
  }) : '';

  const userCrumb = {
    pathname: userInfoPath,
    label: userLabel || userId || '',
    path: userInfoPath,
  };
  const { breadcrumbs } = useBreadcrumbs({ mathCrumbs: userId ? [userCrumb] : [] });

  const isCreateNewUserPage = !!useMatch(AppRoutes.createNewUser);
  const isCreateFlow = !!useMatch(generatePath(AppRoutes.createUserPermissions, { userId }))
      || isCreateNewUserPage;

  const navList = createNewUserNav({ userId, isCreateFlow });

  const isNavSidebarDisabled = !userId;

  const pageTitle: { [key in TAppRoutes]?: string } = {
    [AppRoutes.createNewUser]: t('createNewUser.title'),
    [createUserPermissionsPath]: t('permissions.title'),
    [userInfoPath]: userLabel,
    [editUserInfoPath]: t('createNewUser.editTitle'),
    [editUserPermissionsPath]: t('createNewUser.editTitle'),
  };

  const pageTitleText = pageTitle[pathname as TAppRoutes];
  return (
    <div className={styles.root}>
      <BreadcrumbsNav breadcrumbs={breadcrumbs} />
      { pageTitleText ? (
        <Title
          text={pageTitleText}
          className={styles.title}
        />
      ) : null}
      <div className={styles.wrapper}>
        {isNavMenuVisible ? (
          <div className={styles.sidebar}>
            <NavSidebar
              navList={navList}
              disabled={isNavSidebarDisabled}
              navState={{ redirectPath }}
            />
          </div>
        ) : null}
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default UserManagementLayout;
