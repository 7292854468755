import { clsx } from 'clsx';

import type IPanel from './interfaces/IPanel';

import styles from './scss/Panel.module.scss';

function Panel({ className, children }: IPanel) {
  return (
    <div className={clsx(styles.panel, className)}>
      {children}
    </div>
  );
}

export default Panel;
