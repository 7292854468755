import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import DeleteUserModal from 'components/DeleteUserModal/DeleteUserModal';

import UserSecurity from '../../SuperAdminPages/UserSecurity/UserSecurity';
import PermissionsSection from './components/PermissionsSection/PermissionsSection';
import UserInfoSection from './components/UserInfoSection/UserInfoSection';

import type { IUserInfoView } from './interfaces/IUserInfoView';

import styles from './sass/UserInfoView.module.scss';

function UserInfoView({
  user, onToggleDeleteModal, isNewUser, isDeactivationModalOpen, onToggleDeactivationModal,
  onUserDeactivate, isDeleteModalOpen, onUserDeleteAction, isInfoModalOpen, setIsInfoModalOpen, onChangeUserStatus,
  pillars, isActionsVisible, isAuditOrViewAccess,
}: IUserInfoView) {
  const { t } = useTranslation();
  const { latestDeactivation } = user || {};

  return (
    <div className={styles.root}>
      {!isActionsVisible && !isAuditOrViewAccess ? (
        <div className={styles.warning}>
          <InfoIcon />
          <span className={styles.warningTitle}>
            {t('permissions.companyAdminOwnEditingWarning')}
          </span>
        </div>
      ) : null}
      <div className={styles.wrapper}>
        <UserInfoSection
          user={user}
          onToggleDeleteModal={onToggleDeleteModal}
          isNewUser={isNewUser}
          onChangeUserStatus={onChangeUserStatus}
          isActionsVisible={isActionsVisible}
        />

        <UserSecurity isActionsVisible={isActionsVisible} />

        {pillars ? (
          <PermissionsSection
            pillars={pillars}
            isActionsVisible={isActionsVisible}
          />
        ) : null}

        <DeactivationModal
          open={isDeactivationModalOpen}
          text={t('common.userActivity')}
          onReject={onToggleDeactivationModal}
          onSubmit={onUserDeactivate}
        />
        <DeleteUserModal
          open={isDeleteModalOpen}
          onReject={onToggleDeleteModal}
          onClose={onToggleDeleteModal}
          onConfirm={onUserDeleteAction}
        />
        <DeactivationInfoModal
          open={isInfoModalOpen}
          deactivationInfo={latestDeactivation}
          onClose={() => setIsInfoModalOpen(false)}
        />
      </div>
    </div>
  );
}

export default UserInfoView;
