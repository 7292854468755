import type { ICreateUidNameTuplesOptions } from './interfaces/ICreateUidNameTuplesOptions';

function createUidNameTuplesOptions({ uidNameTuples, values, tuplesKey }: ICreateUidNameTuplesOptions) {
  if (!uidNameTuples?.[tuplesKey] || !values) {
    return [];
  }

  return values.map((id) => uidNameTuples?.[tuplesKey].find((option) => option.value === id)
      || { value: '', label: '' });
}

export default createUidNameTuplesOptions;
