import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AUTHENTICATION_APP, AUTHENTICATION_TYPE } from 'constants/formFields';
import { authenticationSchema } from 'utils/validators';

import type { TUseAuthenticationTypeForm } from '../interfaces/TUseAuthenticationTypeForm';

const useAuthenticationTypeForm = () => {
  const {
    control,
    handleSubmit,
  } = useForm<TUseAuthenticationTypeForm>({
    mode: 'onChange',
    resolver: yupResolver(authenticationSchema),
    defaultValues: {
      [AUTHENTICATION_TYPE]: AUTHENTICATION_APP,
    },
  });

  return {
    control,
    handleSubmit,
  };
};

export default useAuthenticationTypeForm;
