import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BasketIcon } from 'assets/icons/basket.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as FilterActiveIcon } from 'assets/icons/filter-active.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Button from 'components/Button/Button';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import Toggle from 'components/Toggle/Toggle';
import { SEARCH, SHOW_ARCHIVED } from 'constants/formFields';
import { BUTTON_VARIANT, SEPARATOR_VARIANT } from 'constants/interfaces';

import type IProfitCentresFilters from './interfaces/IDataSetsListFilters';

import styles from './sass/DataSetsListFilters.module.scss';

function DataSetsListFilters({
  control, meta, onSearchChange, onArchive, isArchiveSelectedItemsVisible, selectedItems, currentTotalItems,
  isActionsVisible, title, subTitle, onAddRecord, isFilterVisible, setIsFiltersBarVisible, isApplyFilter,
}: IProfitCentresFilters) {
  const { t } = useTranslation();

  const { count, offset } = meta || {};

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div>{title}</div>
          <div className={styles.description}>{subTitle}</div>
        </div>
        {isActionsVisible ? (
          <Button
            title={t('dataSetsManagement.addRecord')}
            startIcon={<PlusIcon />}
            variant={BUTTON_VARIANT.primary}
            onClick={onAddRecord}
          />
        ) : null}
      </div>
      <Separator className={styles.separator} />
      <div className={styles.content}>
        <div className={styles.filters}>

          <div className={styles.search}>
            <Controller
              control={control}
              name={SEARCH}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  name={name}
                  value={value}
                  placeholder={t('common.search')}
                  error={error?.message}
                  icon={<SearchIcon />}
                  onChange={(event) => {
                    onChange(event.target.value);
                    onSearchChange({ search: event.target.value, onChange });
                  }}
                  required
                />
              )}
            />
          </div>
          {isFilterVisible && setIsFiltersBarVisible ? (
            <>
              <Button
                title={t('dataSetsManagement.addFilter')}
                startIcon={<FilterIcon />}
                variant="ghost"
                onClick={() => {
                  setIsFiltersBarVisible(true);
                }}
                endIcon={isApplyFilter ? <FilterActiveIcon /> : null}
                className={isApplyFilter ? styles.filter : ''}
              />

              <Separator className={styles.verticalSeparator} position={SEPARATOR_VARIANT.vertical} />
            </>
          ) : null}

          <div className={styles.showArchived}>
            <Controller
              name={SHOW_ARCHIVED}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Toggle
                  value={name}
                  checked={!!value}
                  name={name}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              )}
            />
            <span>{t('dataSetsManagement.showArchived')}</span>

          </div>

          {isArchiveSelectedItemsVisible && isActionsVisible ? (
            <>
              <Separator className={styles.verticalSeparator} position={SEPARATOR_VARIANT.vertical} />
              <Button
                title={t('dataSetsManagement.archiveSelectedItems')}
                startIcon={<BasketIcon />}
                variant="ghost"
                onClick={onArchive}
              />
            </>
          ) : null}

        </div>
        <div className={styles.pageNumber}>
          {selectedItems.length > 0 ? t('dataSetsManagement.selectedOf', {
            selected: selectedItems.length,
            total: count,
          }) : t('dataSetsManagement.totalPages', {
            offset: offset ? offset + 1 : 1,
            totalPages: offset ? offset + currentTotalItems
              : currentTotalItems,
            total: count,
          })}

        </div>
      </div>

    </div>
  );
}

export default DataSetsListFilters;
