import { useTranslation } from 'react-i18next';

import type INoData from './interfaces/INoData';

import styles from './scss/NoData.module.scss';

function NoData({ title }: INoData) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        {title || t('common.noData')}
      </h2>
    </div>
  );
}

export default NoData;
