import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import Cropper from 'components/Cropper/Cropper';

import Preview from './Preview';

import type { FileRejection } from 'react-dropzone';
import type IDropZone from './interfaces/IDropZone';

import styles from './scss/DropZone.module.scss';

function DropZone({
  className,
  title,
  hint,
  files,
  maxSize,
  accept,
  onUploadChange,
  withCropper,
  dropZoneTitle,
  isMultiFiles = false,
  error = '',
  isActionsVisible,
  icon,
  dropZoneClassName,
  isDataSets,
  onDownloadExample,
}: IDropZone) {
  const { t } = useTranslation();
  const [openCropper, setOpenCropper] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setErrorMessage('');

    if (withCropper) {
      setOpenCropper(true);
    } else {
      onUploadChange(acceptedFiles);
    }
  }, [withCropper, onUploadChange]);

  const onDropRejected = (fileRejections: FileRejection[]) => {
    setErrorMessage(fileRejections[0].errors.map((item) => item.message).join(', '));
  };

  const {
    acceptedFiles: addedFiles, getRootProps, getInputProps, open: onOpen, inputRef, isDragActive,
  } = useDropzone({
    accept,
    onDrop,
    onDropRejected,
    maxSize,
    multiple: isMultiFiles,
    noClick: true,
    noKeyboard: true,
  });

  const onSaveCrop = (file: File) => {
    onUploadChange([file]);
    setOpenCropper(false);
  };

  const onCancelCrop = () => {
    setOpenCropper(false);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleRemove = useCallback((fileIndex: number) => {
    const tempFiles = [...files];
    tempFiles.splice(fileIndex, 1);
    onUploadChange(tempFiles);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [files, inputRef, onUploadChange]);

  const showCropper = openCropper && withCropper && addedFiles.length;

  const imagePreview = typeof files[0] === 'object' ? URL.createObjectURL(files[0]) : files[0];

  const rootClassName = clsx(styles.root, dropZoneClassName);

  return (
    <div className={className}>
      <div className={styles.header}>
        <div>
          {title ? <h4 className={styles.title}>{title}</h4> : hint}
          {hint ? <p className={styles.hint}>{hint}</p> : null}
        </div>
        {isDataSets ? (
          <Button
            title={t('dataSetsManagement.downloadExample')}
            startIcon={<DownloadIcon />}
            variant="ghost"
            onClick={onDownloadExample}
          />
        ) : null}
      </div>

      <div
        {...getRootProps()}
        className={clsx(rootClassName, {
          [styles.rootError]: error || errorMessage,
          [styles.rootDragActive]: isDragActive,
        })}
      >
        <input {...getInputProps()} />
        <div className={styles.wrapper}>
          {withCropper && files.length
            ? (
              <Preview
                imagePreview={imagePreview}
                onDelete={() => {
                  handleRemove(0);
                }}
                isActionsVisible={isActionsVisible}
              />
            ) : isActionsVisible && (
              <>
                {icon ? <div className={styles.icon}>{icon}</div> : null}
                <Button
                  className={styles.button}
                  title={t('common.browse')}
                  onClick={onOpen}
                />
                <p className={styles.text}>{t('common.dragHere')}</p>
              </>
            )}
        </div>
      </div>
      {error || errorMessage ? <p className={styles.error}>{error || errorMessage}</p> : null}
      {showCropper ? createPortal(
        <Cropper
          title={dropZoneTitle}
          file={addedFiles[0]}
          onSave={onSaveCrop}
          onCancel={onCancelCrop}
        />,
        document.body,
      ) : null}
    </div>
  );
}

export default DropZone;
