import { t } from 'i18next';

import { AUTHENTICATION_APP, SMS_CODE } from './formFields';

import { ReactComponent as BarcodeIcon } from '../assets/icons/barcode.svg';
import { ReactComponent as SmsCodeIcon } from '../assets/icons/smsCode.svg';

const AUTHENTICATION_TYPES = [
  {
    name: AUTHENTICATION_APP,
    value: AUTHENTICATION_APP,
    label: t('authenticationType.authenticationApp'),
    icon: <BarcodeIcon />,
  },
  {
    name: SMS_CODE,
    value: SMS_CODE,
    label: t('authenticationType.SMSCode'),
    icon: <SmsCodeIcon />,
  },
];

export default AUTHENTICATION_TYPES;
