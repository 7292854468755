import { matchPath, useLocation } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';

const useIsFooterLogosVisible = () => {
  const location = useLocation();
  const { pathname } = location;

  const isFooterLogosVisible = [
    AppRoutes.login,
    AppRoutes.authenticationType,
    AppRoutes.verificationSms,
    AppRoutes.verificationCode,
    AppRoutes.authenticationSetup,
    AppRoutes.verificationSmsSetup,
  ].some((path) => matchPath(path, pathname));

  return {
    isFooterLogosVisible,
  };
};

export default useIsFooterLogosVisible;
