export default {
  title: 'Security',

  authenticationApp: 'Authentication App',
  phoneNumber: 'Phone number',
  setUpAuthenticationApp: '+ Set up Authentication App ',
  addPhoneNumber: '+ Add Phone Number',

  confirmPhoneRemovalTitle: 'Are you sure you want to remove phone number?',
  confirmAppRemovalTitle: 'Are you sure you want to remove Auth app?',
  requiredMfaTitle: 'MFA Setup Required',

  confirmRemovalDescription: 'It will remove the extra security on your account',
  confirmRequiredRemovalDescription: 'MFA is required in your company. Resetting MFA will restrict your navigation '
    + 'on the platform until you set it up again.',
  requiredMfaDescription: 'For security reasons, your account requires MFA. Your navigation '
    + 'is currently restricted. To continue using the platform, please set up an MFA method.',
};
