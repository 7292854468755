import { useState } from 'react';
import { HexColorInput } from 'react-colorful';
import { clsx } from 'clsx';
import useOutsideClick from 'hooks/useOutsideClick';

import ColorPickerPopup from './ColorPickerPopup';

import type IColorPicker from './interfaces/IColorPicker';

import styles from './scss/ColorPicker.module.scss';

function ColorPicker({
  name, label, color, onChange, placeholder, className, error,
}: IColorPicker) {
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false));

  return (
    <div className={clsx(styles.container, className)}>
      {label ? <p className={styles.label}>{label}</p> : null}
      <div
        className={clsx(styles.root, {
          [styles.rootError]: error,
        })}
      >
        <div
          className={styles.picker}
          role="presentation"
          style={{ backgroundColor: color }}
          onClick={() => setOpen(true)}
        />
        {open ? (
          <ColorPickerPopup
            ref={ref}
            color={color}
            onChange={onChange}
          />
        ) : null}
        <div className={styles.input}>
          <HexColorInput
            name={name}
            color={color}
            onChange={onChange}
            placeholder={placeholder}
            prefixed
            alpha
          />
        </div>
      </div>
      {error ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
}

export default ColorPicker;
