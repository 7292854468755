import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select/Select';
import { MFA_APP, MFA_SMS } from 'constants/formFields';
import { STATUS_TYPES } from 'constants/general';

import type ISecurityForm from '../../interfaces/ISecurityForm';

import styles from './scss/SecurityForm.module.scss';

function SecurityForm({ control }: ISecurityForm) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          <p className={styles.title}>
            {t('clients.mfaAuthentication')}
          </p>
        </div>
        <div className={styles.item}>
          <Controller
            name={MFA_APP}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                label={t('common.status')}
                placeholder={t('common.status')}
                options={STATUS_TYPES}
                value={STATUS_TYPES.find((item) => item.value === value)}
                onChange={(newValue: any) => onChange(newValue?.value)}
                error={error?.message}
              />
            )}
          />
        </div>
        <div className={styles.item}>
          <p className={styles.title}>
            {t('clients.mfaSms')}
          </p>
        </div>
        <div className={styles.item}>
          <Controller
            name={MFA_SMS}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                label={t('common.status')}
                placeholder={t('common.status')}
                options={STATUS_TYPES}
                value={STATUS_TYPES.find((item) => item.value === value)}
                onChange={(newValue: any) => onChange(newValue?.value)}
                error={error?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default SecurityForm;
