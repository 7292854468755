import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ModuleIcon } from 'assets/icons/module.svg';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';

import PermissionItem from './PermissionItem';

import type { IModule } from 'store/slices/companyAdminUsers/interfaces/IUserPermissions';
import type IPermissionsSection from './interfaces/IPermissionsSection';

import styles from './scss/PermissionsSection.module.scss';

function PermissionsSection({
  pillars, isActionsVisible,
}: IPermissionsSection) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId = '' } = useParams();

  const onHandleEdit = () => {
    navigate(
      generatePath(AppRoutes.editUserPermissions, { userId }),
      { state: { redirectPath: generatePath(AppRoutes.userInfo, { userId }) } },
    );
  };

  return (
    <InfoPanel
      title={t('userInfo.permissionsSection.title')}
      icon={<ModuleIcon />}
      onEdit={onHandleEdit}
      isActionsVisible={isActionsVisible}
    >
      {pillars?.map(({ name, pillarModules }, index, array) => (
        <Fragment key={window.crypto.randomUUID()}>
          <h3 className={styles.title}>{t(`pillarsAndModules.${name}`)}</h3>
          <div className={styles.wrapper}>
            {pillarModules?.map(({ name: pilarModuleName, id: permissionId, access }: IModule) => (
              <PermissionItem
                key={window.crypto.randomUUID()}
                permissionId={permissionId}
                access={access}
                pilarModuleName={pilarModuleName}
              />
            ))}
          </div>
          {array.length - 1 !== index ? <Separator /> : null }
        </Fragment>
      ))}
    </InfoPanel>
  );
}

export default PermissionsSection;
