import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { TRANSLATE_STATUS } from 'constants/interfaces';
import i18n from 'i18n';

import type ICompany from '../companies/interfaces/ICompany';
import type ITranslates from './interfaces/ITranslates';

export const initialState: ITranslates = {
  locale: i18n.language,
  status: TRANSLATE_STATUS.idle,
  company: {
    languages: [],
    translations: null,
  },
};

export const toggleLocale = createAsyncThunk(
  'translates/toggleLocale',
  async (locale: string) => {
    await i18n.changeLanguage(locale);
    return locale;
  },
);

const translatesSlice = createSlice({
  name: 'translates',
  initialState,
  reducers: {
    setCompanyTranslations: (state, action: PayloadAction<ICompany>) => (
      {
        ...state,
        company: {
          languages: action.payload.localization?.languages || [],
          translations: action.payload.uiConfiguration?.translations || null,
        },
      }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleLocale.pending, (state) => {
        state.status = TRANSLATE_STATUS.loading;
      })
      .addCase(toggleLocale.fulfilled, (state, action) => {
        state.status = TRANSLATE_STATUS.idle;
        state.locale = action.payload;
      })
      .addCase(toggleLocale.rejected, (state) => {
        state.status = TRANSLATE_STATUS.failed;
      });
  },
});

export const {
  setCompanyTranslations,
} = translatesSlice.actions;

export default translatesSlice.reducer;
