import type IBackdrop from './interfaces/IBackdrop';

import styles from './scss/Backdrop.module.scss';

function Backdrop({ children }: IBackdrop) {
  return (
    <div className={styles.root}>
      {children}
    </div>
  );
}

export default Backdrop;
