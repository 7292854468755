import { DATA_SET_PAGINATION_COUNT } from 'constants/dataSets';
import { API_METHODS, SHEET_EXAMPLE_FILES_NAME } from 'constants/general';
import {
  DATA_SETS_CHANGE_GEO_STRUCTURE,
  DATA_SETS_CHANGE_ORG_STRUCTURE,
  DATA_SETS_CUSTOMER_ARCHIVE,
  DATA_SETS_CUSTOMER_CREATE,
  DATA_SETS_CUSTOMER_DE_ARCHIVE,
  DATA_SETS_CUSTOMER_IMPORT_RECORDS,
  DATA_SETS_CUSTOMER_LIST,
  DATA_SETS_CUSTOMER_RECORD,
  DATA_SETS_CUSTOMER_RECORD_UPDATE,
  DATA_SETS_CUSTOMER_SHEET_EXAMPLE,
  DATA_SETS_GET_ENUM,
  DATA_SETS_GET_UID_NAME_TUPLES,
  DATA_SETS_NACE_CODES_TREE,
  DATA_SETS_OWN_OPERATION_ARCHIVE,
  DATA_SETS_OWN_OPERATION_CREATE,
  DATA_SETS_OWN_OPERATION_DE_ARCHIVE,
  DATA_SETS_OWN_OPERATION_IMPORT_RECORDS,
  DATA_SETS_OWN_OPERATION_LIST,
  DATA_SETS_OWN_OPERATION_RECORD,
  DATA_SETS_OWN_OPERATION_RECORD_UPDATE,
  DATA_SETS_OWN_OPERATION_SHEET_EXAMPLE,
  DATA_SETS_PRODUCT_ARCHIVE,
  DATA_SETS_PRODUCT_CREATE,
  DATA_SETS_PRODUCT_DE_ARCHIVE,
  DATA_SETS_PRODUCT_IMPORT_RECORDS,
  DATA_SETS_PRODUCT_LIST, DATA_SETS_PRODUCT_RECORD,
  DATA_SETS_PRODUCT_SHEET_EXAMPLE, DATA_SETS_PRODUCT_UPDATE,
  DATA_SETS_PROFIT_CENTER_ARCHIVE,
  DATA_SETS_PROFIT_CENTER_CREATE,
  DATA_SETS_PROFIT_CENTER_DE_ARCHIVE,
  DATA_SETS_PROFIT_CENTER_IMPORT_RECORDS,
  DATA_SETS_PROFIT_CENTER_LIST,
  DATA_SETS_PROFIT_CENTER_RECORD,
  DATA_SETS_PROFIT_CENTER_RECORD_UPDATE,
  DATA_SETS_PROFIT_CENTER_SHEET_EXAMPLE,
  DATA_SETS_SUPPLIER_ARCHIVE,
  DATA_SETS_SUPPLIER_CREATE,
  DATA_SETS_SUPPLIER_DE_ARCHIVE,
  DATA_SETS_SUPPLIER_IMPORT_RECORDS,
  DATA_SETS_SUPPLIER_LIST,
  DATA_SETS_SUPPLIER_RECORD,
  DATA_SETS_SUPPLIER_RECORD_UPDATE,
  DATA_SETS_SUPPLIER_SHEET_EXAMPLE,
  DATA_SETS_UNIQ_GEO_STRUCTURES,
  DATA_SETS_UNIQ_ORG_STRUCTURES,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';
import DATA_SETS_PROFIT_CENTER_LIST_TAG, {
  DATA_CUSTOMERS_LIST_TAG,
  DATA_PRODUCTS_LIST_TAG, DATA_SETS_CUSTOMERS_RECORD_TAG,
  DATA_SETS_OWN_OPERATION_LIST_TAG,
  DATA_SETS_OWN_OPERATION_RECORD_TAG, DATA_SETS_PRODUCTS_RECORD_TAG,
  DATA_SETS_PROFIT_CENTER_RECORD_TAG,
  DATA_SETS_SUPPLIERS_RECORD_TAG,
  DATA_SETS_UNIQ_GEO_STRUCTURES_TAG,
  DATA_SETS_UNIQ_ORG_STRUCTURES_TAG,
  DATA_SUPPLIERS_LIST_TAG,
} from 'store/tags/dataSets';
import downloadFileHandler from 'utils/downloadFileHandler';

import type { IBaseQueryPaginationResponse } from 'store/types/IBaseQueryPaginationResponse';
import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type IChangeGeoStructuresRequest from '../interfaces/IChangeGeoStructuresRequest';
import type IChangeOrgStructuresRequest from '../interfaces/IChangeOrgStructuresRequest';
import type ICustomerItem from '../interfaces/ICustomerItem';
import type ICustomerRecordRequest from '../interfaces/ICustomerRecordRequest';
import type IDataSetsArchiveRequest from '../interfaces/IDataSetsArchiveRequest';
import type IDataSetsDeArchiveRequest from '../interfaces/IDataSetsDeArchiveRequest';
import type IDataSetsImportRecordsResponse from '../interfaces/IDataSetsImportRecordsResponse';
import type IDataSetsListRequest from '../interfaces/IDataSetsListRequest';
import type IDataSetsRecordsRequest from '../interfaces/IDataSetsRecordsRequest';
import type IEnumRequest from '../interfaces/IEnumRequest';
import type IEnumResponse from '../interfaces/IEnumResponse';
import type { INaceCodeTreeResponse } from '../interfaces/INaceCodeTreeResponse';
import type IOperationsRecordRequest from '../interfaces/IOperationsRecordRequest';
import type IOwnItem from '../interfaces/IOwnItem';
import type IProductItem from '../interfaces/IProductItem';
import type IProductRecordRequest from '../interfaces/IProductRecordRequest';
import type IProfitCenterCreateResponse from '../interfaces/IProfitCenterCreateResponse';
import type IDataSetsImportRecordsRequest from '../interfaces/IProfitCenterImportRecordsRequest';
import type IProfitCenterItem from '../interfaces/IProfitCenterItem';
import type IProfitCenterRecordRequest from '../interfaces/IProfitCenterRecordRequest';
import type ISupplierItem from '../interfaces/ISupplierItem';
import type ISupplierRecordRequest from '../interfaces/ISupplierRecordRequest';
import type { IUidNameTuplesRequest } from '../interfaces/IUidNameTuplesRequest';
import type { IUidNameTuplesResponse } from '../interfaces/IUidNameTuplesResponse';
import type { TUniqGeoStructuresResponse } from '../interfaces/TUniqGeoStructuresResponse';
import type { TUniqOrgStructuresResponse } from '../interfaces/TUniqOrgStructuresResponse';

export const dataSetsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    ownOperationList: builder.query<IBaseQueryPaginationResponse<IOwnItem[]>, IDataSetsListRequest>({
      query: ({
        orderBy, keyword, archived, offset, orgTypePrefix, geoTypePrefix,
      }) => ({
        url: DATA_SETS_OWN_OPERATION_LIST,
        params: {
          ...(orderBy && { orderBy }),
          ...(keyword && { keyword }),
          ...(archived && { archived }),
          ...(offset ? { offset } : { offset: 0 }),
          ...(orgTypePrefix && { orgTypePrefix }),
          ...(geoTypePrefix && { geoTypePrefix }),
          limit: DATA_SET_PAGINATION_COUNT,
        },
      }),
      providesTags: (result) => (
        result?.data?.items?.length && result?.data?.items?.length > 0
          ? [...result.data.items.map(({ id }) => (
            { type: DATA_SETS_OWN_OPERATION_LIST_TAG, id }))]
          : [DATA_SETS_OWN_OPERATION_LIST_TAG]
      ),
    }),
    supplierList: builder.query<IBaseQueryPaginationResponse<ISupplierItem[]>, IDataSetsListRequest>({
      query: ({
        orderBy, keyword, archived, offset,
      }) => ({
        url: DATA_SETS_SUPPLIER_LIST,
        params: {
          ...(orderBy && { orderBy }),
          ...(keyword && { keyword }),
          ...(archived && { archived }),
          ...(offset && { offset }),
          limit: DATA_SET_PAGINATION_COUNT,
        },
      }),
      providesTags: (result) => (
        result?.data?.items?.length && result?.data?.items?.length > 0
          ? [...result.data.items.map(({ id }) => (
            { type: DATA_SUPPLIERS_LIST_TAG, id }))]
          : [DATA_SUPPLIERS_LIST_TAG]
      ),
    }),
    customerList: builder.query<IBaseQueryPaginationResponse<ICustomerItem[]>, IDataSetsListRequest>({
      query: ({
        orderBy, keyword, archived, offset,
      }) => ({
        url: DATA_SETS_CUSTOMER_LIST,
        params: {
          ...(orderBy && { orderBy }),
          ...(keyword && { keyword }),
          ...(archived && { archived }),
          ...(offset && { offset }),
          limit: DATA_SET_PAGINATION_COUNT,
        },
      }),
    }),
    productList: builder.query<IBaseQueryPaginationResponse<IProductItem[]>, IDataSetsListRequest>({
      query: ({
        orderBy, keyword, archived, offset,
      }) => ({
        url: DATA_SETS_PRODUCT_LIST,
        params: {
          ...(orderBy && { orderBy }),
          ...(keyword && { keyword }),
          ...(archived && { archived }),
          ...(offset && { offset }),
          limit: DATA_SET_PAGINATION_COUNT,
        },
      }),
      providesTags: (result) => (
        result?.data?.items?.length && result?.data?.items?.length > 0
          ? [...result.data.items.map(({ id }) => (
            { type: DATA_PRODUCTS_LIST_TAG, id }))]
          : [DATA_PRODUCTS_LIST_TAG]
      ),
    }),
    profitCenterList: builder.query<IBaseQueryPaginationResponse<IProfitCenterItem[]>, IDataSetsListRequest>({
      query: ({
        orderBy, keyword, archived, offset,
      }) => ({
        url: DATA_SETS_PROFIT_CENTER_LIST,
        params: {
          ...(orderBy && { orderBy }),
          ...(keyword && { keyword }),
          ...(archived && { archived }),
          ...(offset && { offset }),
          limit: DATA_SET_PAGINATION_COUNT,
        },
      }),
      providesTags: (result) => (
        result?.data?.items?.length && result?.data?.items?.length > 0
          ? [...result.data.items.map(({ id }) => (
            { type: DATA_SETS_PROFIT_CENTER_LIST_TAG, id }))]
          : [DATA_SETS_PROFIT_CENTER_LIST_TAG]
      ),
    }),
    profitCenterSheetExample: builder.query<IBaseQueryPaginationResponse<void>, void>({
      query: () => ({
        url: DATA_SETS_PROFIT_CENTER_SHEET_EXAMPLE,
        responseHandler: async (response) => downloadFileHandler({
          filename: SHEET_EXAMPLE_FILES_NAME.profitCenter, response,
        }),
        cache: 'no-cache',
      }),
    }),
    ownOperationExample: builder.query<IBaseQueryPaginationResponse<void>, void>({
      query: () => ({
        url: DATA_SETS_OWN_OPERATION_SHEET_EXAMPLE,
        responseHandler: async (response) => downloadFileHandler({
          filename: SHEET_EXAMPLE_FILES_NAME.ownOperation, response,
        }),
        cache: 'no-cache',
      }),
    }),
    supplierExample: builder.query<IBaseQueryPaginationResponse<void>, void>({
      query: () => ({
        url: DATA_SETS_SUPPLIER_SHEET_EXAMPLE,
        responseHandler: async (response) => downloadFileHandler({
          filename: SHEET_EXAMPLE_FILES_NAME.suppliers, response,
        }),
        cache: 'no-cache',
      }),
    }),
    customerExample: builder.query<IBaseQueryPaginationResponse<void>, void>({
      query: () => ({
        url: DATA_SETS_CUSTOMER_SHEET_EXAMPLE,
        responseHandler: async (response) => downloadFileHandler({
          filename: SHEET_EXAMPLE_FILES_NAME.customers, response,
        }),
        cache: 'no-cache',
      }),
    }),
    productExample: builder.query<IBaseQueryPaginationResponse<void>, void>({
      query: () => ({
        url: DATA_SETS_PRODUCT_SHEET_EXAMPLE,
        responseHandler: async (response) => downloadFileHandler({
          filename: SHEET_EXAMPLE_FILES_NAME.products, response,
        }),
        cache: 'no-cache',
      }),
    }),
    profitCenterImportRecords: builder.mutation <IBaseQueryResponse<IDataSetsImportRecordsResponse>,
    IDataSetsImportRecordsRequest>({
      query: (data) => ({
        url: DATA_SETS_PROFIT_CENTER_IMPORT_RECORDS,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_SETS_PROFIT_CENTER_LIST_TAG],
    }),
    ownOperationImportRecords: builder.mutation <IBaseQueryResponse<IDataSetsImportRecordsResponse>,
    IDataSetsImportRecordsRequest>({
      query: (data) => ({
        url: DATA_SETS_OWN_OPERATION_IMPORT_RECORDS,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_SETS_OWN_OPERATION_LIST_TAG],
    }),
    supplierImportRecords: builder.mutation <IBaseQueryResponse<IDataSetsImportRecordsResponse>,
    IDataSetsImportRecordsRequest>({
      query: (data) => ({
        url: DATA_SETS_SUPPLIER_IMPORT_RECORDS,
        method: API_METHODS.post,
        body: data,
      }),
    }),
    customerImportRecords: builder.mutation <IBaseQueryResponse<IDataSetsImportRecordsResponse>,
    IDataSetsImportRecordsRequest>({
      query: (data) => ({
        url: DATA_SETS_CUSTOMER_IMPORT_RECORDS,
        method: API_METHODS.post,
        body: data,
      }),
    }),
    productImportRecords: builder.mutation <IBaseQueryResponse<IDataSetsImportRecordsResponse>,
    IDataSetsImportRecordsRequest>({
      query: (data) => ({
        url: DATA_SETS_PRODUCT_IMPORT_RECORDS,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_PRODUCTS_LIST_TAG],
    }),
    profitCenterArchive: builder.mutation<IBaseQueryResponse<IProfitCenterItem[]>, IDataSetsArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_PROFIT_CENTER_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: (result, error, { recordIds }) => [
        { type: DATA_SETS_PROFIT_CENTER_LIST_TAG, recordIds },
      ],
    }),
    ownOperationArchive: builder.mutation<IBaseQueryResponse<IOwnItem[]>, IDataSetsArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_OWN_OPERATION_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: (result, error, { recordIds }) => [
        { type: DATA_SETS_OWN_OPERATION_LIST_TAG, recordIds },
      ],
    }),
    supplierArchive: builder.mutation<IBaseQueryResponse<ISupplierItem[]>, IDataSetsArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_SUPPLIER_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_SUPPLIERS_LIST_TAG],
    }),
    customerArchive: builder.mutation<IBaseQueryResponse<ICustomerItem[]>, IDataSetsArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_CUSTOMER_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_CUSTOMERS_LIST_TAG],
    }),
    productArchive: builder.mutation<IBaseQueryResponse<IProductItem[]>, IDataSetsArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_PRODUCT_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_PRODUCTS_LIST_TAG],
    }),
    profitCenterDeArchive: builder.mutation<IBaseQueryResponse<IProfitCenterItem[]>, IDataSetsDeArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_PROFIT_CENTER_DE_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_SETS_PROFIT_CENTER_LIST_TAG],
    }),
    ownOperationDeArchive: builder.mutation<IBaseQueryResponse<IOwnItem[]>, IDataSetsDeArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_OWN_OPERATION_DE_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_SETS_OWN_OPERATION_LIST_TAG],
    }),
    supplierDeArchive: builder.mutation<IBaseQueryResponse<ISupplierItem[]>, IDataSetsDeArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_SUPPLIER_DE_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_SUPPLIERS_LIST_TAG],
    }),
    customerDeArchive: builder.mutation<IBaseQueryResponse<ICustomerItem[]>, IDataSetsDeArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_CUSTOMER_DE_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_CUSTOMERS_LIST_TAG],
    }),
    productDeArchive: builder.mutation<IBaseQueryResponse<IProductItem[]>, IDataSetsDeArchiveRequest>({
      query: (data) => ({
        url: DATA_SETS_PRODUCT_DE_ARCHIVE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [DATA_PRODUCTS_LIST_TAG],
    }),
    enum: builder.query<IEnumResponse, IEnumRequest>({
      query: ({ types }) => ({
        url: DATA_SETS_GET_ENUM,
        params: {
          types,
        },
      }),
    }),
    profitCenterCreate: builder.mutation <IBaseQueryResponse<IProfitCenterCreateResponse>,
    IProfitCenterRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_PROFIT_CENTER_CREATE,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_SETS_PROFIT_CENTER_LIST_TAG],
    }),
    ownOperationsCreate: builder.mutation <IBaseQueryResponse<IOwnItem>,
    IOperationsRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_OWN_OPERATION_CREATE,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_SETS_OWN_OPERATION_LIST_TAG],
    }),
    supplierCreate: builder.mutation <IBaseQueryResponse<ISupplierItem>,
    ISupplierRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_SUPPLIER_CREATE,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_SUPPLIERS_LIST_TAG],
    }),
    customerCreate: builder.mutation <IBaseQueryResponse<ICustomerItem>,
    ICustomerRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_CUSTOMER_CREATE,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_CUSTOMERS_LIST_TAG],
    }),
    productCreate: builder.mutation <IBaseQueryResponse<IProductItem>,
    IProductRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_PRODUCT_CREATE,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: [DATA_PRODUCTS_LIST_TAG],
    }),
    profitCenterRecord: builder.query<IBaseQueryResponse<IProfitCenterItem>, IDataSetsRecordsRequest>({
      query: ({ recordId }) => ({
        url: DATA_SETS_PROFIT_CENTER_RECORD.replace('{recordId}', `${recordId}`),
      }),
      providesTags: (result) => [{ type: DATA_SETS_PROFIT_CENTER_RECORD_TAG, id: result?.data.id }],
    }),
    ownOperationsRecord: builder.query<IBaseQueryResponse<IOwnItem>, IDataSetsRecordsRequest>({
      query: ({ recordId }) => ({
        url: DATA_SETS_OWN_OPERATION_RECORD.replace('{recordId}', `${recordId}`),
      }),
      providesTags: (result) => [{ type: DATA_SETS_OWN_OPERATION_RECORD_TAG, id: result?.data.id }],
    }),
    supplierRecord: builder.query<IBaseQueryResponse<ISupplierItem>, IDataSetsRecordsRequest>({
      query: ({ recordId }) => ({
        url: DATA_SETS_SUPPLIER_RECORD.replace('{recordId}', `${recordId}`),
      }),
      providesTags: (result) => [{ type: DATA_SETS_SUPPLIERS_RECORD_TAG, id: result?.data.id }],
    }),
    customerRecord: builder.query<IBaseQueryResponse<ICustomerItem>, IDataSetsRecordsRequest>({
      query: ({ recordId }) => ({
        url: DATA_SETS_CUSTOMER_RECORD.replace('{recordId}', `${recordId}`),
      }),
      providesTags: (result) => [{ type: DATA_SETS_CUSTOMERS_RECORD_TAG, id: result?.data.id }],
    }),
    productRecord: builder.query<IBaseQueryResponse<IProductItem>, IDataSetsRecordsRequest>({
      query: ({ recordId }) => ({
        url: DATA_SETS_PRODUCT_RECORD.replace('{recordId}', `${recordId}`),
      }),
      providesTags: (result) => [{ type: DATA_SETS_PRODUCTS_RECORD_TAG, id: result?.data.id }],
    }),
    profitCenterRecordUpdate: builder.mutation<IBaseQueryResponse<IProfitCenterCreateResponse>,
    IProfitCenterRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_PROFIT_CENTER_RECORD_UPDATE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: DATA_SETS_PROFIT_CENTER_RECORD_TAG, id },
        { type: DATA_SETS_PROFIT_CENTER_LIST_TAG, id },
      ],
    }),
    getNaceCodeTree: builder.query <INaceCodeTreeResponse, void>({
      query: () => ({
        url: DATA_SETS_NACE_CODES_TREE,
      }),
    }),
    ownOperationsRecordUpdate: builder.mutation<IBaseQueryResponse<IOwnItem>,
    IOperationsRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_OWN_OPERATION_RECORD_UPDATE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: DATA_SETS_OWN_OPERATION_RECORD_TAG, id },
        { type: DATA_SETS_OWN_OPERATION_LIST_TAG, id },
        { type: DATA_SETS_UNIQ_ORG_STRUCTURES_TAG },
        { type: DATA_SETS_UNIQ_GEO_STRUCTURES_TAG },
      ],
    }),
    supplierRecordUpdate: builder.mutation<IBaseQueryResponse<ISupplierItem>,
    ISupplierRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_SUPPLIER_RECORD_UPDATE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: DATA_SETS_SUPPLIERS_RECORD_TAG, id },
        { type: DATA_SUPPLIERS_LIST_TAG, id },
      ],
    }),
    customerRecordUpdate: builder.mutation<IBaseQueryResponse<ICustomerItem>,
    ICustomerRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_CUSTOMER_RECORD_UPDATE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: DATA_SETS_CUSTOMERS_RECORD_TAG, id },
        { type: DATA_CUSTOMERS_LIST_TAG, id },
      ],
    }),
    productRecordUpdate: builder.mutation<IBaseQueryResponse<IProductItem>,
    IProductRecordRequest>({
      query: (data) => ({
        url: DATA_SETS_PRODUCT_UPDATE,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: DATA_SETS_PRODUCTS_RECORD_TAG, id },
        { type: DATA_PRODUCTS_LIST_TAG, id },
      ],
    }),
    uniqOrgStructures: builder.query<IBaseQueryResponse<TUniqOrgStructuresResponse>, void>({
      query: () => ({
        url: DATA_SETS_UNIQ_ORG_STRUCTURES,
      }),
      providesTags: [DATA_SETS_UNIQ_ORG_STRUCTURES_TAG],
    }),
    uniqGeoStructures: builder.query<IBaseQueryResponse<TUniqGeoStructuresResponse>, void>({
      query: () => ({
        url: DATA_SETS_UNIQ_GEO_STRUCTURES,
      }),
      providesTags: [DATA_SETS_UNIQ_GEO_STRUCTURES_TAG],
    }),
    changeOrgStructures: builder.mutation<IBaseQueryResponse<TUniqOrgStructuresResponse>, IChangeOrgStructuresRequest>({
      query: (data) => ({
        url: DATA_SETS_CHANGE_ORG_STRUCTURE,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: () => [
        { type: DATA_SETS_OWN_OPERATION_LIST_TAG },
      ],
    }),
    changeGeoStructures: builder.mutation<IBaseQueryResponse<TUniqOrgStructuresResponse>, IChangeGeoStructuresRequest>({
      query: (data) => ({
        url: DATA_SETS_CHANGE_GEO_STRUCTURE,
        method: API_METHODS.post,
        body: data,
      }),
      invalidatesTags: () => [
        { type: DATA_SETS_OWN_OPERATION_LIST_TAG },
      ],
    }),
    uidNameTuples: builder.query<IUidNameTuplesResponse, IUidNameTuplesRequest>({
      query: ({ types }) => ({
        url: DATA_SETS_GET_UID_NAME_TUPLES,
        params: {
          types,
        },
      }),
    }),
  }),
});

export const {
  useOwnOperationListQuery,
  useLazyOwnOperationListQuery,
  useSupplierListQuery,
  useCustomerListQuery,
  useProductListQuery,
  useProfitCenterListQuery,
  useLazyProfitCenterSheetExampleQuery,
  useLazyOwnOperationExampleQuery,
  useLazySupplierExampleQuery,
  useLazyCustomerExampleQuery,
  useLazyProductExampleQuery,
  useProfitCenterImportRecordsMutation,
  useOwnOperationImportRecordsMutation,
  useSupplierImportRecordsMutation,
  useCustomerImportRecordsMutation,
  useProductImportRecordsMutation,
  useProfitCenterArchiveMutation,
  useProfitCenterDeArchiveMutation,
  useOwnOperationArchiveMutation,
  useOwnOperationDeArchiveMutation,
  useSupplierDeArchiveMutation,
  useCustomerArchiveMutation,
  useCustomerDeArchiveMutation,
  useEnumQuery,
  useProfitCenterCreateMutation,
  useProfitCenterRecordQuery,
  useOwnOperationsRecordQuery,
  useProfitCenterRecordUpdateMutation,
  useSupplierArchiveMutation,
  useGetNaceCodeTreeQuery,
  useProductArchiveMutation,
  useProductDeArchiveMutation,
  useUniqOrgStructuresQuery,
  useUniqGeoStructuresQuery,
  useLazyUniqOrgStructuresQuery,
  useLazyUniqGeoStructuresQuery,
  useChangeOrgStructuresMutation,
  useChangeGeoStructuresMutation,
  useOwnOperationsCreateMutation,
  useUidNameTuplesQuery,
  useOwnOperationsRecordUpdateMutation,
  useSupplierRecordQuery,
  useSupplierCreateMutation,
  useSupplierRecordUpdateMutation,
  useProductCreateMutation,
  useProductRecordQuery,
  useProductRecordUpdateMutation,
  useCustomerRecordQuery,
  useCustomerCreateMutation,
  useCustomerRecordUpdateMutation,
} = dataSetsApi;
