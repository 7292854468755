import Title from 'components/Title/Title';

import type IClientProfileLayout from './interfaces/IClientProfileLayout';

import styles from './scss/ClientProfileHeader.module.scss';

function ClientProfileHeader({ title }: IClientProfileLayout) {
  return (
    <div className={styles.root}>
      <Title
        text={title}
        className={styles.title}
      />
    </div>
  );
}

export default ClientProfileHeader;
