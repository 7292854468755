import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneField from 'components/PhoneField/PhoneField';
import { PHONE } from 'constants/formFields';

import type { ISetupPhoneModal } from '../interfaces/ISetupPhoneModal';

import styles from './sass/SetupPhoneModal.module.scss';

function SetupPhoneModal({
  control,
  setParsedCountry,
}: ISetupPhoneModal) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {t('mfaPhoneSetup.description')}
      <div className={styles.form}>
        <Controller
          control={control}
          name={PHONE}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <PhoneField
              name={name}
              value={value}
              label={t('common.phoneNumber')}
              error={error?.message}
              onChange={(valuePhone, { country }) => {
                setParsedCountry(country);
                onChange(valuePhone);
              }}
              required
              className={styles.phone}
            />
          )}
        />
      </div>
    </div>
  );
}

export default SetupPhoneModal;
