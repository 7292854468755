import TableRow from './TableRow';

import type ITableHead from './interfaces/ITableHead';

function TableHead({ className, children }: ITableHead) {
  return (
    <thead className={className}>
      <TableRow>
        {children}
      </TableRow>
    </thead>
  );
}

export default TableHead;
