import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';

import DeactivatedUserView from './DeactivatedUserView';

import type { ReactElement } from 'react';

function DeactivatedUser(): ReactElement {
  const navigate = useNavigate();
  const onRecoverPasswordSuccessSubmit = () => {
    navigate(AppRoutes.login);
  };

  return (
    <DeactivatedUserView onRecoverPasswordSuccessSubmit={onRecoverPasswordSuccessSubmit} />
  );
}

export default DeactivatedUser;
