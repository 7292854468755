import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import Panel from 'components/Panel/Panel';
import Separator from 'components/Separator/Separator';
import Title from 'components/Title/Title';

import PermissionsForm from './components/PermissionsForm/PermissionsForm';

import type { IPermissionsView } from './interfaces/IPermissionsView';

import styles from './sass/PermissionsView.module.scss';

function PermissionsView({
  control, onPermissionsSubmit, isConfirmationModalOpen, onHandleCloseConfirmation, onPrevClick,
  onDiscardChangesConfirm, pillars, isCreateFlow, onHandleClose, isDisabled,
}: IPermissionsView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <Title text={t('permissions.subTitle')} className={styles.subTitle} />
        <div className={styles.description}>{t('permissions.description')}</div>
        <Separator />
        <PermissionsForm
          control={control}
          onCreateNewUserSubmit={onPermissionsSubmit}
          onHandleClose={onHandleClose}
          onPrevClick={onPrevClick}
          pillars={pillars}
          isCreateFlow={isCreateFlow}
          isDisabled={isDisabled}
        />

      </Panel>
      <Modal
        open={isConfirmationModalOpen}
        title={t('createNewUser.cancelModalTitle')}
        description={t('clients.cancelModalText')}
        confirmTitle={t('common.discardChanges')}
        closeTitle={t('common.keepEditing')}
        confirmVariant="error"
        onClose={() => onHandleCloseConfirmation(false)}
        onReject={() => onHandleCloseConfirmation(false)}
        onConfirm={onDiscardChangesConfirm}
      />
    </div>
  );
}

export default PermissionsView;
