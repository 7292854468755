import { API_METHODS } from 'constants/general';
import { MFA_ENDPOINTS } from 'constants/interfaces';
import {
  LOGIN_INFO, USER_DEACTIVATE_SECURITY, USER_LOCALIZATION, USER_ME,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';
import { VERIFY_TOKEN_TAG } from 'store/tags/auth';
import USER_ME_TAG, { LOGIN_INFO_TAG } from 'store/tags/user';

import { setAuthState } from '../../auth/slice';
import { setCompanyTranslations } from '../../translates/slice';

import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type IUpdateUserRequest from '../interfaces/IUpdateUserRequest';
import type IUser from '../interfaces/IUser';
import type IUserInfo from '../interfaces/IUserInfo';
import type IUserSecurityDeactivationRequest from '../interfaces/IUserSecurityDeactivationRequest';
import type IUserSecurityDeactivationResponse from '../interfaces/IUserSecurityDeactivationResponse';

export const userApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    userMe: builder.query<IBaseQueryResponse<IUser>, void>({
      query: () => ({
        url: USER_ME,
      }),
      providesTags: [USER_ME_TAG],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          if (response.data.data.company) {
            dispatch(setCompanyTranslations(response.data.data.company));
          }
        } catch (e) {
          // error showed from rtk-query
        }
      },
    }),
    updateUserMe: builder.mutation<IBaseQueryResponse<IUser>, IUpdateUserRequest>({
      query: (data) => ({
        url: USER_ME,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [USER_ME_TAG],
    }),
    updateUserLocalization: builder.mutation<void, { language:string }>({
      query: (data) => ({
        url: USER_LOCALIZATION,
        method: API_METHODS.patch,
        body: data,
      }),
      invalidatesTags: [USER_ME_TAG],
    }),
    [MFA_ENDPOINTS.loginInfo]: builder.query<IBaseQueryResponse<IUserInfo>, { token?: string }>({
      query: () => ({
        url: LOGIN_INFO,
      }),
      providesTags: [LOGIN_INFO_TAG],
      onQueryStarted: async ({ token }, { dispatch }) => {
        if (token) {
          dispatch(
            setAuthState({
              verifyToken: token,
            }),
          );
        }
      },
    }),
    deactivateSecurity: builder.mutation<IBaseQueryResponse<IUserSecurityDeactivationResponse>,
    IUserSecurityDeactivationRequest>({
      query: ({ ...rest }) => ({
        url: USER_DEACTIVATE_SECURITY,
        method: API_METHODS.patch,
        body: rest,
      }),
      invalidatesTags: [USER_ME_TAG, VERIFY_TOKEN_TAG],
    }),
  }),
});

export const {
  useUserMeQuery,
  useLazyUserMeQuery,
  useUpdateUserMeMutation,
  useLoginInfoQuery,
  useDeactivateSecurityMutation,
  useUpdateUserLocalizationMutation,
} = userApi;
