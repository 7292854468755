import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BasketIcon } from 'assets/icons/basket.svg';
import { ReactComponent as CheckFolderIcon } from 'assets/icons/checkFolder.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';

import IconButton from '../IconButton/IconButton';
import Tooltip from '../Tooltip/Tooltip';

import type IDataSetsItemButtons from './interfaces/IDataSetsItemButtons';

import styles from './sass/DataSetsItemButtons.module.scss';

function DataSetsItemButtons({
  onEditRecord, onMoveToArchive, onDeArchive, id, archived,
}: IDataSetsItemButtons) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Tooltip hint={t('common.editRecord')}>
        <IconButton
          icon={<PenIcon />}
          onClick={() => onEditRecord(id)}
        />
      </Tooltip>
      {archived ? (
        <Tooltip className={styles.tooltip} hint={t('common.restoreRecord')}>
          <IconButton
            icon={<CheckFolderIcon />}
            onClick={() => onDeArchive(id)}
          />
        </Tooltip>
      ) : (
        <Tooltip className={styles.tooltip} hint={t('common.archiveRecord')}>
          <IconButton
            icon={<BasketIcon />}
            onClick={() => onMoveToArchive(id)}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default DataSetsItemButtons;
