import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AUTHENTICATION_GOOGLE, AUTHENTICATION_SETUP } from 'constants/formFields';
import { authenticationSetupSchema } from 'utils/validators';

import type { TAuthenticationSetupSchema } from 'utils/validators';

const useAuthenticationSetupForm = () => {
  const {
    control,
    handleSubmit,
  } = useForm<TAuthenticationSetupSchema>({
    mode: 'onChange',
    resolver: yupResolver(authenticationSetupSchema),
    defaultValues: {
      [AUTHENTICATION_SETUP]: AUTHENTICATION_GOOGLE,
    },
  });

  return {
    control,
    handleSubmit,
  };
};

export default useAuthenticationSetupForm;
