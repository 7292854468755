import type { IPresignedData, IPresignedFields } from 'store/slices/files/interfaces/IPresignedFileResponse';

export const getCountries = (lang = 'en') => {
  const A = 65;
  const Z = 90;
  const countryName = new Intl.DisplayNames([lang], { type: 'region' });
  const countries = [];
  for (let i = A; i <= Z; i += 1) {
    for (let j = A; j <= Z; j += 1) {
      const code = String.fromCharCode(i) + String.fromCharCode(j);
      const name = countryName.of(code) || '';
      if (code !== name) {
        countries.push({
          label: name,
          value: code,
        });
      }
    }
  }
  return countries;
};

export const uploadFileToS3 = (file: File, presignedData: IPresignedData) => {
  const { url, fields } = presignedData;

  const formData = new FormData();

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof IPresignedFields]);
  });

  formData.append('Content-Type', file.type);
  formData.append('file', file);

  return fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error) => error);
};

export const getKeyByValue = <T extends Record<string, string>>(
  value: string | undefined,
  object: T,
): keyof T | undefined => (
    Object.keys(object).find((key) => object[key] === value)
  );
