import { useTranslation } from 'react-i18next';
import Title from 'components/Title/Title';

import type ICreateClientHeader from './interfaces/ICreateClientHeader';

import styles from './scss/CreateClientHeader.module.scss';

function CreateClientHeader({ isCreate }: ICreateClientHeader) {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <Title
        text={isCreate ? t('clients.createClient') : t('clients.editClient')}
        className={styles.title}
      />
    </div>
  );
}

export default CreateClientHeader;
