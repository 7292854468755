export default {
  title: 'Create New User',
  editTitle: 'Edit User Info',
  subTitle: 'User Info',
  menu: {
    userInfo: 'User Info',
    permissions: 'Permissions',
  },
  cancelModalTitle: 'Discard changes',
  cancelModalText: 'There are unsaved changes. Are you sure you want to leave the page? All entered '
      + 'information will be lost.',
};
