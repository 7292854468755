import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashBox.svg';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import DeleteUserModal from 'components/DeleteUserModal/DeleteUserModal';
import IconButton from 'components/IconButton/IconButton';
import { USER_STATUS } from 'constants/interfaces';
import { USER_STATUSES } from 'constants/user';
import useUserStatusChange from 'hooks/useUserStatusChange';

import type IAdminItem from './interfaces/IAdminItem';

import styles from './scss/AdminItem.module.scss';

function AdminItem({
  id, firstName, lastName, email, latestDeactivation, status = USER_STATUS.draft,
}: IAdminItem) {
  const { t } = useTranslation();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const {
    isNewUser,
    isDeactivationModalOpen,
    isDeleteModalOpen,
    onToggleDeactivationModal,
    onToggleDeleteModal,
    onChangeUserStatus,
    onUserDeactivate,
    onUserDelete,
  } = useUserStatusChange({
    userId: id as number,
    status,
    email,
  });

  return (
    <div className={styles.item}>
      <div className={styles.info}>
        <p className={styles.name}>{`${firstName} ${lastName}`}</p>
        <p className={styles.email}>{email}</p>
      </div>
      <div className={styles.actions}>
        <div className={styles.status}>
          <Badge
            status={USER_STATUSES[status].label}
            color={USER_STATUSES[status].color}
          />
          {status === USER_STATUS.deactivated ? (
            <IconButton
              className={styles.icon}
              size="small"
              icon={<InfoIcon color="#F89603" />}
              onClick={() => setIsInfoModalOpen(true)}
            />
          ) : null}
        </div>
        <div className={styles.wrapper}>
          <Button
            title={USER_STATUSES[status].actionLabel}
            startIcon={USER_STATUSES[status].actionIcon}
            variant="ghost"
            onClick={onChangeUserStatus}
          />
          {isNewUser ? (
            <Button
              title={t('common.delete')}
              startIcon={<TrashIcon />}
              variant="ghost"
              onClick={onToggleDeleteModal}
            />
          ) : null}
        </div>
      </div>
      <DeactivationModal
        open={isDeactivationModalOpen}
        text={t('common.userActivity')}
        onReject={onToggleDeactivationModal}
        onSubmit={onUserDeactivate}
      />
      <DeleteUserModal
        open={isDeleteModalOpen}
        onReject={onToggleDeleteModal}
        onClose={onToggleDeleteModal}
        onConfirm={onUserDelete}
      />
      <DeactivationInfoModal
        open={isInfoModalOpen}
        deactivationInfo={latestDeactivation}
        onClose={() => setIsInfoModalOpen(false)}
      />
    </div>
  );
}

export default AdminItem;
