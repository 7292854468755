import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import Title from 'components/Title/Title';
import useBreadcrumbs from 'hooks/useBreadcrumbs';

import styles from './scss/SettingsLayout.module.scss';

function SettingsLayout() {
  const { t } = useTranslation();

  const { breadcrumbs } = useBreadcrumbs({ mathCrumbs: [] });

  return (
    <div className={styles.root}>
      <BreadcrumbsNav breadcrumbs={breadcrumbs} />
      <Title
        text={t('pillarsAndModules.settings/ui-configuration')}
        className={styles.title}
      />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SettingsLayout;
