import { useTranslation } from 'react-i18next';
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import Button from 'components/Button/Button';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import UserStatusBadge from 'components/UserStatusBadge/UserStatusBadge';
import { STATUS, USER_STATUS } from 'constants/interfaces';

import type IUserSecuritySection from '../../interfaces/IUserSecuritySection';

import styles from './scss/UserSecuritySection.module.scss';

function UserSecuritySection({
  user, onResetPassword, onResetMfa, isUserInfoPage, isActionsVisible,
}: IUserSecuritySection) {
  const { t } = useTranslation();

  const { status } = user || {};

  const isResetMfaVisible = !isUserInfoPage;

  const isResetPasswordVisible = status !== USER_STATUS.deactivated
      && user?.status !== USER_STATUS.draft && status !== USER_STATUS.invited;

  const isPasswordMaskVisible = user?.status !== USER_STATUS.draft && status !== USER_STATUS.invited;

  const mfaAppTitle = user?.profile?.mfaAppEnabled && STATUS.enabled === user.profile.mfaAppEnabled
    ? t('common.configured') : t('common.notConfigured');
  const mfaSmsTitle = user?.profile?.mfaSmsEnabled && STATUS.enabled === user.profile.mfaSmsEnabled
    ? t('common.configured') : t('common.notConfigured');

  const isMfaAppEnabled = user?.company?.security?.mfaApp === STATUS.enabled;
  const isMfaSmsEnabled = user?.company?.security?.mfaSms === STATUS.enabled;

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <p className={styles.itemTitle}>
          {t('editUserInfo.password')}
        </p>
        <div className={styles.itemContent}>
          <div className={styles.status}>
            <p>{isPasswordMaskVisible ? '*************' : '-'}</p>
          </div>
          {isResetPasswordVisible && isActionsVisible ? (
            <Button
              title={t('editUserInfo.resetPassword')}
              variant="ghost"
              startIcon={<ResetIcon />}
              onClick={onResetPassword}
            />
          ) : null}
        </div>
      </div>
      { isMfaSmsEnabled ? (
        <div className={styles.item}>
          <p className={styles.itemTitle}>
            {t('editUserInfo.mfaSms')}
          </p>
          <div className={styles.itemContent}>
            <UserStatusBadge
              modalTitle={t('editUserInfo.resetMfaDetails')}
              showInfoButton={STATUS.disabled === user?.profile?.mfaSmsEnabled}
              deactivationInfo={user?.latestMfaSmsDeactivation}
            >
              <StatusBadge
                status={user?.profile?.mfaSmsEnabled || STATUS.disabled}
                title={mfaSmsTitle}
              />
            </UserStatusBadge>
            {isResetMfaVisible && STATUS.enabled === user?.profile?.mfaSmsEnabled && isActionsVisible ? (
              <Button
                title={t('editUserInfo.resetMfa')}
                variant="ghost"
                startIcon={<ResetIcon />}
                onClick={() => onResetMfa(true)}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      { isMfaAppEnabled ? (
        <div className={styles.item}>
          <p className={styles.itemTitle}>
            {t('editUserInfo.mfaAuthentication')}
          </p>
          <div className={styles.itemContent}>
            <UserStatusBadge
              modalTitle={t('editUserInfo.resetMfaDetails')}
              showInfoButton={STATUS.disabled === user?.profile?.mfaAppEnabled}
              deactivationInfo={user?.latestMfaAppDeactivation}
            >
              <StatusBadge
                status={user?.profile?.mfaAppEnabled || STATUS.disabled}
                title={mfaAppTitle}
              />
            </UserStatusBadge>
            {STATUS.enabled === user?.profile?.mfaAppEnabled && isActionsVisible && !isUserInfoPage ? (
              <Button
                title={t('editUserInfo.resetMfa')}
                variant="ghost"
                startIcon={<ResetIcon />}
                onClick={() => onResetMfa()}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default UserSecuritySection;
