import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import AppRoutes from 'constants/AppRoutes';
import {
  ADMINS, EMAIL, FIRST_NAME, LAST_NAME, STATUS,
} from 'constants/formFields';
import { USER_STATUS } from 'constants/interfaces';
import useCompanyDraft from 'hooks/useCompanyDraft';
import useCreateClient from 'hooks/useCreateClient';
import { useAppSelector } from 'store/hooks/useApp';
import { useGetCompanyDetailsQuery, useUpdateCompanyAdminsMutation } from 'store/slices/companies/apis/companiesApi';
import { createFlowCompanyIdSelector, selectCompanyAdmins } from 'store/slices/companies/selectors';
import { companyAdminSchema } from 'utils/validators';

import CompanyAdminView from './CompanyAdminView';

import type { TUserStatus } from 'constants/interfaces';
import type IHandleFormSubmit from 'hooks/interfaces/IHandleFormSubmit';
import type { TCompanyAdminSchema } from 'utils/validators';

function CompanyAdmin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newCompanyId = useAppSelector(createFlowCompanyIdSelector);
  const { isCreateFlow, clientId } = useCreateClient();

  const currentCompanyId = newCompanyId && isCreateFlow ? newCompanyId : +clientId;

  const fetchQuery = newCompanyId || !isCreateFlow ? {
    companyId: currentCompanyId,
  } : skipToken;

  const { admins } = useGetCompanyDetailsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, admins: selectCompanyAdmins(result.data) }),
    },
  );

  const [updateCompanyAdmins] = useUpdateCompanyAdminsMutation();

  const {
    control,
    handleSubmit: onSubmit,
    formState: { isValid, isSubmitted, isDirty },
    reset,
  } = useForm<TCompanyAdminSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(companyAdminSchema),
    defaultValues: {
      [ADMINS]: [{
        [FIRST_NAME]: '',
        [LAST_NAME]: '',
        [EMAIL]: '',
      }],
    },
  });

  const {
    fields: adminsArray,
    append,
    remove: onRemoveAdminByForm,
  } = useFieldArray({
    control,
    name: ADMINS,
  });

  useEffect(() => {
    if (admins.length) {
      reset({
        [ADMINS]: admins,
      });
    }
  }, [reset, admins]);

  const disableButton = !isValid && isSubmitted;

  const handleFormSubmit: IHandleFormSubmit<TCompanyAdminSchema> = ({ values, isDraftSave }) => {
    const isAllowedStatuses = (adminStatus?: TUserStatus) => ([USER_STATUS.active, USER_STATUS.deactivated]
      .some((allowedStatus) => allowedStatus === adminStatus));

    const newAdmins = values.admins.map((admin) => ({
      ...admin,
      [STATUS]: isAllowedStatuses(admin[STATUS]) ? admin[STATUS] : undefined,
    }));

    const data = {
      companyId: currentCompanyId,
      companyAdminUsers: newAdmins,
    };

    updateCompanyAdmins(data).unwrap()
      .then(() => {
        reset(undefined, { keepValues: true });
        if (isDraftSave) {
          toast.success(t('notifications.clientCreatedInDraft'));
        } else if (isCreateFlow) {
          navigate(generatePath(AppRoutes.uiConfiguration, { clientId }));
        }
      })
      .catch(() => {});
  };

  const { onDraftSave } = useCompanyDraft<TCompanyAdminSchema>({ handleFormSubmit, onSubmit });

  const onHandleNextClick = onSubmit((values) => {
    handleFormSubmit({ values });
  });

  const onHandlePrevClick = () => {
    navigate(generatePath(AppRoutes.multiLingual, { clientId }));
  };

  const onAddAdmin = () => {
    append({
      [FIRST_NAME]: '', [LAST_NAME]: '', [EMAIL]: '',
    });
  };

  return (
    <CompanyAdminView
      control={control}
      adminsArray={adminsArray}
      isCreatePage={isCreateFlow}
      isDisabledSave={disableButton}
      onAddAdmin={onAddAdmin}
      onRemoveAdmin={onRemoveAdminByForm}
      onPrevClick={onHandlePrevClick}
      onSave={onHandleNextClick}
      isDirty={isDirty}
      onDraftSave={onDraftSave}
    />
  );
}

export default CompanyAdmin;
