import { ReactComponent as AcrossIcon } from 'assets/icons/across.svg';
import { ReactComponent as CheckFolderIcon } from 'assets/icons/checkFolder.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CrossFolderIcon } from 'assets/icons/crossFolder.svg';

import i18n from '../i18n';
import { COMPANY_STATUS } from './interfaces';

import type { ReactNode } from 'react';

type TCompanyActionStatusItem = {
  actionIcon: ReactNode,
  hintText: string,
};

type TCompanyStatusItem = {
  label: string,
  color: string,
  textColor?: string,
  actionIcon: ReactNode,
  actionLabel: string,
};

export const COMPANY_HEAD_CELLS = [
  {
    id: 'name',
    label: 'clients.companyName',
    withSort: true,
  },
  {
    id: 'createdAt',
    label: 'clients.createdDate',
    withSort: true,
  },
  {
    id: 'status',
    label: 'clients.status',
    withSort: false,
  },
  {
    id: 'companyAdminUsers',
    label: 'clients.companyAdmin',
    withSort: false,
  },
  {
    id: 'actions',
    label: 'clients.actions',
    withSort: false,
  },
];

export const COMPANY_ACTION_STATUSES: Record<string, TCompanyActionStatusItem> = {
  [COMPANY_STATUS.draft]: {
    actionIcon: <CheckFolderIcon />,
    hintText: i18n.t('clients.activateClient'),
  },
  [COMPANY_STATUS.active]: {
    actionIcon: <CrossFolderIcon />,
    hintText: i18n.t('clients.deactivateClient'),
  },
  [COMPANY_STATUS.deactivated]: {
    actionIcon: <CheckFolderIcon />,
    hintText: i18n.t('clients.activateClient'),
  },
};

export const COMPANY_STATUSES: Record<string, TCompanyStatusItem> = {
  [COMPANY_STATUS.draft]: {
    label: i18n.t('common.draft'),
    color: '#DBDDDE',
    textColor: '#5A5F60',
    actionIcon: <CheckmarkIcon />,
    actionLabel: i18n.t('common.activateInvite'),
  },
  [COMPANY_STATUS.active]: {
    label: i18n.t('common.active'),
    color: '#7A9A01',
    actionIcon: <AcrossIcon />,
    actionLabel: i18n.t('common.deactivate'),
  },
  [COMPANY_STATUS.deactivated]: {
    label: i18n.t('common.deactivated'),
    color: '#DC2626',
    actionIcon: <CheckmarkIcon />,
    actionLabel: i18n.t('common.activate'),
  },
};
