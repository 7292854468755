export default {
  title: 'Edit User Info',
  subtitle: 'Security',
  password: 'Password',
  mfaSms: 'MFA SMS',
  mfaAuthentication: 'MFA Auth app',
  resetPassword: 'Send Reset Password Link',
  resetMfa: 'Reset MFA',
  resetMfaReason: 'Specify the reason for resetting MFA',
  resetMfaDetails: 'MFA Reset details',
};
