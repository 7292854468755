import { API_METHODS } from 'constants/general';
import {
  COMPANY_ADMIN_COMPANIES_DETAILS, COMPANY_ADMIN_COMPANIES_UI_CONFIGURATION,
  COMPANY_ADMIN_USERS_ACTIVATE,
  COMPANY_ADMIN_USERS_DEACTIVATE,
  COMPANY_ADMIN_USERS_DELETE,
  COMPANY_ADMIN_USERS_LIST,
  COMPANY_ADMIN_USERS_PERMISSIONS,
  COMPANY_ADMIN_USERS_UPDATE_PERMISSIONS,
  COMPANY_ADMIN_USERS_USER_CREATE,
  COMPANY_ADMIN_USERS_USER_DETAILS,
  COMPANY_ADMIN_USERS_USER_UPDATE,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';
import {
  COMPANY_ADMIN_COMPANIES_DETAILS_TAG,
  COMPANY_ADMIN_USERS_LIST_TAG,
  COMPANY_ADMIN_USERS_PERMISSIONS_TAG,
  COMPANY_ADMIN_USERS_USER_DETAIL_TAG,
} from 'store/tags/companyAdminUsers';
import USER_ME_TAG from 'store/tags/user';

import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type ICompany from '../../companies/interfaces/ICompany';
import type IUser from '../../user/interfaces/IUser';
import type ICompanyUserActivateRequest from '../interfaces/ICompanyUserActivateRequest';
import type ICompanyUserDeactivationRequest from '../interfaces/ICompanyUserDeactivationRequest';
import type ICompanyUsersListRequest from '../interfaces/ICompanyUsersListRequest';
import type ICompanyUsersListResponse from '../interfaces/ICompanyUsersListResponse';
import type IUpdateCompanyUiConfigurationRequest from '../interfaces/IUpdateCompanyUiConfigurationRequest';
import type IUpdateUserPermissionsRequest from '../interfaces/IUpdateUserPermissionsRequest';
import type IUserCreateRequest from '../interfaces/IUserCreateRequest';
import type IUserDetailsRequest from '../interfaces/IUserDetailsRequest';
import type IUserPermissions from '../interfaces/IUserPermissions';
import type IUserUpdateDetailsRequest from '../interfaces/IUserUpdateDetailsRequest';

export const companyAdminUsersApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    companyAdminUsersList: builder.query<IBaseQueryResponse<ICompanyUsersListResponse>, ICompanyUsersListRequest>({
      query: ({ filterByStatus, search, orderBy }) => ({
        url: COMPANY_ADMIN_USERS_LIST,
        params: {
          ...(filterByStatus && { status: filterByStatus }),
          ...(search && { keyword: search }),
          ...(orderBy && { orderBy }),
        },
      }),
      providesTags: (result) => (
        result
          ? [...result.data.items.map(({ id }) => (
            { type: COMPANY_ADMIN_USERS_LIST_TAG, id })), COMPANY_ADMIN_USERS_LIST_TAG]
          : [COMPANY_ADMIN_USERS_LIST_TAG]
      ),
    }),
    userDetails: builder.query<IBaseQueryResponse<IUser>, IUserDetailsRequest>({
      query: ({ userId }) => ({
        url: COMPANY_ADMIN_USERS_USER_DETAILS.replace('{userId}', `${userId}`),
      }),
      providesTags: (result) => [{ type: COMPANY_ADMIN_USERS_USER_DETAIL_TAG, id: result?.data.id }],
    }),
    userPermissions: builder.query<IBaseQueryResponse<IUserPermissions>, { userId: string }>({
      query: ({ userId }) => ({
        url: COMPANY_ADMIN_USERS_PERMISSIONS.replace('{userId}', `${userId}`),
      }),
      providesTags: [COMPANY_ADMIN_USERS_PERMISSIONS_TAG],
    }),
    activateCompanyAdminUser: builder.mutation<void, ICompanyUserActivateRequest>({
      query: ({ userId }) => ({
        url: COMPANY_ADMIN_USERS_ACTIVATE.replace('{userId}', `${userId}`),
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: COMPANY_ADMIN_USERS_USER_DETAIL_TAG, id: userId },
        { type: COMPANY_ADMIN_USERS_LIST_TAG, id: userId },
      ],
    }),
    deactivateCompanyAdminUser: builder.mutation<void, ICompanyUserDeactivationRequest>({
      query: ({ userId, reason }) => ({
        url: COMPANY_ADMIN_USERS_DEACTIVATE.replace('{userId}', `${userId}`),
        method: 'DELETE',
        body: {
          reason,
        },
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: COMPANY_ADMIN_USERS_USER_DETAIL_TAG, id: userId },
        { type: COMPANY_ADMIN_USERS_LIST_TAG, id: userId },
      ],
    }),
    deleteCompanyAdminUser: builder.mutation<void, { userId: number }>({
      query: ({ userId }) => ({
        url: COMPANY_ADMIN_USERS_DELETE.replace('{userId}', `${userId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: COMPANY_ADMIN_USERS_USER_DETAIL_TAG, id: userId },
        { type: COMPANY_ADMIN_USERS_LIST_TAG, id: userId },
      ],
    }),
    updateUserDetails: builder.mutation<IBaseQueryResponse<IUser>, IUserUpdateDetailsRequest>({
      query: ({ userId, data }) => ({
        url: COMPANY_ADMIN_USERS_USER_UPDATE.replace('{userId}', `${userId}`),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: COMPANY_ADMIN_USERS_USER_DETAIL_TAG, id: userId },
        { type: COMPANY_ADMIN_USERS_LIST_TAG, id: userId },
        { type: COMPANY_ADMIN_USERS_PERMISSIONS_TAG },
      ],
    }),
    createUserDetails: builder.mutation<IBaseQueryResponse<IUser>, IUserCreateRequest>({
      query: ({ data }) => ({
        url: COMPANY_ADMIN_USERS_USER_CREATE,
        method: 'POST',
        body: data,
      }),
    }),
    updateUserPermissions: builder.mutation<IBaseQueryResponse<IUserPermissions>, IUpdateUserPermissionsRequest>({
      query: ({ userId, data }) => ({
        url: COMPANY_ADMIN_USERS_UPDATE_PERMISSIONS.replace('{userId}', `${userId}`),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: COMPANY_ADMIN_USERS_USER_DETAIL_TAG, id: userId },
        { type: COMPANY_ADMIN_USERS_PERMISSIONS_TAG },
      ],
    }),
    companyAbilitiesDetails: builder.query<IBaseQueryResponse<ICompany>, void>({
      query: () => ({
        url: COMPANY_ADMIN_COMPANIES_DETAILS,
      }),
      providesTags: [COMPANY_ADMIN_COMPANIES_DETAILS_TAG],
    }),
    updateCompanyUiConfiguration:
        builder.mutation<IBaseQueryResponse<ICompany>, IUpdateCompanyUiConfigurationRequest>({
          query: (data) => ({
            url: COMPANY_ADMIN_COMPANIES_UI_CONFIGURATION,
            method: API_METHODS.patch,
            body: data,
          }),
          invalidatesTags: [COMPANY_ADMIN_COMPANIES_DETAILS_TAG, USER_ME_TAG],
        }),
  }),
});

export const {
  useCompanyAdminUsersListQuery,
  useActivateCompanyAdminUserMutation,
  useDeactivateCompanyAdminUserMutation,
  useDeleteCompanyAdminUserMutation,
  useUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useCreateUserDetailsMutation,
  useUserPermissionsQuery,
  useUpdateUserPermissionsMutation,
  useCompanyAbilitiesDetailsQuery,
  useUpdateCompanyUiConfigurationMutation,
} = companyAdminUsersApi;
