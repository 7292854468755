import { useParams } from 'react-router-dom';
import { AUTHENTICATOR_SETUP_MODAL_STEPS } from 'constants/interfaces';

import AuthenticationSetup from './AuthenticationSetup';
import DownloadApp from './DownloadApp';
import QRCode from './QRCode';
import SecretCode from './SecretCode';
import VerificationCode from './VerificationCode';

import type { TAuthenticatorSetupModalSteps } from 'constants/interfaces';
import type { ISetupModalContent } from '../interfaces/ISetupModalContent';

function SetupModalContent({ control, setIsSecretCodeVisible, currentStep }: ISetupModalContent) {
  const { step = AUTHENTICATOR_SETUP_MODAL_STEPS.downloadApp } = useParams<{ step: TAuthenticatorSetupModalSteps }>();

  const stepComponents = {
    [AUTHENTICATOR_SETUP_MODAL_STEPS.qrCode]: <QRCode setIsSecretCodeVisible={setIsSecretCodeVisible} />,
    [AUTHENTICATOR_SETUP_MODAL_STEPS.secretCode]: <SecretCode />,
    [AUTHENTICATOR_SETUP_MODAL_STEPS.verificationCode]: <VerificationCode control={control} />,
    [AUTHENTICATOR_SETUP_MODAL_STEPS.downloadApp]: <DownloadApp />,
    [AUTHENTICATOR_SETUP_MODAL_STEPS.authenticationSetup]: <AuthenticationSetup control={currentStep?.control} />,
    [AUTHENTICATOR_SETUP_MODAL_STEPS.phone]: null,
    [AUTHENTICATOR_SETUP_MODAL_STEPS.smsCode]: null,
  };

  return stepComponents[step]
    || stepComponents[AUTHENTICATOR_SETUP_MODAL_STEPS.downloadApp];
}

export default SetupModalContent;
