import type { IPaginationData, IPaginationForceRefetch, IPaginationSerializeQueryArgs } from './interfaces/IStoreHelpers';

export const paginationMerge = <T>(
  currentData: IPaginationData<T>, newData: IPaginationData<T>,
) => {
  const mergedData = currentData;
  if (currentData.data.meta.offset === newData.data.meta.offset) {
    return currentData;
  }
  // clear old cache if we are on the first page
  if (newData?.data.meta.offset === 0) {
    return newData;
  }

  mergedData.data.items.push(...newData.data.items);
  mergedData.data.meta = {
    ...mergedData.data.meta,
    ...newData.data.meta,
  };

  return mergedData;
};

export const paginationSerializeQueryArgs = ({ endpointName, queryArgs }: IPaginationSerializeQueryArgs) => {
  const { offset, ...other } = queryArgs;
  return `${endpointName}(${JSON.stringify(other)})`;
};

export const paginationForceRefetch = ({ currentArg, previousArg }: IPaginationForceRefetch) => (
  currentArg?.offset !== previousArg?.offset
);
