import { Controller } from 'react-hook-form';
import Checkbox from 'components/Checkbox/Checkbox';
import { LANGUAGES } from 'constants/formFields';
import { SUPPORT_LANGUAGES } from 'constants/languages';

import type IMultilingualForm from '../../interfaces/IMultilingualForm';

import styles from './scss/MultilingualForm.module.scss';

function MultilingualForm({ control }: IMultilingualForm) {
  return (
    <div className={styles.root}>
      <Controller
        control={control}
        render={({ field: { onChange, value: formValue } }) => (
          <>
            {Object.values(SUPPORT_LANGUAGES).map(({
              label, value, icon, disabled,
            }) => (
              <Checkbox
                key={value}
                name={value}
                checked={formValue.includes(value)}
                value={value}
                disabled={disabled}
                onChange={(event) => {
                  onChange(event.target.checked
                    ? [...formValue, value]
                    : formValue.filter((lang) => lang !== value));
                }}
                label={(
                  <div className={styles.label}>
                    <span>{icon}</span>
                    <span>{label}</span>
                  </div>
                )}
              />
            ))}
          </>
        )}
        name={LANGUAGES}
      />
    </div>
  );
}

export default MultilingualForm;
