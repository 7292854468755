export default {
  userInfoSection: {
    title: 'User Info',
    fullName: 'Full Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    role: 'Role',
    status: 'Status',
  },
  securitySection: {
    title: 'Security',
    password: 'Password',
    sso: 'SSO',
    sms: 'MFA SMS',
    mfaAuthentication: 'MFA Authentication',
    resetPassword: 'Reset Password',
  },
  permissionsSection: {
    title: 'Permissions',
  },
};
