import { createSlice } from '@reduxjs/toolkit';
import { THEME } from 'constants/interfaces';

import type ITheme from './interfaces/ITheme';

export const initialState: ITheme = {
  theme: THEME.light,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => ({ theme: state.theme === THEME.light ? THEME.dark : THEME.light }),
  },
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
