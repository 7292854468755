import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Accordion from 'components/Accordion/Accordion';
import Select from 'components/Select/Select';
import Toggle from 'components/Toggle/Toggle';
import {
  ACCESS, CAN_VIEW, PILLAR_MODULES, PILLARS,
} from 'constants/formFields';
import { ACCESS_TYPES } from 'constants/general';

import type IPillalCard from '../../interfaces/IPillalCard';

import styles from './scss/ModuleManagementForm.module.scss';

function PillarCard({
  control, pillarName, pillarIndex, pillarModules, onResetPillarModules,
}: IPillalCard) {
  const { t } = useTranslation();
  const isPillarActive = useWatch({ control, name: `${PILLARS}.${pillarIndex}.${CAN_VIEW}` });

  return (
    <Accordion
      header={(
        <div className={styles.header}>
          <Controller
            name={`${PILLARS}.${pillarIndex}.${CAN_VIEW}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle
                value={pillarName}
                checked={value}
                name={pillarName}
                onChange={(e) => {
                  onChange(e.target.checked);
                  if (!e.target.checked) {
                    pillarModules.forEach((_, j) => {
                      onResetPillarModules(pillarIndex, j);
                    });
                  }
                }}
              />
            )}
          />
          <p>{pillarName}</p>
        </div>
        )}
    >
      <h4 className={styles.subtitle}>
        {t('clients.featureModule')}
      </h4>
      <div className={styles.wrapper}>
        {pillarModules
          .map((module, j) => (
            <div className={styles.container} key={module.id}>
              <div className={styles.item}>
                <p className={styles.itemTitle}>
                  {module.name}
                </p>
              </div>
              <div className={styles.item}>
                <Controller
                  name={`${PILLARS}.${pillarIndex}.${PILLAR_MODULES}.${j}.${ACCESS}`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      label={t('common.access')}
                      placeholder={t('common.access')}
                      options={ACCESS_TYPES}
                      value={ACCESS_TYPES.find((item) => item.value === value)}
                      onChange={(newValue: any) => onChange(newValue?.value)}
                      isDisabled={!isPillarActive}
                    />
                  )}
                />
              </div>
            </div>
          ))}
      </div>
    </Accordion>
  );
}

export default PillarCard;
