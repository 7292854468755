import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PlanetIcon } from 'assets/icons/planet.svg';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import NoData from 'components/NoData/NoData';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';
import { SUPPORT_LANGUAGES } from 'constants/languages';

import type IMultilingualSection from './interfaces/IMultilingualSection';

import styles from './scss/MultilingualSection.module.scss';

function MultilingualSection({
  languages,
}: IMultilingualSection) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId = '' } = useParams();

  const onHandleEdit = () => {
    const redirectPath = generatePath(AppRoutes.clientProfile, { clientId });
    navigate(generatePath(AppRoutes.multiLingual, { clientId }), { state: { redirectPath } });
  };

  return (
    <InfoPanel
      title={t('clients.multiLingual')}
      icon={<PlanetIcon />}
      onEdit={onHandleEdit}
    >
      {languages.length ? languages.map((item, index, array) => (
        <Fragment key={item}>
          <div className={styles.item}>
            <span>{SUPPORT_LANGUAGES?.[item]?.icon}</span>
            <span>{SUPPORT_LANGUAGES?.[item]?.label}</span>
          </div>
          {array.length - 1 !== index ? <Separator /> : null}
        </Fragment>
      )) : <NoData />}
    </InfoPanel>
  );
}

export default MultilingualSection;
