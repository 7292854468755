import { useTranslation } from 'react-i18next';

import Rule from '../Rule/Rule';

import type { IPasswordRules } from './interfaces/IPasswordRules';

import styles from './scss/PasswordRules.module.scss';

function PasswordRules({ isRecoverPasswordPath }: IPasswordRules) {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.label}>{t('profilePassword.passwordRulesLabel')}</div>

      <div className={styles.wrapper}>
        {isRecoverPasswordPath ? <Rule label={t('profilePassword.notRepeatPreviousPasswords')} /> : null}
        <Rule label={t('profilePassword.notContainUserParts')} />
      </div>

    </div>

  );
}

export default PasswordRules;
