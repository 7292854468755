import { createSelector } from '@reduxjs/toolkit';

import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type ISelectPillarByKey from './interfaces/ISelectPillarByKey';
import type ISelectPillarModuleByKey from './interfaces/ISelectPillarModuleByKey';
import type IUser from './interfaces/IUser';

export const selectPermissions = createSelector(
  (data?: IBaseQueryResponse<IUser>) => data?.data,
  (data) => data?.permissions,
);

export const selectPillarByKey = createSelector(
  ({ data, pillarKey }:
  ISelectPillarByKey) => data?.data?.permissions?.pillars?.find(
    (pillar) => pillar.key === pillarKey,
  ),
  (data) => data,
);

export const selectPillarModuleByKey = createSelector(
  ({ data, pillarModuleKey }:
  ISelectPillarModuleByKey) => data?.data?.permissions?.pillarModules?.find(
    (pillar) => pillar.key === pillarModuleKey,
  ),
  (data) => data,
);

export default selectPermissions;
