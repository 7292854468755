import { ReactComponent as DeleteIcon } from 'assets/icons/tresh.svg';
import Avatar from 'components/Avatar/Avatar';
import IconButton from 'components/IconButton/IconButton';

import type IPreview from './interfaces/IPreview';

import styles from './scss/Preview.module.scss';

function Preview({ imagePreview, onDelete, isActionsVisible }: IPreview) {
  return (
    <div className={styles.root}>
      <Avatar
        className={styles.preview}
        src={imagePreview}
      />
      {isActionsVisible ? (
        <IconButton
          className={styles.button}
          icon={<DeleteIcon />}
          onClick={onDelete}
        />
      ) : null}

    </div>
  );
}

export default Preview;
