export default {
  pageTitle: 'Clients',
  createClient: 'Create new client',
  editClient: 'Edit Company Info',
  companyName: 'Company name',
  companyNameAdmin: 'Company name, admin',
  createdDate: 'Created date',
  status: 'Status',
  companyAdmin: 'Company admin',
  actions: 'Actions',
  filterByStatus: 'Filter by status:',
  editInformation: 'Edit information',
  loginAsClientAdmin: 'Login as the client\'s admin',
  deactivateClient: 'Deactivate client',
  activateClient: 'Activate client',
  noClientsYet: 'You haven\'t set up any client yet',
  companyInfo: 'Company Info',
  multiLingual: 'Multi-Lingual',
  compAdmin: 'Company Admin',
  uiConfiguration: 'UI Configuration',
  moduleManagement: 'Module Management',
  security: 'Security',
  previewHint: 'You can preview client\'s launchpad on the last step of creation',
  addCompanyInfo: 'Add as much company information as possible',
  createNewClient: 'Create New Client',
  selectLanguages: 'Select available languages for this client',
  createAdminText: 'Create an admin who will manage the company. '
    + 'The invitation will be sent automatically on the final step.',
  setAuthentication: 'Set up authentication for the company\'s users',
  sso: 'SSO',
  mfaAuthentication: 'MFA Authentication app',
  mfaShortAuthentication: 'MFA Auth app',
  mfaSms: 'MFA SMS',
  configureModules: 'Configure the availability of modules for the company',
  selectPillars: 'Select needed pillars:',
  featureModule: 'Feature & module:',
  entityEditing: 'Entity Editing',
  entityLinking: 'Entity Linking',
  dataSetsManagement: 'Data Sets Management',
  uploadLogoAndPalette: 'Upload your logo and adjust the colour palette',
  colorPalette: 'Color Palette',
  preview: 'Preview:',
  cardTitle: 'First Value Chain',
  cardText: 'Created on January 5',
  cancelModalTitle: 'Are you sure you want to cancel the new client creation?',
  cancelModalText: 'All entered information will be lost',
  closeModalSaving: 'Close Without Saving',
  discardChanges: 'Discard changes?',
  informationWillBeLost: 'All entered information will be lost',
  kepEditing: 'Keep editing',
  discardChangesButton: 'Discard changes',
};
