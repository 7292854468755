import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DataSetsRecordButtons from 'components/DataSetsRecordButtons/DataSetsRecordButtons';
import FieldWithCurrency from 'components/FieldWithCurrency/FieldWithCurrency';
import Modal from 'components/Modal/Modal';
import NumberField from 'components/NumberField/NumberField';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from 'constants/dataSets';
import {
  CODE,
  CRITICALITY_FROM_DOWNSTREAM_WASTE,
  CRITICALITY_RATING,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_CONTROL,
  EXPECTED_GROWTH_DECLINE, KEY_SUPPLIER_TIER_1_TOTAL,
  KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY,
  KEY_SUPPLIER_TIER_10_OF_TOTAL,
  NAME,
  PROFIT_CENTER_ID,
  RELIANCE_ON_FREE_NATURAL_RESOURCES,
  RELIANCE_ON_FREE_SOCIAL_RESOURCES,
  SUPPLIER,
  SUPPLIER_ID,
  UNIQUE_IDENTIFIER,
  VOLUME_QTY,
  VOLUME_QTY_UNIT,
} from 'constants/formFields';
import { DATA_SETS_DATE_MASK } from 'constants/inputMasks';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type IFieldError from 'store/types/IFieldError';
import type { ICreateProductView } from './interfaces/ICreateProductView';

import styles from './sass/CreateProductView.module.scss';
import stylesCurrency from 'components/FieldWithCurrency/scss/FieldWithCurrency.module.scss';

function CreateProductView({
  control, onProductSubmit, isConfirmationModalOpen, onHandleCloseConfirmation,
  onDiscardChangesConfirm, enumData, isPageLoading,
  uidNameTuples,
}: ICreateProductView) {
  const { t } = useTranslation();

  const { recordId = '' } = useParams();
  const placeholder = recordId ? t('common.notProvided') : '';

  return (
    <div className={styles.root}>
      {!isPageLoading ? (
        <Panel className={styles.panel}>
          <div className={styles.wrapper}>
            <DataSetsPanel title={t('products.selectionsGeneral')}>
              <Controller
                control={control}
                name={NAME}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('products.fields.productName')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={CODE}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('products.fields.productCode')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={UNIQUE_IDENTIFIER}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('products.fields.uniqueIdentifier')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={PROFIT_CENTER_ID}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('products.fields.profitCentreId')}
                    placeholder={recordId ? placeholder : t('products.fields.profitCentreId')}
                    options={uidNameTuples?.[UID_NAME_TUPLES.profitCenter]}
                    value={value || undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.message}
                    isMulti
                    isSearchable
                  />
                )}
              />
              <Controller
                control={control}
                name={SUPPLIER}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('products.fields.supplier')}
                    placeholder={t('products.fields.supplier')}
                    options={uidNameTuples?.[UID_NAME_TUPLES.supplier]}
                    value={value || undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={SUPPLIER_ID}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('products.fields.supplierId')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    disabled
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={DATE_OPENED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('products.fields.dateFrom')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={DATE_CLOSED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('products.fields.dateTo')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={VOLUME_QTY}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('products.fields.volume')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={VOLUME_QTY_UNIT}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('products.fields.volumeUnit')}
                    placeholder={t('products.fields.volumeUnit')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.volumeQtyUnit]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={EQUITY_CONTROL}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('products.fields.equityControl')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={EXPECTED_GROWTH_DECLINE}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('products.fields.expectedGrowth')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={RELIANCE_ON_FREE_NATURAL_RESOURCES}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('products.fields.relianceNatural')}
                    placeholder={recordId ? placeholder : t('products.fields.relianceNatural')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.relianceOnFreeNaturalResources]}
                    value={value || undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    isMulti
                  />
                )}
              />
              <Controller
                control={control}
                name={RELIANCE_ON_FREE_SOCIAL_RESOURCES}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('products.fields.relianceSocial')}
                    placeholder={recordId ? placeholder : t('products.fields.relianceSocial')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.relianceOnFreeSocialResources]}
                    value={value || undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    isMulti
                  />
                )}
              />
              <Controller
                control={control}
                name={CRITICALITY_RATING}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('products.fields.rating')}
                    placeholder={t('products.fields.rating')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.criticalityRating]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={CRITICALITY_FROM_DOWNSTREAM_WASTE}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('products.fields.circularityPercentage')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

            <DataSetsPanel title={t('products.selectionsFinancials')}>
              <Controller
                control={control}
                name={KEY_SUPPLIER_TIER_1_TOTAL}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <FieldWithCurrency name={name} label={t('products.fields.suppliersTier1')}>
                    <div className={stylesCurrency.fieldWithCurrency}>
                      <NumberField
                        name={name}
                        value={value}
                        error={error?.message}
                        onChange={(values) => onChange(values.value)}
                        placeholder={placeholder}
                      />
                      <Controller
                        control={control}
                        name={KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY}
                        render={({
                          field: { value: valueCurrency, onChange: onChangeCurrency },
                          fieldState: { error: errorCurrency },
                        }) => (
                          <Select
                            placeholder={t('common.currency')}
                            options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                            value={valueCurrency?.value ? valueCurrency : undefined}
                            onChange={onChangeCurrency}
                            error={(errorCurrency as IFieldError)?.value?.message}
                            className={stylesCurrency.select}
                          />
                        )}
                      />
                    </div>
                  </FieldWithCurrency>
                )}
              />
              <Controller
                control={control}
                name={KEY_SUPPLIER_TIER_10_OF_TOTAL}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('products.fields.suppliersTier1InPercentage')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

          </div>
          <Separator />
          <Modal
            open={isConfirmationModalOpen}
            title={t('createNewUser.cancelModalTitle')}
            description={t('clients.cancelModalText')}
            confirmTitle={t('common.discardChanges')}
            closeTitle={t('common.keepEditing')}
            confirmVariant="error"
            onClose={() => onHandleCloseConfirmation(false)}
            onReject={() => onHandleCloseConfirmation(false)}
            onConfirm={onDiscardChangesConfirm}
          />
          <DataSetsRecordButtons onClose={onHandleCloseConfirmation} onSubmit={onProductSubmit} />
        </Panel>
      ) : null}
    </div>
  );
}

export default CreateProductView;
