import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EMAIL } from 'constants/formFields';
import { forgotPasswordSchema } from 'utils/validators';

import type { TUseRecoverPasswordForm } from '../interfaces/TUseRecoverPasswordForm';

const useRecoverPasswordForm = () => {
  const {
    control,
    handleSubmit,
    formState,
  } = useForm<TUseRecoverPasswordForm>({
    mode: 'onSubmit',
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      [EMAIL]: '',
    },
  });
  const { isValid } = formState;

  return {
    control,
    handleSubmit,
    isValid,
  };
};

export default useRecoverPasswordForm;
