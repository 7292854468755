import { ReactComponent as CardIcon } from 'assets/icons/valueChainCircle.svg';
import { clsx } from 'clsx';

import type ICard from './interfaces/ICard';

import styles from './scss/Card.module.scss';

function Card({
  children, className, previewColors,
}: ICard) {
  const { header, icon } = previewColors || {};
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.header} style={{ background: header }}>
        <CardIcon style={{ color: icon }} />
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

export default Card;
