import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as AdminIcon } from 'assets/icons/user-settings.svg';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import NoData from 'components/NoData/NoData';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';

import AdminItem from './components/AdminItem/AdminItem';

import type IAdminsSection from './interfaces/IAdminsSection';

function AdminsSection({
  admins,
}: IAdminsSection) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId = '' } = useParams();

  const onHandleEdit = () => {
    const redirectPath = generatePath(AppRoutes.clientProfile, { clientId });
    navigate(generatePath(AppRoutes.companyAdmin, { clientId }), { state: { redirectPath } });
  };

  return (
    <InfoPanel
      title={t('clients.compAdmin')}
      icon={<AdminIcon />}
      onEdit={onHandleEdit}
    >
      {admins.length ? admins.map((admin, index) => (
        <Fragment key={admin.id}>
          <AdminItem
            id={admin.id}
            firstName={admin.firstName}
            lastName={admin.lastName}
            email={admin.email}
            status={admin.status}
            latestDeactivation={admin.latestDeactivation}
          />
          {admins.length - 1 !== index ? <Separator /> : null}
        </Fragment>
      )) : <NoData />}
    </InfoPanel>
  );
}

export default AdminsSection;
