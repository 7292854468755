import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { clsx } from 'clsx';
import { BUTTON_VARIANT } from 'constants/interfaces';

import Button from '../Button/Button';

import type IClientPanelFooter from './interfaces/IClientPanelFooter';

import styles from './scss/ClientPanelFooter.module.scss';

function ClientPanelFooter({
  onPrevClick,
  onSave,
  onCancel,
  isDisabledSave,
  isCreate,
  isLastStep,
}: IClientPanelFooter) {
  const { t } = useTranslation();

  const createButtonTitle = isLastStep ? t('common.finish') : t('common.next');

  return (
    <div className={clsx(styles.root, {
      [styles.edit]: !isCreate,
    })}
    >
      <Button
        className={styles.button}
        variant={BUTTON_VARIANT.cancel}
        title={t('common.cancel')}
        onClick={onCancel}
      />
      <div className={styles.wrapper}>
        {onPrevClick && isCreate ? (
          <Button
            className={styles.button}
            variant={BUTTON_VARIANT.secondary}
            title={t('common.prev')}
            startIcon={<ArrowLeftIcon />}
            onClick={onPrevClick}
          />
        ) : null}
        <Button
          className={styles.button}
          disabled={isDisabledSave}
          title={isCreate ? createButtonTitle : t('common.save')}
          endIcon={isCreate && !isLastStep ? <ArrowRightIcon /> : null}
          onClick={onSave}
        />
      </div>
    </div>
  );
}

export default ClientPanelFooter;
