import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as DisabledIcon } from 'assets/icons/disabled.svg';
import { ReactComponent as EnabledIcon } from 'assets/icons/enabled.svg';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import { DATA_SET_MAX_MENU_HEIGHT } from 'constants/dataSets';
import { MAPPING_FIELDS_PREFIX } from 'constants/general';

import type IMappingItem from '../interfaces/IMappingItem';

import styles from '../scss/MappingItem.module.scss';

function MappingItem({
  wrapperRef, control, nameTarget, nameSource, sourcesOptions, watch,
}: IMappingItem) {
  const { t } = useTranslation();

  const currentSource = watch(`${MAPPING_FIELDS_PREFIX.source}${nameSource}`);

  const isMatched = currentSource?.isMatched || !!currentSource?.value;

  return (
    <div className={styles.root}>
      <div className={styles.status}>{isMatched ? <EnabledIcon /> : <DisabledIcon />}</div>

      <Controller
        control={control}
        name={`${MAPPING_FIELDS_PREFIX.target}${nameTarget}`}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <TextField
            className={styles.field}
            name={name}
            value={value}
            error={error?.message}
            onChange={(event) => onChange(event.target.value)}
            required
          />
        )}
      />

      <div className={styles.arrow}>
        <ChevronIcon />
      </div>
      <Controller
        control={control}
        name={`${MAPPING_FIELDS_PREFIX.source}${nameSource}`}
        render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
          <Select
            className={styles.field}
            options={sourcesOptions}
            placeholder={t('dataSetsManagement.mappingModal.selectPlaceholder')}
            value={error?.message && !isDirty ? '' : value}
            onChange={onChange}
            menuPosition="absolute"
            required
            placeholderClassName={styles.placeholder}
            maxMenuHeight={DATA_SET_MAX_MENU_HEIGHT}
            modalRef={wrapperRef}
          />
        )}
      />

    </div>
  );
}

export default MappingItem;
