import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';

import type ITextarea from './interfaces/ITextarea';

import styles from './scss/Textarea.module.scss';

function Textarea({
  name, label, value, onChange, className, error,
  placeholder, required, charactersLimit,
}: ITextarea) {
  const { t } = useTranslation();
  const classes = clsx(styles.root, className, {
    [styles.rootError]: error,
  });

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={name}>
          {required ? `${label}*` : label}
        </label>
      ) : null}
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div className={styles.wrapper}>
        {error ? <p className={styles.error}>{error}</p> : null}
        <p className={styles.counter}>
          {t('common.charactersLimit', {
            counter: value?.length || 0,
            limit: charactersLimit,
          })}
        </p>
      </div>
    </div>
  );
}

export default Textarea;
