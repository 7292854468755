export const OK = 200;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const INTERNAL_SERVER_ERROR = 500;

export const ERROR_CODES = {
  badRequest: '1.400',
  unauthorized: '1.401',
  deactivated: '2.5',
  internalServerError: '1.500',
};
