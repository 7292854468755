import { useLocation, useParams } from 'react-router-dom';
import { clientsNav } from 'constants/navigationLists';

import { NEW_CLIENT_ID } from '../constants/general';

const useCreateClient = () => {
  const { clientId = NEW_CLIENT_ID } = useParams();
  const { pathname } = useLocation();

  const isCreateFlow = clientId === NEW_CLIENT_ID;

  const isLastPage = pathname === clientsNav(clientId).pop()?.to;

  return {
    isCreateFlow,
    isLastPage,
    clientId,
  };
};

export default useCreateClient;
