import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { COMPANY_STATUS } from 'constants/interfaces';
import {
  useActivateCompanyMutation,
  useArchiveCompanyMutation,
  useDeArchiveCompanyMutation,
} from 'store/slices/companies/apis/companiesApi';

import type IUseCompanyStatusChange from './interfaces/IUseClientStatusChange';

const useCompanyStatusChange = ({
  companyId, status,
}: IUseCompanyStatusChange) => {
  const { t } = useTranslation();
  const [isDeactivationModalOpen, setIsDeactivationModalOpen] = useState(false);

  const [activateCompany] = useActivateCompanyMutation();
  const [archiveCompany] = useArchiveCompanyMutation();
  const [deArchiveCompany] = useDeArchiveCompanyMutation();

  const onToggleDeactivationModal = () => {
    setIsDeactivationModalOpen((prev) => !prev);
  };

  const onChangeCompanyStatus = () => {
    let action;

    switch (status) {
      case COMPANY_STATUS.draft:
        action = activateCompany;
        break;
      case COMPANY_STATUS.active:
        setIsDeactivationModalOpen(true);
        break;
      default:
        action = deArchiveCompany;
        break;
    }

    action?.({ companyId }).unwrap()
      .then(() => {
        toast.success(t('notifications.clientActivated'));
      })
      .catch(() => {});
  };

  const onCompanyDeactivate = (reason: string) => {
    archiveCompany({
      companyId,
      reason,
    }).unwrap()
      .then(() => {
        toast.success(t('notifications.clientDeactivated'));
        setIsDeactivationModalOpen(false);
      })
      .catch(() => {});
  };

  return {
    isDeactivationModalOpen,
    onToggleDeactivationModal,
    onChangeCompanyStatus,
    onCompanyDeactivate,
  };
};

export default useCompanyStatusChange;
