import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Outlet, useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { clsx } from 'clsx';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import NavSidebar from 'components/NavSidebar/NavSidebar';
import AppRoutes from 'constants/AppRoutes';
import { clientsNav } from 'constants/navigationLists';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useCreateClient from 'hooks/useCreateClient';
import { useAppDispatch } from 'store/hooks/useApp';
import { resetState } from 'store/slices/companies/slice';

import { useGetCompanyDetailsQuery } from '../../store/slices/companies/apis/companiesApi';
import CreateClientHeader from './components/CreateClientHeader/CreateClientHeader';

import styles from './scss/CreateClientLayout.module.scss';

function CreateClientLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    clientId, isCreateFlow,
  } = useCreateClient();
  const dispatch = useAppDispatch();

  const fetchQuery = clientId ? {
    companyId: +clientId,
  } : skipToken;

  const { data: company } = useGetCompanyDetailsQuery(fetchQuery);

  const clientCrumb = {
    pathname: generatePath(AppRoutes.clientsCreate, { clientId }),
    label: isCreateFlow ? t('clients.createNewClient') : company?.data.name || '',
    path: isCreateFlow ? undefined : generatePath(AppRoutes.clientProfile, { clientId }),
  };

  useEffect(() => () => {
    dispatch(resetState());
  }, [dispatch]);

  const { breadcrumbs } = useBreadcrumbs({ mathCrumbs: [clientCrumb] });

  const clientsNavList = useMemo(() => clientsNav(clientId), [clientId]);
  const { redirectPath } = location.state || {};

  return (
    <div className={styles.root}>
      <BreadcrumbsNav breadcrumbs={breadcrumbs} />
      <CreateClientHeader
        isCreate={isCreateFlow}
      />
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <NavSidebar
            navList={clientsNavList}
            disabled={isCreateFlow}
            navState={{ redirectPath }}
          />
        </div>
        <div className={clsx(styles.content, {
          [styles.createContent]: isCreateFlow,
        })}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default CreateClientLayout;
