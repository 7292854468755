import { useTranslation } from 'react-i18next';
import { ReactComponent as BarCodeIcon } from 'assets/icons/barcode.svg';
import { ReactComponent as SMSCodeIcon } from 'assets/icons/smsCode.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashBox.svg';
import Button from 'components/Button/Button';
import IconButton from 'components/IconButton/IconButton';
import Separator from 'components/Separator/Separator';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Tooltip from 'components/Tooltip/Tooltip';
import { BUTTON_VARIANT, STATUS } from 'constants/interfaces';

import type { IMFA } from '../interfaces/IMFA';

import styles from '../sass/MFA.module.scss';

function MFA({
  isMfaApp, isConnected, phone, isSeparator, onConfirmationModalOpen, onMfaAppCreate, onMfaSmsCreate,
}:IMFA) {
  const { t } = useTranslation();

  const title = isMfaApp ? 'authenticationApp' : 'phoneNumber';

  const buttonTranslateKey = isMfaApp ? 'setUpAuthenticationApp' : 'addPhoneNumber';

  const Icon = isMfaApp ? BarCodeIcon : SMSCodeIcon;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.title}>
          <h4>
            {isConnected ? <Icon /> : null}
            {t(`profileSecurity.${title}`)}
          </h4>
          {!isMfaApp && phone ? (
            <div className={styles.subTitle}>
              {phone}
            </div>
          ) : null}
        </div>
        <div>
          {isConnected ? (
            <StatusBadge
              status={STATUS.enabled}
              title={t('common.configured')}
              className={styles.badge}
            />
          ) : (
            <Button
              title={t(`profileSecurity.${buttonTranslateKey}`)}
              variant={BUTTON_VARIANT.action}
              onClick={isMfaApp ? onMfaAppCreate : onMfaSmsCreate}
            />
          )}

        </div>

        {isConnected ? (
          <div className={styles.delete}>
            <Tooltip hint={t('common.delete')}>
              <IconButton
                icon={<TrashIcon />}
                onClick={() => onConfirmationModalOpen({ isMfaApp })}
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
      {isSeparator ? <Separator /> : null }
    </>
  );
}

export default MFA;
