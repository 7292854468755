import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import Title from 'components/Title/Title';
import { AppRoutes } from 'constants/AppRoutes';
import { NEW_CLIENT_ID } from 'constants/general';

import styles from './scss/ClientsHeading.module.scss';

function ClientsHeading() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onHandleClick = () => {
    navigate(
      generatePath(AppRoutes.companyInfo, { clientId: NEW_CLIENT_ID }),
      { state: { redirectPath: AppRoutes.clients } },
    );
  };

  return (
    <div className={styles.root}>
      <Title className={styles.title} text={t('clients.pageTitle')} />
      <Button
        title={t('clients.createClient')}
        onClick={onHandleClick}
      />
    </div>
  );
}

export default ClientsHeading;
