import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import AppRoutes from 'constants/AppRoutes';
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED } from 'constants/statusCodes';
import * as queryString from 'query-string';
import { logoutUser } from 'store/slices/auth/slice';
import AUTHENTICATED_USER from 'store/tags/auth';
import COMPANY_TAG, { COMPANY_PERMISSION_TAG, COMPANY_USER } from 'store/tags/companies';
import { refNavigateTo } from 'utils/navigationRef';

import { MFA_ENDPOINTS } from '../../constants/interfaces';
import { setAuthState } from '../slices/auth/slice';

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import type { RootState } from 'store/types/TStore';
import type IRefreshTokenResponse from '../slices/auth/interfaces/IRefreshTokenResponse';
import type IBaseQueryResponse from '../types/IBaseQueryResponse';

const authBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const {
      accessToken, verifyToken, userAccessToken,
    } = (getState() as RootState).auth;

    const isMfaEndpoint = [...Object.values(MFA_ENDPOINTS)].some((api) => api === endpoint);
    const generalAccessToken = userAccessToken || accessToken;

    if (isMfaEndpoint && verifyToken) {
      headers.set('authorization', `Bearer ${verifyToken}`);
      return headers;
    }

    if (generalAccessToken) {
      headers.set('authorization', `Bearer ${generalAccessToken}`);
    }
    return headers;
  },
  paramsSerializer: (params) => queryString.default.stringify(params),
});

const mutex = new Mutex();
export const appBaseQueryWithReauth: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await authBaseQuery(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === UNAUTHORIZED) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const state = api.getState();
          const { refreshToken, userRefreshToken } = (state as RootState).auth;

          const generalRefreshToken = userRefreshToken || refreshToken;
          if (generalRefreshToken) {
            const { data: refreshData } = await authBaseQuery({
              url: '/auth/refresh/',
              method: 'PATCH',
              body: {
                token: generalRefreshToken,
              },
            }, api, extraOptions);
            const { data } = (refreshData as IBaseQueryResponse<IRefreshTokenResponse>) || {};

            if (data) {
              const { accessToken, userAccessToken } = data;
              api.dispatch(setAuthState({
                accessToken: userAccessToken || accessToken,
              }));

              const resultBaseQuery = await authBaseQuery(args, api, extraOptions);

              result = resultBaseQuery;

              if (resultBaseQuery?.meta?.response?.status === UNAUTHORIZED) {
                api.dispatch(logoutUser());
                refNavigateTo(AppRoutes.login);
              }
            } else {
              api.dispatch(logoutUser());
              refNavigateTo(AppRoutes.login);
            }
          } else {
            api.dispatch(logoutUser());
            refNavigateTo(AppRoutes.login);
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await authBaseQuery(args, api, extraOptions);
      }
    } else if (
      +status >= INTERNAL_SERVER_ERROR
        || ('originalStatus' in result.error && +result.error.originalStatus >= INTERNAL_SERVER_ERROR)
    ) {
      // Handle server errors here
    }
  }
  return result;
};

const mainApi = createApi({
  reducerPath: 'mainApi',
  tagTypes: [
    AUTHENTICATED_USER,
    COMPANY_TAG,
    COMPANY_PERMISSION_TAG,
    COMPANY_USER,
  ],
  baseQuery: appBaseQueryWithReauth,
  endpoints: () => ({}),
});

export default mainApi;
