import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import IconButton from 'components/IconButton/IconButton';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import UserStatusBadge from 'components/UserStatusBadge/UserStatusBadge';
import { AppRoutes } from 'constants/AppRoutes';
import { STATUS, USER_STATUS } from 'constants/interfaces';
import USER_ROLES, { USER_STATUSES } from 'constants/user';

import type ICompanyUserCard from '../../interfaces/ICompanyUserCard';

import styles from './scss/CompanyUserCard.module.scss';

function CompanyUserCard({ user, setIsConfirmationModalOpen }: ICompanyUserCard) {
  const { t } = useTranslation();
  const { clientId = '' } = useParams();
  const navigate = useNavigate();

  const mfaAppTitle = user.profile?.mfaAppEnabled && STATUS.enabled === user.profile.mfaAppEnabled
    ? t('common.configured') : t('common.notConfigured');
  const mfaSmsTitle = user.profile?.mfaSmsEnabled && STATUS.enabled === user.profile.mfaSmsEnabled
    ? t('common.configured') : t('common.notConfigured');

  const showEditSecurity = user?.status === USER_STATUS.active;

  return (
    <>
      <div className={styles.item}>
        <div className={styles.info}>
          <p className={styles.name}>{`${user.firstName} ${user.lastName}`}</p>
          <p className={styles.email}>{user.email}</p>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.role}>
            {USER_ROLES?.[user.role].icon}
            <p>{USER_ROLES?.[user.role].label}</p>
          </div>
          {showEditSecurity ? (
            <div className={styles.loginButton}>
              <Button
                title={t('common.loginAsUser')}
                variant="cancel"
                onClick={() => setIsConfirmationModalOpen(true)}
              />
              <IconButton
                icon={<PenIcon />}
                onClick={() => navigate(generatePath(AppRoutes.clientUsersSecurity, {
                  clientId,
                  userId: `${user.id}`,
                }))}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.info} />
        <div className={styles.statuses}>
          <UserStatusBadge
            title={`${t('common.status')}:`}
            showInfoButton={USER_STATUS.deactivated === 'deactivated'}
            deactivationInfo={user.latestDeactivation}
          >
            <Badge
              status={USER_STATUSES?.[user.status].label}
              color={USER_STATUSES?.[user.status].color}
            />
          </UserStatusBadge>
          <UserStatusBadge
            title={`${t('clients.mfaShortAuthentication')}:`}
            modalTitle={t('companyUsers.mfaResetDetails')}
            showInfoButton={STATUS.disabled === user.profile?.mfaAppEnabled}
            deactivationInfo={user.latestMfaAppDeactivation}
          >
            <StatusBadge
              status={user.profile?.mfaAppEnabled || STATUS.disabled}
              title={mfaAppTitle}
            />
          </UserStatusBadge>
          <UserStatusBadge
            title={`${t('clients.mfaSms')}:`}
            modalTitle={t('companyUsers.mfaResetDetails')}
            showInfoButton={STATUS.disabled === user.profile?.mfaSmsEnabled}
            deactivationInfo={user.latestMfaSmsDeactivation}
          >
            <StatusBadge
              status={user.profile?.mfaSmsEnabled || STATUS.disabled}
              title={mfaSmsTitle}
            />
          </UserStatusBadge>
        </div>
      </div>
    </>
  );
}

export default CompanyUserCard;
