import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { ReactComponent as SendIcon } from 'assets/icons/send-rounded.svg';
import { AppRoutes } from 'constants/AppRoutes';
import { MFA_APP, MFA_SMS, SSO } from 'constants/formFields';
import { STATUS } from 'constants/interfaces';
import useCreateClient from 'hooks/useCreateClient';
import { useAppSelector } from 'store/hooks/useApp';
import { useActivateCompanyMutation, useGetCompanyDetailsQuery, useUpdateCompanySecurityMutation } from 'store/slices/companies/apis/companiesApi';
import { createFlowCompanyIdSelector, selectCompanySecurity } from 'store/slices/companies/selectors';
import { companySecuritySchema } from 'utils/validators';

import SecurityView from './SecurityView';

import type { TCompanySecuritySchema } from 'utils/validators';

function Security() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newCompanyId = useAppSelector(createFlowCompanyIdSelector);
  const { isCreateFlow, clientId } = useCreateClient();

  const currentCompanyId = newCompanyId && isCreateFlow ? newCompanyId : +clientId;

  const fetchQuery = newCompanyId || !isCreateFlow ? {
    companyId: currentCompanyId,
  } : skipToken;

  const { security } = useGetCompanyDetailsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, security: selectCompanySecurity(result.data) }),
    },
  );

  const [updateCompanySecurity] = useUpdateCompanySecurityMutation();
  const [activateCompany] = useActivateCompanyMutation();

  const {
    control,
    formState: { isDirty, isValid, isSubmitted },
    handleSubmit: onSubmit,
    reset,
  } = useForm<TCompanySecuritySchema>({
    mode: 'onChange',
    resolver: yupResolver(companySecuritySchema),
    defaultValues: {
      [SSO]: STATUS.disabled,
      [MFA_APP]: STATUS.disabled,
      [MFA_SMS]: STATUS.disabled,
    },
  });

  const onHandleNextClick = onSubmit((values) => {
    const data = {
      companyId: currentCompanyId,
      ...values,
    };

    updateCompanySecurity(data).unwrap()
      .then(() => {
        if (isCreateFlow) {
          activateCompany({ companyId: currentCompanyId }).unwrap()
            .then(() => {
              toast.success(t('notifications.clientActivated'), {
                icon: <SendIcon />,
              });
            })
            .catch(() => {});
          navigate(generatePath(AppRoutes.clientProfile, { clientId: currentCompanyId }));
        }
        reset(undefined, { keepValues: true });
      })
      .catch(() => {});
  });

  const onHandlePrevClick = () => {
    navigate(generatePath(AppRoutes.moduleManagement, { clientId }));
  };

  useEffect(() => {
    if (security) {
      reset(security);
    }
  }, [reset, security]);

  const disableButton = !isValid && isSubmitted;

  return (
    <SecurityView
      control={control}
      isCreatePage={isCreateFlow}
      isLastStep
      onPrevClick={onHandlePrevClick}
      onSave={onHandleNextClick}
      isDirty={isDirty}
      isDisabledSave={disableButton}
    />
  );
}

export default Security;
