import { useEffect } from 'react';
import {
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { PASSWORD_STRENGTH_METER } from 'constants/general';
import i18n from 'i18n';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import {
  useCheckTokenQuery,
  useForgotPasswordConfirmMutation,
  useNewPasswordMutation,
} from 'store/slices/auth/apis/auth';
import { authTokensSelector } from 'store/slices/auth/selectors';
import { setAuthState } from 'store/slices/auth/slice';
import { useLoginInfoQuery } from 'store/slices/user/apis/user';
import authNavigation from 'utils/authNavigation';
import helpersPasswordStrengthMeter from 'utils/helpersPasswordStrengthMeter';

import useNewPasswordForm from './hooks/useNewPasswordForm';
import NewPasswordView from './NewPasswordView';

import type { ReactElement } from 'react';

function NewPassword(): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [newPassword, { isSuccess: isSuccessNewPassword }] = useNewPasswordMutation();
  const [forgotPasswordConfirm, { isSuccess: isSuccessNForgotPassword }] = useForgotPasswordConfirmMutation();
  const token = searchParams.get('token') || '';

  const checkToken = useCheckTokenQuery({ token });

  const { valid } = checkToken?.data?.data || {};

  const isSuccessRequest = isSuccessNewPassword || isSuccessNForgotPassword;

  const { verifyToken } = useAppSelector(authTokensSelector);

  const isRecoverPasswordPath = !!useMatch(AppRoutes.recoverPasswordConfirm);

  const endpoint = isRecoverPasswordPath ? forgotPasswordConfirm : newPassword;

  if (!token) {
    navigate(AppRoutes.login);
  }

  const queryParams = verifyToken ? {} : skipToken;

  const { data: userData, isLoading } = useLoginInfoQuery(queryParams);

  const {
    control,
    handleSubmit,
    password,
    isValid,
    isSubmitted,
  } = useNewPasswordForm();

  useEffect(() => {
    if (!checkToken?.isLoading && !valid) {
      toast.error(i18n.t('errors.linkWithTokenExpired'));
      navigate(AppRoutes.login);
    }
  }, [checkToken?.isLoading, valid, navigate]);

  useEffect(() => {
    if (!isLoading && verifyToken && userData && isSuccessRequest) {
      authNavigation({ navigate, user: userData.data });
    }
  }, [userData, isLoading, verifyToken, navigate, isSuccessRequest]);

  const passwordStrengthMeter = helpersPasswordStrengthMeter({ value: password });

  const isButtonDisabled = (passwordStrengthMeter < PASSWORD_STRENGTH_METER.medium)
      || (!isValid && isSubmitted) || isLoading;

  const onNewPasswordSubmit = handleSubmit((values) => {
    const requestData = {
      data: values,
      token,
    };
    endpoint(requestData)
      .unwrap()
      .then((data) => {
        if (!isRecoverPasswordPath) {
          dispatch(setAuthState({
            isFirstLogin: true,
          }));
        }

        // If user have accessToken and refreshToken -> Login user
        if (data.data.accessToken && data.data.refreshToken) {
          dispatch(setAuthState({
            accessToken: data.data.accessToken,
            refreshToken: data.data.refreshToken,
          }));
          navigate(AppRoutes.launchpad);
          return;
        }

        if (data.data.verifyToken) {
          dispatch(setAuthState({
            verifyToken: data.data.verifyToken,
          }));
        }
      }).catch(() => {});
  });
  return (
    <NewPasswordView
      control={control}
      onNewPasswordSubmit={onNewPasswordSubmit}
      passwordStrengthMeter={passwordStrengthMeter}
      isButtonDisabled={isButtonDisabled}
      isRecoverPasswordPath={isRecoverPasswordPath}
    />
  );
}

export default NewPassword;
