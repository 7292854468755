import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { UID_NAME_TUPLES } from 'constants/dataSets';
import {
  useCustomerRecordQuery,
  useGetNaceCodeTreeQuery, useUidNameTuplesQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectUidNameTuples } from 'store/slices/dataSets/selectors';
import getCustomerDefaultValues from 'utils/formHelpers/getCustomerDefaultValues';
import { createCustomerSchema } from 'utils/validators';

import type { TCreateCustomerSchema } from 'utils/validators';
import type { ICreateCustomerForm } from '../interfaces/ICreateCustomerForm';

const useCreateCustomerForm = ({ recordId, isPageLoading }: ICreateCustomerForm) => {
  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { data } = useCustomerRecordQuery(fetchQuery);

  const { data: naceCodeTreeData } = useGetNaceCodeTreeQuery();

  const { data: uidNameTuples } = useUidNameTuplesQuery({
    types: [UID_NAME_TUPLES.profitCenter],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectUidNameTuples(result.data) }),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
    setError,
    getValues,
    watch,
    setValue,
  } = useForm<TCreateCustomerSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createCustomerSchema),
    defaultValues: getCustomerDefaultValues({ values: data?.data, naceCodeTreeData, uidNameTuples }),
  });

  useEffect(() => {
    if (data?.data && !isPageLoading) {
      reset(getCustomerDefaultValues(
        { values: data?.data, naceCodeTreeData, uidNameTuples },
      ));
    }
  }, [reset, data, isPageLoading, naceCodeTreeData, uidNameTuples]);

  const fieldsName = Object.keys(getValues());

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
    isLoading,
    setError,
    fieldsName,
    watch,
    setValue,
  };
};

export default useCreateCustomerForm;
