import { clsx } from 'clsx';

import type ITooltip from './interfaces/ITooltip';

import styles from './scss/Tooltip.module.scss';

function Tooltip({ hint, className, children }: ITooltip) {
  return (
    <div className={clsx(styles.root, className)}>
      {children}
      {hint ? <p className={styles.hint}>{hint}</p> : null}
    </div>
  );
}

export default Tooltip;
