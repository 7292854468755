import { useTranslation } from 'react-i18next';
import ClientPanel from 'components/ClientPanel/ClientPanel';
import SaveDraftButton from 'components/SaveDraftButton/SaveDraftButton';

import CompanyAdminForm from './components/CompanyAdminForm/CompanyAdminForm';

import type ICompanyAdminView from './interfaces/ICompanyAdminView';

function CompanyAdminView({
  control,
  adminsArray,
  isCreatePage,
  isDisabledSave,
  onAddAdmin,
  onPrevClick,
  onSave,
  isDirty,
  onRemoveAdmin,
  onDraftSave,
}: ICompanyAdminView) {
  const { t } = useTranslation();

  return (
    <>
      {isCreatePage ? <SaveDraftButton isDisabled={!isDirty || isDisabledSave} onDraftSave={onDraftSave} /> : null }
      <ClientPanel
        title={t('clients.compAdmin')}
        text={t('clients.createAdminText')}
        onPrevClick={onPrevClick}
        onSave={onSave}
        isCreate={isCreatePage}
        isDisabledSave={isDisabledSave}
        isDirty={isDirty}
      >
        <CompanyAdminForm
          control={control}
          adminsArray={adminsArray}
          onAddAdmin={onAddAdmin}
          onRemoveAdmin={onRemoveAdmin}
        />
      </ClientPanel>
    </>
  );
}

export default CompanyAdminView;
