import { useTranslation } from 'react-i18next';
import DataSetsEmptyList from 'components/DataSetsEmptyList/DataSetsEmptyList';
import DataSetsItems from 'components/DataSetsItems/DataSetsItems';
import DataSetsListFilters from 'components/DataSetsListFilters/DataSetsListFilters';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import EmptyList from 'components/EmptyList/EmptyList';
import ImportErrorsModal from 'components/ImportErrorsModal/ImportErrorsModal';
import MappingModal from 'components/MappingModal/MappingModal';
import Pagination from 'components/Pagination/Pagination';
import Panel from 'components/Panel/Panel';
import {
  DATA_SET_PAGINATION_COUNT,
  DATA_SETS_SUPPLIERS_HEAD_CELLS,
} from 'constants/dataSets';

import SuppliersItem from './SuppliersItem/SuppliersItem';

import type { Control } from 'react-hook-form';
import type { TDataSetsListSchema } from 'utils/validators';
import type { TUseOwnOperationImportRecordsMutation } from '../OwnOperations/interfaces/TUseOwnOperationImportRecordsMutation';
import type ISuppliersView from './interfaces/ISuppliersView';

import styles from './sass/SuppliersView.module.scss';

function SuppliersView({
  isEmptyList, meta, uploadControl, isUploadFileVisible, isMappingModalVisible,
  onCloseMappingModal, setIsImportErrorsModalVisible, isImportErrorsModalVisible, onDownloadExample, importRecords,
  items, onSortChange, onSearchChange, onSelectAll, isLineMark, onArchive, setIsArchiveModalVisible,
  onArchiveConfirm, isArchiveModalVisible, isArchiveSelectedItemsVisible, isArchiveModalInfoVisible,
  setIsArchiveModalInfoVisible, latestArchivation, onArchiveModalInfoOpen, onMoveToArchive, onDeArchive, selectedItems,
  onPageChange, currentTotalItems, onEditRecord, isActionsVisible, onAddRecord,
  control, naceCodeTreeData, isWithSearch,
}: ISuppliersView) {
  const { t } = useTranslation();

  return (
    <>
      {isEmptyList && !isWithSearch ? (
        <DataSetsEmptyList
          title={t('suppliers.title')}
          subTitle={t('suppliers.subTitle')}
          uploadControl={uploadControl}
          isUploadFileVisible={isUploadFileVisible}
          onDownloadExample={onDownloadExample}
        />
      )
        : (
          <div className={styles.root}>
            <Panel className={styles.panel}>
              <DataSetsListFilters
                control={control}
                meta={meta}
                onSearchChange={onSearchChange}
                onArchive={onArchive}
                isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
                selectedItems={selectedItems}
                currentTotalItems={currentTotalItems}
                isActionsVisible={isActionsVisible}
                title={t('suppliers.title')}
                subTitle={t('suppliers.subTitle')}
                onAddRecord={onAddRecord}
              />
              {isEmptyList ? (<EmptyList />) : (
                <>
                  <DataSetsItems<Control<TDataSetsListSchema>>
                    control={control}
                    onSortChange={onSortChange}
                    onSelectAll={onSelectAll}
                    isLineMark={isLineMark}
                    isActionsVisible={isActionsVisible}
                    headCells={DATA_SETS_SUPPLIERS_HEAD_CELLS}
                  >
                    {items?.map((item) => (
                      <SuppliersItem
                        key={item.id}
                        item={item}
                        control={control}
                        onArchiveModalInfoOpen={onArchiveModalInfoOpen}
                        onMoveToArchive={onMoveToArchive}
                        onDeArchive={onDeArchive}
                        onEditRecord={onEditRecord}
                        isActionsVisible={isActionsVisible}
                        naceCodeTreeData={naceCodeTreeData}
                      />
                    ))}
                  </DataSetsItems>
                  <Pagination
                    count={meta?.count || 0}
                    offset={meta?.offset || 0}
                    limit={DATA_SET_PAGINATION_COUNT}
                    onPageChange={onPageChange}
                  />
                </>
              )}
            </Panel>
          </div>
        )}
      <MappingModal<TUseOwnOperationImportRecordsMutation>
        isMappingModalVisible={isMappingModalVisible}
        onCloseMappingModal={onCloseMappingModal}
        setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
        importRecords={importRecords}
      />
      <ImportErrorsModal
        isImportErrorsModalVisible={isImportErrorsModalVisible}
        setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
      />
      <DeactivationModal
        open={isArchiveModalVisible}
        title={t('profitCentres.archivingReasonTitle')}
        text={t('profitCentres.archivingReasonDescription')}
        onReject={() => { setIsArchiveModalVisible(false); }}
        onSubmit={onArchiveConfirm}
      />
      <DeactivationInfoModal
        open={isArchiveModalInfoVisible}
        title={t('profitCentres.archiveDetails')}
        deactivationInfo={latestArchivation}
        onClose={() => setIsArchiveModalInfoVisible(false)}
      />
    </>
  );
}

export default SuppliersView;
