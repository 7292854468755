import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import {
  SHOW_ARCHIVED,
} from 'constants/formFields';
import {
  USER_PILLAR_MODULES,
} from 'constants/interfaces';
import useDataSetListActions from 'hooks/useDataSetListActions';
import useDownloadExample from 'hooks/useDownloadExample';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import {
  useLazyProfitCenterSheetExampleQuery, useProfitCenterArchiveMutation,
  useProfitCenterDeArchiveMutation,
  useProfitCenterImportRecordsMutation,
  useProfitCenterListQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';

import useDataSetsListForm from './hooks/useDataSetsListForm';
import ProfitCentresView from './ProfitCentresView';

import type IProfitCenterItem from 'store/slices/dataSets/interfaces/IProfitCenterItem';
import type IProfitCentresParams from './interfaces/IProfitCentresParams';
import type { TUseProfitCenterImportRecordsMutation } from './interfaces/TUseProfitCenterImportRecordsMutation';

function ProfitCentres() {
  const {
    isActionsVisible,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.dataSetsManagement },
  );
  const navigate = useNavigate();
  const {
    control, getValues, setValue, watch,
  } = useDataSetsListForm();
  const [orderBy, setOrderBy] = useState('');
  const [params, setParams] = useState<IProfitCentresParams>({});

  const isArchived = watch(SHOW_ARCHIVED);
  const { search: searchParam, offset } = params;
  const { data } = useProfitCenterListQuery({
    orderBy, keyword: searchParam, archived: isArchived, offset,
  });
  const [getProfitCenterSheetExample] = useLazyProfitCenterSheetExampleQuery();

  const [deArchive] = useProfitCenterDeArchiveMutation();

  const [archive] = useProfitCenterArchiveMutation();

  const [importRecords] = useProfitCenterImportRecordsMutation();

  const { meta, items } = data?.data || {};

  const {
    isArchiveModalVisible,
    isArchiveModalInfoVisible,
    latestArchivation,
    isLineMark,
    onSortChange,
    onSearchChange,
    onSelectAll,
    onArchiveConfirm,
    onArchive,
    onArchiveModalInfoOpen,
    onMoveToArchive,
    onDeArchive,
    onPageChange,
    isArchiveSelectedItemsVisible,
    setIsArchiveModalVisible,
    setIsArchiveModalInfoVisible,
    selectedItems,
  } = useDataSetListActions<IProfitCenterItem[]>({
    items,
    archiveAction: archive,
    deArchiveAction: deArchive,
    setOrderBy,
    setParams,
    getValues,
    setValue,
    watch,
  });

  const isWithSearch = !!searchParam;

  const isEmptyList = !meta?.count;

  const currentTotalItems = items?.length || 0;

  const {
    uploadControl, isMappingModalVisible, onCloseMappingModal, isImportErrorsModalVisible,
    setIsImportErrorsModalVisible,
  } = useDownloadExample<TUseProfitCenterImportRecordsMutation>({ importRecords });

  const onDownloadExample = () => {
    getProfitCenterSheetExample();
  };

  const onEditRecord = (recordId: number) => {
    navigate(generatePath(AppRoutes.profitCentresEdit, { recordId: String(recordId) }));
  };

  const onAddRecord = () => {
    navigate(AppRoutes.profitCentresCreate);
  };

  return (
    <ProfitCentresView
      isEmptyList={isEmptyList}
      uploadControl={uploadControl}
      isActionsVisible={isActionsVisible}
      onDownloadExample={onDownloadExample}
      isMappingModalVisible={isMappingModalVisible}
      onCloseMappingModal={onCloseMappingModal}
      isImportErrorsModalVisible={isImportErrorsModalVisible}
      setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
      control={control}
      meta={meta}
      items={items}
      onSortChange={onSortChange}
      onSearchChange={onSearchChange}
      onSelectAll={onSelectAll}
      isLineMark={isLineMark}
      onArchive={onArchive}
      setIsArchiveModalVisible={setIsArchiveModalVisible}
      onArchiveConfirm={onArchiveConfirm}
      isArchiveModalVisible={isArchiveModalVisible}
      isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
      isArchiveModalInfoVisible={isArchiveModalInfoVisible}
      setIsArchiveModalInfoVisible={setIsArchiveModalInfoVisible}
      latestArchivation={latestArchivation}
      onArchiveModalInfoOpen={onArchiveModalInfoOpen}
      onMoveToArchive={onMoveToArchive}
      onDeArchive={onDeArchive}
      selectedItems={selectedItems}
      onPageChange={onPageChange}
      currentTotalItems={currentTotalItems}
      onEditRecord={onEditRecord}
      importRecords={importRecords}
      onAddRecord={onAddRecord}
      isWithSearch={isWithSearch}
    />
  );
}

export default ProfitCentres;
