import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { USER_PILLAR_MODULES } from 'constants/interfaces';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import useUserStatusChange from 'hooks/useUserStatusChange';
import { useUserDetailsQuery, useUserPermissionsQuery } from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';
import selectUserPermissions from 'store/slices/companyAdminUsers/selectors';

import UserInfoView from './UserInfoView';

function UserInfo() {
  const navigate = useNavigate();
  const { userId = '' } = useParams();

  const fetchQuery = userId ? {
    userId: +userId,
  } : skipToken;
  const { data } = useUserDetailsQuery(fetchQuery);

  const fetchUserPermissionsQuery = userId ? {
    userId,
  } : skipToken;

  const { pillars } = useUserPermissionsQuery(
    fetchUserPermissionsQuery,
    {
      selectFromResult: (result) => ({ ...result, pillars: selectUserPermissions(result.data) }),
    },
  );

  const {
    isActionsVisible, isAuditOrViewAccess, isLoading,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.userManagement, userId: +userId },
  );

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { id, email, status } = data?.data || {};

  const {
    onToggleDeleteModal, isNewUser, isDeactivationModalOpen, onToggleDeactivationModal,
    onUserDeactivate, isDeleteModalOpen, onUserDelete, onChangeUserStatus,
  } = useUserStatusChange({ userId: id, status, email });

  const onUserDeleteAction = () => {
    onUserDelete().then(() => {
      navigate(AppRoutes.userManagement);
    });
  };

  return (
    <UserInfoView
      user={data?.data}
      onToggleDeleteModal={onToggleDeleteModal}
      isNewUser={isNewUser}
      isDeactivationModalOpen={isDeactivationModalOpen}
      onToggleDeactivationModal={onToggleDeactivationModal}
      onUserDeactivate={onUserDeactivate}
      isDeleteModalOpen={isDeleteModalOpen}
      onUserDeleteAction={onUserDeleteAction}
      isInfoModalOpen={isInfoModalOpen}
      setIsInfoModalOpen={setIsInfoModalOpen}
      onChangeUserStatus={onChangeUserStatus}
      pillars={pillars}
      isActionsVisible={isActionsVisible}
      isAuditOrViewAccess={isAuditOrViewAccess}
      isLoading={isLoading}
    />
  );
}

export default UserInfo;
