import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';

import type { IRecoverPasswordSuccessView } from './interfaces/IRecoverPasswordSuccessView';

import styles from './sass/ForgotPasswordSuccess.module.scss';

function RecoverPasswordSuccessView({
  onRecoverPasswordSuccessSubmit,
}: IRecoverPasswordSuccessView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('recoverPasswordSuccess.title')}
      </div>
      <div className={styles.subTitle}>{t('recoverPasswordSuccess.subTitle')}</div>

      <Button
        title={t('common.done')}
        onClick={onRecoverPasswordSuccessSubmit}
      />
    </div>
  );
}

export default RecoverPasswordSuccessView;
