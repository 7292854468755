import { ReactComponent as CeckFolderIcon } from 'assets/icons/checkFolder.svg';
import { ReactComponent as CrossFolderIcon } from 'assets/icons/crossFolder.svg';
import { ReactComponent as TreshIcon } from 'assets/icons/tresh.svg';
import { USER_STATUS } from 'constants/interfaces';

import type { IActionIcon } from '../../interfaces/IActionIcon';

function ActionIcon({ status }: IActionIcon) {
  const actionIcons = {
    [USER_STATUS.draft]: <TreshIcon />,
    [USER_STATUS.invited]: <TreshIcon />,
    [USER_STATUS.active]: <CrossFolderIcon />,
    [USER_STATUS.deactivated]: <CeckFolderIcon />,
  };

  return actionIcons?.[status];
}

export default ActionIcon;
