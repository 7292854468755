export default {
  title: 'Password',

  currentPasswordLabel: 'Current Password',
  newPasswordLabel: 'New Password',
  repeatNewPasswordLabel: 'Repeat New Password',

  enterPassword: 'Enter Password',

  weak: 'Weak',
  strong: 'Strong',

  passwordRulesLabel: 'The new password must:',
  notRepeatPreviousPasswords: 'not be a repeat of previous 5 passwords',
  notContainUserParts: 'not contain parts of the user name within',

  confirmModalTitle: 'Confirm Password Change',
  confirmModalDescription: 'You are about to change your password. For security reasons, you will be logged out of your'
    + ' account after the password change is successful. Do you wish to proceed with changing your password?',
  confirmModalButton: 'Change Password',
};
