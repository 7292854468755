import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DATA_SET_UPLOAD_FILE,
} from 'constants/formFields';
import {
  dataSetUploadSchema,
} from 'utils/validators';

import type { TDataSetUploadSchema } from 'utils/validators';

const useDataSetsUploadForm = () => {
  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
    reset,
    watch,
  } = useForm<TDataSetUploadSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(dataSetUploadSchema),
    defaultValues: {
      [DATA_SET_UPLOAD_FILE]: undefined,
    },
  });

  return {
    control,
    handleSubmit,
    isValid,
    reset,
    watch,
  };
};

export default useDataSetsUploadForm;
