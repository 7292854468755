import i18next from 'i18next';

import { setCompanyTranslations } from '../slices/translates/slice';

import type { Middleware } from '@reduxjs/toolkit';
import type { RootState } from '../types/TStore';

const translatesMiddleware: Middleware = (store) => (next) => async (action) => {
  const result = next(action);
  const state = store.getState() as RootState;

  const loadResourcesFromFile = (language: string, namespace: string, filePath: string) => (
    fetch(filePath).then((response) => response.json().then((data) => {
      const namespaceToCamelCase = namespace.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

      const resources = {
        [namespaceToCamelCase]: { ...data },
      };

      i18next.addResourceBundle(language, 'translation', resources, true, true);
    })));

  if (action.type === setCompanyTranslations.type && state.translates.company) {
    const { languages, translations } = state.translates.company;

    const baseTranslates = translations?.base.filter((item) => (
      languages.includes(item.language.toLowerCase()))) || [];
    const companyTranslates = translations?.company?.filter((item) => (
      languages.includes(item.language.toLowerCase()))) || [];

    const baseTranslatesPromises = baseTranslates
      .map((translate) => loadResourcesFromFile(
        translate.language.toLowerCase(),
        translate.component,
        translate.url,
      ));

    await Promise.all(baseTranslatesPromises);

    const companyTranslatesPromises = companyTranslates
      .map((translate) => loadResourcesFromFile(
        translate.language.toLowerCase(),
        translate.component,
        translate.url,
      ));
    Promise.all(companyTranslatesPromises).then(() => {
      i18next.changeLanguage(state.translates.locale);
    });
  }

  return result;
};

export default translatesMiddleware;
