import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FILTER_BY_STATUS,
  GEO_TYPE_PREFIX,
  ORDER_KEY,
  ORDER_TYPE,
  ORG_TYPE_PREFIX,
  SEARCH,
  SELECT_ALL,
  SELECTED_ITEMS,
  SHOW_ARCHIVED,
  SORT,
} from 'constants/formFields';
import { ORDERS } from 'constants/interfaces';
import { FILTER_USER_STATUSES } from 'constants/user';
import { dataSetsListSchema } from 'utils/validators';

import type { TDataSetsListSchema } from 'utils/validators';

const useDataSetsListForm = () => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm<TDataSetsListSchema>({
    mode: 'onChange',
    resolver: yupResolver(dataSetsListSchema),
    defaultValues: {
      [SEARCH]: '',
      [FILTER_BY_STATUS]: { value: FILTER_USER_STATUSES[0].value, label: FILTER_USER_STATUSES[0].label },
      [SHOW_ARCHIVED]: false,
      [SELECT_ALL]: '',
      [SELECTED_ITEMS]: [],
      [SORT]: {
        [ORDER_KEY]: '',
        [ORDER_TYPE]: ORDERS.asc,
      },
      [ORG_TYPE_PREFIX]: '',
      [GEO_TYPE_PREFIX]: '',
    },
  });

  return {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
  };
};

export default useDataSetsListForm;
