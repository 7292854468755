import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DataSetsRecordButtons from 'components/DataSetsRecordButtons/DataSetsRecordButtons';
import FieldWithCurrency from 'components/FieldWithCurrency/FieldWithCurrency';
import Modal from 'components/Modal/Modal';
import NumberField from 'components/NumberField/NumberField';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY,
  ADDRESS_POST_CODE,
  ADDRESS_STATE,
  ADDRESS_STREET,
  BUSINESS_UNIT_TYPE,
  CODE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING,
  EU_TAXONOMY_CODE,
  FINANCIAL_CONTROL, KEY_SUPPLIER_TIER_1_TOTAL,
  KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY,
  KEY_SUPPLIER_TIER_10_OF_TOTAL,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  OWNERSHIP,
  SPEND,
  SPEND_CURRENCY,
  UNIQUE_IDENTIFIER,
  UPSTREAM_TITLE,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_MASK } from 'constants/inputMasks';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type IFieldError from 'store/types/IFieldError';
import type { ICreateSupplierView } from './interfaces/ICreateSupplierView';

import styles from './sass/CreateSupplierView.module.scss';
import stylesCurrency from 'components/FieldWithCurrency/scss/FieldWithCurrency.module.scss';

function CreateSupplierView({
  control, onCreateSupplierSubmit, isConfirmationModalOpen, onHandleCloseConfirmation,
  onDiscardChangesConfirm, enumData, naceCodesData, naceDivisions, onNaceSelectionChange, isPageLoading,
  onFindCountries, onFindStates, onFindCities, isStatesDisabled, isCitiesDisabled, onChangeCountry, onChangeState,
}: ICreateSupplierView) {
  const { t } = useTranslation();
  const { recordId = '' } = useParams();
  const placeholder = recordId ? t('common.notProvided') : '';

  return (
    <div className={styles.root}>
      {!isPageLoading ? (
        <Panel className={styles.panel}>
          <div className={styles.wrapper}>
            <DataSetsPanel title={t('suppliers.selections.general')}>
              <Controller
                control={control}
                name={NAME}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.supplierName')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={CODE}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.supplierCode')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={UNIQUE_IDENTIFIER}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.uniqueIdentifier')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <div />
              <Controller
                control={control}
                name={VALUE_CHAIN_SECTION}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('suppliers.fields.valueChain')}
                    placeholder={t('suppliers.fields.valueChain')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.valueChainSection]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={UPSTREAM_TITLE}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('suppliers.fields.upstreamTier')}
                    placeholder={t('suppliers.fields.upstreamTier')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.upstreamTitle]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={OWNERSHIP}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('suppliers.fields.ownership')}
                    placeholder={recordId ? placeholder : t('suppliers.fields.ownership')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.ownership]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                  />
                )}
              />
              <div />
              <Controller
                control={control}
                name={EQUITY_SHAREHOLDING}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.equityShareholding')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={FINANCIAL_CONTROL}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.financialControl')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={BUSINESS_UNIT_TYPE}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('suppliers.fields.physicalOrLogical')}
                    placeholder={t('suppliers.fields.physicalOrLogical')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.businessUnitType]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <div />
              <Controller
                control={control}
                name={DATE_OPENED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.dateOpened')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={DATE_CLOSED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.dateClosed')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>
            <DataSetsPanel title={t('suppliers.selections.details')}>
              <Controller
                control={control}
                name={NACE_SECTION}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('suppliers.fields.industry')}
                    placeholder={recordId ? placeholder : t('suppliers.fields.industry')}
                    options={naceCodesData}
                    value={value?.value ? value : undefined}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onNaceSelectionChange();
                    }}
                    error={(error as IFieldError)?.value?.message}
                    isSearchable
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={NACE_CODE}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('suppliers.fields.sector')}
                    placeholder={recordId ? placeholder : t('suppliers.fields.sector')}
                    options={naceDivisions}
                    value={value || null}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    isSearchable
                  />
                )}
              />
              <Controller
                control={control}
                name={EU_TAXONOMY_CODE}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.taxonomy')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

            <DataSetsPanel title={t('suppliers.selections.financials')}>
              <Controller
                control={control}
                name={SPEND}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <FieldWithCurrency name={name} label={t('suppliers.fields.spend')}>
                    <div className={stylesCurrency.fieldWithCurrency}>
                      <NumberField
                        name={name}
                        value={value}
                        error={error?.message}
                        onChange={(values) => onChange(values.value)}
                      />
                      <Controller
                        control={control}
                        name={SPEND_CURRENCY}
                        render={({
                          field: {
                            value: valueCurrency,
                            onChange: onChangeCurrency,
                          },
                          fieldState: { error: errorCurrency },
                        }) => (
                          <Select
                            placeholder={t('common.currency')}
                            options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                            value={valueCurrency?.value ? valueCurrency : undefined}
                            onChange={onChangeCurrency}
                            error={(errorCurrency as IFieldError)?.value?.message}
                            className={stylesCurrency.select}
                          />
                        )}
                      />
                    </div>
                  </FieldWithCurrency>
                )}
              />
              <div />
              <Controller
                control={control}
                name={KEY_SUPPLIER_TIER_1_TOTAL}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <FieldWithCurrency name={name} label={t('suppliers.fields.keySuppliers')}>
                    <div className={stylesCurrency.fieldWithCurrency}>
                      <NumberField
                        name={name}
                        value={value}
                        error={error?.message}
                        onChange={(values) => onChange(values.value)}
                        placeholder={placeholder}
                      />
                      <Controller
                        control={control}
                        name={KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY}
                        render={({
                          field: {
                            value: valueCurrency,
                            onChange: onChangeCurrency,
                          },
                          fieldState: { error: errorCurrency },
                        }) => (
                          <Select
                            placeholder={t('common.currency')}
                            options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                            value={valueCurrency?.value ? valueCurrency : undefined}
                            onChange={onChangeCurrency}
                            error={(errorCurrency as IFieldError)?.value?.message}
                            className={stylesCurrency.select}
                          />
                        )}
                      />
                    </div>
                  </FieldWithCurrency>
                )}
              />
              <Controller
                control={control}
                name={KEY_SUPPLIER_TIER_10_OF_TOTAL}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.keySuppliersInPercentage')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

            <DataSetsPanel title={t('suppliers.selections.address')}>
              <Controller
                control={control}
                name={ADDRESS_COUNTRY}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('suppliers.fields.country')}
                    placeholder={null}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onChangeCountry();
                    }}
                    error={error?.message}
                    loadOptions={onFindCountries}
                    isSearchable
                    isAsync
                    isClearable
                    className={styles.asyncSelect}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_STATE}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('suppliers.fields.state')}
                    placeholder={null}
                    value={value || null}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onChangeState();
                    }}
                    error={error?.message}
                    loadOptions={onFindStates}
                    isSearchable
                    isAsync
                    isClearable
                    className={clsx(styles.asyncSelect, {
                      [styles.inactiveSelect]: isStatesDisabled,
                    })}
                    isDisabled={isStatesDisabled}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_POST_CODE}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.post')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_CITY}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('suppliers.fields.town')}
                    placeholder={null}
                    value={value || null}
                    onChange={onChange}
                    error={error?.message}
                    loadOptions={onFindCities}
                    isSearchable
                    isAsync
                    isClearable
                    className={clsx(styles.asyncSelect, {
                      [styles.inactiveSelect]: isCitiesDisabled || isStatesDisabled,
                    })}
                    isDisabled={isCitiesDisabled}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_STREET}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.street')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_BUILDING}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.building')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_COORDINATES}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('suppliers.fields.coordinates')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

          </div>
          <Separator />
          <Modal
            open={isConfirmationModalOpen}
            title={t('createNewUser.cancelModalTitle')}
            description={t('clients.cancelModalText')}
            confirmTitle={t('common.discardChanges')}
            closeTitle={t('common.keepEditing')}
            confirmVariant="error"
            onClose={() => onHandleCloseConfirmation(false)}
            onReject={() => onHandleCloseConfirmation(false)}
            onConfirm={onDiscardChangesConfirm}
          />
          <DataSetsRecordButtons onClose={onHandleCloseConfirmation} onSubmit={onCreateSupplierSubmit} />
        </Panel>
      ) : null}
    </div>
  );
}

export default CreateSupplierView;
