import { clsx } from 'clsx';

import type ITableBody from './interfaces/ITableBody';

import styles from './sass/TableBody.module.scss';

function TableBody({ children, className }: ITableBody) {
  return (
    <tbody className={clsx(styles.root, className)}>
      {children}
    </tbody>
  );
}

export default TableBody;
