import React from 'react';

import type ICustomDragPreview from './interfaces/ICustomDragPreview';

import styles from './scss/CustomDragPreview.module.css';
import customNodeStyles from './scss/CustomNode.module.css';

export function CustomDragPreview({ monitorProps }: ICustomDragPreview) {
  const { item } = monitorProps;

  return (
    <div className={styles.root}>
      <div className={customNodeStyles.activeEditItem}>
        <span className={customNodeStyles.area}>...</span>
        <span className={customNodeStyles.label}>{item.text}</span>
      </div>
    </div>
  );
}

export default CustomDragPreview;
