import { useEffect } from 'react';
import {
  generatePath,
  Navigate, useLocation, useMatch, useNavigate,
} from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { USERS_ROLE } from 'constants/interfaces';
import { useAppSelector } from 'store/hooks/useApp';
import { authTokensSelector, isFirstLoginSelector } from 'store/slices/auth/selectors';
import { useUserMeQuery } from 'store/slices/user/apis/user';

import Page from './Page';

import type { ReactElement } from 'react';
import type IPageProtected from './interfaces/IPageProtected';

/**
 * Protected page layout
 */
function PageProtected({ redirectUri }: IPageProtected): ReactElement {
  const location = useLocation();
  const isProfileSecurityPage = !!useMatch(generatePath(AppRoutes.profileSecurity));
  const navigate = useNavigate();
  const { pathname } = location;
  const isFirstLogin = useAppSelector(isFirstLoginSelector);
  const { accessToken, verifyToken } = useAppSelector(authTokensSelector);
  const { data } = useUserMeQuery(undefined, {
    skip: !accessToken,
  });
  const { role } = data?.data || {};

  useEffect(() => {
    if (role && pathname === AppRoutes.home) {
      switch (role) {
        case USERS_ROLE.admin:
          navigate(AppRoutes.clients);
          break;
        default:
          navigate(AppRoutes.launchpad);
          break;
      }
    }
  }, [role, pathname, navigate]);

  const isPageAllowed = accessToken || verifyToken || isProfileSecurityPage;

  const companyLanguages = data?.data?.company?.localization?.languages || [];
  const isLanguageSelectorModalOpen = isFirstLogin && companyLanguages.length > 1;

  return isPageAllowed ? (
    <Page isLanguageSelectorModalOpen={isLanguageSelectorModalOpen} />
  ) : (
    <Navigate to={redirectUri} />
  );
}

export default PageProtected;
