import { useTranslation } from 'react-i18next';
import { ReactComponent as GlobIcon } from 'assets/icons/globe.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { ReactComponent as AdminIcon } from 'assets/icons/userWithGear.svg';
import ProfileCard from 'components/ProfileCard/ProfileCard';

import Button from '../../../components/Button/Button';
import Title from '../../../components/Title/Title';
import { WEBLATE_LINKS } from '../../../constants/general';

import type IBaseMultilingualView from './interfaces/IBaseMultilingualView';

import styles from './sass/BaseMultilingualView.module.scss';

function BaseMultilingualView({
  onReleaseBaseTranslation,
  isLoadingReleaseBaseTranslation,
}: IBaseMultilingualView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <Title
          text={t('multilingual.title')}
          className={styles.title}
        />
        <Button
          title={t('multilingual.retrieveBasicTranslations')}
          startIcon={<ReloadIcon />}
          onClick={onReleaseBaseTranslation}
          disabled={isLoadingReleaseBaseTranslation}
        />
      </div>
      <div className={styles.wrapper}>
        <ProfileCard
          icon={<AdminIcon />}
          title={t('multilingual.weblateSuperAdminPanel')}
          description={t('multilingual.weblateSuperAdminPanelDescription')}
          navLink={WEBLATE_LINKS.superAdmin}
          target="_blank"
        />
        <ProfileCard
          icon={<GlobIcon width={30} height={30} />}
          title={t('multilingual.weblateTranslations')}
          description={t('multilingual.weblateTranslationsDescription')}
          navLink={WEBLATE_LINKS.translations}
          target="_blank"
        />
      </div>
    </div>
  );
}

export default BaseMultilingualView;
