import { clsx } from 'clsx';

import Panel from '../Panel/Panel';

import type IDataSetsPanel from './interfaces/IDataSetsPanel';

import styles from './scss/DataSetsPanel.module.scss';

function DataSetsPanel({ className, children, title }: IDataSetsPanel) {
  return (
    <Panel className={styles.root}>
      <div className={styles.header}>
        <p>{title}</p>
      </div>
      <div className={clsx(styles.content, className)}>
        {children}
      </div>
    </Panel>
  );
}

export default DataSetsPanel;
