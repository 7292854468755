import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/Checkbox/Checkbox';
import AUTHENTICATION_TYPES from 'constants/authenticationSetup';
import { AUTHENTICATION_SETUP } from 'constants/formFields';

import type { IAuthenticationType } from '../interfaces/IAuthenticationType';

import styles from 'pages/AuthPages/AuthenticationSetup/sass/AuthenticationSetupView.module.scss';

function AuthenticationSetup({ control }: IAuthenticationType) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.description}>{t('authenticationModal.authenticationSetup.description')}</div>

      <div className={styles.formWrapper}>
        <Controller
          control={control}
          render={({ field: { onChange, value: formValue } }) => (
            <>
              {AUTHENTICATION_TYPES.map(({
                label, value, icon, name,
              }) => (
                <div className={styles.radioWrapper} key={`authenticationSetupView_${value}`}>
                  <Checkbox
                    name={name}
                    checked={formValue === value}
                    value={value}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                    type="radio"
                    label={(
                      <div className={styles.radioLabelWrapper}>
                        <span>{icon}</span>
                        <span className={styles.label}>{label}</span>
                      </div>
                    )}
                  />
                </div>
              ))}
            </>
          )}
          name={AUTHENTICATION_SETUP}
        />
      </div>

    </div>
  );
}

export default AuthenticationSetup;
