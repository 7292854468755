import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';

import type INavTabs from './interfaces/INavTabs';

import styles from './scss/NavTabs.module.scss';

function NavTabs({ navList, className }: INavTabs) {
  return (
    <nav className={className}>
      <ul className={styles.list}>
        {navList.map(({ key, name, to }) => (
          <li key={key}>
            <NavLink
              to={to}
              end
              className={({ isActive }) => clsx(styles.link, {
                [styles.active]: isActive,
              })}
            >
              {name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavTabs;
