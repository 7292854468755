const prefix = 'DATA_SETS/';
export const DATA_SETS_PROFIT_CENTER_LIST_TAG = `${prefix}LIST`;
export const DATA_SETS_OWN_OPERATION_LIST_TAG = `${prefix}OWN_OPERATION_LIST`;

export const DATA_SUPPLIERS_LIST_TAG = `${prefix}SUPPLIERS_LIST`;

export const DATA_CUSTOMERS_LIST_TAG = `${prefix}CUSTOMERS_LIST`;

export const DATA_PRODUCTS_LIST_TAG = `${prefix}PRODUCTS_LIST`;

export const DATA_SETS_PROFIT_CENTER_RECORD_TAG = `${prefix}PROFIT_CENTER_RECORD`;
export const DATA_SETS_OWN_OPERATION_RECORD_TAG = `${prefix}OWN_OPERATION_RECORD`;
export const DATA_SETS_SUPPLIERS_RECORD_TAG = `${prefix}OWN_SUPPLIERS_RECORD`;
export const DATA_SETS_PRODUCTS_RECORD_TAG = `${prefix}PRODUCTS_RECORD`;

export const DATA_SETS_CUSTOMERS_RECORD_TAG = `${prefix}CUSTOMERS_RECORD`;
export const DATA_SETS_UNIQ_GEO_STRUCTURES_TAG = `${prefix}UNIQ_GEO_STRUCTURES`;
export const DATA_SETS_UNIQ_ORG_STRUCTURES_TAG = `${prefix}UNIQ_ORG_STRUCTURES`;
export default DATA_SETS_PROFIT_CENTER_LIST_TAG;
