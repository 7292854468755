import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { clsx } from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import DataSetsItemButtons from 'components/DataSetsItemButtons/DataSetsItemButtons';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import { SELECTED_ITEMS } from 'constants/formFields';
import { NUMBER_FORMATS } from 'constants/interfaces';
import formatNumber from 'utils/formatNumber';

import type { IProfitCentresItem } from './interfaces/IProfitCentresItem';

import styles from './scss/ProfitCentresItem.module.scss';

function ProfitCentresItem({
  item, control, onArchiveModalInfoOpen, onMoveToArchive, onDeArchive, onEditRecord, isActionsVisible,
}: IProfitCentresItem): React.ReactElement {
  const { t } = useTranslation();
  const {
    id, uniqueIdentifier, name, totalRevenueRolling12MPercents, totalRevenueRolling12MActual,
    totalGrossMarginRolling12MPercents, totalGrossMarginRolling12MActual, wipFinishedGoodsStock,
    archived, latestArchivation,
  } = item;

  const hyphen = t('common.hyphen');

  return (
    <TableRow className={clsx(undefined, { [styles.archived]: archived })}>
      <TableCell>
        <div className={styles.staticCell}>
          {archived ? (
            <span>
              {name}
              {latestArchivation ? (
                <InfoIcon
                  onClick={() => onArchiveModalInfoOpen(latestArchivation)}
                />
              ) : null}
            </span>
          ) : null}

          {!archived && (
            isActionsVisible ? (
              <Controller
                control={control}
                render={({ field: { onChange, value, disabled } }) => (
                  <Checkbox
                    key={id}
                    name={`${SELECTED_ITEMS}-${id}`}
                    checked={!!value.includes(id)}
                    value={id}
                    disabled={disabled}
                    onChange={(event) => {
                      onChange(event.target.checked
                        ? [...value, id]
                        : value.filter((selectedItem) => selectedItem !== id));
                    }}
                    label={(<span>{name}</span>)}
                  />
                )}
                name={SELECTED_ITEMS}
              />
            ) : (<span>{name}</span>)
          )}
        </div>
      </TableCell>
      <TableCell>
        {uniqueIdentifier}
      </TableCell>
      <TableCell>
        {totalRevenueRolling12MPercents ? formatNumber(
          {
            value: totalRevenueRolling12MPercents,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {totalRevenueRolling12MActual?.amount ? formatNumber(
          {
            value: totalRevenueRolling12MActual.amount,
            currency: totalRevenueRolling12MActual.currency,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {totalGrossMarginRolling12MPercents ? formatNumber(
          {
            value: totalGrossMarginRolling12MPercents,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {totalGrossMarginRolling12MActual?.amount ? formatNumber(
          {
            value: totalGrossMarginRolling12MActual.amount,
            currency: totalGrossMarginRolling12MActual.currency,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {wipFinishedGoodsStock?.amount ? formatNumber(
          {
            value: wipFinishedGoodsStock.amount,
            currency: wipFinishedGoodsStock.currency,
          },
        ) : hyphen}
      </TableCell>
      {isActionsVisible ? (
        <TableCell>
          <DataSetsItemButtons
            id={id}
            archived={archived}
            onEditRecord={onEditRecord}
            onMoveToArchive={onMoveToArchive}
            onDeArchive={onDeArchive}
          />
        </TableCell>
      ) : null}
    </TableRow>
  );
}

export default ProfitCentresItem;
