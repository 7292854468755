import { clsx } from 'clsx';
import { SEPARATOR_VARIANT } from 'constants/interfaces';

import type ISeparator from './interfaces/ISeparator';

import styles from './scss/Separator.module.scss';

function Separator({ className, position = SEPARATOR_VARIANT.horizontal }: ISeparator) {
  return (
    <div className={clsx(styles.root, styles[position], className)} />
  );
}

export default Separator;
