import { COMPANIES_LIST_TAG } from 'store/tags/companies';

import type ICompaniesRequest from 'store/slices/companies/interfaces/ICompaniesRequest';
import type ICompany from 'store/slices/companies/interfaces/ICompany';
import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type { TApi } from 'store/types/TApi';
import type { AppDispatch, RootState } from 'store/types/TStore';

export const updateCompaniesList = ({
  companiesApi, state, updatedData, dispatch, companyId,
}: {
  companiesApi: TApi<ICompaniesRequest, ICompany[], 'getCompaniesList'>,
  state: RootState,
  updatedData: IBaseQueryResponse<ICompany>,
  dispatch: AppDispatch,
  companyId: number,

}) => {
  companiesApi.util.selectInvalidatedBy(state, [COMPANIES_LIST_TAG])
    .forEach(({ endpointName, originalArgs }) => {
      if (endpointName === 'getCompaniesList') { // Pagination endpoint
        dispatch(companiesApi.util.updateQueryData(
          endpointName,
          originalArgs,
          (cache) => {
            const parseCache = cache;
            const index = parseCache.data.items.findIndex((item) => item.id === companyId);

            if (index !== undefined && index !== -1) {
              parseCache.data.items[index].status = updatedData.data.status;
            }

            return parseCache;
          },
        ));
      }
    });
};

export default updateCompaniesList;
