import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { clsx } from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import DataSetsItemButtons from 'components/DataSetsItemButtons/DataSetsItemButtons';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import { SELECTED_ITEMS } from 'constants/formFields';
import { NUMBER_FORMATS } from 'constants/interfaces';
import formatNumber from 'utils/formatNumber';
import getMultiLabels from 'utils/getMultiLabels';

import type { IProductsItem } from './interfaces/IProductsItem';

import styles from './scss/ProductsItem.module.scss';

function ProductsItem({
  item, control, onArchiveModalInfoOpen, onMoveToArchive, onDeArchive, onEditRecord, isActionsVisible,
}: IProductsItem): React.ReactElement {
  const { t } = useTranslation();
  const {
    id, name, code, profitCenterId, archived, criticalityRating, dateClosed, dateOpened,
    latestArchivation, equityControl, supplier, supplierId, uniqueIdentifier, volumeQty,
    volumeQtyUnit, expectedGrowthDecline,
    relianceOnFreeNaturalResources,
    relianceOnFreeSocialResources,
    keySupplierTier1OfTotal, keySupplierTier1Total, circularityFromDownstreamWaste,
  } = item;

  const hyphen = t('common.hyphen');

  return (
    <TableRow className={clsx(undefined, { [styles.archived]: archived })}>
      <TableCell>
        <div className={styles.staticCell}>
          {archived ? (
            <span>
              {name}
              {latestArchivation ? (
                <InfoIcon
                  onClick={() => onArchiveModalInfoOpen(latestArchivation)}
                />
              ) : null}
            </span>
          ) : null}

          {!archived && (
            isActionsVisible ? (
              <Controller
                control={control}
                render={({ field: { onChange, value, disabled } }) => (
                  <Checkbox
                    key={id}
                    name={`${SELECTED_ITEMS}-${id}`}
                    checked={!!value.includes(id)}
                    value={id}
                    disabled={disabled}
                    onChange={(event) => {
                      onChange(event.target.checked
                        ? [...value, id]
                        : value.filter((selectedItem) => selectedItem !== id));
                    }}
                    label={(<span>{name}</span>)}
                  />
                )}
                name={SELECTED_ITEMS}
              />
            ) : (<span>{name}</span>)
          )}
        </div>
      </TableCell>
      <TableCell>
        {code || hyphen}
      </TableCell>
      <TableCell>
        {uniqueIdentifier || hyphen}
      </TableCell>
      <TableCell>
        {profitCenterId ? getMultiLabels({ values: profitCenterId }) : hyphen}
      </TableCell>
      <TableCell>
        {supplier || hyphen}
      </TableCell>
      <TableCell>
        {supplierId || hyphen}
      </TableCell>
      <TableCell>
        {dateOpened || hyphen}
      </TableCell>
      <TableCell>
        {dateClosed || hyphen}
      </TableCell>
      <TableCell>
        {volumeQty || hyphen}
      </TableCell>
      <TableCell>
        {volumeQtyUnit || hyphen}
      </TableCell>
      <TableCell>
        {equityControl ? formatNumber(
          {
            value: equityControl,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {expectedGrowthDecline ? formatNumber(
          {
            value: expectedGrowthDecline,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {relianceOnFreeNaturalResources ? getMultiLabels({ values: relianceOnFreeNaturalResources }) : hyphen}
      </TableCell>
      <TableCell>
        {relianceOnFreeSocialResources ? getMultiLabels({ values: relianceOnFreeSocialResources }) : hyphen}
      </TableCell>
      <TableCell>
        {criticalityRating || hyphen}
      </TableCell>
      <TableCell>
        {circularityFromDownstreamWaste ? formatNumber(
          {
            value: circularityFromDownstreamWaste,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>

      <TableCell>
        {keySupplierTier1Total?.amount ? formatNumber(
          {
            value: keySupplierTier1Total.amount,
            currency: keySupplierTier1Total.currency,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>

        {keySupplierTier1OfTotal ? formatNumber(
          {
            value: keySupplierTier1OfTotal,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>

      {isActionsVisible ? (
        <TableCell>
          <DataSetsItemButtons
            id={id}
            archived={archived}
            onEditRecord={onEditRecord}
            onMoveToArchive={onMoveToArchive}
            onDeArchive={onDeArchive}
          />
        </TableCell>
      ) : null}
    </TableRow>
  );
}

export default ProductsItem;
