import { useTranslation } from 'react-i18next';

import styles from './sass/FooterLogos.module.scss';

function FooterLogos() {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.item}>{t('login.logo1')}</div>
      <div className={styles.item}>{t('login.logo2')}</div>
      <div className={styles.item}>{t('login.logo3')}</div>
    </div>
  );
}

export default FooterLogos;
