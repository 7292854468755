import { MFA_ENDPOINTS } from 'constants/interfaces';
import {
  AUTH_CHANGE_PASSWORD,
  AUTH_CHECK_TOKEN,
  AUTH_FORGOT_PASSWORD,
  AUTH_INVITE_USER,
  AUTH_RESEND_SMS,
  AUTH_VERIFY_TOKEN,
  RESEND_PASSWORD_LINK,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';
import AUTHENTICATED_USER, {
  START_SYNC_WITH_APP_TAG,
  VERIFY_TOKEN_TAG,
} from 'store/tags/auth';
import COMPANY_TAG from 'store/tags/companies';
import { COMPANY_ADMIN_USERS_LIST_TAG, COMPANY_ADMIN_USERS_USER_DETAIL_TAG } from 'store/tags/companyAdminUsers';

import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type IChangePasswordRequest from '../interfaces/IChangePasswordRequest';
import type ICheckTokenRequest from '../interfaces/ICheckTokenRequest';
import type ICheckTokenResponse from '../interfaces/ICheckTokenResponse';
import type IEndSyncWithAppRequest from '../interfaces/IEndSyncWithAppRequest';
import type IEndSyncWithPhoneRequest from '../interfaces/IEndSyncWithPhoneRequest';
import type IForgotPasswordConfirmRequest from '../interfaces/IForgotPasswordConfirmRequest';
import type IForgotPasswordRequest from '../interfaces/IForgotPasswordRequest';
import type IInviteUserRequest from '../interfaces/IInviteUserRequest';
import type ILoginAdminAsUserRequest from '../interfaces/ILoginAdminAsUserRequest';
import type ILoginRequest from '../interfaces/ILoginRequest';
import type ILoginResponse from '../interfaces/ILoginResponse';
import type INewPasswordRequest from '../interfaces/INewPasswordRequest';
import type IResendPasswordLinkRequest from '../interfaces/IResendPasswordLinkRequest';
import type IStartSyncWithAppResponse from '../interfaces/IStartSyncWithAppResponse';
import type IStartSyncWithPhoneRequest from '../interfaces/IStartSyncWithPhoneRequest';
import type IVerifyTokenResponse from '../interfaces/IVerifyTokenResponse';

export const authApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IBaseQueryResponse<ILoginResponse>, ILoginRequest>({
      invalidatesTags: [AUTHENTICATED_USER],
      query: (user) => ({
        url: 'auth/sign-in',
        method: 'POST',
        body: user,
      }),
    }),
    newPassword: builder.mutation<IBaseQueryResponse<ILoginResponse>, INewPasswordRequest>({
      query: ({ data, token }) => ({
        url: 'auth/users/invite/confirm',
        method: 'POST',
        body: data,
        headers: { authorization: `Bearer ${token}` },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'auth/sign-out',
        method: 'DELETE',
      }),
    }),
    forgotPassword: builder.mutation<void, IForgotPasswordRequest>({
      query: (data) => ({
        url: AUTH_FORGOT_PASSWORD,
        method: 'POST',
        body: data,
      }),
    }),
    resendPasswordLink: builder.mutation<void, IResendPasswordLinkRequest>({
      query: (data) => ({
        url: RESEND_PASSWORD_LINK,
        method: 'POST',
        body: data,
      }),
    }),
    forgotPasswordConfirm: builder.mutation<IBaseQueryResponse<ILoginResponse>, IForgotPasswordConfirmRequest>({
      query: ({ data, token }) => ({
        url: 'auth/users/forgot-password/confirm',
        method: 'POST',
        body: data,
        headers: { authorization: `Bearer ${token}` },
      }),
    }),
    [MFA_ENDPOINTS.startSyncWithApp]: builder.query<IBaseQueryResponse<IStartSyncWithAppResponse>, void>({
      query: () => ({
        url: 'auth/mfa/start-sync-with-app',
        method: 'POST',
      }),
      providesTags: [START_SYNC_WITH_APP_TAG],
    }),
    [MFA_ENDPOINTS.endSyncWithApp]: builder.mutation<IBaseQueryResponse<ILoginResponse>, IEndSyncWithAppRequest>({
      query: (data) => ({
        url: 'auth/mfa/end-sync-with-app',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [START_SYNC_WITH_APP_TAG],
    }),
    [MFA_ENDPOINTS.verifyViaApp]: builder.mutation<IBaseQueryResponse<ILoginResponse>, IEndSyncWithAppRequest>({
      query: (data) => ({
        url: 'auth/mfa/verify-via-app',
        method: 'POST',
        body: data,
      }),
    }),
    [MFA_ENDPOINTS.startSyncWithPhone]: builder.mutation<void, IStartSyncWithPhoneRequest>({
      query: ({ data }) => ({
        url: 'auth/mfa/start-sync-with-phone',
        method: 'POST',
        body: data,
      }),
    }),
    [MFA_ENDPOINTS.endSyncWithPhone]: builder.mutation<IBaseQueryResponse<ILoginResponse>, IEndSyncWithPhoneRequest>({
      query: (data) => ({
        url: 'auth/mfa/end-sync-with-phone',
        method: 'POST',
        body: data,
      }),
    }),
    [MFA_ENDPOINTS.verifyViaPhone]: builder.mutation<IBaseQueryResponse<ILoginResponse>, IEndSyncWithPhoneRequest>({
      query: (data) => ({
        url: 'auth/mfa/verify-via-phone',
        method: 'POST',
        body: data,
      }),
    }),
    [MFA_ENDPOINTS.sendSms]: builder.mutation<void, void>({
      query: () => ({
        url: AUTH_RESEND_SMS,
        method: 'POST',
      }),
    }),
    inviteUser: builder.mutation<void, IInviteUserRequest>({
      query: ({ email }) => ({
        url: AUTH_INVITE_USER,
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: [COMPANY_ADMIN_USERS_USER_DETAIL_TAG, COMPANY_ADMIN_USERS_LIST_TAG, COMPANY_TAG],
    }),
    changePassword: builder.mutation<IBaseQueryResponse<ILoginResponse>, IChangePasswordRequest>({
      query: (data) => ({
        url: AUTH_CHANGE_PASSWORD,
        method: 'POST',
        body: data,
      }),
    }),
    verifyToken: builder.query<IBaseQueryResponse<IVerifyTokenResponse>, void>({
      query: () => ({
        url: AUTH_VERIFY_TOKEN,
      }),
      providesTags: [VERIFY_TOKEN_TAG],
    }),
    checkToken: builder.query<IBaseQueryResponse<ICheckTokenResponse>, ICheckTokenRequest>({
      query: (params) => ({
        url: AUTH_CHECK_TOKEN,
        method: 'POST',
        body: params,
      }),
    }),
    loginAdminAsUser: builder.mutation<IBaseQueryResponse<ILoginResponse>, ILoginAdminAsUserRequest >({
      query: (data) => ({
        url: 'auth/admin/become-user',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useNewPasswordMutation,
  useForgotPasswordMutation,
  useResendPasswordLinkMutation,
  useForgotPasswordConfirmMutation,
  useStartSyncWithAppQuery,
  useEndSyncWithAppMutation,
  useVerifyViaAppMutation,
  useStartSyncWithPhoneMutation,
  useEndSyncWithPhoneMutation,
  useVerifyViaPhoneMutation,
  useSendSmsMutation,
  useInviteUserMutation,
  useChangePasswordMutation,
  useVerifyTokenQuery,
  useLazyVerifyTokenQuery,
  useCheckTokenQuery,
  useLoginAdminAsUserMutation,
} = authApi;
