import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ModuleIcon } from 'assets/icons/module.svg';
import Badge from 'components/Badge/Badge';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';
import { MODULE_STATUSES } from 'constants/moduleStatuses';

import type IModuleManagementSection from './interfaces/IModuleManagementSection';

import styles from './scss/ModuleManagementSection.module.scss';

function ModuleManagementSection({
  pillars, pillarModules,
}: IModuleManagementSection) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId = '' } = useParams();

  const onHandleEdit = () => {
    const redirectPath = generatePath(AppRoutes.clientProfile, { clientId });
    navigate(
      generatePath(AppRoutes.moduleManagement, { clientId }),
      { state: { redirectPath } },
    );
  };

  return (
    <InfoPanel
      title={t('clients.moduleManagement')}
      icon={<ModuleIcon />}
      onEdit={onHandleEdit}
    >
      {pillars.map(({ id, name }, index, array) => (
        <Fragment key={`${name}-${id}`}>
          <h3 className={styles.title}>{name}</h3>
          <div className={styles.wrapper}>
            {pillarModules
              .filter(({ pillarId }) => pillarId === id)
              .map((pillar) => (
                <div className={styles.item} key={`${pillar.name}-${pillar.pillarId}`}>
                  <p>{pillar.name}</p>
                  <div>
                    <Badge
                      status={MODULE_STATUSES[pillar.access].label}
                      color={MODULE_STATUSES[pillar.access].color}
                    />
                  </div>
                </div>
              ))}
          </div>
          {array.length - 1 !== index ? <Separator /> : null }
        </Fragment>
      ))}
    </InfoPanel>
  );
}

export default ModuleManagementSection;
