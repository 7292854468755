import { useTranslation } from 'react-i18next';
import { ReactComponent as DatabaseIcon } from 'assets/icons/database.svg';
import { ReactComponent as NetworkIcon } from 'assets/icons/network.svg';
import { ReactComponent as UserSettingsIcon } from 'assets/icons/user-settings.svg';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import Title from 'components/Title/Title';
import { AppRoutes } from 'constants/AppRoutes';
import { USER_PILLAR_MODULES } from 'constants/interfaces';

import styles from './sass/SettingsView.module.scss';

function SettingsView() {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <Title text={t('pillarsAndModules.settings')} className={styles.title} />
      <div className={styles.wrapper}>
        <ProfileCard
          icon={<UserSettingsIcon />}
          title={t('pillarsAndModules.settings/user-management')}
          description={t('pillarsAndModules.settings/user-management-description')}
          navLink={AppRoutes.userManagement}
          className={styles.card}
          pillarModuleKey={USER_PILLAR_MODULES.settings.userManagement}
        />
        <ProfileCard
          icon={<DatabaseIcon />}
          title={t('pillarsAndModules.settings/data-sets-management')}
          description={t('pillarsAndModules.settings/data-sets-management-description')}
          navLink={AppRoutes.ownOperations}
          className={styles.card}
          pillarModuleKey={USER_PILLAR_MODULES.settings.dataSetsManagement}
        />
        <ProfileCard
          icon={<NetworkIcon />}
          title={t('pillarsAndModules.settings/ui-configuration')}
          description={t('pillarsAndModules.settings/ui-configuration-description')}
          navLink={AppRoutes.companyUiConfiguration}
          className={styles.card}
          pillarModuleKey={USER_PILLAR_MODULES.settings.uiConfiguration}
        />
      </div>
    </div>
  );
}

export default SettingsView;
