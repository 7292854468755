import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';

import type { IDeactivatedUserView } from './interfaces/IDeactivatedUserView';

import styles from './sass/DeactivatedUser.module.scss';

function DeactivatedUserView({
  onRecoverPasswordSuccessSubmit,
}: IDeactivatedUserView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('deactivatedUser.title')}
      </div>
      <div className={styles.description}>
        <Trans
          i18nKey="deactivatedUser.description"
          t={t}
          components={{
            br: <br />,
          }}
        />
      </div>

      <Button
        title={t('deactivatedUser.backToLogin')}
        onClick={onRecoverPasswordSuccessSubmit}
      />
    </div>
  );
}

export default DeactivatedUserView;
