import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ADDRESS_CITY, ADDRESS_COUNTRY, ADDRESS_STATE } from '../constants/formFields';
import {
  useLazyGeoLocationsCitiesQuery,
  useLazyGeoLocationsCountriesQuery,
  useLazyGeoLocationsStatesQuery,
} from '../store/slices/geoLocations/apis/geoLocationsApi';
import createGeoLocationsOptions from '../utils/createGeoLocationsOptions';
import useDebouncedCallback from './useDebouncedCallback';

import type { FieldValues, Path, PathValue } from 'react-hook-form';
import type IOption from '../components/MappingModal/interfaces/IOption';
import type { IUseGeoLocations } from './interfaces/IUseGeoLocations';
import type { TAsyncSelectResolve } from './interfaces/TAsyncSelectResolve';

const useGeoLocations = <T extends FieldValues>({ watch, setValue }: IUseGeoLocations<T>) => {
  const { recordId = '' } = useParams();
  const [isStatesEditable, setIsStatesEditable] = useState(!recordId);
  const [isCitiesEditable, setIsCitiesEditable] = useState(!recordId);
  const [geoLocationsCountries] = useLazyGeoLocationsCountriesQuery();
  const [geoLocationsStates] = useLazyGeoLocationsStatesQuery();
  const [geoLocationsCities] = useLazyGeoLocationsCitiesQuery();

  // TODO Remove all the anys
  const countryField = ADDRESS_COUNTRY as Path<T>;
  const stateField = ADDRESS_STATE as Path<T>;
  const cityField = ADDRESS_CITY as Path<T>;
  const emptyOption = null as PathValue<T, Path<T>>;

  const geoLocationsCountriesDebounced = useDebouncedCallback(
    (inputValue: string, resolve: TAsyncSelectResolve) => {
      geoLocationsCountries({ keyword: inputValue }).unwrap().then((data) => {
        const countriesOptions: IOption[] = createGeoLocationsOptions({ data: data?.data || [] });
        resolve(countriesOptions);
      });
    },
  );

  const geoLocationsStatesDebounced = useDebouncedCallback(
    (inputValue: string, resolve: TAsyncSelectResolve) => {
      const country = watch(countryField).value;
      geoLocationsStates({ keyword: inputValue, country }).unwrap().then((data) => {
        const statesOptions: IOption[] = createGeoLocationsOptions({ data: data?.data || [], isState: true });
        resolve(statesOptions);
      });
    },
  );

  const geoLocationsCitiesDebounced = useDebouncedCallback(
    (inputValue: string, resolve: TAsyncSelectResolve) => {
      const country = watch(countryField).value;
      const placeId = watch(stateField).value;
      geoLocationsCities({ keyword: inputValue, country, placeId }).unwrap().then((data) => {
        const statesOptions: IOption[] = createGeoLocationsOptions({ data: data?.data || [] });
        resolve(statesOptions);
      });
    },
  );

  const onFindCountries = (inputValue: string): Promise<IOption[]> => new Promise((resolve) => {
    if (inputValue) {
      geoLocationsCountriesDebounced(inputValue, resolve);
    } else {
      resolve([]);
    }
  });

  const onFindStates = (inputValue: string): Promise<IOption[]> => new Promise((resolve) => {
    if (inputValue) {
      geoLocationsStatesDebounced(inputValue, resolve);
    } else {
      resolve([]);
    }
  });

  const onFindCities = (inputValue: string): Promise<IOption[]> => new Promise((resolve) => {
    if (inputValue) {
      geoLocationsCitiesDebounced(inputValue, resolve);
    } else {
      resolve([]);
    }
  });
  const isStatesDisabled = !watch(countryField)?.value || !isStatesEditable;
  const isCitiesDisabled = !watch(stateField)?.value || isStatesDisabled || !isCitiesEditable;

  const onChangeCountry = () => {
    setValue(stateField, emptyOption);
    setValue(cityField, emptyOption);
    setIsStatesEditable(true);
    setIsCitiesEditable(true);
  };

  const onChangeState = () => {
    setValue(cityField, emptyOption);
    setIsCitiesEditable(true);
  };

  return {
    onFindCountries, onFindStates, onFindCities, isStatesDisabled, isCitiesDisabled, onChangeCountry, onChangeState,
  };
};

export default useGeoLocations;
