import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import AppRoutes from 'constants/AppRoutes';

import LinkButton from '../LinkButton/LinkButton';

import type { IBannerProps } from './interfaces/IBanner';

import styles from './scss/Banner.module.scss';

function Banner({
  title, onButtonClick, buttonText,
}: IBannerProps) {
  return (
    <div className={styles.banner}>
      <div className={styles.title}>
        {title}
      </div>
      <LinkButton
        to={AppRoutes.clients}
        onClick={onButtonClick}
        className={styles.button}
        title={buttonText}
        endIcon={<ArrowRightIcon />}
      />
    </div>
  );
}

export default Banner;
