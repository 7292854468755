import { useMemo } from 'react';
import {
  generatePath, matchPath, Outlet, useLocation, useParams,
} from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import NavTabs from 'components/NavTabs/NavTabs';
import AppRoutes from 'constants/AppRoutes';
import { clientsInfoNav } from 'constants/navigationLists';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useGetCompanyDetailsQuery } from 'store/slices/companies/apis/companiesApi';

import ClientProfileHeader from './components/ClientProfileHeader/ClientProfileHeader';

import styles from './scss/ClientProfileLayout.module.scss';

function ClientProfileLayout() {
  const { clientId = '' } = useParams();
  const { pathname } = useLocation();

  const fetchQuery = clientId ? {
    companyId: +clientId,
  } : skipToken;

  const { data: company } = useGetCompanyDetailsQuery(fetchQuery);

  const isNavMenuVisible = [
    AppRoutes.clientProfile,
    AppRoutes.clientUsers,
  ].some((path) => matchPath(path, pathname));

  const clientCrumb = {
    pathname: generatePath(AppRoutes.clientProfile, { clientId }),
    label: company?.data.name || '',
    path: generatePath(AppRoutes.clientProfile, { clientId }),
  };

  const { breadcrumbs } = useBreadcrumbs({
    mathCrumbs: [clientCrumb],
  });

  const clientsNavList = useMemo(() => clientsInfoNav(clientId), [clientId]);

  return (
    <div className={styles.root}>
      <BreadcrumbsNav breadcrumbs={breadcrumbs} />
      <div className={styles.wrapper}>
        {isNavMenuVisible ? <ClientProfileHeader title={company?.data.name || ''} /> : null}
        {isNavMenuVisible ? <NavTabs navList={clientsNavList} /> : null}
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default ClientProfileLayout;
