export default {
  pageTitle: 'Login',
  title: 'Welcome to Attaima',
  forgotPassword: 'Forgot password?',
  logout: 'Logout',
  logo1: 'Logo 1',
  logo2: 'Logo 2',
  logo3: 'Logo 3',
  invalidCredentials: 'Invalid Credentials',
};
