import { useTranslation } from 'react-i18next';
import { ReactComponent as DisabledIcon } from 'assets/icons/disabled.svg';
import { ReactComponent as EnabledIcon } from 'assets/icons/enabled.svg';
import { clsx } from 'clsx';
import { STATUS } from 'constants/interfaces';

import type IStatusBadge from './interfaces/IStatusBadge';

import styles from './scss/StatusBadge.module.scss';

function StatusBadge({ status, title, className }: IStatusBadge) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.root, className)}>
      <div>{status === STATUS.enabled ? <EnabledIcon /> : <DisabledIcon />}</div>
      <p className={styles.status}>{title || t(`common.${status}`)}</p>
    </div>
  );
}

export default StatusBadge;
