import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import AUTHENTICATION_TYPES from 'constants/authenticationSetup';
import { AUTHENTICATION_SETUP } from 'constants/formFields';

import AuthenticationSetupModal from './components/AuthenticationSetupModal';

import type { IAuthenticationSetupView } from './interfaces/IAuthenticationSetupView';

import styles from './sass/AuthenticationSetupView.module.scss';

function AuthenticationSetupView({
  control,
  onAuthenticationSetupSubmit,
  steps,
  setIsSecretCodeVisible,
  isModalVisible,
}: IAuthenticationSetupView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('authenticationSetup.title')}
      </div>
      <div className={styles.subTitle}>{t('authenticationSetup.subTitle')}</div>
      <div className={styles.formWrapper}>
        <Controller
          control={control}
          render={({ field: { onChange, value: formValue } }) => (
            <>
              {AUTHENTICATION_TYPES.map(({
                label, value, icon, name,
              }, index) => (
                <div className={styles.radioWrapper} key={`authenticationSetupView_${index.toString()}`}>
                  <Checkbox
                    key={value}
                    name={name}
                    checked={formValue === value}
                    value={value}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                    type="radio"
                    label={(
                      <div className={styles.radioLabelWrapper}>
                        <span>{icon}</span>
                        <span className={styles.label}>{label}</span>
                      </div>
                    )}
                  />
                </div>

              ))}
            </>
          )}
          name={AUTHENTICATION_SETUP}
        />
      </div>
      <Button
        className={styles.button}
        title={t('common.continue')}
        onClick={onAuthenticationSetupSubmit}
      />

      <AuthenticationSetupModal
        steps={steps}
        setIsSecretCodeVisible={setIsSecretCodeVisible}
        isModalVisible={isModalVisible}
      />
    </div>
  );
}

export default AuthenticationSetupView;
