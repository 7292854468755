import type { IDownloadFileHandler } from './interfaces/IDownloadFileHandler';

export const downloadFileHandler = async ({
  filename, response,
}: IDownloadFileHandler) => {
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = filename;
  link.click();
  link.remove();
};

export default downloadFileHandler;
