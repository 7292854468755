import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type IAuthStore from './interfaces/IAuthStore';

export const initialState: IAuthStore = {
  accessToken: '',
  refreshToken: '',
  verifyToken: '',
  userAccessToken: '',
  userRefreshToken: '',
  isFirstLogin: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<Partial<IAuthStore>>) => ({ ...state, ...action.payload }),
    clearAuthStore: () => ({ ...initialState }),
    logoutUser: () => initialState,
  },

  extraReducers: () => {},
});

export const {
  setAuthState, clearAuthStore, logoutUser,
} = authSlice.actions;

export default authSlice.reducer;
