import { clsx } from 'clsx';

import type ITable from './interfaces/ITable';

import styles from './sass/Table.module.scss';

function Table({ children, className }: ITable) {
  return (
    <div className={clsx(styles.root, className)}>
      <table className={styles.table}>
        {children}
      </table>
    </div>

  );
}

export default Table;
