import {
  COMPANIES_ACTIVATE,
  COMPANIES_ADMINS, COMPANIES_ARCHIVE, COMPANIES_CREATE, COMPANIES_DE_ARCHIVE,
  COMPANIES_DELETE, COMPANIES_DETAILS, COMPANIES_LANGUAGES, COMPANIES_LIST,
  COMPANIES_PERMISSIONS, COMPANIES_SECURITY, COMPANIES_UI_CONFIGURATION, COMPANIES_UPDATE_INFO,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';
import COMPANY_TAG, { COMPANIES_LIST_TAG, COMPANY_PERMISSION_TAG } from 'store/tags/companies';
import USER_ME_TAG from 'store/tags/user';
import { updateCompaniesList } from 'utils/companiesApi';
import { paginationForceRefetch, paginationMerge, paginationSerializeQueryArgs } from 'utils/storeHelpers';

import type { IBaseQueryPaginationResponse } from 'store/types/IBaseQueryPaginationResponse';
import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type { RootState } from '../../../types/TStore';
import type IArchiveCompanyRequest from '../interfaces/IArchiveCompanyRequest';
import type ICompaniesRequest from '../interfaces/ICompaniesRequest';
import type ICompany from '../interfaces/ICompany';
import type ICreateCompanyRequest from '../interfaces/ICreateCompanyRequest';
import type ICreateCompanyResponse from '../interfaces/ICreateCompanyResponse';
import type IPermissions from '../interfaces/IPermissions';
import type IUpdateCompanyAdminRequest from '../interfaces/IUpdateCompanyAdminRequest';
import type IUpdateCompanyInfoRequest from '../interfaces/IUpdateCompanyInfoRequest';
import type IUpdateCompanyLanguagesRequest from '../interfaces/IUpdateCompanyLanguagesRequest';
import type IUpdateCompanyPermissionRequest from '../interfaces/IUpdateCompanyPermissionRequest';
import type IUpdateCompanySecurityRequest from '../interfaces/IUpdateCompanySecurityRequest';
import type IUpdateCompanyUiConfigurationRequest from '../interfaces/IUpdateCompanyUiConfigurationRequest';

export const companiesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompaniesList: builder.query<IBaseQueryPaginationResponse<ICompany[]>, ICompaniesRequest>({
      query: ({
        search, ...rest
      }) => ({
        url: COMPANIES_LIST,
        method: 'GET',
        params: {
          keyword: search,
          ...rest,
        },
      }),
      merge: paginationMerge,
      serializeQueryArgs: paginationSerializeQueryArgs,
      forceRefetch: paginationForceRefetch,
      providesTags: (result) => (
        result
          ? [...result.data.items.map(({ id }) => ({ type: COMPANIES_LIST_TAG, id })), COMPANIES_LIST_TAG]
          : [COMPANIES_LIST_TAG]
      ),
    }),

    getCompanyDetails: builder.query<IBaseQueryResponse<ICompany>, { companyId: number }>({
      query: ({ companyId }) => ({
        url: COMPANIES_DETAILS.replace('{companyId}', `${companyId}`),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: COMPANY_TAG, id: result?.data.id }],
    }),

    getCompanyPermissions: builder.query<IBaseQueryResponse<IPermissions>, { companyId: number }>({
      query: ({ companyId }) => ({
        url: COMPANIES_PERMISSIONS.replace('{companyId}', `${companyId}`),
        method: 'GET',
      }),
      providesTags: [COMPANY_PERMISSION_TAG],
    }),

    createCompany: builder.mutation<IBaseQueryResponse<ICreateCompanyResponse>, ICreateCompanyRequest>({
      query: (data) => ({
        url: COMPANIES_CREATE,
        method: 'POST',
        body: data,
      }),
    }),

    updateCompanyInfo: builder.mutation<IBaseQueryResponse<ICompany>, IUpdateCompanyInfoRequest>({
      query: ({ companyId, ...rest }) => ({
        url: COMPANIES_UPDATE_INFO.replace('{companyId}', `${companyId}`),
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (result, error, { companyId }) => [{ type: COMPANY_TAG, id: companyId }],
    }),

    updateCompanyLanguages:
      builder.mutation<IBaseQueryResponse<ICompany>, IUpdateCompanyLanguagesRequest>({
        query: ({ companyId, ...rest }) => ({
          url: COMPANIES_LANGUAGES.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: (result, error, { companyId }) => [{ type: COMPANY_TAG, id: companyId }],
      }),

    updateCompanyAdmins:
      builder.mutation<IBaseQueryResponse<ICompany>, IUpdateCompanyAdminRequest>({
        query: ({ companyId, ...rest }) => ({
          url: COMPANIES_ADMINS.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: (result, error, { companyId }) => [{ type: COMPANY_TAG, id: companyId }],
      }),

    updateUiConfiguration:
      builder.mutation<IBaseQueryResponse<ICompany>, IUpdateCompanyUiConfigurationRequest>({
        query: ({ companyId, ...rest }) => ({
          url: COMPANIES_UI_CONFIGURATION.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: (result, error, { companyId }) => [
          { type: COMPANY_TAG, id: companyId },
          { type: USER_ME_TAG }],
      }),

    updateCompanyPermissions:
      builder.mutation<IBaseQueryResponse<IPermissions>, IUpdateCompanyPermissionRequest>({
        query: ({ companyId, ...rest }) => ({
          url: COMPANIES_PERMISSIONS.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: [COMPANY_PERMISSION_TAG],
      }),

    updateCompanySecurity:
      builder.mutation<IBaseQueryResponse<ICompany>, IUpdateCompanySecurityRequest>({
        query: ({ companyId, ...rest }) => ({
          url: COMPANIES_SECURITY.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: (result, error, { companyId }) => [{ type: COMPANY_TAG, id: companyId }],
      }),

    activateCompany:
      builder.mutation<IBaseQueryResponse<ICompany>, { companyId: number }>({
        query: ({ companyId }) => ({
          url: COMPANIES_ACTIVATE.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
        }),
        invalidatesTags: (result, error, { companyId }) => [
          { type: COMPANY_TAG, id: companyId },
          { type: COMPANIES_LIST_TAG, id: companyId },
        ],
        // onQueryStarted for updating company status in the pagination list
        onQueryStarted: async ({ companyId }, { getState, dispatch, queryFulfilled }) => {
          const { data: updatedData } = await queryFulfilled;
          updateCompaniesList({
            companiesApi, state: getState() as RootState, updatedData, dispatch, companyId,
          });
        },
      }),

    archiveCompany:
      builder.mutation<IBaseQueryResponse<ICompany>, IArchiveCompanyRequest>({
        query: ({ companyId, ...rest }) => ({
          url: COMPANIES_ARCHIVE.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: (result, error, { companyId }) => [
          { type: COMPANY_TAG, id: companyId },
          { type: COMPANIES_LIST_TAG, id: companyId },
        ],
        // onQueryStarted for updating company status in the pagination list
        onQueryStarted: async ({ companyId }, { getState, dispatch, queryFulfilled }) => {
          const { data: updatedData } = await queryFulfilled;
          updateCompaniesList({
            companiesApi, state: getState() as RootState, updatedData, dispatch, companyId,
          });
        },
      }),

    deArchiveCompany:
      builder.mutation<IBaseQueryResponse<ICompany>, { companyId: number }>({
        query: ({ companyId }) => ({
          url: COMPANIES_DE_ARCHIVE.replace('{companyId}', `${companyId}`),
          method: 'PATCH',
        }),
        invalidatesTags: (result, error, { companyId }) => [
          { type: COMPANY_TAG, id: companyId },
          { type: COMPANIES_LIST_TAG, id: companyId },
        ],
        // onQueryStarted for updating company status in the pagination list
        onQueryStarted: async ({ companyId }, { getState, dispatch, queryFulfilled }) => {
          const { data: updatedData } = await queryFulfilled;
          updateCompaniesList({
            companiesApi, state: getState() as RootState, updatedData, dispatch, companyId,
          });
        },

      }),

    deleteCompany:
      builder.mutation<void, { companyId: number }>({
        query: ({ companyId }) => ({
          url: COMPANIES_DELETE.replace('{companyId}', `${companyId}`),
          method: 'DELETE',
        }),
      }),
  }),
});

export const {
  useGetCompaniesListQuery,
  useGetCompanyDetailsQuery,
  useGetCompanyPermissionsQuery,
  useCreateCompanyMutation,
  useUpdateCompanyInfoMutation,
  useUpdateCompanyLanguagesMutation,
  useUpdateCompanyAdminsMutation,
  useUpdateUiConfigurationMutation,
  useUpdateCompanyPermissionsMutation,
  useUpdateCompanySecurityMutation,
  useActivateCompanyMutation,
  useArchiveCompanyMutation,
  useDeArchiveCompanyMutation,
  useDeleteCompanyMutation,
} = companiesApi;

export default companiesApi;
