import { useUserMeQuery } from 'store/slices/user/apis/user';

import SettingsView from './SettingsView';

import type { ReactElement } from 'react';

function Settings(): ReactElement {
  useUserMeQuery();
  return (
    <SettingsView />
  );
}

export default Settings;
