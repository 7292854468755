import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { useUpdateUserMeMutation } from 'store/slices/user/apis/user';

import usePersonalInfoForm from './hooks/usePersonalInfoForm';
import PersonalInfoView from './PersonalInfoView';

import type { ReactElement } from 'react';

function PersonalInfo(): ReactElement {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
  } = usePersonalInfoForm();

  const [updateUserMe] = useUpdateUserMeMutation();

  const onPersonalInfoSubmit = handleSubmit((values) => {
    const requestData = {
      firstName: values.firstName,
      lastName: values.lastName,
    };
    updateUserMe(requestData).unwrap().then(() => {
      reset(undefined, { keepValues: true });
    }).catch(() => {});
  });

  const onCancel = () => {
    navigate(AppRoutes.profile);
  };

  return (
    <PersonalInfoView control={control} onPersonalInfoSubmit={onPersonalInfoSubmit} onCancel={onCancel} />
  );
}

export default PersonalInfo;
