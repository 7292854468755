import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import { SHOW_ARCHIVED } from 'constants/formFields';
import { USER_PILLAR_MODULES } from 'constants/interfaces';
import useDataSetListActions from 'hooks/useDataSetListActions';
import useDownloadExample from 'hooks/useDownloadExample';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import {
  useCustomerArchiveMutation,
  useCustomerDeArchiveMutation,
  useCustomerImportRecordsMutation, useCustomerListQuery,
  useGetNaceCodeTreeQuery, useLazyCustomerExampleQuery,
  useProfitCenterListQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';

import useDataSetsListForm from '../ProfitCentres/hooks/useDataSetsListForm';
import CustomersView from './CustomersView';

import type ICustomerItem from 'store/slices/dataSets/interfaces/ICustomerItem';
import type IProfitCentresParams from '../ProfitCentres/interfaces/IProfitCentresParams';
import type { TUseCustomerImportRecordsMutation } from './interfaces/TUseCustomerImportRecordsMutation';

function Customers() {
  const navigate = useNavigate();
  const {
    isActionsVisible,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.dataSetsManagement },
  );

  const [getCustomersheetExample] = useLazyCustomerExampleQuery();
  const {
    control, getValues, setValue, watch,
  } = useDataSetsListForm();
  const [orderBy, setOrderBy] = useState('');
  const [params, setParams] = useState<IProfitCentresParams>({});

  const isArchived = watch(SHOW_ARCHIVED);
  const { search: searchParam, offset } = params;

  const { data } = useCustomerListQuery({
    orderBy, keyword: searchParam, archived: isArchived, offset,
  });

  const [importRecords] = useCustomerImportRecordsMutation();

  const {
    uploadControl, isMappingModalVisible, onCloseMappingModal, isImportErrorsModalVisible,
    setIsImportErrorsModalVisible,
  } = useDownloadExample<TUseCustomerImportRecordsMutation>({ importRecords });

  const { items } = data?.data || {};

  const [deArchive] = useCustomerDeArchiveMutation();

  const [archive] = useCustomerArchiveMutation();

  const { data: naceCodeTreeData = { data: {} } } = useGetNaceCodeTreeQuery();

  const {
    isArchiveModalVisible,
    isArchiveModalInfoVisible,
    latestArchivation,
    isLineMark,
    onSortChange,
    onSearchChange,
    onSelectAll,
    onArchiveConfirm,
    onArchive,
    onArchiveModalInfoOpen,
    onMoveToArchive,
    onDeArchive,
    onPageChange,
    isArchiveSelectedItemsVisible,
    setIsArchiveModalVisible,
    setIsArchiveModalInfoVisible,
    selectedItems,
  } = useDataSetListActions<ICustomerItem[]>({
    items,
    archiveAction: archive,
    deArchiveAction: deArchive,
    setOrderBy,
    setParams,
    getValues,
    setValue,
    watch,
  });

  const { data: profitCenterData } = useProfitCenterListQuery({});

  const isWithSearch = !!searchParam;

  const isProfitCenterEmpty = !profitCenterData?.data?.meta?.count && isActionsVisible;

  const isUploadFileVisible = isActionsVisible && !isProfitCenterEmpty;

  const isEmptyList = !data?.data?.meta?.count;

  const currentTotalItems = items?.length || 0;

  const onDownloadExample = () => {
    getCustomersheetExample();
  };

  const onEditRecord = (recordId: number) => {
    navigate(generatePath(AppRoutes.customerEdit, { recordId: String(recordId) }));
  };

  const onAddRecord = () => {
    navigate(AppRoutes.customerCreate);
  };

  const meta = data?.data?.meta;

  return (
    <CustomersView
      isEmptyList={isEmptyList}
      uploadControl={uploadControl}
      isUploadFileVisible={isUploadFileVisible}
      isMappingModalVisible={isMappingModalVisible}
      setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
      onCloseMappingModal={onCloseMappingModal}
      isImportErrorsModalVisible={isImportErrorsModalVisible}
      onDownloadExample={onDownloadExample}
      importRecords={importRecords}
      control={control}
      isArchiveModalVisible={isArchiveModalVisible}
      isArchiveModalInfoVisible={isArchiveModalInfoVisible}
      latestArchivation={latestArchivation}
      isLineMark={isLineMark}
      onSortChange={onSortChange}
      onSearchChange={onSearchChange}
      onSelectAll={onSelectAll}
      onArchiveConfirm={onArchiveConfirm}
      onArchive={onArchive}
      onArchiveModalInfoOpen={onArchiveModalInfoOpen}
      onMoveToArchive={onMoveToArchive}
      onDeArchive={onDeArchive}
      onPageChange={onPageChange}
      isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
      setIsArchiveModalVisible={setIsArchiveModalVisible}
      setIsArchiveModalInfoVisible={setIsArchiveModalInfoVisible}
      isActionsVisible={isActionsVisible}
      items={items}
      selectedItems={selectedItems}
      currentTotalItems={currentTotalItems}
      onEditRecord={onEditRecord}
      onAddRecord={onAddRecord}
      isProfitCenterEmpty={isProfitCenterEmpty}
      meta={meta}
      naceCodeTreeData={naceCodeTreeData}
      isWithSearch={isWithSearch}
    />
  );
}

export default Customers;
