import { Trans, useTranslation } from 'react-i18next';
import {
  generatePath, useMatch, useNavigate, useParams,
} from 'react-router-dom';
import Button from 'components/Button/Button';
import { AppRoutes } from 'constants/AppRoutes';
import { AUTHENTICATION_GOOGLE } from 'constants/formFields';
import { AUTHENTICATOR_SETUP_MODAL_STEPS } from 'constants/interfaces';
import { useStartSyncWithAppQuery } from 'store/slices/auth/apis/auth';

import type { IQRCode } from '../interfaces/IQRCode';

import styles from './sass/QRCode.module.scss';

function QRCode({ setIsSecretCodeVisible }: IQRCode) {
  const { type = AUTHENTICATION_GOOGLE } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isProfileSecurityPage = !!useMatch(generatePath(
    AppRoutes.profileSecurity,
    { step: AUTHENTICATOR_SETUP_MODAL_STEPS.qrCode, type },
  ));
  const { data } = useStartSyncWithAppQuery();

  const onSecretCodeVisible = () => {
    const path = isProfileSecurityPage ? AppRoutes.profileSecurity : AppRoutes.authenticationSetup;
    setIsSecretCodeVisible(true);
    const secretPath = generatePath(
      path,
      { step: AUTHENTICATOR_SETUP_MODAL_STEPS.secretCode, type },
    );
    navigate(secretPath);
  };

  return (
    <div className={styles.root}>
      <ul>
        <li>
          <Trans
            i18nKey="authenticationModal.qrCode.inTheAuthenticator"
            t={t}
            components={{
              authenticatorType: <span>{type}</span>,
            }}
          />
        </li>
        <li>
          {t('authenticationModal.qrCode.selectScan')}
        </li>
      </ul>

      <div className={styles.qrCodeWrapper}>
        <img src={data?.data.qrCode} alt={t('authenticationModal.qrCode.imgAlt')} />
        <Button
          title={t('authenticationModal.qrCode.linkText')}
          onClick={onSecretCodeVisible}
          className={styles.button}
        />
      </div>

    </div>
  );
}

export default QRCode;
