import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import RoleIcon from 'components/RoleIcon/RoleIcon';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import UserStatusBadge from 'components/UserStatusBadge/UserStatusBadge';
import AppRoutes from 'constants/AppRoutes';
import { USER_PILLARS, USER_STATUS } from 'constants/interfaces';
import { USER_STATUSES } from 'constants/user';

import UserActions from '../UserActions/UserActions';

import type { IUsersTableRow } from '../../interfaces/IUsersTableRow';

import styles from './scss/UsersTableRow.module.scss';

function UsersTableRow({ user }: IUsersTableRow) {
  const { t } = useTranslation();

  const {
    firstName, lastName, email, status,
  } = user;
  const id = user.id as unknown as string;
  const userInfoPath = generatePath(AppRoutes.userInfo, { userId: id });

  return (
    <TableRow>
      <TableCell>
        <div className={styles.user}>
          <Link to={userInfoPath} className={styles.link}>
            <p className={styles.userName}>
              {t('userManagement.fullName', {
                firstName,
                lastName,
              })}
            </p>
          </Link>
          <p className={styles.userEmail}>
            {email}
          </p>
        </div>
      </TableCell>
      <TableCell className={styles.role}>
        <div className={styles.wrapper}>
          <RoleIcon role={user.role} />
          {t(`userManagement.roles.${user.role}`)}
        </div>
      </TableCell>
      <TableCell className={styles.pillars}>
        {user?.pillars?.map((pillar) => (
          <Fragment key={window.crypto.randomUUID()}>
            {pillar?.key === USER_PILLARS.valueChainAssessment && pillar.canView ? (
              <Button
                title={t('pillarsAndModules.valueChainAssessment')}
                className={styles.pillar}
              />
            ) : null}
            {pillar?.key === USER_PILLARS.materialityAssessment && pillar.canView ? (
              <Button
                title={t('pillarsAndModules.materialityAssessment')}
                className={styles.pillar}
              />
            ) : null}
            {pillar?.key === USER_PILLARS.settings && pillar.canView ? (
              <Button
                title={t('pillarsAndModules.settings')}
                className={styles.pillar}
              />
            ) : null}
          </Fragment>
        ))}
      </TableCell>
      <TableCell>
        <UserStatusBadge
          showInfoButton={status === USER_STATUS.deactivated}
          deactivationInfo={user.latestDeactivation}
        >
          <Badge
            status={USER_STATUSES?.[status].label}
            color={USER_STATUSES?.[status].color}
            textColor={USER_STATUSES?.[status].textColor}
          />
        </UserStatusBadge>
      </TableCell>
      <TableCell>
        <UserActions user={user} />
      </TableCell>
    </TableRow>
  );
}

export default UsersTableRow;
