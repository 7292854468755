import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TableCell from 'components/Table/TableCell';
import TableHead from 'components/Table/TableHead';
import TableSort from 'components/Table/TableSort';
import { COMPANY_HEAD_CELLS } from 'constants/company';
import { SORT } from 'constants/formFields';

import type IClientsTableHead from '../../interfaces/IClientsTableHead';

import styles from './sass/ClientsTableHead.module.scss';

function ClientsTableHead({
  control, onSortChange,
}: IClientsTableHead) {
  const { t } = useTranslation();

  return (
    <TableHead>
      {COMPANY_HEAD_CELLS.map(({ id, label, withSort }) => (withSort ? (
        <Controller
          key={id}
          name={SORT}
          control={control}
          render={({
            field: { value },
          }) => (
            <TableCell head className={styles.date}>
              <TableSort
                active={id === value.orderKey}
                onClickSort={() => onSortChange(id)}
              >
                {t(label)}
              </TableSort>
            </TableCell>
          )}
        />
      ) : (
        <TableCell head className={styles.actions} key={id}>
          {t(label)}
        </TableCell>
      )))}
    </TableHead>
  );
}

export default ClientsTableHead;
