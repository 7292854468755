import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EMAIL, FIRST_NAME, LAST_NAME,
} from 'constants/formFields';
import { useUserMeQuery } from 'store/slices/user/apis/user';
import { personalInfoSchema } from 'utils/validators';

import type { TPersonalInfoSchema } from 'utils/validators';

const usePersonalInfoForm = () => {
  const { data } = useUserMeQuery();

  const { firstName, lastName, email } = data?.data || {};
  const {
    control,
    handleSubmit,
    formState,
    reset,
  } = useForm<TPersonalInfoSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(personalInfoSchema),
    defaultValues: {
      [FIRST_NAME]: '',
      [LAST_NAME]: '',
      [EMAIL]: '',
    },
  });

  const { isValid } = formState;

  useEffect(() => {
    if (firstName) {
      reset({
        [FIRST_NAME]: firstName,
        [LAST_NAME]: lastName,
        [EMAIL]: email,
      });
    }
  }, [reset, firstName, lastName, email]);

  return {
    control,
    handleSubmit,
    isValid,
    reset,
  };
};

export default usePersonalInfoForm;
