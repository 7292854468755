import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { PASSWORD_STRENGTH_METER } from 'constants/general';

import type { IPasswordStrengthProgress } from './interfaces/IPasswordStrengthProgress';

import styles from './scss/PasswordStrengthProgress.module.scss';

function PasswordStrengthProgress({ progress }: IPasswordStrengthProgress) {
  const { t } = useTranslation();
  const isCustomBorderRadius = progress === PASSWORD_STRENGTH_METER.strong;
  const customBorderRadius = { borderTopRightRadius: 0, borderBottomRightRadius: 0 };
  return (
    <div className={styles.root}>
      <div className={styles.progress}>
        <div
          className={clsx(styles.bar, {
            [styles.orange]: progress > PASSWORD_STRENGTH_METER.weak && progress < PASSWORD_STRENGTH_METER.strong,
            [styles.red]: progress === PASSWORD_STRENGTH_METER.weak,
          })}
          style={{ width: `${progress}%`, ...(!isCustomBorderRadius && customBorderRadius) }}
        />
      </div>
      <div className={styles.labels}>
        <span>{t('profilePassword.weak')}</span>
        <span>{t('profilePassword.strong')}</span>
      </div>
    </div>

  );
}

export default PasswordStrengthProgress;
