import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useReleaseBaseTranslationMutation } from 'store/slices/weblate/apis/weblateApi';

import BaseMultilingualView from './BaseMultilingualView';

import type { ReactElement } from 'react';

function BaseMultilingual(): ReactElement {
  const { t } = useTranslation();
  const [releaseBaseTranslation, { isLoading }] = useReleaseBaseTranslationMutation();

  const handleReleaseBaseTranslation = () => {
    releaseBaseTranslation().unwrap().then(() => {
      toast.success(t('toast.successReleaseBaseTranslation'));
    });
  };

  return (
    <BaseMultilingualView
      onReleaseBaseTranslation={handleReleaseBaseTranslation}
      isLoadingReleaseBaseTranslation={isLoading}
    />
  );
}

export default BaseMultilingual;
