export default {
  title: 'Profile',
  personalInfo: 'Personal Info',
  personalInfoDescription: 'Furnish your personal information along with the means to contact you.',
  loginSecurity: 'Login & Security',
  loginSecurityDescription: 'Revise your password and ensure the security of your account.',
  notifications: 'Notifications',
  notificationsDescription: 'Select your notification settings and indicate your preferred method of contact.',
  language: 'Language',
  languageDescription: 'Select your preferred language',
};
