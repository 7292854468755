import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'components/ColorPicker/ColorPicker';
import DropZone from 'components/DropZone/DropZone';
import Separator from 'components/Separator/Separator';
import {
  COMPANY_LOGO,
  HEADINGS_COLOR_1, HEADINGS_COLOR_2,
  PARTNER_LOGO, PRIMARY_COLOR, SECONDARY_COLOR,
  SUBTITLE_COLOR,
} from 'constants/formFields';
import { LOGO_MIME_TYPES_ACCEPT, MAX_LOGO_SIZE } from 'constants/general';

import type IUIConfigurationForm from '../../interfaces/IUIConfigurationForm';

import styles from './scss/UIConfigurationForm.module.scss';

function UIConfigurationForm({ control, isSuperAdmin, isActionsVisible }: IUIConfigurationForm) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <Controller
          name={COMPANY_LOGO}
          control={control}
          render={({
            field: { onChange, value }, fieldState: { error },
          }) => (
            <DropZone
              title={t('common.companyLogo')}
              hint={t('common.acceptedFormats')}
              dropZoneTitle={t('common.companyLogo')}
              files={value ? [value] : []}
              onUploadChange={([file]) => onChange(file)}
              accept={LOGO_MIME_TYPES_ACCEPT}
              maxSize={MAX_LOGO_SIZE}
              withCropper
              error={error?.message}
              isActionsVisible={isActionsVisible}
            />
          )}
        />
      </div>
      <Separator className={styles.separator} />
      {isSuperAdmin ? (
        <>
          <div className={styles.logo}>
            <Controller
              name={PARTNER_LOGO}
              control={control}
              render={({
                field: { onChange, value }, fieldState: { error },
              }) => (
                <DropZone
                  title={t('common.partnerLogo')}
                  hint={t('common.acceptedFormats')}
                  dropZoneTitle={t('common.partnerLogo')}
                  files={value ? [value] : []}
                  onUploadChange={([file]) => onChange(file)}
                  accept={LOGO_MIME_TYPES_ACCEPT}
                  maxSize={MAX_LOGO_SIZE}
                  withCropper
                  error={error?.message}
                  isActionsVisible
                />
              )}
            />
          </div>
          <Separator className={styles.separator} />
        </>
      ) : null }
      <div className={styles.colors}>
        <h4 className={styles.colorsTitle}>{t('clients.colorPalette')}</h4>
        <div className={styles.colorsPickers}>
          <Controller
            control={control}
            name={PRIMARY_COLOR}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <ColorPicker
                name={name}
                label={t('common.primary')}
                color={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={SECONDARY_COLOR}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <ColorPicker
                name={name}
                label={t('common.secondary')}
                color={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={HEADINGS_COLOR_1}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <ColorPicker
                name={name}
                label={t('common.headings1')}
                color={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={HEADINGS_COLOR_2}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <ColorPicker
                name={name}
                label={t('common.headings2')}
                color={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={SUBTITLE_COLOR}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <ColorPicker
                name={name}
                label={t('common.subtitle')}
                color={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default UIConfigurationForm;
