export default {
  createNewUser: 'Create new user',
  searchPlaceholder: 'User name, email',
  user: 'User',
  role: 'Role',
  pillar: 'Pillar(s)',
  status: 'Status',
  actions: 'Actions',
  fullName: '{{firstName}} {{lastName}}',
  roles: {
    administrator: 'Administrator',
    facilitator: 'Facilitator',
    contributor: 'Contributor',
    auditor: 'Auditor',
    'company-admin': 'Administrator',
  },
  statuses: {
    invited: 'Invited',
    deactivated: 'Deactivated',
    active: 'Active',
  },
};
