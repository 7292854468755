import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { useProfitCenterRecordQuery } from 'store/slices/dataSets/apis/dataSetsApi';
import getProfitCentreDefaultValues from 'utils/formHelpers/getProfitCentreDefaultValues';
import { createProfitCentreSchema } from 'utils/validators';

import type { TCreateProfitCentreSchema } from 'utils/validators';
import type { ICreateProfitCentreForm } from '../interfaces/ICreateProfitCentreForm';

const useCreateProfitCentreForm = ({ recordId, isPageLoading }: ICreateProfitCentreForm) => {
  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { data } = useProfitCenterRecordQuery(fetchQuery);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
    setError,
    getValues,
  } = useForm<TCreateProfitCentreSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createProfitCentreSchema),
    values: isPageLoading ? undefined : getProfitCentreDefaultValues({ values: data?.data }),
  });

  const fieldsName = Object.keys(getValues());

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
    isLoading,
    setError,
    fieldsName,
  };
};

export default useCreateProfitCentreForm;
