import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import AppRoutes from 'constants/AppRoutes';
import {
  DESCRIPTION, NAME, TYPE,
} from 'constants/formFields';
import { CLIENT_TYPES } from 'constants/general';
import useCompanyDraft from 'hooks/useCompanyDraft';
import useCreateClient from 'hooks/useCreateClient';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import { useCreateCompanyMutation, useGetCompanyDetailsQuery, useUpdateCompanyInfoMutation } from 'store/slices/companies/apis/companiesApi';
import { createFlowCompanyIdSelector, selectCompanyInfo } from 'store/slices/companies/selectors';
import { setCompanyState } from 'store/slices/companies/slice';
import { companyInfoSchema } from 'utils/validators';

import CompanyInfoView from './CompanyInfoView';

import type { TCompanyInfoSchema } from 'utils/validators';
import type IHandleFormSubmit from '../../../hooks/interfaces/IHandleFormSubmit';

function CompanyInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const newCompanyId = useAppSelector(createFlowCompanyIdSelector);
  const { isCreateFlow, clientId } = useCreateClient();

  const currentCompanyId = newCompanyId && isCreateFlow ? newCompanyId : +clientId;

  const fetchQuery = newCompanyId || !isCreateFlow ? {
    companyId: currentCompanyId,
  } : skipToken;

  const { companyInfo } = useGetCompanyDetailsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, companyInfo: selectCompanyInfo(result.data) }),
    },
  );

  const [createCompany] = useCreateCompanyMutation();
  const [updateCompanyInfo] = useUpdateCompanyInfoMutation();

  const {
    control,
    handleSubmit: onSubmit,
    formState: { isValid, isSubmitted, isDirty },
    reset,
  } = useForm<TCompanyInfoSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(companyInfoSchema),
    defaultValues: {
      [NAME]: '',
      [TYPE]: undefined,
      [DESCRIPTION]: '',
    },
  });

  useEffect(() => {
    if (companyInfo) {
      reset({
        [NAME]: companyInfo.name,
        [TYPE]: CLIENT_TYPES.find((item) => item.value === companyInfo.type),
        [DESCRIPTION]: companyInfo.description,
      });
    }
  }, [reset, companyInfo]);

  const disableButton = !isValid && isSubmitted;

  const handleFormSubmit: IHandleFormSubmit<TCompanyInfoSchema> = ({ values, isDraftSave }) => {
    const data = {
      name: values.name,
      type: values.type.value,
      description: values.description,
    };
    if (newCompanyId || !isCreateFlow) {
      updateCompanyInfo({
        companyId: currentCompanyId,
        ...data,
      }).unwrap().then(() => {
        reset(undefined, { keepValues: true });
        if (isDraftSave) {
          toast.success(t('notifications.clientCreatedInDraft'));
        } else if (isCreateFlow) {
          navigate(generatePath(AppRoutes.multiLingual, { clientId }));
        }
      });
    } else {
      createCompany(data).unwrap().then((response) => {
        dispatch(setCompanyState({ createFlowCompanyId: response.data.id }));
        reset(undefined, { keepValues: true });
        if (isDraftSave) {
          toast.success(t('notifications.clientCreatedInDraft'));
        } else {
          navigate(generatePath(AppRoutes.multiLingual, { clientId }));
        }
      });
    }
  };

  const { onDraftSave } = useCompanyDraft<TCompanyInfoSchema>({ handleFormSubmit, onSubmit });

  const onHandleNextClick = onSubmit((values) => {
    handleFormSubmit({ values });
  });

  return (
    <CompanyInfoView
      control={control}
      isDisabledSave={disableButton}
      isCreatePage={isCreateFlow}
      onSave={onHandleNextClick}
      isDirty={isDirty}
      onDraftSave={onDraftSave}
    />
  );
}

export default CompanyInfo;
