import { LAUNCHPAD_PILLARS_COUNT } from 'constants/general';

import LaunchpadCard from './components/LaunchpadCard/LaunchpadCard';

function LaunchpadLoading() {
  return (
    <>
      { Array.from({ length: LAUNCHPAD_PILLARS_COUNT }).map(() => (
        <LaunchpadCard key={window.crypto.randomUUID()} />
      ))}
    </>
  );
}

export default LaunchpadLoading;
