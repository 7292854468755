import { useTranslation } from 'react-i18next';
import {
  generatePath, matchPath, Outlet, useLocation, useParams,
} from 'react-router-dom';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import NavSidebar from 'components/NavSidebar/NavSidebar';
import Title from 'components/Title/Title';
import AppRoutes, { type TAppRoutes } from 'constants/AppRoutes';
import { dataSetsNav } from 'constants/navigationLists';
import useBreadcrumbs from 'hooks/useBreadcrumbs';

import styles from './scss/DataSetsLayout.module.scss';

function DataSetsLayout() {
  const { t } = useTranslation();
  const { recordId = '' } = useParams();
  const location = useLocation();
  const { pathname } = location;

  const { breadcrumbs } = useBreadcrumbs({ mathCrumbs: [] });

  const navList = dataSetsNav();

  const isNavMenuVisible = [
    AppRoutes.ownOperations,
    AppRoutes.suppliers,
    AppRoutes.customers,
    AppRoutes.products,
    AppRoutes.profitCentres,
  ].some((path) => matchPath(path, pathname));

  const pageTitle: { [key in TAppRoutes]?: string } = {
    [AppRoutes.profitCentresCreate]: t('profitCentres.createProfitCentre.title'),
    [generatePath(AppRoutes.profitCentresEdit, { recordId })]: t('profitCentres.createProfitCentre.editTitle'),
    [AppRoutes.ownOperationsCreate]: t('ownOperations.addNewOwnOperation'),
    [generatePath(AppRoutes.ownOperationsEdit, { recordId })]: t('ownOperations.editOwnOperation'),
    [AppRoutes.supplierCreate]: t('suppliers.addNewSupplier'),
    [generatePath(AppRoutes.supplierEdit, { recordId })]: t('suppliers.editSupplier'),
    [AppRoutes.productsCreate]: t('products.addNewInputProduct'),
    [generatePath(AppRoutes.productsEdit, { recordId })]: t('products.editInputProduct'),
    [AppRoutes.customerCreate]: t('customers.addNewCustomer'),
    [generatePath(AppRoutes.customerEdit, { recordId })]: t('customers.editCustomer'),
  };
  const pageTitleText = pageTitle[pathname as TAppRoutes] || t('pillarsAndModules.settings/data-sets-management');

  return (
    <div className={styles.root}>
      <BreadcrumbsNav breadcrumbs={breadcrumbs} />
      <Title
        text={pageTitleText}
        className={styles.title}
      />
      <div className={styles.wrapper}>
        {isNavMenuVisible ? (
          <div className={styles.sidebar}>
            <NavSidebar
              navList={navList}
              activeClassName={styles.active}
            />
          </div>
        ) : null}
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DataSetsLayout;
