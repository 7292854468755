import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashBox.svg';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import DeleteUserModal from 'components/DeleteUserModal/DeleteUserModal';
import IconButton from 'components/IconButton/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import { AppRoutes } from 'constants/AppRoutes';
import { USER_PILLAR_MODULES } from 'constants/interfaces';
import { USER_STATUSES } from 'constants/user';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import useUserStatusChange from 'hooks/useUserStatusChange';

import ActionIcon from './ActionIcon';

import type { IUsersTableRow } from '../../interfaces/IUsersTableRow';

import styles from './scss/UserActions.module.scss';

function UserActions({ user }: IUsersTableRow) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    id, email, status,
  } = user;

  const {
    isNewUser,
    isDeactivationModalOpen,
    isDeleteModalOpen,
    onToggleDeactivationModal,
    onToggleDeleteModal,
    onChangeUserStatus,
    onUserDeactivate,
    onUserDelete,
  } = useUserStatusChange({
    userId: id,
    status,
    email,
  });

  const onUserEdit = () => {
    const userId = id as unknown as string;
    navigate(generatePath(AppRoutes.editUserInfo, { userId }), { state: { redirectPath: AppRoutes.userManagement } });
  };

  const {
    isActionsVisible,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.userManagement, userId: id },
  );

  return isActionsVisible ? (
    <div className={styles.root}>
      {isNewUser ? (
        <Tooltip hint={t('common.sendInvite')}>
          <IconButton
            icon={<SendIcon />}
            onClick={onChangeUserStatus}
          />
        </Tooltip>
      ) : null}

      <Tooltip hint={t('clients.editInformation')}>
        <IconButton
          icon={<PenIcon />}
          onClick={onUserEdit}
        />
      </Tooltip>

      {isNewUser ? (
        <Tooltip hint={t('common.delete')}>
          <IconButton
            icon={<TrashIcon />}
            onClick={onToggleDeleteModal}
          />
        </Tooltip>
      ) : (
        <Tooltip hint={USER_STATUSES[status].actionLabel}>
          <IconButton
            icon={<ActionIcon status={status} />}
            onClick={onChangeUserStatus}
          />
        </Tooltip>
      )}
      <DeactivationModal
        open={isDeactivationModalOpen}
        text={t('common.userActivity')}
        onReject={onToggleDeactivationModal}
        onSubmit={onUserDeactivate}
      />
      <DeleteUserModal
        open={isDeleteModalOpen}
        onReject={onToggleDeleteModal}
        onClose={onToggleDeleteModal}
        onConfirm={onUserDelete}
      />
    </div>
  ) : null;
}

export default UserActions;
