import { clsx } from 'clsx';

import type { ReactElement } from 'react';
import type ITitle from './interfaces/ITitle';

import styles from './sass/Title.module.scss';

export default function Title({ text, className }: ITitle): ReactElement {
  return (
    <h1 className={clsx(styles.appTitle, className)}>
      {text}
    </h1>
  );
}
