import { Outlet } from 'react-router-dom';
import { ReactComponent as AttaimaLogo } from 'assets/icons/attaima.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';

import FooterLogos from './components/FooterLogos/FooterLogos';
import useIsFooterLogosVisible from './hooks/useIsFooterLogosVisible';

import styles from './sass/AuthLayout.module.scss';

function AuthLayout() {
  const { isFooterLogosVisible } = useIsFooterLogosVisible();

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <AttaimaLogo />
        <div className={styles.bigLogo}><LogoIcon /></div>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <Outlet />
        </div>
        {isFooterLogosVisible ? <FooterLogos /> : null}
      </div>
    </div>
  );
}

export default AuthLayout;
