import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from 'components/TextField/TextField';
import { VERIFICATION_CODE } from 'constants/formFields';
import { VERIFICATION_CODE_MASK } from 'constants/inputMasks';

import type { IVerificationCode } from '../interfaces/IVerificationCode';

import styles from './sass/VerificationCode.module.scss';

function VerificationCode({ control }: IVerificationCode) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {t('authenticationModal.verificationCode.description')}

      <div className={styles.formWrapper}>
        <Controller
          control={control}
          name={VERIFICATION_CODE}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              name={name}
              value={value}
              label={t('verificationCode.verificationCode')}
              error={error?.message}
              onChange={(event) => onChange(event.target.value)}
              mask={VERIFICATION_CODE_MASK}
              alwaysShowMask
              required
              className={styles.inputWrapper}
            />
          )}
        />
      </div>

    </div>
  );
}

export default VerificationCode;
