import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SMS_CODE } from 'constants/formFields';
import { verificationSmsSchema } from 'utils/validators';

import type { TUseVerificationSmsForm } from '../interfaces/TUseVerificationSmsForm';

const useVerificationSmsForm = () => {
  const {
    control,
    handleSubmit,
    formState,
    setError,
  } = useForm<TUseVerificationSmsForm>({
    mode: 'onSubmit',
    resolver: yupResolver(verificationSmsSchema),
    defaultValues: {
      [SMS_CODE]: '',
    },
  });

  const { isValid } = formState;

  return {
    control,
    handleSubmit,
    isValid,
    setError,
  };
};

export default useVerificationSmsForm;
