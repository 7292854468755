import { useEffect, useState } from 'react';
import {
  ORDER_KEY, ORDER_TYPE, SELECT_ALL, SELECTED_ITEMS, SORT,
} from 'constants/formFields';

import { DATA_SET_PAGINATION_COUNT, SORT_DIRECTION_INDICATOR } from '../constants/dataSets';
import { EMPTY_SYMBOL } from '../constants/general';
import { ORDERS } from '../constants/interfaces';
import useDebouncedCallback from './useDebouncedCallback';

import type { ISearchInputHandler } from '../components/TextField/interfaces/ITextField';
import type IOnPageChange from '../pages/UserPages/ProfitCentres/interfaces/IOnPageChange';
import type IProfitCentresParams from '../pages/UserPages/ProfitCentres/interfaces/IProfitCentresParams';
import type { TOrderByIdentifier } from '../pages/UserPages/ProfitCentres/interfaces/TOrderByIdentifier';
import type ILatestArchivation from '../store/slices/dataSets/interfaces/ILatestArchivation';
import type IUseDataSetListActions from './interfaces/IUseDataSetListActions';

const useDataSetListActions = <T>(
  {
    items, archiveAction, deArchiveAction, setParams, setOrderBy,
    getValues,
    setValue,
    watch,
  }: IUseDataSetListActions<T>) => {
  const [isLineMark, setIsLineMark] = useState(false);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [isArchiveModalInfoVisible, setIsArchiveModalInfoVisible] = useState(false);
  const [latestArchivation, setLatestArchivation] = useState<ILatestArchivation | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState(0);

  const isSelectAll = watch(SELECT_ALL);
  const selectedItems = watch(SELECTED_ITEMS);

  const isArchiveSelectedItemsVisible = selectedItems?.length > 0;

  useEffect(() => {
    if (selectedItems?.length > 0 && !isSelectAll && !isLineMark) {
      setIsLineMark(true);
      setValue(SELECT_ALL, EMPTY_SYMBOL);
    } else if (selectedItems?.length === 0) {
      setIsLineMark(false);
      setValue(SELECT_ALL, '');
    }
  }, [selectedItems, isLineMark, isSelectAll, setValue]);

  const onSortChange = (orderByIdentifier: TOrderByIdentifier) => {
    const { sort: { orderKey, orderType } } = getValues();
    const isAsc = orderKey === orderByIdentifier && orderType === ORDERS.asc;
    setValue(SORT, { [ORDER_KEY]: orderByIdentifier, [ORDER_TYPE]: isAsc ? ORDERS.desc : ORDERS.asc });
    const order = isAsc ? `${SORT_DIRECTION_INDICATOR}${orderByIdentifier}` : orderByIdentifier;
    setOrderBy(order);
  };

  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    setParams((prev: IProfitCentresParams) => ({ ...prev, search }));
  });

  const onSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const onSelectAll = () => {
    const isSelectAllSelected = watch(SELECT_ALL);
    const allItems = (items as Array<{ id: number }>)?.map((item) => item.id) || [];
    setValue(SELECTED_ITEMS, isSelectAllSelected ? allItems : []);
  };

  const onArchiveConfirm = (reason: string) => {
    const requestData = { recordIds: currentItem ? [currentItem] : selectedItems, reason };

    archiveAction(requestData).unwrap().then(() => {
      setCurrentItem(0);
      setValue(SELECT_ALL, '');
      setValue(SELECTED_ITEMS, []);
      setIsArchiveModalVisible(false);
    }).catch(() => {});
  };

  const onArchive = () => {
    setCurrentItem(0);
    setIsArchiveModalVisible(true);
  };

  const onArchiveModalInfoOpen = (latestArchivationParam: ILatestArchivation) => {
    setIsArchiveModalInfoVisible(true);
    setLatestArchivation(latestArchivationParam);
  };

  const onMoveToArchive = (id: number) => {
    setCurrentItem(id);
    setIsArchiveModalVisible(true);
  };

  const onDeArchive = (id: number) => {
    deArchiveAction({ recordIds: [id] }).unwrap().then(() => {
      setValue(SELECT_ALL, '');
      setValue(SELECTED_ITEMS, []);
    }).catch(() => {});
  };

  const onPageChange = ({ selected }: IOnPageChange) => {
    setParams((prev: IProfitCentresParams) => ({ ...prev, offset: selected * DATA_SET_PAGINATION_COUNT }));
  };

  return {
    isArchiveModalVisible,
    isArchiveModalInfoVisible,
    latestArchivation,
    currentItem,
    isLineMark,
    onSortChange,
    onSearchChange,
    onSelectAll,
    onArchiveConfirm,
    onArchive,
    onArchiveModalInfoOpen,
    onMoveToArchive,
    onDeArchive,
    onPageChange,
    isArchiveSelectedItemsVisible,
    setIsArchiveModalVisible,
    setIsArchiveModalInfoVisible,
    selectedItems,
  };
};

export default useDataSetListActions;
