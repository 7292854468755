import type { NavigateFunction } from 'react-router-dom';

const navigationRef = {
  current: null as NavigateFunction | null,
};

export const setRefNavigate = (navigate: NavigateFunction) => {
  navigationRef.current = navigate;
};

export const refNavigateTo = (path: string) => {
  if (navigationRef.current) {
    navigationRef.current(path);
  }
};

export default navigationRef;
