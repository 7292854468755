import type { ICreateEnumOptionsOptions } from './interfaces/ICreateEnumOptionsOptions';

function createEnumOptions({ enumData, values, enumKey }: ICreateEnumOptionsOptions) {
  if (!enumData?.[enumKey] || !values || values.length === 0) {
    return [];
  }

  return values?.map((id) => {
    const value = enumData?.[enumKey].find((option) => option.value === id)?.value || '';
    return { value, label: value };
  });
}

export default createEnumOptions;
