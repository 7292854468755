import { useRef, useState } from 'react';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { clsx } from 'clsx';

import type IAccordion from './interfaces/IAccordion';

import styles from './scss/Accordion.module.scss';

function Accordion({ header, children, className }: IAccordion) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onHandleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={clsx(styles.card, className)}>
      <div
        className={clsx(styles.header, {
          [styles.active]: open,
        })}
        role="presentation"
        onClick={onHandleToggle}
      >
        {header}
        <div className={styles.icon}>
          <ChevronIcon />
        </div>
      </div>
      <div
        ref={ref}
        className={clsx(styles.collapse, {
          [styles.show]: open,
        })}
        style={open
          ? { height: ref?.current?.scrollHeight }
          : { height: '0px' }}
      >
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
