import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DataSetsRecordButtons from 'components/DataSetsRecordButtons/DataSetsRecordButtons';
import Modal from 'components/Modal/Modal';
import NumberField from 'components/NumberField/NumberField';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from 'constants/dataSets';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY,
  ADDRESS_POST_CODE,
  ADDRESS_STATE,
  ADDRESS_STREET,
  BUSINESS_UNIT_TYPE,
  CODE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING, EXPECTED_GROWTH_NEXT_12M,
  FINANCIAL_CONTROL,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  OWNERSHIP,
  PRIORITY_RATING,
  PROFIT_CENTER_ID,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
  UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_MASK } from 'constants/inputMasks';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type IFieldError from 'store/types/IFieldError';
import type { ICreateCustomerView } from './interfaces/ICreateCustomerView';

import styles from './sass/CreateCustomerView.module.scss';

function CreateCustomerView({
  control, onCreateCustomerSubmit, isConfirmationModalOpen, onHandleCloseConfirmation,
  onDiscardChangesConfirm, enumData, naceCodesData, naceDivisions, onNaceSelectionChange, isPageLoading, uidNameTuples,
  onFindCountries, onFindStates, onFindCities, isStatesDisabled, isCitiesDisabled, onChangeCountry, onChangeState,
}: ICreateCustomerView) {
  const { t } = useTranslation();
  const { recordId = '' } = useParams();
  const placeholder = recordId ? t('common.notProvided') : '';

  return (
    <div className={styles.root}>
      {!isPageLoading ? (
        <Panel className={styles.panel}>
          <div className={styles.wrapper}>
            <DataSetsPanel title={t('customers.selections.general')}>
              <Controller
                control={control}
                name={NAME}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.customerName')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={CODE}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.customerCode')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={UNIQUE_IDENTIFIER}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.uniqueIdentifier')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={PROFIT_CENTER_ID}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('customers.fields.profitCentreId')}
                    placeholder={recordId ? placeholder : t('customers.fields.profitCentreId')}
                    options={uidNameTuples?.[UID_NAME_TUPLES.profitCenter]}
                    value={value || undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.message}
                    isMulti
                    isSearchable
                  />
                )}
              />
              <Controller
                control={control}
                name={VALUE_CHAIN_SECTION}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('customers.fields.valueChain')}
                    placeholder={t('customers.fields.valueChain')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.valueChainSection]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={OWNERSHIP}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('customers.fields.ownership')}
                    placeholder={recordId ? placeholder : t('customers.fields.ownership')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.ownership]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name={EQUITY_SHAREHOLDING}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('customers.fields.equityShareholding')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={FINANCIAL_CONTROL}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('customers.fields.financialControl')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={BUSINESS_UNIT_TYPE}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('customers.fields.physicalOrLogical')}
                    placeholder={t('customers.fields.physicalOrLogical')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.businessUnitType]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <div />
              <Controller
                control={control}
                name={DATE_OPENED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.dateOpened')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={DATE_CLOSED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.dateClosed')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={PRIORITY_RATING}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('customers.fields.priorityRating')}
                    placeholder={t('customers.fields.priorityRating')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.priorityRating]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                  />
                )}
              />
            </DataSetsPanel>
            <DataSetsPanel title={t('customers.selections.details')}>
              <Controller
                control={control}
                name={NACE_SECTION}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('customers.fields.industry')}
                    placeholder={recordId ? placeholder : t('customers.fields.industry')}
                    options={naceCodesData}
                    value={value?.value ? value : undefined}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onNaceSelectionChange();
                    }}
                    error={(error as IFieldError)?.value?.message}
                    isSearchable
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={NACE_CODE}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('customers.fields.sector')}
                    placeholder={recordId ? placeholder : t('customers.fields.sector')}
                    options={naceDivisions}
                    value={value || null}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    isSearchable
                  />
                )}
              />
            </DataSetsPanel>

            <DataSetsPanel title={t('customers.selections.financials')}>
              <Controller
                control={control}
                name={EXPECTED_GROWTH_NEXT_12M}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('customers.fields.expectedGrowthNext12M')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <div />
              <Controller
                control={control}
                name={REVENUE_ROLLING_PERCENTS}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('customers.fields.totalRevenue')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={REVENUE_ROLLING_AMOUNT}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <div className={styles.amount}>
                    <NumberField
                      name={name}
                      value={value}
                      label={t('customers.fields.actualRevenue')}
                      error={error?.message}
                      onChange={(values) => onChange(values.value)}
                      placeholder={placeholder}
                    />
                    <Controller
                      control={control}
                      name={REVENUE_ROLLING_CURRENCY}
                      render={({
                        field: { value: valueCurrency, onChange: onChangeCurrency },
                        fieldState: { error: errorCurrency },
                      }) => (
                        <Select
                          placeholder={t('common.currency')}
                          options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                          value={valueCurrency?.value ? valueCurrency : undefined}
                          onChange={onChangeCurrency}
                          error={errorCurrency?.message}
                          className={styles.select}
                        />
                      )}
                    />
                  </div>
                )}
              />
            </DataSetsPanel>
            <DataSetsPanel title={t('customers.selections.address')}>
              <Controller
                control={control}
                name={ADDRESS_COUNTRY}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('customers.fields.country')}
                    placeholder={null}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onChangeCountry();
                    }}
                    error={error?.message}
                    loadOptions={onFindCountries}
                    isSearchable
                    isAsync
                    isClearable
                    className={styles.asyncSelect}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_STATE}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('suppliers.fields.state')}
                    placeholder={null}
                    value={value || null}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onChangeState();
                    }}
                    error={error?.message}
                    loadOptions={onFindStates}
                    isSearchable
                    isAsync
                    isClearable
                    className={clsx(styles.asyncSelect, {
                      [styles.inactiveSelect]: isStatesDisabled,
                    })}
                    isDisabled={isStatesDisabled}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_POST_CODE}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.post')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_CITY}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('suppliers.fields.town')}
                    placeholder={null}
                    value={value || null}
                    onChange={onChange}
                    error={error?.message}
                    loadOptions={onFindCities}
                    isSearchable
                    isAsync
                    isClearable
                    className={clsx(styles.asyncSelect, {
                      [styles.inactiveSelect]: isCitiesDisabled || isStatesDisabled,
                    })}
                    isDisabled={isCitiesDisabled}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_STREET}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.street')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_BUILDING}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.building')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_COORDINATES}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('customers.fields.coordinates')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

          </div>
          <Separator />
          <Modal
            open={isConfirmationModalOpen}
            title={t('createNewUser.cancelModalTitle')}
            description={t('clients.cancelModalText')}
            confirmTitle={t('common.discardChanges')}
            closeTitle={t('common.keepEditing')}
            confirmVariant="error"
            onClose={() => onHandleCloseConfirmation(false)}
            onReject={() => onHandleCloseConfirmation(false)}
            onConfirm={onDiscardChangesConfirm}
          />
          <DataSetsRecordButtons onClose={onHandleCloseConfirmation} onSubmit={onCreateCustomerSubmit} />
        </Panel>
      ) : null}
    </div>
  );
}

export default CreateCustomerView;
