import {
  ACCESS,
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY,
  ADDRESS_POST_CODE,
  ADDRESS_STATE,
  ADDRESS_STREET,
  ADMINS,
  ASSET_CATEGORY,
  AUTHENTICATION_GOOGLE,
  AUTHENTICATION_MICROSOFT,
  AUTHENTICATION_SETUP,
  AUTHENTICATION_TYPE,
  BUSINESS_UNIT_TYPE,
  CAN_VIEW,
  CODE,
  COMPANY_LOGO,
  CRITICALITY_FROM_DOWNSTREAM_WASTE,
  CRITICALITY_RATING,
  CURRENT_PASSWORD,
  DATA_SET_UPLOAD_FILE,
  DATE_CLOSED,
  DATE_OPENED,
  DESCRIPTION,
  EMAIL,
  EQUITY_CONTROL,
  EQUITY_SHAREHOLDING,
  EU_TAXONOMY_CODE,
  EXPECTED_GROWTH_DECLINE,
  EXPECTED_GROWTH_NEXT_12M,
  FILTER_BY_STATUS,
  FINANCIAL_CONTROL,
  FINISHED_GOOD_STOCK_AMOUNT,
  FINISHED_GOOD_STOCK_AMOUNT_CURRENCY,
  FIRST_NAME,
  GEO_TYPE_PREFIX,
  GROSS_MARGIN_ROLLING_AMOUNT,
  GROSS_MARGIN_ROLLING_CURRENCY,
  GROSS_MARGIN_ROLLING_PERCENTS,
  HEADINGS_COLOR_1,
  HEADINGS_COLOR_2,
  ID,
  KEY_SUPPLIER_TIER_1_TOTAL,
  KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY,
  KEY_SUPPLIER_TIER_10_OF_TOTAL,
  LANGUAGE,
  LANGUAGES,
  LAST_NAME,
  LEGAL_NAME,
  MFA_APP,
  MFA_SMS,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  NET_BOOK,
  NET_BOOK_CURRENCY,
  NUMBER_OF_EMPLOYEES,
  ORDER_KEY,
  ORDER_TYPE,
  ORG_PATH,
  ORG_TYPE_PREFIX,
  OWNED_LEASED,
  OWNERSHIP,
  PARTNER_LOGO,
  PASSWORD,
  PHONE,
  PILLAR_ID,
  PILLAR_MODULES,
  PILLARS,
  PRIMARY_COLOR,
  PRIORITY_RATING,
  PROFIT_CENTER_ID,
  PROFIT_CENTRE_NAME,
  PROFIT_CENTRE_UNIQUE_IDENTIFIER,
  REASON,
  RELIANCE_ON_FREE_NATURAL_RESOURCES,
  RELIANCE_ON_FREE_SOCIAL_RESOURCES,
  REPEAT_NEW_PASSWORD,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
  ROLE,
  SEARCH,
  SECONDARY_COLOR,
  SELECT_ALL,
  SELECTED_ITEMS,
  SHOW_ARCHIVED,
  SMS_CODE,
  SORT,
  SPEND,
  SPEND_CURRENCY,
  SSO,
  STATUS,
  SUBTITLE_COLOR,
  SUPPLIER,
  SUPPLIER_ID,
  TYPE,
  UNIQUE_IDENTIFIER,
  UPSTREAM_TITLE,
  VALUE_CHAIN_SECTION,
  VERIFICATION_CODE,
  VOLUME_QTY,
  VOLUME_QTY_UNIT,
} from 'constants/formFields';
import {
  ALPHANUMERIC_AND_NUMBER_REGEX, ALPHANUMERIC_REGEX, ALPHANUMERIC_SPACES_SPECIAL_CHARTERS_REGEX,
  COMPANY_NAME_MAX_LENGTH, COMPANY_NAME_MIN_LENGTH, DATA_SETS_NAME_MAX_LENGTH,
  DATA_SETS_NAME_MIN_LENGTH, DESCRIPTION_LENGTH, NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from 'constants/general';
import i18next from 'i18next';
import * as yup from 'yup';

import {
  BUSINESS_UNIT_TYPE_LOGICAL,
  BUSINESS_UNIT_TYPE_REQUIRED_VALUE,
  UPSTREAM_TITLE_REQUIRED_VALUE,
} from '../constants/dataSets';

import type {
  TOrders, TPillarAccessTypes, TStatus,
  TUserPillarAccessTypes, TUserStatus,
} from 'constants/interfaces';
import type { ParsedCountry } from 'react-international-phone/dist/types';

export const companiesListSchema = yup.object({
  [SEARCH]: yup.string(),
  [STATUS]: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }).default(undefined),
  [SORT]: yup.object().shape({
    [ORDER_KEY]: yup.string().required(),
    [ORDER_TYPE]: yup.string<TOrders>().required(),
  }),
});
export type TCompaniesListSchema = yup.InferType<typeof companiesListSchema>;

export const companyInfoSchema = yup.object({
  [NAME]: yup.string().min(COMPANY_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('common.name'), length: COMPANY_NAME_MIN_LENGTH },
  )).max(COMPANY_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('common.name'), length: COMPANY_NAME_MAX_LENGTH },
  )).trim()
    .matches(ALPHANUMERIC_SPACES_SPECIAL_CHARTERS_REGEX, i18next.t('errors.nameContainLettersAndSpecialSymbols'))
    .required(i18next.t('errors.nameRequired')),
  [TYPE]: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }).default(undefined)
    .required(i18next.t('errors.typeRequired')),
  [DESCRIPTION]: yup.string().max(DESCRIPTION_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('common.description'), length: DESCRIPTION_LENGTH },
  )),
});
export type TCompanyInfoSchema = yup.InferType<typeof companyInfoSchema>;

export const companyMultilingualSchema = yup.object({
  [LANGUAGES]: yup.array().of(yup.string().required()).min(1).required(),
});
export type TCompanyMultilingualSchema = yup.InferType<typeof companyMultilingualSchema>;

export const firstLastNameSchema = yup.object({
  [FIRST_NAME]: yup.string().min(NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('common.firstName'), length: NAME_MIN_LENGTH },
  )).max(NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('common.firstName'), length: NAME_MAX_LENGTH },
  )).required(i18next.t('errors.firstNameRequired'))
    .transform(
      (value) => value.trim(),
    )
    .matches(ALPHANUMERIC_REGEX, i18next.t('errors.nameContainLettersAndHyphens'))
    .trim(),

  [LAST_NAME]: yup.string().min(NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('common.lastName'), length: NAME_MIN_LENGTH },
  )).max(NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('common.lastName'), length: NAME_MAX_LENGTH },
  )).required(i18next.t('errors.lastNameRequired'))
    .transform(
      (value) => value.trim(),
    )
    .matches(ALPHANUMERIC_REGEX, i18next.t('errors.nameContainLettersAndHyphens'))
    .trim(),
});

export const companyAdminSchema = yup.object({
  [ADMINS]: yup.array().of(
    firstLastNameSchema.shape({
      [ID]: yup.number(),
      [STATUS]: yup.string<TUserStatus>(),
      [EMAIL]: yup.string().email(i18next.t('errors.emailNotValid')).required(i18next.t('errors.emailNotValid')),
    }),
  ).required(),
});
export type TCompanyAdminSchema = yup.InferType<typeof companyAdminSchema>;

export const companyUIConfigurationSchema = yup.object({
  [COMPANY_LOGO]: yup.mixed<File | string>().required(i18next.t('errors.companyLogoRequired')),
  [PARTNER_LOGO]: yup.mixed<File | string>(),
  [PRIMARY_COLOR]: yup.string().required(),
  [SECONDARY_COLOR]: yup.string().required(),
  [HEADINGS_COLOR_1]: yup.string().required(),
  [HEADINGS_COLOR_2]: yup.string().required(),
  [SUBTITLE_COLOR]: yup.string().required(),
});
export type TCompanyUiConfigurationSchema = yup.InferType<typeof companyUIConfigurationSchema>;

export const companyPermissionsSchema = yup.object({
  [PILLARS]: yup.array().of(yup.object({
    [ID]: yup.number().required(),
    [NAME]: yup.string(),
    [CAN_VIEW]: yup.boolean().required(),
    [PILLAR_MODULES]: yup.array().of(yup.object({
      [ID]: yup.number().required(),
      [NAME]: yup.string(),
      [PILLAR_ID]: yup.number().required(),
      [ACCESS]: yup.string<TPillarAccessTypes>().required(),
    })).required(),
  })).required(),
});
export type TCompanyPermissionsSchema = yup.InferType<typeof companyPermissionsSchema>;

export const companySecuritySchema = yup.object({
  [SSO]: yup.string<TStatus>().required(),
  [MFA_APP]: yup.string<TStatus>().required(),
  [MFA_SMS]: yup.string<TStatus>().required(),
});
export type TCompanySecuritySchema = yup.InferType<typeof companySecuritySchema>;

export const authenticationSchema = yup.object({
  [AUTHENTICATION_TYPE]: yup.string().required().min(1).required(),
});

export const authenticationSetupSchema = yup.object({
  [AUTHENTICATION_SETUP]: yup.string().oneOf([AUTHENTICATION_GOOGLE, AUTHENTICATION_MICROSOFT]),
});

export type TAuthenticationSetupSchema = yup.InferType<typeof authenticationSetupSchema>;

export const verificationSmsSchema = yup.object({
  [SMS_CODE]: yup.string().required().transform(
    (value) => value?.replace(/[^\d]/g, ''),
  ).min(6),
});

export const verificationCodeSchema = yup.object({
  [VERIFICATION_CODE]: yup.string().required().transform(
    (value) => value?.replace(/[^\d]/g, ''),
  ).min(6),
});

export const forgotPasswordSchema = yup.object({
  [EMAIL]: yup.string().required().email(),
});

export const loginSchema = yup.object({
  [EMAIL]: yup.string().required().email(),
  [PASSWORD]: yup.string().required().max(128)
    .trim(),
});

export const newPasswordSchema = yup.object({
  [PASSWORD]: yup.string().required().min(12)
    .max(128)
    .trim(),
  [REPEAT_NEW_PASSWORD]: yup.string().required().min(12)
    .max(128)
    .oneOf([yup.ref(PASSWORD)], i18next.t('errors.passwordMismatch'))
    .trim(),
});

export const personalInfoSchema = firstLastNameSchema.shape({
  [EMAIL]: yup.string().email(i18next.t('errors.emailNotValid')).required(),
});

export type TPersonalInfoSchema = yup.InferType<typeof personalInfoSchema>;

export const profilePasswordSchema = yup.object({
  [CURRENT_PASSWORD]: yup.string().required().min(12)
    .max(128)
    .trim(),
  [PASSWORD]: yup.string().required().min(12)
    .max(128)
    .trim(),
  [REPEAT_NEW_PASSWORD]: yup.string().required().min(12)
    .max(128)
    .oneOf([yup.ref(PASSWORD)], i18next.t('errors.passwordMismatch'))
    .trim(),
});

export const profileLanguageSchema = yup.object({
  [LANGUAGE]: yup.string().required(),
});

export type TProfileLanguageSchema = yup.InferType<typeof profileLanguageSchema>;

export const userManagementSchema = yup.object({
  [SEARCH]: yup.string(),
  [FILTER_BY_STATUS]: yup.object({ value: yup.string(), label: yup.string() }),
  [SORT]: yup.object().shape({
    [ORDER_KEY]: yup.string(),
    [ORDER_TYPE]: yup.string<TOrders>(),
  }),
});

export type TUserManagementSchema = yup.InferType<typeof userManagementSchema>;

export const createNewUserSchema = firstLastNameSchema.shape({
  [ROLE]: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }).default(undefined).required(),
  [EMAIL]: yup.string().email(i18next.t('errors.emailNotValid')).required(),
});

export type TCreateNewUserSchema = yup.InferType<typeof createNewUserSchema>;

export const userPermissionsSchema = yup.object({
  [PILLARS]: yup.array().of(yup.object({
    [ID]: yup.number().required(),
    [NAME]: yup.string(),
    [PILLAR_MODULES]: yup.array().of(yup.object({
      [ID]: yup.number().required(),
      [NAME]: yup.string(),
      [PILLAR_ID]: yup.number().required(),
      [ACCESS]: yup.string<TUserPillarAccessTypes | TPillarAccessTypes>(),
    })).required(),
  })).required(),
});

export type TUserPermissionsSchema = yup.InferType<typeof userPermissionsSchema>;

export const deactivationSchema = yup.object({
  [REASON]: yup.string().max(DESCRIPTION_LENGTH).required(i18next.t('errors.reasonRequired')),
});
export type TDeactivationSchema = yup.InferType<typeof deactivationSchema>;

export const verificationPhoneSchema = ({ parsedCountry }: { parsedCountry?: ParsedCountry }) => {
  const { format } = parsedCountry as { format: string } || '';
  const phoneLength = format?.match?.(/\./g)?.length || 10;
  const countryLength = parsedCountry?.dialCode?.length || 1;
  const minPhoneLength = phoneLength + countryLength + 1;

  return yup.object({
    [PHONE]: yup.string().required().transform(
      (value) => `+${value?.replace(/[^\d]/g, '')}`,
    ).min(minPhoneLength),
  });
};

export const usersListSchema = yup.object({
  [SEARCH]: yup.string(),
});
export type TUsersListSchema = yup.InferType<typeof usersListSchema>;

export const dataSetUploadSchema = yup.object({
  [DATA_SET_UPLOAD_FILE]: yup.mixed<File | string>().required(),
});
export type TDataSetUploadSchema = yup.InferType<typeof dataSetUploadSchema>;

export const dataSetsListSchema = yup.object({
  [SEARCH]: yup.string(),
  [FILTER_BY_STATUS]: yup.object({ value: yup.string(), label: yup.string() }),
  [SHOW_ARCHIVED]: yup.boolean(),
  [SORT]: yup.object().shape({
    [ORDER_KEY]: yup.string(),
    [ORDER_TYPE]: yup.string<TOrders>(),
  }),
  [SELECTED_ITEMS]: yup.array().of(yup.number().required()).required(),
  [SELECT_ALL]: yup.string(),
  [ORG_TYPE_PREFIX]: yup.string(),
  [GEO_TYPE_PREFIX]: yup.string(),
});

export type TDataSetsListSchema = yup.InferType<typeof dataSetsListSchema>;

export const createProfitCentreSchema = yup.object({
  [PROFIT_CENTRE_NAME]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('profitCentres.createProfitCentre.fields.name'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('profitCentres.createProfitCentre.fields.name'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('profitCentres.createProfitCentre.fields.name') }))
    .transform(
      (value) => value.trim(),
    )
    .matches(ALPHANUMERIC_SPACES_SPECIAL_CHARTERS_REGEX, i18next.t('errors.nameContainLettersAndSpecialSymbols'))
    .trim(),
  [PROFIT_CENTRE_UNIQUE_IDENTIFIER]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('profitCentres.createProfitCentre.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('profitCentres.createProfitCentre.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', {
    name: i18next.t('profitCentres.createProfitCentre.fields.uniqueIdentifier'),
  }))
    .matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),
  [REVENUE_ROLLING_PERCENTS]: yup.string(),
  [GROSS_MARGIN_ROLLING_PERCENTS]: yup.string(),
  [REVENUE_ROLLING_AMOUNT]: yup.string(),
  [GROSS_MARGIN_ROLLING_AMOUNT]: yup.string(),
  [FINISHED_GOOD_STOCK_AMOUNT]: yup.string(),
  [REVENUE_ROLLING_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).when(REVENUE_ROLLING_AMOUNT, {
    is: (value: string) => value && value !== '',
    then: () => yup.object().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('common.currency') },
    )),
    otherwise: () => yup.object().default(undefined),
  }),
  [GROSS_MARGIN_ROLLING_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).when(GROSS_MARGIN_ROLLING_AMOUNT, {
    is: (value: string) => value && value !== '',
    then: () => yup.object().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('common.currency') },
    )),
    otherwise: () => yup.object().default(undefined),
  }),
  [FINISHED_GOOD_STOCK_AMOUNT_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).when(FINISHED_GOOD_STOCK_AMOUNT, {
    is: (value: string) => value && value !== '',
    then: () => yup.object().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('common.currency') },
    )),
    otherwise: () => yup.object().default(undefined),
  }),
});

export type TCreateProfitCentreSchema = yup.InferType<typeof createProfitCentreSchema>;

export const createOwnOperationsSchema = yup.object({
  [NAME]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('ownOperations.fields.name'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.name'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('ownOperations.fields.name') }))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [ORG_PATH]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('ownOperations.fields.orgPath'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DESCRIPTION_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.orgPath'), length: DESCRIPTION_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('ownOperations.fields.orgPath') }))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [UNIQUE_IDENTIFIER]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('ownOperations.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('ownOperations.fields.uniqueIdentifier') }))
    .matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),
  [LEGAL_NAME]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('ownOperations.fields.legalEntity'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DESCRIPTION_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.legalEntity'), length: DESCRIPTION_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('ownOperations.fields.legalEntity') }))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [LEGAL_NAME]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('ownOperations.fields.legalEntity'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DESCRIPTION_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.legalEntity'), length: DESCRIPTION_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('ownOperations.fields.legalEntity') }))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [PROFIT_CENTER_ID]: yup.array().of(yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  })).when(BUSINESS_UNIT_TYPE, {
    is: (businessUnitType: { value: string }) => businessUnitType?.value === BUSINESS_UNIT_TYPE_LOGICAL,
    then: () => yup.array().min(1).of(yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })),
    otherwise: () => yup.array().of(yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })),
  }),
  [VALUE_CHAIN_SECTION]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('ownOperations.fields.valueChain') },
    )),
  }),
  [OWNERSHIP]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  [EQUITY_SHAREHOLDING]: yup.string(),
  [FINANCIAL_CONTROL]: yup.string(),
  [BUSINESS_UNIT_TYPE]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('ownOperations.fields.physicalOrLogical') },
    )),
  }),
  [ASSET_CATEGORY]: yup.array().of(yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('ownOperations.fields.assetCategory') },
    )),
  })).min(1, i18next.t(
    'errors.requiredField',
    { name: i18next.t('ownOperations.fields.assetCategory') },
  )),
  [OWNED_LEASED]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).when(BUSINESS_UNIT_TYPE, {
    is: (businessUnitType: { value: string }) => businessUnitType?.value === BUSINESS_UNIT_TYPE_REQUIRED_VALUE,
    then: () => yup.object().shape({
      label: yup.string(),
      value: yup.string().required(i18next.t(
        'errors.requiredField',
        { name: i18next.t('ownOperations.fields.owned'), interpolation: { escapeValue: false } },
      )),
    }),
  }),
  [NUMBER_OF_EMPLOYEES]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('ownOperations.fields.employees') },
  )),
  [DATE_OPENED]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('ownOperations.fields.dateOpened') },
  )),
  [DATE_CLOSED]: yup.string(),
  [NACE_SECTION]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('ownOperations.fields.industry') },
    )),
    divisions: yup.array(),
  }),
  [NACE_CODE]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  [EU_TAXONOMY_CODE]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('ownOperations.fields.taxonomy'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.taxonomy'), length: DATA_SETS_NAME_MAX_LENGTH },
  ))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [NET_BOOK]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('ownOperations.fields.netBook') },
  )),
  [NET_BOOK_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('common.currency') },
    )),
  }),
  [ADDRESS_COUNTRY]: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }).default(undefined).required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('ownOperations.fields.country'), interpolation: { escapeValue: false } },
  )),
  [ADDRESS_STATE]: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }).default(undefined).required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('ownOperations.fields.state'), interpolation: { escapeValue: false } },
  )),
  [ADDRESS_POST_CODE]: yup.string().max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.post'), length: DATA_SETS_NAME_MAX_LENGTH },
  ))
    .when(BUSINESS_UNIT_TYPE, {
      is: (businessUnitType: { value: string }) => businessUnitType?.value === BUSINESS_UNIT_TYPE_REQUIRED_VALUE,
      then: () => yup.string().required(i18next.t(
        'errors.requiredField',
        { name: i18next.t('ownOperations.fields.post') },
      )).matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),
    }),
  [ADDRESS_CITY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).nullable()
    .when(BUSINESS_UNIT_TYPE, {
      is: (businessUnitType: { value: string }) => businessUnitType?.value === BUSINESS_UNIT_TYPE_REQUIRED_VALUE,
      then: () => yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }).default(undefined).required(i18next.t(
        'errors.requiredField',
        { name: i18next.t('ownOperations.fields.town'), interpolation: { escapeValue: false } },
      )),
    }),
  [ADDRESS_STREET]: yup.string()
    .when(BUSINESS_UNIT_TYPE, {
      is: (businessUnitType: { value: string }) => businessUnitType?.value === BUSINESS_UNIT_TYPE_REQUIRED_VALUE,
      then: () => yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
        'errors.minCharactersLength',
        { name: i18next.t('ownOperations.fields.street'), length: DATA_SETS_NAME_MIN_LENGTH },
      )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
        'errors.maxCharactersLength',
        { name: i18next.t('ownOperations.fields.street'), length: DATA_SETS_NAME_MAX_LENGTH },
      )).required(i18next.t(
        'errors.requiredField',
        { name: i18next.t('ownOperations.fields.street') },
      ))
        .transform(
          (value) => value.trim(),
        )
        .trim(),
    }),
  [ADDRESS_BUILDING]: yup.string()
    .when(BUSINESS_UNIT_TYPE, {
      is: (businessUnitType: { value: string }) => businessUnitType?.value === BUSINESS_UNIT_TYPE_REQUIRED_VALUE,
      then: () => yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
        'errors.minCharactersLength',
        { name: i18next.t('ownOperations.fields.building'), length: DATA_SETS_NAME_MIN_LENGTH },
      )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
        'errors.maxCharactersLength',
        { name: i18next.t('ownOperations.fields.building'), length: DATA_SETS_NAME_MAX_LENGTH },
      )).required(i18next.t(
        'errors.requiredField',
        { name: i18next.t('ownOperations.fields.building') },
      ))
        .transform(
          (value) => value.trim(),
        )
        .trim(),
    }),
  [ADDRESS_COORDINATES]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('ownOperations.fields.coordinates'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).transform(
    (value) => value.trim(),
  )
    .trim(),
});

export type TCreateOperationsSchema = yup.InferType<typeof createOwnOperationsSchema>;

export const createSupplierSchema = yup.object({
  [NAME]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('suppliers.fields.supplierName'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('suppliers.fields.supplierName'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('suppliers.fields.supplierName') }))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [CODE]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('suppliers.fields.supplierCode'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DESCRIPTION_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('suppliers.fields.supplierCode'), length: DESCRIPTION_LENGTH },
  )).matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers'))
    .required(i18next.t('errors.requiredField', { name: i18next.t('suppliers.fields.supplierCode') })),
  [UNIQUE_IDENTIFIER]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('suppliers.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('suppliers.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('suppliers.fields.uniqueIdentifier') }))
    .matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),
  [VALUE_CHAIN_SECTION]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('suppliers.fields.valueChain') },
    )),
  }),
  [UPSTREAM_TITLE]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('suppliers.fields.upstreamTier') },
    )),
  }),
  [OWNERSHIP]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  [EQUITY_SHAREHOLDING]: yup.string(),
  [FINANCIAL_CONTROL]: yup.string(),
  [BUSINESS_UNIT_TYPE]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('suppliers.fields.physicalOrLogical') },
    )),
  }),
  [DATE_OPENED]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('suppliers.fields.dateOpened') },
  )),
  [DATE_CLOSED]: yup.string(),
  [NACE_SECTION]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('suppliers.fields.industry') },
    )),
    divisions: yup.array(),
  }),
  [NACE_CODE]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  [EU_TAXONOMY_CODE]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('suppliers.fields.taxonomy'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('suppliers.fields.taxonomy'), length: DATA_SETS_NAME_MAX_LENGTH },
  ))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [SPEND]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('suppliers.fields.spend') },
  )),
  [SPEND_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('common.currency') },
    )),
  }),

  [KEY_SUPPLIER_TIER_1_TOTAL]: yup.string().when(UPSTREAM_TITLE, {
    is: (upstreamTitle: { value: string }) => upstreamTitle?.value === UPSTREAM_TITLE_REQUIRED_VALUE,
    then: () => yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('suppliers.fields.keySuppliers') },
    )),
  }),
  [KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).when(KEY_SUPPLIER_TIER_1_TOTAL, {
    is: (value: string) => value && value !== '',
    then: () => yup.object().shape({
      label: yup.string(),
      value: yup.string().required(i18next.t(
        'errors.requiredField',
        { name: i18next.t('common.currency') },
      )),
    }),
    otherwise: () => yup.object().default(undefined),
  }),
  [KEY_SUPPLIER_TIER_10_OF_TOTAL]: yup.string().when(UPSTREAM_TITLE, {
    is: (upstreamTitle: { value: string }) => upstreamTitle?.value === UPSTREAM_TITLE_REQUIRED_VALUE,
    then: () => yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('suppliers.fields.keySuppliersInPercentage') },
    )),
  }),
  [ADDRESS_COUNTRY]: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }).default(undefined).required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('suppliers.fields.country'), interpolation: { escapeValue: false } },
  )),
  [ADDRESS_STATE]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).nullable(),
  [ADDRESS_POST_CODE]: yup.string().max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('suppliers.fields.post'), length: DATA_SETS_NAME_MAX_LENGTH },
  ))
    .matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),
  [ADDRESS_POST_CODE]: yup.string().max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('ownOperations.fields.post'), length: DATA_SETS_NAME_MAX_LENGTH },
  )),
  [ADDRESS_CITY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).nullable(),
  [ADDRESS_STREET]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('suppliers.fields.coordinates'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).transform(
    (value) => value.trim(),
  )
    .trim(),
  [ADDRESS_BUILDING]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('suppliers.fields.coordinates'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).transform(
    (value) => value.trim(),
  )
    .trim(),
  [ADDRESS_COORDINATES]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('suppliers.fields.coordinates'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).transform(
    (value) => value.trim(),
  )
    .trim(),
});

export type TCreateSupplierSchema = yup.InferType<typeof createSupplierSchema>;

export const createProductSchema = yup.object({
  [NAME]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('products.fields.productName'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('products.fields.productName'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('products.fields.productName') }))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [CODE]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('products.fields.productCode'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DESCRIPTION_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('products.fields.productCode'), length: DESCRIPTION_LENGTH },
  )).matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers'))
    .required(i18next.t('errors.requiredField', { name: i18next.t('products.fields.productCode') })),
  [UNIQUE_IDENTIFIER]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('products.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('products.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('products.fields.uniqueIdentifier') }))
    .matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),
  [PROFIT_CENTER_ID]: yup.array().min(1, i18next.t(
    'errors.requiredField',
    { name: i18next.t('products.fields.profitCentreId') },
  )).of(yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  })),
  [SUPPLIER]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('products.fields.supplier') },
    )),
  }),
  [SUPPLIER_ID]: yup.string(),
  [DATE_OPENED]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('products.fields.dateTo') },
  )),
  [DATE_CLOSED]: yup.string(),
  [VOLUME_QTY]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('products.fields.volume') },
  )),
  [VOLUME_QTY_UNIT]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('products.fields.volumeUnit') },
    )),
  }),
  [EQUITY_CONTROL]: yup.string(),
  [EXPECTED_GROWTH_DECLINE]: yup.string(),
  [RELIANCE_ON_FREE_NATURAL_RESOURCES]: yup.array().of(yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  })),
  [RELIANCE_ON_FREE_SOCIAL_RESOURCES]: yup.array().of(yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  })),
  [CRITICALITY_RATING]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('products.fields.rating') },
    )),
  }),
  [CRITICALITY_FROM_DOWNSTREAM_WASTE]: yup.string(),
  [KEY_SUPPLIER_TIER_1_TOTAL]: yup.string(),
  [KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).when(KEY_SUPPLIER_TIER_1_TOTAL, {
    is: (value: string) => value && value !== '',
    then: () => yup.object().shape({
      label: yup.string(),
      value: yup.string().required(i18next.t(
        'errors.requiredField',
        { name: i18next.t('common.currency') },
      )),
    }),
    otherwise: () => yup.object().default(undefined),
  }),
  [KEY_SUPPLIER_TIER_10_OF_TOTAL]: yup.string(),
});

export type TCreateProductSchema = yup.InferType<typeof createProductSchema>;

export const createCustomerSchema = yup.object({
  [NAME]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('customers.fields.customerName'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('customers.fields.customerName'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('suppliers.fields.customerName') }))
    .transform(
      (value) => value.trim(),
    )
    .trim(),
  [CODE]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('customers.fields.customerCode'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DESCRIPTION_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('customers.fields.customerCode'), length: DESCRIPTION_LENGTH },
  )).matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers'))
    .required(i18next.t('errors.requiredField', { name: i18next.t('customers.fields.customerCode') })),

  [UNIQUE_IDENTIFIER]: yup.string().min(DATA_SETS_NAME_MIN_LENGTH, i18next.t(
    'errors.minCharactersLength',
    { name: i18next.t('customers.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MIN_LENGTH },
  )).max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('customers.fields.uniqueIdentifier'), length: DATA_SETS_NAME_MAX_LENGTH },
  )).required(i18next.t('errors.requiredField', { name: i18next.t('customers.fields.uniqueIdentifier') }))
    .matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),

  [PROFIT_CENTER_ID]: yup.array().of(yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  })).when(BUSINESS_UNIT_TYPE, {
    is: (businessUnitType: { value: string }) => businessUnitType?.value === BUSINESS_UNIT_TYPE_LOGICAL,
    then: () => yup.array().min(1).of(yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })),
    otherwise: () => yup.array().of(yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })),
  }),

  [VALUE_CHAIN_SECTION]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('customers.fields.valueChain') },
    )),
  }),
  [OWNERSHIP]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  [EQUITY_SHAREHOLDING]: yup.string(),
  [FINANCIAL_CONTROL]: yup.string(),
  [BUSINESS_UNIT_TYPE]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('customers.fields.physicalOrLogical') },
    )),
  }),
  [DATE_OPENED]: yup.string().required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('customers.fields.dateOpened') },
  )),
  [DATE_CLOSED]: yup.string(),
  [PRIORITY_RATING]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  [NACE_SECTION]: yup.object().shape({
    label: yup.string(),
    value: yup.string().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('suppliers.fields.industry') },
    )),
    divisions: yup.array(),
  }),
  [NACE_CODE]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  [EXPECTED_GROWTH_NEXT_12M]: yup.string(),
  [REVENUE_ROLLING_PERCENTS]: yup.string(),
  [REVENUE_ROLLING_AMOUNT]: yup.string(),
  [REVENUE_ROLLING_CURRENCY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).when(REVENUE_ROLLING_AMOUNT, {
    is: (value: string) => value && value !== '',
    then: () => yup.object().required(i18next.t(
      'errors.requiredField',
      { name: i18next.t('common.currency') },
    )),
    otherwise: () => yup.object().default(undefined),
  }),
  [ADDRESS_COUNTRY]: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }).default(undefined).required(i18next.t(
    'errors.requiredField',
    { name: i18next.t('customers.fields.country'), interpolation: { escapeValue: false } },
  )),
  [ADDRESS_STATE]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).nullable(),
  [ADDRESS_POST_CODE]: yup.string().max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('customers.fields.post'), length: DATA_SETS_NAME_MAX_LENGTH },
  ))
    .matches(ALPHANUMERIC_AND_NUMBER_REGEX, i18next.t('errors.nameContainLettersAndNumbers')),
  [ADDRESS_POST_CODE]: yup.string().max(DATA_SETS_NAME_MAX_LENGTH, i18next.t(
    'errors.maxCharactersLength',
    { name: i18next.t('customers.fields.post'), length: DATA_SETS_NAME_MAX_LENGTH },
  )),
  [ADDRESS_CITY]: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).nullable(),
  [ADDRESS_STREET]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('customers.fields.coordinates'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).transform(
    (value) => value.trim(),
  )
    .trim(),
  [ADDRESS_BUILDING]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('customers.fields.coordinates'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).transform(
    (value) => value.trim(),
  )
    .trim(),
  [ADDRESS_COORDINATES]: yup.string().test(
    'Min length when not empty value',
    i18next.t(
      'errors.minCharactersLength',
      { name: i18next.t('customers.fields.coordinates'), length: DATA_SETS_NAME_MIN_LENGTH },
    ),
    (value) => !value || value.length >= DATA_SETS_NAME_MIN_LENGTH,
  ).transform(
    (value) => value.trim(),
  )
    .trim(),
});

export type TCreateCustomerSchema = yup.InferType<typeof createCustomerSchema>;
