import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'components/Modal/Modal';
import { LANGUAGE } from 'constants/formFields';
import i18n from 'i18next';
import {
  profileLanguageSchema, type TProfileLanguageSchema,
} from 'utils/validators';

import { SUPPORT_LANGUAGES } from '../../constants/languages';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { setAuthState } from '../../store/slices/auth/slice';
import { selectLocale } from '../../store/slices/translates/selectors';
import { toggleLocale } from '../../store/slices/translates/slice';
import { useUpdateUserLocalizationMutation, useUserMeQuery } from '../../store/slices/user/apis/user';
import Checkbox from '../Checkbox/Checkbox';
import Separator from '../Separator/Separator';

import type ISelectDefaultLanguageModal from './interfaces/ISelectDefaultLanguageModal';

import styles from './scss/SelectDefaultLanguageModal.module.scss';

function SelectDefaultLanguageModal({
  open,
}: ISelectDefaultLanguageModal) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const language = useAppSelector(selectLocale);

  const { data } = useUserMeQuery();
  const [updateUserLocalization] = useUpdateUserLocalizationMutation();

  const companyLanguages = data?.data?.company?.localization?.languages || [];

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<TProfileLanguageSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(profileLanguageSchema),
    defaultValues: {
      [LANGUAGE]: language,
    },
  });

  const closeModal = () => {
    dispatch(setAuthState({
      isFirstLogin: false,
    }));
  };

  const onHandleConfirm = handleSubmit((values) => {
    updateUserLocalization(values).unwrap().then(() => {
      dispatch(toggleLocale(values.language));
      toast.success(i18n.t('toast.successChangeLanguage'));
      closeModal();
    });
  });

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Modal
      open={open}
      title={t('staticTranslates.selectTheDefaultLanguage')}
      description={t('staticTranslates.youCanChangeTheDefaultLanguage')}
      closeTitle={t('common.cancel')}
      onReject={closeModal}
      onConfirm={onHandleConfirm}
    >
      <Separator />
      <Controller
        control={control}
        render={({ field: { onChange, value: formValue } }) => (
          <div className={styles.list}>
            {Object.values(SUPPORT_LANGUAGES).filter(
              ({ value }) => !companyLanguages || companyLanguages.includes(value),
            ).map(({
              label, value, icon,
            }) => (
              <Checkbox
                key={value}
                name={value}
                checked={formValue === value}
                value={value}
                onChange={() => onChange(value)}
                type="radio"
                label={(
                  <div className={styles.label}>
                    <span>{icon}</span>
                    <span>{label}</span>
                  </div>
                )}
              />
            ))}
          </div>
        )}
        name={LANGUAGE}
      />
      <Separator />
    </Modal>
  );
}

export default SelectDefaultLanguageModal;
