import { clsx } from 'clsx';

import type ITableCell from './interfaces/ITableCell';

import styles from './sass/TableCell.module.scss';

function TableCell({
  children,
  className,
  head,
  colSpan,
  rowSpan,
}: ITableCell) {
  const classes = clsx(styles.root, className, {
    [styles.head]: head,
  });

  return (
    head ? (
      <th className={classes} colSpan={colSpan}>
        {children}
      </th>
    ) : (
      <td className={classes} colSpan={colSpan} rowSpan={rowSpan}>
        {children}
      </td>
    )
  );
}

export default TableCell;
