import { t } from 'i18next';

import { PILLAR_ACCESS_TYPES } from './interfaces';

export type TModuleAccessItem = {
  label: string,
  color: string,
};

export const MODULE_STATUSES: Record<string, TModuleAccessItem> = {
  [PILLAR_ACCESS_TYPES.dimmed_disabled]: {
    label: t('common.dimmedDisabled'),
    color: '#7F2BED',
  },
  [PILLAR_ACCESS_TYPES.invisible_disabled]: {
    label: t('common.invisibleDisabled'),
    color: '#DC2626',
  },
  [PILLAR_ACCESS_TYPES.visible_enabled]: {
    label: t('common.visibleEnabled'),
    color: '#00D1FF',
  },
};
