import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Button from 'components/Button/Button';

import CompanyAdminCard from '../CompanyAdminCard/CompanyAdminCard';

import type ICompanyAdminForm from '../../interfaces/ICompanyAdminForm';

import styles from './scss/CompanyAdminForm.module.scss';

function CompanyAdminForm({
  control, adminsArray, onAddAdmin, onRemoveAdmin,
}: ICompanyAdminForm) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {adminsArray.map((admin, index) => (
          <CompanyAdminCard
            key={admin.id}
            control={control}
            index={index}
            id={admin.id}
            status={admin?.status}
            onRemoveAdmin={onRemoveAdmin}
            showSeparator={index !== adminsArray.length - 1}
          />
        ))}
      </div>

      <Button
        title={t('common.addAdmin')}
        onClick={onAddAdmin}
        startIcon={<PlusIcon />}
        variant="ghost"
      />
    </div>
  );
}

export default CompanyAdminForm;
