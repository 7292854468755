import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import DropZone from 'components/DropZone/DropZone';
import { DATA_SET_UPLOAD_FILE } from 'constants/formFields';
import { DATA_SETS_MIME_TYPES_ACCEPT, MAX_LOGO_SIZE } from 'constants/general';

import type { IUploadFile } from './interfaces/IUploadFile';

import styles from './scss/UploadFile.module.scss';

function UploadFile({ uploadControl, onDownloadExample }: IUploadFile) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>

      <div className={styles.wrapper}>
        <Controller
          name={DATA_SET_UPLOAD_FILE}
          control={uploadControl}
          render={({
            field: { onChange, value }, fieldState: { error },
          }) => (
            <DropZone
              title={t('dataSetsManagement.uploadFile.title')}
              hint={t('dataSetsManagement.uploadFile.subTitle')}
              files={value ? [value] : []}
              onUploadChange={([file]) => onChange(file)}
              accept={DATA_SETS_MIME_TYPES_ACCEPT}
              maxSize={MAX_LOGO_SIZE}
              error={error?.message}
              isActionsVisible
              icon={<CloudIcon />}
              dropZoneClassName={styles.dropZone}
              onDownloadExample={onDownloadExample}
              isDataSets
            />
          )}
        />
      </div>
    </div>
  );
}

export default UploadFile;
