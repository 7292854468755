import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LANGUAGE } from 'constants/formFields';
import { profileLanguageSchema } from 'utils/validators';

import { useAppSelector } from '../../../../store/hooks/useApp';
import { selectLocale } from '../../../../store/slices/translates/selectors';

import type { TProfileLanguageSchema } from 'utils/validators';

const useProfileLanguageForm = () => {
  const language = useAppSelector(selectLocale);

  const {
    control,
    handleSubmit,
    formState,
    reset,
  } = useForm<TProfileLanguageSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(profileLanguageSchema),
    defaultValues: {
      [LANGUAGE]: language,
    },
  });

  const { isValid } = formState;

  return {
    control,
    handleSubmit,
    isValid,
    reset,
  };
};

export default useProfileLanguageForm;
