export const LANGUAGES = 'languages';
export const LANGUAGE = 'language';
export const ADMINS = 'admins';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL = 'email';
export const NAME = 'name';
export const TYPE = 'type';
export const COUNTRY = 'country';
export const DESCRIPTION = 'description';
export const AUTHENTICATION_TYPE = 'authenticationType';
export const AUTHENTICATION_SETUP = 'authenticationSetup';
export const AUTHENTICATION_GOOGLE = 'google';
export const AUTHENTICATION_MICROSOFT = 'microsoft';
export const AUTHENTICATION_APP = 'authenticationApp';
export const SMS_CODE = 'code';
export const PHONE = 'phone';
export const VERIFICATION_CODE = 'code';
export const PASSWORD = 'password';
export const COMPANY_LOGO = 'companyLogo';
export const PARTNER_LOGO = 'partnerLogo';
export const PRIMARY_COLOR = 'primary';
export const SECONDARY_COLOR = 'secondary';
export const HEADINGS_COLOR_1 = 'headings1';
export const HEADINGS_COLOR_2 = 'headings2';
export const SUBTITLE_COLOR = 'subtitle';
export const CURRENT_PASSWORD = 'currentPassword';
export const REPEAT_NEW_PASSWORD = 'repeatPassword';
export const ID = 'id';
export const SEARCH = 'search';
export const FILTER_BY_STATUS = 'filterByStatus';
export const ROLE = 'role';
export const STATUS = 'status';
export const PILLARS = 'pillars';
export const PILLAR_MODULES = 'pillarModules';
export const CAN_VIEW = 'canView';
export const PILLAR_ID = 'pillarId';
export const ACCESS = 'access';
export const SSO = 'sso';
export const MFA_APP = 'mfaApp';
export const MFA_SMS = 'mfaSms';
export const VALUE_CHAIN_EDITING_ACCESS = 'valueChainEditingAccess';
export const VALUE_CHAIN_LINKING_ACCESS = 'valueChainLinkingAccess';
export const USER_MANAGEMENT_ACCESS = 'userManagementAccess';
export const MULTI_LINGUAL_ACCESS = 'multiLingualAccess';
export const UI_CONFIGURATION_ACCESS = 'UIConfigurationAccess';
export const DATA_SETS_MANAGEMENT_ACCESS = 'dataSetsManagementAccess';
export const REASON = 'reason';
export const SORT = 'sort';
export const ORDER_KEY = 'orderKey';
export const ORDER_TYPE = 'orderType';
export const DATA_SET_UPLOAD_FILE = 'dataSetUploadFile';
export const MAPPING_TARGET = 'mappingTarget';
export const MAPPING_SOURCES = 'mappingSources';
export const SHOW_ARCHIVED = 'showArchived';
export const SELECTED_ITEMS = 'selectedItems';
export const SELECT_ALL = 'selectAll';
export const PROFIT_CENTRE_NAME = 'name';
export const PROFIT_CENTRE_UNIQUE_IDENTIFIER = 'uniqueIdentifier';
export const REVENUE_ROLLING_PERCENTS = 'totalRevenueRolling12MPercents';
export const GROSS_MARGIN_ROLLING_PERCENTS = 'totalGrossMarginRolling12MPercents';
export const REVENUE_ROLLING_AMOUNT = 'totalRevenueRolling12MActual';
export const GROSS_MARGIN_ROLLING_AMOUNT = 'totalGrossMarginRolling12MActual';
export const FINISHED_GOOD_STOCK_AMOUNT = 'wipFinishedGoodsStock';
export const REVENUE_ROLLING_CURRENCY = 'totalRevenueRolling12MActual_currency';
export const GROSS_MARGIN_ROLLING_CURRENCY = 'totalGrossMarginRolling12MActual_currency';
export const FINISHED_GOOD_STOCK_AMOUNT_CURRENCY = 'wipFinishedGoodsStock_currency';
export const ORG_TYPE_PREFIX = 'orgTypePrefix';
export const GEO_TYPE_PREFIX = 'geoTypePrefix';
export const ORG_PATH = 'orgPath';
export const UNIQUE_IDENTIFIER = 'uniqueIdentifier';
export const LEGAL_NAME = 'legalName';
export const PROFIT_CENTER_ID = 'profitCenterId';
export const VALUE_CHAIN_SECTION = 'valueChainSection';
export const OWNERSHIP = 'ownership';
export const EQUITY_SHAREHOLDING = 'equityShareholding';
export const FINANCIAL_CONTROL = 'financialControl';
export const BUSINESS_UNIT_TYPE = 'businessUnitType';
export const ASSET_CATEGORY = 'assetCategory';
export const OWNED_LEASED = 'ownedLeased';
export const NUMBER_OF_EMPLOYEES = 'numberOfEmployees';
export const DATE_OPENED = 'dateOpened';
export const DATE_CLOSED = 'dateClosed';
export const NACE_SECTION = 'naceSection';
export const NACE_CODE = 'naceCode';
export const EU_TAXONOMY_CODE = 'euTaxonomyCode';
export const NET_BOOK = 'netBookValue';
export const NET_BOOK_CURRENCY = 'netBookValue_currency';
export const ADDRESS_COUNTRY = 'addressCountry';
export const ADDRESS_STATE = 'addressState';
export const ADDRESS_POST_CODE = 'addressPostCode';
export const ADDRESS_CITY = 'addressCity';
export const ADDRESS_STREET = 'addressStreet';
export const ADDRESS_BUILDING = 'addressBuilding';
export const ADDRESS_COORDINATES = 'addressCoordinates';
export const CODE = 'code';
export const UPSTREAM_TITLE = 'upstreamTitle';
export const SPEND = 'spend';
export const SPEND_CURRENCY = 'spend_currency';
export const KEY_SUPPLIER_TIER_1_TOTAL = 'keySupplierTier1Total';
export const KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY = 'keySupplierTier1Total_currency';
export const KEY_SUPPLIER_TIER_10_OF_TOTAL = 'keySupplierTier1OfTotal';
export const SUPPLIER = 'supplier';
export const SUPPLIER_ID = 'supplierId';
export const VOLUME_QTY = 'volumeQty';
export const VOLUME_QTY_UNIT = 'volumeQtyUnit';
export const EQUITY_CONTROL = 'equityControl';
export const EXPECTED_GROWTH_DECLINE = 'expectedGrowthDecline';
export const RELIANCE_ON_FREE_NATURAL_RESOURCES = 'relianceOnFreeNaturalResources';
export const RELIANCE_ON_FREE_SOCIAL_RESOURCES = 'relianceOnFreeSocialResources';
export const CRITICALITY_RATING = 'criticalityRating';
export const CRITICALITY_FROM_DOWNSTREAM_WASTE = 'circularityFromDownstreamWaste';
export const EXPECTED_GROWTH_NEXT_12M = 'expectedGrowthNext12M';
export const PRIORITY_RATING = 'priorityRating';
