import { skipToken } from '@reduxjs/toolkit/query';
import { USER_PILLAR_ACCESS_TYPES } from 'constants/interfaces';

import { useUserMeQuery } from '../store/slices/user/apis/user';
import { selectPillarModuleByKey } from '../store/slices/user/selectors';

import type IUsePillarModulePermission from './interfaces/IUsePillarModulePermission';

const usePillarModulePermission = ({
  pillarModuleKey, userId,
}: IUsePillarModulePermission) => {
  const { data, pillarModule, isLoading } = useUserMeQuery(
    pillarModuleKey ? undefined : skipToken,
    {
      selectFromResult: (result) => ({
        ...result,
        pillarModule: selectPillarModuleByKey(
          { data: result.data, pillarModuleKey },
        ),
      }),
    },
  );

  const { access } = pillarModule || {};

  const isDisabled = access === USER_PILLAR_ACCESS_TYPES.noAccess
      || access === USER_PILLAR_ACCESS_TYPES.dimmedDisabled;

  const isVisible = !isDisabled && access !== USER_PILLAR_ACCESS_TYPES.invisibleDisabled;

  const isOwnUser = data?.data.id === userId;

  const isActionsVisible = access === USER_PILLAR_ACCESS_TYPES.viewEdit && !isLoading && !isOwnUser;

  const isAuditOrViewAccess = access === USER_PILLAR_ACCESS_TYPES.viewAudit
  || access === USER_PILLAR_ACCESS_TYPES.viewOnly;

  return {
    isDisabled,
    isVisible,
    isActionsVisible,
    isLoading,
    isAuditOrViewAccess,
  };
};

export default usePillarModulePermission;
