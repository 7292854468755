import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EMAIL, PASSWORD } from 'constants/formFields';
import { loginSchema } from 'utils/validators';

import type { TUseLoginForm } from '../interfaces/TUseLoginForm';

const useLoginForm = () => {
  const {
    control,
    handleSubmit,
    setError,
  } = useForm<TUseLoginForm>({
    mode: 'onSubmit',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      [EMAIL]: '',
      [PASSWORD]: '',
    },
  });

  return {
    control,
    handleSubmit,
    setError,
  };
};

export default useLoginForm;
