import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PILLARS } from 'constants/formFields';
import { userPermissionsSchema } from 'utils/validators';

import type { TUserPermissionsSchema } from 'utils/validators';
import type { IUsePermissionsForm } from '../interfaces/IUsePermissionsForm';

const usePermissionsForm = ({ pillars }: IUsePermissionsForm) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<TUserPermissionsSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(userPermissionsSchema),
    defaultValues: {
      [PILLARS]: [],
    },
  });

  useEffect(() => {
    if (pillars) {
      reset({
        [PILLARS]: pillars,
      });
    }
  }, [reset, pillars]);

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
  };
};

export default usePermissionsForm;
