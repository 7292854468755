import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import AppRoutes from 'constants/AppRoutes';

import type { IEmptyList } from './interfaces/IEmptyList';

import styles from './scss/EmptyList.module.scss';

function EmptyList({ title, isProfitCenterEmpty, isSupplierEmpty }: IEmptyList) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onProfitCentersOpen = () => {
    navigate(AppRoutes.profitCentres);
  };

  const onSupplierOpen = () => {
    navigate(AppRoutes.suppliers);
  };

  return (
    <div className={clsx(styles.root, {
      [styles.warning]: isProfitCenterEmpty || isSupplierEmpty,
    })}
    >
      <InfoIcon />
      <p className={styles.text}>
        {!isProfitCenterEmpty && !isSupplierEmpty
          ? t('dataSetsManagement.emptyData', { name: title })
          : null}

        {isProfitCenterEmpty
          ? (
            <Trans
              i18nKey="dataSetsManagement.emptyDataWithEmptyDataSet"
              t={t}
              components={{
                br: <br />,
              }}
              values={{
                name: title,
                emptyDataSetName: t('dataSetsManagement.profitCentres.title'),
              }}
            />
          )
          : isSupplierEmpty && (
          <Trans
            i18nKey="dataSetsManagement.emptyDataWithEmptyDataSet"
            t={t}
            components={{
              br: <br />,
            }}
            values={{
              name: title,
              emptyDataSetName: t('dataSetsManagement.suppliers.title'),
            }}
          />
          )}
      </p>
      {isProfitCenterEmpty ? (
        <Button
          className={styles.button}
          title={t('dataSetsManagement.openProfitCentres')}
          onClick={onProfitCentersOpen}
        />
      ) : isSupplierEmpty && (
      <Button
        className={styles.button}
        title={t('dataSetsManagement.openSuppliers')}
        onClick={onSupplierOpen}
      />
      )}
    </div>
  );
}

export default EmptyList;
