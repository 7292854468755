import { t } from 'i18next';

import type { ReactNode } from 'react';

import { ReactComponent as NETIcon } from '../assets/icons/dutch.svg';
import { ReactComponent as GBIcon } from '../assets/icons/english.svg';
import { ReactComponent as FRIcon } from '../assets/icons/french.svg';
import { ReactComponent as DLIcon } from '../assets/icons/germany.svg';
import { ReactComponent as ITIcon } from '../assets/icons/italian.svg';
import { ReactComponent as PRIcon } from '../assets/icons/portuguese.svg';
import { ReactComponent as SPIcon } from '../assets/icons/spenish.svg';

export type TSupportLanguages = {
  value: string,
  label: string,
  icon: ReactNode,
  disabled?: boolean,
};

export const SUPPORT_LANGUAGES: Record<string, TSupportLanguages> = {
  en: {
    value: 'en',
    label: t('languages.english'),
    icon: <GBIcon />,
    disabled: true,
  },
  de: {
    value: 'de',
    label: t('languages.german'),
    icon: <DLIcon />,
  },
  nl: {
    value: 'nl',
    label: t('languages.dutch'),
    icon: <NETIcon />,
  },
  fr: {
    value: 'fr',
    label: t('languages.french'),
    icon: <FRIcon />,
  },
  es: {
    value: 'es',
    label: t('languages.spanish'),
    icon: <SPIcon />,
  },
  pt: {
    value: 'pt',
    label: t('languages.portuguese'),
    icon: <PRIcon />,
  },
  it: {
    value: 'it',
    label: t('languages.italian'),
    icon: <ITIcon />,
  },
};
