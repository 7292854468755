import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import { SEARCH, STATUS } from 'constants/formFields';
import { FILTER_STATUSES } from 'constants/general';

import type IClientsFilters from '../../interfaces/IClientsFilters';

import styles from './scss/ClientsFilters.module.scss';

function ClientsFilters({ control, onSearch, onFilterChange }: IClientsFilters) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.input}>
        <Controller
          control={control}
          name={SEARCH}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              name={name}
              value={value}
              placeholder={t('clients.companyNameAdmin')}
              icon={<SearchIcon />}
              onChange={({ target }) => onSearch({ search: target.value, onChange })}
              error={error?.message}
            />
          )}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.label}>
          <FilterIcon />
          <p>{t('clients.filterByStatus')}</p>
        </div>
        <div className={styles.select}>
          <Controller
            control={control}
            name={STATUS}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                options={FILTER_STATUSES}
                value={value}
                onChange={(status) => {
                  onChange(status);
                  onFilterChange();
                }}
                error={error?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default ClientsFilters;
