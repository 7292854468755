import { clsx } from 'clsx';

import type IBadgeStatus from './interfaces/IBadgeStatus';

import styles from './scss/BadgeStatus.module.scss';

function Badge({
  status, color, textColor, className,
}: IBadgeStatus) {
  const classes = clsx(styles.badge, className);

  return (
    <div
      className={classes}
      style={{ backgroundColor: color, color: textColor }}
    >
      {status}
    </div>
  );
}

export default Badge;
