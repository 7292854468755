import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import AUTHENTICATION_TYPES from 'constants/authenticationTypes';
import { AUTHENTICATION_TYPE } from 'constants/formFields';

import type { IAuthenticationTypeView } from './interfaces/IAuthenticationTypeView';

import styles from './sass/AuthenticationTypeView.module.scss';

function AuthenticationTypeView({
  control,
  onAuthenticationTypeSubmit,
}: IAuthenticationTypeView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('login.title')}
      </div>
      <div className={styles.subTitle}>{t('authenticationType.subTitle')}</div>
      <div className={styles.formWrapper}>
        <Controller
          control={control}
          render={({ field: { onChange, value: formValue } }) => (
            <>
              {AUTHENTICATION_TYPES.map(({
                label, value, icon, name,
              }, index) => (
                <div className={styles.radioWrapper} key={`authenticationTypeView_${index.toString()}`}>
                  <Checkbox
                    key={value}
                    name={name}
                    checked={formValue === value}
                    value={value}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                    type="radio"
                    label={(
                      <div className={styles.radioLabelWrapper}>
                        <span>{icon}</span>
                        <span className={styles.label}>{label}</span>
                      </div>
                    )}
                  />
                </div>

              ))}
            </>
          )}
          name={AUTHENTICATION_TYPE}
        />
      </div>
      <Button
        className={styles.button}
        title={t('common.continue')}
        onClick={onAuthenticationTypeSubmit}
      />
    </div>
  );
}

export default AuthenticationTypeView;
