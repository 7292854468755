import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg';
import Avatar from 'components/Avatar/Avatar';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import NoData from 'components/NoData/NoData';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';

import type IUIConfigurationSection from './interfaces/IUIConfigurationSection';

import styles from './scss/UIConfigurationSection.module.scss';

function UIConfigurationSection({
  uiConfiguration,
}: IUIConfigurationSection) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId = '' } = useParams();

  const onHandleEdit = () => {
    const redirectPath = generatePath(AppRoutes.clientProfile, { clientId });
    navigate(
      generatePath(AppRoutes.uiConfiguration, { clientId }),
      { state: { redirectPath } },
    );
  };

  return (
    <InfoPanel
      title={t('clients.uiConfiguration')}
      icon={<PaletteIcon />}
      onEdit={onHandleEdit}
    >
      <h3 className={styles.title}>{t('common.logos')}</h3>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          <p>{t('common.companyLogo')}</p>
          <div>
            <Avatar
              className={styles.logo}
              src={uiConfiguration?.companyLogo}
            />
          </div>
        </div>
        <div className={styles.item}>
          <p>{t('common.partnerLogo')}</p>
          <div>
            <Avatar
              className={styles.logo}
              src={uiConfiguration?.partnerLogo}
            />
          </div>
        </div>
      </div>
      <Separator />
      <h3 className={styles.title}>{t('common.colors')}</h3>
      <div className={styles.wrapper}>
        {uiConfiguration?.colorPalette
          ? Object.entries(uiConfiguration?.colorPalette).map(([key, value]) => (
            <div className={styles.item} key={value}>
              <p>{t(`common.${key}`)}</p>
              <div className={styles.color}>
                <div
                  className={styles.colorItem}
                  style={{ backgroundColor: value }}
                />
                <p className={styles.colorText}>
                  {value}
                </p>
              </div>
            </div>
          )) : <NoData />}
      </div>
    </InfoPanel>
  );
}

export default UIConfigurationSection;
