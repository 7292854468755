import {
  RELEASE_BASE_TRANSLATION,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';

import { API_METHODS } from '../../../../constants/general';

export const weblateApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    releaseBaseTranslation: builder.mutation<void, void>({
      query: () => ({
        url: RELEASE_BASE_TRANSLATION,
        method: API_METHODS.post,
      }),
    }),
  }),
});

export const {
  useReleaseBaseTranslationMutation,
} = weblateApi;
