import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { IUseStepper } from './interfaces/IUseStepper';

const useStepper = ({
  steps,
  onProcessCompleted = () => {},
}: IUseStepper) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const stepperPaths = useMemo(
    () => steps.filter(({ isSkipped }) => !isSkipped).map(({ path }) => path) || [],
    [steps],
  );

  const activeStepIndex = stepperPaths.indexOf(pathname);
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === stepperPaths.length - 1;

  const currentStep = steps[activeStepIndex];

  const onContinue = useCallback(() => {
    if (isLastStep) {
      onProcessCompleted();
    } else if (currentStep?.onSubmit) {
      currentStep?.onSubmit?.();
    } else {
      navigate(stepperPaths[activeStepIndex + 1]);
    }
  }, [activeStepIndex, isLastStep, navigate, onProcessCompleted, stepperPaths, currentStep]);

  const onPrev = () => {
    if (isFirstStep) return;

    navigate(stepperPaths[activeStepIndex - 1]);
  };

  return {
    isFirstStep,
    isLastStep,
    onContinue,
    onPrev,
    currentStep,
  };
};

export default useStepper;
