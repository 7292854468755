import { useTranslation } from 'react-i18next';
import ClientPanel from 'components/ClientPanel/ClientPanel';
import SaveDraftButton from 'components/SaveDraftButton/SaveDraftButton';

import MultilingualForm from './components/MultilingualForm/MultilingualForm';

import type IMultilingualView from './interfaces/IMultilingualView';

function MultilingualView({
  control, isCreatePage, onPrevClick, onSave, isDirty, isDisabledSave, onDraftSave,
}: IMultilingualView) {
  const { t } = useTranslation();

  return (
    <>
      {isCreatePage ? <SaveDraftButton isDisabled={isDisabledSave} onDraftSave={onDraftSave} /> : null }
      <ClientPanel
        title={t('clients.multiLingual')}
        text={t('clients.selectLanguages')}
        onPrevClick={onPrevClick}
        onSave={onSave}
        isCreate={isCreatePage}
        isDisabledSave={isDisabledSave}
        isDirty={isDirty}
      >
        <MultilingualForm control={control} />
      </ClientPanel>
    </>

  );
}

export default MultilingualView;
