import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClientPanelFooter from 'components/ClientPanel/ClientPanelFooter';
import Panel from 'components/Panel/Panel';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import { EMAIL, FIRST_NAME, LAST_NAME } from 'constants/formFields';

import type { IPersonalInfoView } from './interfaces/IPersonalInfoView';

import styles from './sass/PersonalInfoView.module.scss';

function PersonalInfoView({ control, onPersonalInfoSubmit, onCancel }: IPersonalInfoView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <div className={styles.wrapper}>
          <Controller
            control={control}
            name={FIRST_NAME}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.firstName')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value)}
                required
              />
            )}
          />
          <Controller
            control={control}
            name={LAST_NAME}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.lastName')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value)}
                required
              />
            )}
          />
          <Controller
            control={control}
            name={EMAIL}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <div>
                <TextField
                  name={name}
                  value={value}
                  label={t('common.email')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  disabled
                  required
                />
              </div>
            )}
          />
        </div>
        <Separator />
        <ClientPanelFooter
          onSave={onPersonalInfoSubmit}
          onCancel={onCancel}
        />
      </Panel>
    </div>
  );
}

export default PersonalInfoView;
