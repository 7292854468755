import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import { SORT_DIRECTION_INDICATOR } from 'constants/dataSets';
import { ORDER_KEY, ORDER_TYPE, SORT } from 'constants/formFields';
import { ORDERS, USER_PILLAR_MODULES } from 'constants/interfaces';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import { useAppDispatch } from 'store/hooks/useApp';
import { useCompanyAdminUsersListQuery } from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';
import { resetState } from 'store/slices/companyAdminUsers/slice';

import useUserManagementForm from './hooks/useUserManagementForm';
import UserManagementView from './UserManagementView';

import type { ISearchInputHandler } from 'components/TextField/interfaces/ITextField';
import type { TOrderByIdentifier } from 'constants/interfaces';
import type { ReactElement } from 'react';
import type ISearchParams from './interfaces/ISearchParams';

function UserManagement(): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [params, setParams] = useState<ISearchParams>({});

  const [orderBy, setOrderBy] = useState('');
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
  } = useUserManagementForm();

  const {
    isActionsVisible,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.userManagement },
  );

  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    setParams((prev) => ({ ...prev, search }));
  });

  const { data } = useCompanyAdminUsersListQuery({
    search: params.search,
    filterByStatus: params.filterByStatus?.value || '',
    orderBy,
  });

  const onFilterChange = () => {
    const { filterByStatus } = getValues();
    setParams((prev) => ({
      ...prev,
      filterByStatus: {
        ...filterByStatus,
        value: filterByStatus.value || '',
        label: filterByStatus.label || '',
      },
    }));
  };

  const { items: users } = data?.data || {};

  const onUserManagementSubmit = handleSubmit(() => {
  });

  const onCreateNewUser = (() => {
    dispatch(resetState());
    navigate(AppRoutes.createNewUser, { state: { redirectPath: AppRoutes.userManagement } });
  });

  const onSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const onSortChange = (orderByIdentifier: TOrderByIdentifier) => {
    const { sort: { orderKey, orderType } } = getValues();
    const isAsc = orderKey === orderByIdentifier && orderType === ORDERS.asc;
    setValue(SORT, { [ORDER_KEY]: orderByIdentifier, [ORDER_TYPE]: isAsc ? ORDERS.desc : ORDERS.asc });
    const order = isAsc ? `${SORT_DIRECTION_INDICATOR}${orderByIdentifier}` : orderByIdentifier;
    setOrderBy(order);
  };

  return (
    <UserManagementView
      control={control}
      onUserManagementSubmit={onUserManagementSubmit}
      onCreateNewUser={onCreateNewUser}
      users={users}
      onSearch={onSearchChange}
      onFilterChange={onFilterChange}
      isActionsVisible={isActionsVisible}
      onSortChange={onSortChange}
    />
  );
}

export default UserManagement;
