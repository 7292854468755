import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetNaceCodeTreeQuery,
  useSupplierRecordQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import getSupplierDefaultValues from 'utils/formHelpers/getSupplierDefaultValues';
import { createSupplierSchema } from 'utils/validators';

import type { TCreateSupplierSchema } from 'utils/validators';
import type { ICreateSupplierForm } from '../interfaces/ICreateSupplierForm';

const useCreateSupplierForm = ({ recordId, isPageLoading }: ICreateSupplierForm) => {
  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { data } = useSupplierRecordQuery(fetchQuery);

  const { data: naceCodeTreeData } = useGetNaceCodeTreeQuery();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
    setError,
    getValues,
    watch,
    setValue,
    resetField,
  } = useForm<TCreateSupplierSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createSupplierSchema),
    defaultValues: getSupplierDefaultValues({ values: data?.data, naceCodeTreeData }),
  });

  useEffect(() => {
    if (data?.data && !isPageLoading) {
      reset(getSupplierDefaultValues(
        { values: data?.data, naceCodeTreeData },
      ));
    }
  }, [reset, data, isPageLoading, naceCodeTreeData]);

  const fieldsName = Object.keys(getValues());

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
    isLoading,
    setError,
    fieldsName,
    watch,
    setValue,
    resetField,
  };
};

export default useCreateSupplierForm;
