import { GEO_LOCATIONS_CITIES, GEO_LOCATIONS_COUNTRIES, GEO_LOCATIONS_STATES } from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';

import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type IGeoLocationsCitiesRequest from '../interfaces/IGeoLocationsCitiesRequest';
import type { IGeoLocationsCitiesResponse } from '../interfaces/IGeoLocationsCitiesResponse';
import type IGeoLocationsCountriesRequest from '../interfaces/IGeoLocationsCountriesRequest';
import type { IGeoLocationsCountriesResponse } from '../interfaces/IGeoLocationsCountriesResponse';
import type IGeoLocationsStatesRequest from '../interfaces/IGeoLocationsStatesRequest';
import type { IGeoLocationsStatesResponse } from '../interfaces/IGeoLocationsStatesResponse';

const geoLocationsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    geoLocationsCountries: builder.query<IBaseQueryResponse<IGeoLocationsCountriesResponse[]>,
    IGeoLocationsCountriesRequest>({
      query: (data) => ({
        url: GEO_LOCATIONS_COUNTRIES,
        params: data,
      }),
    }),
    geoLocationsStates: builder.query<IBaseQueryResponse<IGeoLocationsStatesResponse[]>,
    IGeoLocationsStatesRequest>({
      query: (data) => ({
        url: GEO_LOCATIONS_STATES,
        params: data,
      }),
    }),
    geoLocationsCities: builder.query<IBaseQueryResponse<IGeoLocationsCitiesResponse[]>,
    IGeoLocationsCitiesRequest>({
      query: (data) => ({
        url: GEO_LOCATIONS_CITIES,
        params: data,
      }),
    }),
  }),
});

export const {
  useLazyGeoLocationsCountriesQuery,
  useLazyGeoLocationsStatesQuery,
  useLazyGeoLocationsCitiesQuery,
} = geoLocationsApi;

export default geoLocationsApi;
