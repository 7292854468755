import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/query';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import Backdrop from 'components/Modal/Backdrop';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';
import { VERIFICATION_CODE } from 'constants/formFields';
import { BUTTON_VARIANT } from 'constants/interfaces';
import useStepper from 'hooks/useStepper';
import useVerificationCodeForm from 'hooks/useVerificationCodeForm';
import i18n from 'i18n';
import { useAppDispatch } from 'store/hooks/useApp';
import { useEndSyncWithAppMutation, useVerifyTokenQuery } from 'store/slices/auth/apis/auth';
import { setAuthState } from 'store/slices/auth/slice';
import { useLazyUserMeQuery, useUserMeQuery } from 'store/slices/user/apis/user';

import SetupModalContent from './SetupModalContent';

import type { IAuthenticationSetupModal } from '../interfaces/IAuthenticationSetupModal';

import styles from './sass/AuthenticationSetupModal.module.scss';
import baseModalStyles from 'components/Modal/scss/Modal.module.scss';

function AuthenticationSetupModal({
  steps, isModalVisible, setIsSecretCodeVisible, isProfile, onModalClose, onResetBlockers,
}: IAuthenticationSetupModal) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userQueryParams = isProfile ? undefined : skipToken;
  const { data: userData } = useUserMeQuery(userQueryParams);
  const [getUserMe] = useLazyUserMeQuery();
  const { id } = userData?.data || {};
  const queryParams = id ? undefined : skipToken;

  const { data: verifyTokenData, isLoading } = useVerifyTokenQuery(queryParams);

  const { verifyToken } = verifyTokenData?.data || {};

  useEffect(() => {
    if (verifyToken && !isLoading) {
      dispatch(setAuthState({
        verifyToken,
      }));
    }
  }, [dispatch, isLoading, verifyToken]);

  const [endSyncWithApp] = useEndSyncWithAppMutation();

  const {
    control,
    handleSubmit,
    setError,
  } = useVerificationCodeForm();

  const onAuthenticationSetupModalSubmit = handleSubmit((values) => {
    endSyncWithApp(values)
      .unwrap().then(({ data }) => {
        if (data) {
          dispatch(
            setAuthState({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              verifyToken: data.verifyToken,
            }),
          );
          const path = isProfile ? generatePath(
            AppRoutes.profileSecurity,
          ) : AppRoutes.launchpad;
          onResetBlockers?.();
          onModalClose?.(true);
          navigate(path);
          if (isProfile) {
            getUserMe();
            toast.success(i18n.t('notifications.mfaCreated'));
          }
        }
      }).catch(({ data }) => {
        if (data?.error) {
          setError(VERIFICATION_CODE, { message: t('authenticationModal.invalidCode') });
        }
      });
  });

  const {
    onContinue, onPrev, isFirstStep, currentStep,
  } = useStepper({ steps, onProcessCompleted: onAuthenticationSetupModalSubmit });

  const onReject = () => {
    const path = generatePath(isProfile ? AppRoutes.profileSecurity : AppRoutes.authenticationSetup);

    navigate(path);
    onModalClose?.();
  };

  const onContinueStep = () => {
    setIsSecretCodeVisible(false);
    onContinue();
  };

  return isModalVisible ? createPortal(
    <Backdrop>
      <div className={clsx(baseModalStyles.root, styles.root)}>
        <h2 className={baseModalStyles.title}>{t('authenticationModal.title')}</h2>
        <p className={clsx(baseModalStyles.description, styles.description)}>
          <SetupModalContent
            control={control}
            setIsSecretCodeVisible={setIsSecretCodeVisible}
            currentStep={currentStep}
          />
        </p>

        <div className={styles.footer}>
          <Separator />
          <div className={styles.actions}>
            <Button
              title={t('common.close')}
              variant={BUTTON_VARIANT.cancel}
              onClick={onReject}
            />
            {!isFirstStep && (
            <Button
              title={t('common.prev')}
              variant={BUTTON_VARIANT.secondary}
              startIcon={<ArrowLeftIcon />}
              onClick={onPrev}
            />
            )}

            <Button
              title={t('common.continue')}
              onClick={onContinueStep}
              disabled={isLoading}
            />
          </div>
        </div>

      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default AuthenticationSetupModal;
