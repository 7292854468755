import type { IUseCompanyDraft } from './interfaces/IUseCompanyDraft';

const useCompanyDraft = <T>({
  handleFormSubmit,
  onSubmit,
}: IUseCompanyDraft<T>) => {
  const onDraftSave = onSubmit((values) => {
    handleFormSubmit({ values, isDraftSave: true });
  });
  return {
    onDraftSave,
  };
};

export default useCompanyDraft;
