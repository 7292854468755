import { useTranslation } from 'react-i18next';
import ClientPanel from 'components/ClientPanel/ClientPanel';
import SaveDraftButton from 'components/SaveDraftButton/SaveDraftButton';

import CompanyInfoForm from './components/CompanyInfoForm/CompanyInfoForm';

import type ICompanyInfoView from './interfaces/ICompanyInfoView';

function CompanyInfoView({
  control,
  isDisabledSave,
  isCreatePage,
  onSave,
  isDirty,
  onDraftSave,
}: ICompanyInfoView) {
  const { t } = useTranslation();

  return (
    <>
      {isCreatePage ? <SaveDraftButton isDisabled={!isDirty || isDisabledSave} onDraftSave={onDraftSave} /> : null }
      <ClientPanel
        title={t('clients.companyInfo')}
        text={t('clients.addCompanyInfo')}
        onSave={onSave}
        isCreate={isCreatePage}
        isDisabledSave={isDisabledSave}
        isDirty={isDirty}
      >
        <CompanyInfoForm
          control={control}
        />
      </ClientPanel>
    </>

  );
}

export default CompanyInfoView;
