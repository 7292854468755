import { PhoneInput } from 'react-international-phone';
import { clsx } from 'clsx';

import type IPhoneField from './interfaces/IPhoneField';

import 'react-international-phone/style.css';
import styles from './scss/PhoneField.module.scss';

function PhoneField({
  name, label, value, onChange, disabled,
  className, error, placeholder, required,
}: IPhoneField) {
  const classes = clsx(styles.root, className, {
    [styles.rootError]: error,
  });

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={name}>
          {required ? `${label}*` : label}
        </label>
      ) : null}
      <div className={styles.wrapper}>
        <PhoneInput
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
        />
      </div>
      {error ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
}

export default PhoneField;
