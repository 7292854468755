import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';

import type IBreadcrumbs from './interfaces/IBreadcrumbs';

import styles from './scss/Breadcrumbs.module.scss';

function Breadcrumbs({ breadcrumbs, className }: IBreadcrumbs) {
  return (
    <nav aria-label="breadcrumb">
      <ul className={clsx(styles.root, className)}>
        {breadcrumbs?.map((item, index) => (
          <Fragment key={`${item.path}-${item.label}`}>
            <li
              className={clsx(styles.item, {
                [styles.active]: index === breadcrumbs.length - 1,
              })}
            >
              {item?.path ? (
                <Link to={item.path}>{item.label}</Link>
              ) : (
                <span>{item.label}</span>
              )}
            </li>
            {index !== breadcrumbs.length - 1 ? (
              <li>
                <span className={styles.separator}>/</span>
              </li>
            ) : null}
          </Fragment>
        ))}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
