export default {
  title: 'Own Operations',
  subTitle: 'Lorem ipsum dolor',
  addNewOwnOperation: 'Add New Own Operation',
  editOwnOperation: 'Edit Own Operation',
  fields: {
    orgPath: 'Org Structure',
    name: 'Site Name or Logical Name',
    uniqueIdentifier: 'Unique Identifier',
    legalEntity: 'Legal Entity Name',
    profitCentreID: 'Profit Centre ID',
    valueChain: 'Value Chain Section',
    ownership: 'Ownership',
    equityShareholding: 'Equity Shareholding %',
    financialControl: 'Financial Control %',
    physicalOrLogical: 'Physical or Logical',
    assetCategory: 'Asset Category',
    owned: 'Owned/Leased',
    employees: 'No. of Employees',
    dateOpened: 'Date Opened',
    dateClosed: 'Date Closed',
    industry: 'Industry (NACE)',
    sector: 'Sector (NACE)',
    taxonomy: 'EU Taxonomy Activity Code',
    netBook: 'Net Book Value',
    country: 'Country of Operation/Incorporation',
    state: 'State / Region / County',
    post: 'Post Code',
    town: 'Town / City',
    street: 'Street',
    building: 'Building Name or Number',
    coordinates: 'Coordinates',
    actions: 'Actions',
  },
  filter: {
    resetFilters: 'Reset Filters',
    menu: {
      organizational: 'Organizational',
      geographical: 'Geographical',
    },
  },
  selections: {
    general: 'General',
    details: 'Details',
    financials: 'Financials',
    address: 'Address',
  },
};
