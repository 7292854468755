import { createSelector } from '@reduxjs/toolkit';
import { PILLAR_ACCESS_TYPES, USER_PILLARS } from 'constants/interfaces';

import { getKeyByValue } from '../../../utils/helpers';

import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type { RootState } from 'store/types/TStore';
import type ICompany from '../companies/interfaces/ICompany';
import type IUserPermissions from './interfaces/IUserPermissions';

const selfCompanyAdminUsers = (state: RootState) => state.companyAdminUsers;

export const selectUserPermissions = createSelector(
  (data?: IBaseQueryResponse<IUserPermissions>) => data?.data,
  (data) => data?.pillars.filter((pillar) => pillar.canView).map(({ id, key }) => ({
    id,
    name: getKeyByValue(key, USER_PILLARS) || '',
    pillarModules: data?.pillarModules.filter((module) => module?.pillarId === id
      && module?.access !== PILLAR_ACCESS_TYPES.invisible_disabled).map((module) => ({
      ...module,
      name: module.key || '',
    })),
  })),
);
export const selectCompanyUiConfiguration = createSelector(
  (data?: IBaseQueryResponse<ICompany>) => data?.data,
  (company) => company?.uiConfiguration,
);

export const selectCreateFlowUserId = createSelector(
  [selfCompanyAdminUsers],
  ({ createFlowUserId }) => createFlowUserId,
);

export default selectUserPermissions;
