import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DataSetsRecordButtons from 'components/DataSetsRecordButtons/DataSetsRecordButtons';
import FieldWithCurrency from 'components/FieldWithCurrency/FieldWithCurrency';
import Modal from 'components/Modal/Modal';
import NumberField from 'components/NumberField/NumberField';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from 'constants/dataSets';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY, ADDRESS_COORDINATES,
  ADDRESS_COUNTRY, ADDRESS_POST_CODE, ADDRESS_STATE, ADDRESS_STREET,
  ASSET_CATEGORY,
  BUSINESS_UNIT_TYPE, DATE_CLOSED, DATE_OPENED,
  EQUITY_SHAREHOLDING, EU_TAXONOMY_CODE, FINANCIAL_CONTROL,
  LEGAL_NAME, NACE_CODE, NACE_SECTION,
  NAME, NET_BOOK, NET_BOOK_CURRENCY, NUMBER_OF_EMPLOYEES,
  ORG_PATH, OWNED_LEASED,
  OWNERSHIP,
  PROFIT_CENTER_ID,
  UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_MASK } from 'constants/inputMasks';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type IFieldError from 'store/types/IFieldError';
import type { ICreateOwnOperationsView } from './interfaces/ICreateOwnOperationsView';

import styles from './sass/CreateOwnOperationsView.module.scss';
import stylesCurrency from 'components/FieldWithCurrency/scss/FieldWithCurrency.module.scss';

function CreateOwnOperationsView({
  control, onOwnOperationsSubmit, isConfirmationModalOpen, onHandleCloseConfirmation,
  onDiscardChangesConfirm, enumData, naceCodesData, naceDivisions, onNaceSelectionChange, uidNameTuples, isPageLoading,
  onFindCountries, onFindStates, onFindCities, isStatesDisabled, isCitiesDisabled, onChangeCountry, onChangeState,
}: ICreateOwnOperationsView) {
  const { t } = useTranslation();
  const { recordId = '' } = useParams();
  const placeholder = recordId ? t('common.notProvided') : '';

  return (
    <div className={styles.root}>
      {!isPageLoading ? (
        <Panel className={styles.panel}>
          <div className={styles.wrapper}>
            <DataSetsPanel title={t('ownOperations.selections.general')}>
              <Controller
                control={control}
                name={ORG_PATH}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.orgPath')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                    className={styles.fullRow}
                  />
                )}
              />
              <Controller
                control={control}
                name={NAME}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.name')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={UNIQUE_IDENTIFIER}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.uniqueIdentifier')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={LEGAL_NAME}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.legalEntity')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={PROFIT_CENTER_ID}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.profitCentreID')}
                    placeholder={recordId ? placeholder : t('ownOperations.fields.profitCentreID')}
                    options={uidNameTuples?.[UID_NAME_TUPLES.profitCenter]}
                    value={value || undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.message}
                    isMulti
                  />
                )}
              />
              <Controller
                control={control}
                name={VALUE_CHAIN_SECTION}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.valueChain')}
                    placeholder={t('ownOperations.fields.valueChain')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.valueChainSection]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={OWNERSHIP}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.ownership')}
                    placeholder={recordId ? placeholder : t('ownOperations.fields.ownership')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.ownership]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name={EQUITY_SHAREHOLDING}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.equityShareholding')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={FINANCIAL_CONTROL}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.financialControl')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    type={NUMBER_FORMATS.percent}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={BUSINESS_UNIT_TYPE}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.physicalOrLogical')}
                    placeholder={t('ownOperations.fields.physicalOrLogical')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.businessUnitType]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={ASSET_CATEGORY}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.assetCategory')}
                    placeholder={t('ownOperations.fields.assetCategory')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.assetCategory]}
                    value={value || undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.message}
                    required
                    isMulti
                  />
                )}
              />
              <Controller
                control={control}
                name={OWNED_LEASED}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.owned')}
                    placeholder={recordId ? placeholder : t('ownOperations.fields.owned')}
                    options={enumData?.[DATA_SETS_ENUM_TYPES.ownedLeased]}
                    value={value?.value ? value : undefined}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name={NUMBER_OF_EMPLOYEES}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <NumberField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.employees')}
                    error={error?.message}
                    onChange={(values) => onChange(values.value)}
                    decimalScale={0}
                    required
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={DATE_OPENED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.dateOpened')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    required
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={DATE_CLOSED}
                render={({
                  field: {
                    name,
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.dateClosed')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    mask={DATA_SETS_DATE_MASK}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

            <DataSetsPanel title={t('ownOperations.fields.name')}>

              <Controller
                control={control}
                name={NACE_SECTION}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.industry')}
                    placeholder={t('ownOperations.fields.industry')}
                    options={naceCodesData}
                    value={value?.value ? value : undefined}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onNaceSelectionChange();
                    }}
                    error={(error as IFieldError)?.value?.message}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={NACE_CODE}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                  fieldState: { error },
                }) => (
                  <Select
                    label={t('ownOperations.fields.sector')}
                    placeholder={t('ownOperations.fields.sector')}
                    options={naceDivisions}
                    value={value || null}
                    onChange={onChange}
                    error={(error as IFieldError)?.value?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name={EU_TAXONOMY_CODE}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.taxonomy')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

            <DataSetsPanel title={t('ownOperations.selections.financials')}>
              <Controller
                control={control}
                name={NET_BOOK}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <FieldWithCurrency name={name} label={t('ownOperations.fields.netBook')}>
                    <div className={stylesCurrency.fieldWithCurrency}>
                      <NumberField
                        name={name}
                        value={value}
                        error={error?.message}
                        onChange={(values) => onChange(values.value)}
                        required
                        placeholder={placeholder}
                      />
                      <Controller
                        control={control}
                        name={NET_BOOK_CURRENCY}
                        render={({
                          field: { value: valueCurrency, onChange: onChangeCurrency },
                          fieldState: { error: errorCurrency },
                        }) => (
                          <Select
                            placeholder={t('common.currency')}
                            options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                            value={valueCurrency?.value ? valueCurrency : undefined}
                            onChange={onChangeCurrency}
                            error={(errorCurrency as IFieldError)?.value?.message}
                            className={stylesCurrency.select}
                            required
                          />
                        )}
                      />
                    </div>
                  </FieldWithCurrency>
                )}
              />
            </DataSetsPanel>

            <DataSetsPanel title={t('ownOperations.selections.address')}>
              <Controller
                control={control}
                name={ADDRESS_COUNTRY}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('ownOperations.fields.country')}
                    placeholder={null}
                    value={value || null}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onChangeCountry();
                    }}
                    error={error?.message}
                    loadOptions={onFindCountries}
                    isSearchable
                    isAsync
                    isClearable
                    className={styles.asyncSelect}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_STATE}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('ownOperations.fields.state')}
                    placeholder={null}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      onChangeState();
                    }}
                    error={error?.message}
                    loadOptions={onFindStates}
                    isSearchable
                    isAsync
                    isClearable
                    className={clsx(styles.asyncSelect, {
                      [styles.inactiveSelect]: isStatesDisabled,
                    })}
                    isDisabled={isStatesDisabled}
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_POST_CODE}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.post')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_CITY}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('ownOperations.fields.town')}
                    placeholder={null}
                    value={value || null}
                    onChange={onChange}
                    error={error?.message}
                    loadOptions={onFindCities}
                    isSearchable
                    isAsync
                    isClearable
                    className={clsx(styles.asyncSelect, {
                      [styles.inactiveSelect]: isCitiesDisabled || isStatesDisabled,
                    })}
                    isDisabled={isCitiesDisabled}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_STREET}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.street')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_BUILDING}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.building')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
              <Controller
                control={control}
                name={ADDRESS_COORDINATES}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label={t('ownOperations.fields.coordinates')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                  />
                )}
              />
            </DataSetsPanel>

          </div>
          <Separator />
          <Modal
            open={isConfirmationModalOpen}
            title={t('createNewUser.cancelModalTitle')}
            description={t('clients.cancelModalText')}
            confirmTitle={t('common.discardChanges')}
            closeTitle={t('common.keepEditing')}
            confirmVariant="error"
            onClose={() => onHandleCloseConfirmation(false)}
            onReject={() => onHandleCloseConfirmation(false)}
            onConfirm={onDiscardChangesConfirm}
          />
          <DataSetsRecordButtons onClose={onHandleCloseConfirmation} onSubmit={onOwnOperationsSubmit} />
        </Panel>
      ) : null}
    </div>
  );
}

export default CreateOwnOperationsView;
