import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import PasswordRules from 'components/Password/PasswordRules/PasswordRules';
import PasswordStrengthProgress from 'components/Password/PasswordStrengthProgress/PasswordStrengthProgress';
import TextField from 'components/TextField/TextField';
import { PASSWORD, REPEAT_NEW_PASSWORD } from 'constants/formFields';
import { REPLACE_DOUBLE_SPACE_REGEX } from 'constants/general';
import { INPUT_TYPES } from 'constants/interfaces';

import type { INewPasswordView } from './interfaces/INewPasswordView';

import styles from './sass/NewPasswordView.module.scss';

function NewPasswordView({
  control,
  onNewPasswordSubmit,
  passwordStrengthMeter,
  isButtonDisabled,
  isRecoverPasswordPath,
}: INewPasswordView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('newPassword.title')}
      </div>
      <div className={styles.formWrapper}>
        <Controller
          control={control}
          name={PASSWORD}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              name={name}
              value={value}
              label={t('common.newPassword')}
              type={INPUT_TYPES.password}
              placeholder={t('common.enterPassword')}
              error={error?.message}
              onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' '))}
              required
            />
          )}
        />
        <Controller
          control={control}
          name={REPEAT_NEW_PASSWORD}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              name={name}
              value={value}
              type={INPUT_TYPES.password}
              label={t('common.repeatNewPassword')}
              placeholder={t('common.enterPassword')}
              error={error?.message}
              onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' '))}
              required
            />
          )}
        />
      </div>
      <PasswordStrengthProgress progress={passwordStrengthMeter} />
      <PasswordRules isRecoverPasswordPath={isRecoverPasswordPath} />
      <Button
        title={t('newPassword.savePassword')}
        onClick={onNewPasswordSubmit}
        disabled={isButtonDisabled}
      />
    </div>
  );
}

export default NewPasswordView;
