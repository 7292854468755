import { PASSWORD_STRENGTH_METER } from 'constants/general';

import type { IHelpersPasswordStrengthMeter } from './interfaces/IHelpersPasswordStrengthMeter';

function helpersPasswordStrengthMeter({ value }: IHelpersPasswordStrengthMeter) {
  const strong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{13,}$/.test(value)
   && PASSWORD_STRENGTH_METER.strong;
  const medium = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(value)
   && PASSWORD_STRENGTH_METER.medium;
  const weak = value.length > 0 && PASSWORD_STRENGTH_METER.weak;

  return strong || medium || weak || 0;
}

export default helpersPasswordStrengthMeter;
