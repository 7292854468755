import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';

export const selfClubProfile = (state: RootState) => state.auth;

export const accessTokenSelector = createSelector(
  [selfClubProfile],
  (auth) => auth.accessToken,
);
export const authTokensSelector = createSelector(
  [selfClubProfile],
  ({ accessToken, refreshToken, verifyToken }) => ({
    accessToken,
    refreshToken,
    verifyToken,
  }),
);

export const isFirstLoginSelector = createSelector(
  [selfClubProfile],
  ({ isFirstLogin }) => isFirstLogin,
);
