import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import Panel from 'components/Panel/Panel';
import { useUserMeQuery } from 'store/slices/user/apis/user';
import { selectPillarByKey } from 'store/slices/user/selectors';

import type ILaunchpadCard from './interfaces/ILaunchpadCard';

import styles from './scss/LaunchpadCard.module.scss';

function LaunchpadCard({
  pillarKey, icon, title, className, navLink = '',
}: ILaunchpadCard) {
  const { pillar } = useUserMeQuery(
    undefined,
    {
      selectFromResult: (result) => ({
        ...result,
        pillar: selectPillarByKey(
          { data: result.data, pillarKey },
        ),
      }),
    },
  );
  const isEmptyCard = !pillar?.canView;
  return (
    <div className={styles.root}>
      {isEmptyCard ? <div className={styles.emptyCard} /> : (
        <Link to={navLink} className={styles.navLink}>
          <Panel className={styles.panel}>
            <div className={clsx(styles.header, className)}>{icon}</div>
            <div className={styles.title}>{title}</div>
          </Panel>
        </Link>
      )}
    </div>

  );
}

export default LaunchpadCard;
