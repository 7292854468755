import type IToggle from './interfaces/IToggle';

import styles from './scss/Toggle.module.scss';

function Toggle({
  value,
  checked,
  onChange,
  name,
  disabled,
}: IToggle) {
  return (
    <label className={styles.root} htmlFor={name}>
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <div className={styles.indicator} />
    </label>
  );
}

export default Toggle;
