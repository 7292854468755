import type ITableRow from './interfaces/ITableRow';

function TableRow({ children, className }: ITableRow) {
  return (
    <tr className={className}>
      {children}
    </tr>
  );
}

export default TableRow;
