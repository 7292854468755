import { useEffect } from 'react';
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { setRefNavigate } from 'utils/navigationRef';

import useTheme from './hooks/useTheme';

function AppWrapper() {
  const navigate = useNavigate();
  useTheme();

  useEffect(() => {
    setRefNavigate(navigate);
  }, [navigate]);

  return (
    <>
      <ScrollRestoration getKey={(location) => location.pathname} />
      <Outlet />
    </>
  );
}

export default AppWrapper;
