import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PASSWORD, REPEAT_NEW_PASSWORD } from 'constants/formFields';
import { newPasswordSchema } from 'utils/validators';

import type { IUseNewPasswordForm } from '../interfaces/IUseNewPasswordForm';

const useNewPasswordForm = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitted },
  } = useForm<IUseNewPasswordForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      [PASSWORD]: '',
      [REPEAT_NEW_PASSWORD]: '',
    },
  });

  const password = watch(PASSWORD);

  return {
    control,
    handleSubmit,
    password,
    isValid,
    isSubmitted,
  };
};

export default useNewPasswordForm;
