export const clientRoutes = () => ({
  clients: '/clients',
  clientsCreate: '/clients/:clientId',
  companyInfo: '/clients/:clientId/company-info',
  multiLingual: '/clients/:clientId/multi-lingual',
  companyAdmin: '/clients/:clientId/company-admin',
  uiConfiguration: '/clients/:clientId/ui-configuration',
  moduleManagement: '/clients/:clientId/module-management',
  security: '/clients/:clientId/security',
  companyProfile: '/clients/:clientId/company-profile',
  clientProfile: '/clients/:clientId/info',
  clientUsers: '/clients/:clientId/info/users',
  clientUsersSecurity: '/clients/:clientId/info/users/:userId',
});

export const AppRoutes = {
  home: '/',
  login: '/login',
  authenticationType: '/login/auth',
  authenticationSetup: '/login/auth/app/setup/:type?/:step?',
  verificationSmsSetup: '/login/auth/sms/setup',
  verificationSms: '/login/auth/sms',
  verificationCode: '/login/auth/app/code',
  recoverPassword: '/login/recover-password',
  recoverPasswordSuccess: '/login/recover-password/success',
  recoverPasswordConfirm: '/login/recover-password/confirm',
  newPassword: '/login/new-password',
  deactivated: '/login/auth/deactivated',
  profile: '/profile',
  personalInfo: '/profile/personal-info',
  profilePassword: '/profile/password',
  profileLanguage: '/profile/language',
  profileSecurity: '/profile/security/:type?/:step?',
  launchpad: '/launchpad',
  launchpadSettings: '/launchpad/settings',
  userManagement: '/launchpad/settings/user-management',
  companyUiConfiguration: '/launchpad/settings/ui-configuration',
  createNewUser: '/launchpad/settings/user-management/create-new-user',
  createUserPermissions: '/launchpad/settings/user-management/:userId/create-user-permissions',
  userInfo: '/launchpad/settings/user-management/:userId',
  editUserInfo: '/launchpad/settings/user-management/:userId/edit-user-info',
  editUserPermissions: '/launchpad/settings/user-management/:userId/edit-user-permissions',
  ownOperations: '/launchpad/settings/data-sets-management/own-operations',
  ownOperationsCreate: '/launchpad/settings/data-sets-management/own-operations/create',
  ownOperationsEdit: '/launchpad/settings/data-sets-management/own-operations/edit/:recordId',
  suppliers: '/launchpad/settings/data-sets-management/suppliers',
  customers: '/launchpad/settings/data-sets-management/customers',
  products: '/launchpad/settings/data-sets-management/products',
  profitCentres: '/launchpad/settings/data-sets-management/profit-centres',
  profitCentresCreate: '/launchpad/settings/data-sets-management/profit-centres/create',
  profitCentresEdit: '/launchpad/settings/data-sets-management/profit-centres/edit/:recordId',
  supplierCreate: '/launchpad/settings/data-sets-management/suppliers/create',
  supplierEdit: '/launchpad/settings/data-sets-management/suppliers/edit/:recordId',
  productsCreate: '/launchpad/settings/data-sets-management/products/create',
  productsEdit: '/launchpad/settings/data-sets-management/products/edit/:recordId',
  customerCreate: '/launchpad/settings/data-sets-management/customers/create',
  customerEdit: '/launchpad/settings/data-sets-management/customers/edit/:recordId',
  baseMultilingual: '/base-multilingual',
  ...clientRoutes(),
} as const;

export type TAppRoutes = typeof AppRoutes[keyof typeof AppRoutes];

export default AppRoutes;
