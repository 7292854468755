import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { clsx } from 'clsx';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Separator from 'components/Separator/Separator';

import Button from '../Button/Button';

import type IBreadcrumbs from 'components/Breadcrumbs/interfaces/IBreadcrumbs';

import styles from './scss/BreadcrumbsNav.module.scss';

function BreadcrumbsNav({ breadcrumbs, className }: IBreadcrumbs) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <div className={clsx(styles.root, className)}>
      <Button
        onClick={onBackClick}
        title={t('common.back')}
        startIcon={<ArrowLeftIcon />}
        className={styles.backButton}
      />
      <Separator position="vertical" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </div>
  );
}

export default BreadcrumbsNav;
