import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import useCompanyStatusChange from 'hooks/useCompanyStatusChange';
import {
  useGetCompanyDetailsQuery,
  useGetCompanyPermissionsQuery,
} from 'store/slices/companies/apis/companiesApi';

import CompanyProfileView from './CompanyProfileView';

import type { TCompanyStatus } from 'constants/interfaces';

function CompanyProfile() {
  const { clientId = '' } = useParams();

  const fetchQuery = clientId ? {
    companyId: +clientId,
  } : skipToken;

  const { data: company } = useGetCompanyDetailsQuery(fetchQuery);
  const { data: permissions } = useGetCompanyPermissionsQuery(fetchQuery);

  const {
    isDeactivationModalOpen,
    onToggleDeactivationModal,
    onChangeCompanyStatus,
    onCompanyDeactivate,
  } = useCompanyStatusChange({
    companyId: +clientId,
    status: company?.data.status as TCompanyStatus,
  });

  return (
    <CompanyProfileView
      company={company?.data}
      permissions={permissions?.data}
      onChangeCompanyStatus={onChangeCompanyStatus}
      isDeactivateCompanyModalOpen={isDeactivationModalOpen}
      onCloseDeactivateCompanyModal={onToggleDeactivationModal}
      onConfirmDeactivateCompanyModal={onCompanyDeactivate}
    />
  );
}

export default CompanyProfile;
