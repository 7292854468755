import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useBlocker, useLocation, useMatch, useNavigate,
} from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import { AppRoutes } from 'constants/AppRoutes';
import { BLOCKER_STATES } from 'constants/general';
import { BUTTON_VARIANT } from 'constants/interfaces';

import Panel from '../Panel/Panel';
import Separator from '../Separator/Separator';
import ClientPanelFooter from './ClientPanelFooter';
import ClientPanelHeader from './ClientPanelHeader';

import type IClientPanel from './interfaces/IClientPanel';

import styles from './scss/ClientPanel.module.scss';

function ClientPanel({
  children,
  title,
  text,
  onPrevClick,
  onSave,
  isDisabledSave,
  isCreate,
  isLastStep,
  isDirty = false,
  isActionsVisible = true,
}: IClientPanel) {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isCompanyUiConfigurationPage = !!useMatch(AppRoutes.companyUiConfiguration);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty
    && currentLocation.pathname !== nextLocation.pathname
    && !isModalOpen && isActionsVisible);

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      if (isDirty) {
        setIsModalOpen(true);
      } else {
        blocker.proceed?.();
      }
    }
  }, [blocker, isDirty]);

  const onHandleReject = () => {
    if (isCompanyUiConfigurationPage) {
      navigate(AppRoutes.launchpadSettings);
    } else if (location.state?.redirectPath) {
      navigate(location.state.redirectPath);
    } else {
      navigate(AppRoutes.clients);
    }
  };

  const onHandleClose = () => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      blocker.reset?.();
    }
    setIsModalOpen(false);
  };

  const onDiscardChanges = () => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      blocker.reset?.();

      const currentLocation = blocker?.location?.pathname;

      if (currentLocation) {
        navigate(currentLocation);
      } else if (location.state?.redirectPath) {
        navigate(location.state.redirectPath);
      }
    }
    setIsModalOpen(false);
  };

  return (
    <Panel className={styles.root}>

      {title && text ? (
        <>
          <ClientPanelHeader
            title={title}
            text={text}
          />
          <Separator />
        </>
      ) : null}

      {children}
      <Separator />
      {isActionsVisible ? (
        <ClientPanelFooter
          onPrevClick={onPrevClick}
          onSave={onSave}
          onCancel={onHandleReject}
          isDisabledSave={isDisabledSave}
          isCreate={isCreate}
          isLastStep={isLastStep}
        />
      ) : null}
      <Modal
        open={isModalOpen}
        title={t('clients.discardChanges')}
        description={t('clients.informationWillBeLost')}
        confirmTitle={t('clients.discardChangesButton')}
        closeTitle={t('clients.kepEditing')}
        confirmVariant={BUTTON_VARIANT.error}
        onClose={onHandleClose}
        onReject={onHandleClose}
        onConfirm={onDiscardChanges}
      />
    </Panel>
  );
}

export default ClientPanel;
