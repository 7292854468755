import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/IconButton/IconButton';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import {
  ADMINS, EMAIL, FIRST_NAME, LAST_NAME,
} from 'constants/formFields';
import { REPLACE_DOUBLE_SPACE_REGEX } from 'constants/general';

import useCreateClient from '../../../../../hooks/useCreateClient';

import type ICompanyAdminCard from '../../interfaces/ICompanyAdminCard';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';

import styles from './scss/CompanyAdminCard.module.scss';

function CompanyAdminCard({
  control, index, id, onRemoveAdmin, showSeparator, status,
}: ICompanyAdminCard) {
  const { t } = useTranslation();
  const { isCreateFlow } = useCreateClient();

  const isAdminCreated = !!status;

  return (
    <div className={styles.admin} key={id}>
      <div className={styles.header}>
        <div className={styles.info}>
          <h3 className={styles.title}>
            {t('common.admin', { number: index + 1 })}
          </h3>
          {isAdminCreated ? (
            <>
              <Separator position="vertical" />
              <p className={styles.status}>
                {t(`common.${status}`)}
              </p>
            </>
          ) : null}
        </div>
        {(!isAdminCreated || isCreateFlow) && index ? (
          <IconButton
            className={styles.close}
            size="small"
            icon={<CloseIcon />}
            onClick={() => onRemoveAdmin(index)}
          />
        ) : null}
      </div>

      <div className={styles.wrapper}>
        <div className={styles.item}>
          <Controller
            control={control}
            name={`${ADMINS}.${index}.${FIRST_NAME}`}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.firstName')}
                placeholder={t('common.firstName')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' '))}
                required
              />
            )}
          />
        </div>
        <div className={styles.item}>
          <Controller
            control={control}
            name={`${ADMINS}.${index}.${LAST_NAME}`}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.lastName')}
                placeholder={t('common.lastName')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' '))}
                required
              />
            )}
          />
        </div>
        <div className={styles.item}>
          <Controller
            control={control}
            name={`${ADMINS}.${index}.${EMAIL}`}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.email')}
                placeholder={t('common.email')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value)}
                disabled={isAdminCreated}
                required
              />
            )}
          />
        </div>
        {showSeparator ? <Separator className={styles.separator} /> : null}
      </div>
    </div>
  );
}

export default CompanyAdminCard;
