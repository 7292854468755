import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as AppleIcon } from 'assets/icons/apple.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/icons/google-play.svg';
import Button from 'components/Button/Button';
import { AUTHENTICATION_GOOGLE } from 'constants/formFields';
import { AUTHENTICATOR_APP_LINKS } from 'constants/general';
import { BUTTON_VARIANT } from 'constants/interfaces';

import styles from './sass/DownloadApp.module.scss';

function DownloadApp() {
  const { t } = useTranslation();
  const { type = AUTHENTICATION_GOOGLE } = useParams();

  const { apple, google } = type === AUTHENTICATION_GOOGLE ? AUTHENTICATOR_APP_LINKS.google
    : AUTHENTICATOR_APP_LINKS.microsoft;

  return (
    <div className={styles.root}>
      {t('authenticationModal.downloadApp.description')}

      <Link to={google} target="_blank" rel="noopener noreferrer" className={styles.link}>
        <Button
          title={t('authenticationModal.downloadApp.appStore')}
          variant={BUTTON_VARIANT.secondary}
          startIcon={<AppleIcon />}
          onClick={() => {}}
        />
      </Link>

      <Link to={apple} target="_blank" rel="noopener noreferrer" className={styles.link}>
        <Button
          title={t('authenticationModal.downloadApp.appStore')}
          variant={BUTTON_VARIANT.secondary}
          startIcon={<GooglePlayIcon />}
          onClick={() => {}}
        />
      </Link>

    </div>
  );
}

export default DownloadApp;
