import { useTranslation } from 'react-i18next';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import IconButton from 'components/IconButton/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import { COMPANY_ACTION_STATUSES } from 'constants/company';
import useCompanyStatusChange from 'hooks/useCompanyStatusChange';

import type IClientsTableActions from '../../interfaces/IClientsTableActions';

import styles from './scss/ClientsTableActions.module.scss';

function ClientsTableActions({
  companyId, status, onHandleEdit,
}: IClientsTableActions) {
  const { t } = useTranslation();

  const {
    isDeactivationModalOpen,
    onToggleDeactivationModal,
    onChangeCompanyStatus,
    onCompanyDeactivate,
  } = useCompanyStatusChange({
    companyId, status,
  });

  return (
    <>
      <div className={styles.wrapper}>
        <Tooltip hint={t('clients.editInformation')}>
          <IconButton icon={<PenIcon />} onClick={onHandleEdit} />
        </Tooltip>
        <Tooltip hint={COMPANY_ACTION_STATUSES[status].hintText}>
          <IconButton
            icon={COMPANY_ACTION_STATUSES[status].actionIcon}
            onClick={onChangeCompanyStatus}
          />
        </Tooltip>
      </div>
      <DeactivationModal
        open={isDeactivationModalOpen}
        text={t('common.clientActivity')}
        onReject={onToggleDeactivationModal}
        onSubmit={onCompanyDeactivate}
      />
    </>
  );
}

export default ClientsTableActions;
