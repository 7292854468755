import { AppRoutes } from 'constants/AppRoutes';

import { USER_ROLE_TYPES } from '../constants/interfaces';

import type { IAuthNavigation } from './interfaces/IAuthNavigation';

function authNavigation({ navigate, user }: IAuthNavigation) {
  const { mfaAppEnabled, mfaSmsEnabled } = user.profile || {};
  const { mfaApp, mfaSms } = user.company?.security || {};

  const role = user?.role || '';

  const isUserAdmin = role === USER_ROLE_TYPES.admin;

  const isCompanyMfaAppEnabled = mfaApp === 'enabled' || isUserAdmin;
  const isCompanyMfaSmsEnabled = mfaSms === 'enabled' || isUserAdmin;
  const isUserMfaAppEnabled = mfaAppEnabled === 'enabled';
  const isUserMfaSmsEnabled = mfaSmsEnabled === 'enabled';

  // If company has enabled MFA and user has not enabled it or enabled both -> Run setup authentication flow
  if (isCompanyMfaAppEnabled && isCompanyMfaSmsEnabled
      && ((!isUserMfaAppEnabled && !isUserMfaSmsEnabled) || (isUserMfaAppEnabled && isUserMfaSmsEnabled))) {
    navigate(AppRoutes.authenticationType);
    return;
  }

  // If company has enabled MFA and user has enabled it -> Run verification code flow
  if (isCompanyMfaAppEnabled && isUserMfaAppEnabled) {
    navigate(AppRoutes.verificationCode);
    return;
  }

  // If company has enabled MFA and user has enabled it -> Run verification SMS flow
  if (isCompanyMfaSmsEnabled && isUserMfaSmsEnabled) {
    navigate(AppRoutes.verificationSms);
    return;
  }

  // If company has enabled MFA and user has not enabled it -> Run setup authentication flow App
  if (isCompanyMfaAppEnabled && !isUserMfaAppEnabled) {
    navigate(AppRoutes.authenticationSetup);
    return;
  }

  // If company has enabled MFA and user has not enabled it -> Run setup authentication flow SMS
  if (isCompanyMfaSmsEnabled && !isUserMfaSmsEnabled) {
    navigate(AppRoutes.verificationSmsSetup);
  }
}

export default authNavigation;
