import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PHONE } from 'constants/formFields';
import { verificationPhoneSchema } from 'utils/validators';

import type { ParsedCountry } from 'react-international-phone/dist/types';
import type { TUseVerificationSmsSetupForm } from '../interfaces/TUseVerificationSmsSetupForm';

const useVerificationSmsSetupForm = ({ parsedCountry }: { parsedCountry?: ParsedCountry }) => {
  const {
    control,
    handleSubmit,
    formState,
    setError,
  } = useForm<TUseVerificationSmsSetupForm>({
    mode: 'onSubmit',
    resolver: yupResolver(verificationPhoneSchema({ parsedCountry })),
    defaultValues: {
      [PHONE]: '',
    },
  });
  const { isValid } = formState;

  return {
    control,
    handleSubmit,
    isValid,
    setError,
  };
};

export default useVerificationSmsSetupForm;
