import { useState } from 'react';
import InputMask from 'react-input-mask';
import { ReactComponent as ClosedEye } from 'assets/icons/closedEye.svg';
import { ReactComponent as OpenedEye } from 'assets/icons/openedEye.svg';
import { clsx } from 'clsx';
import { INPUT_TYPES } from 'constants/interfaces';

import type ITextField from './interfaces/ITextField';

import 'react-international-phone/style.css';
import styles from './scss/TextField.module.scss';

function TextField({
  name, label, value, onChange, disabled,
  className, error, placeholder, icon,
  required, type = INPUT_TYPES.text, mask = '',
}: ITextField) {
  const classes = clsx(styles.root, className, {
    [styles.rootError]: error,
  });

  const wrapperClasses = clsx(styles.wrapper, {
    [styles.withIcon]: icon,
  });

  const isPassword = type === INPUT_TYPES.password;

  const [isPasswordVisible, setIsPasswordVisible] = useState(isPassword);

  const onTextVisibility = () => {
    setIsPasswordVisible(
      (prevIsPasswordTypeVisible) => !prevIsPasswordTypeVisible,
    );
  };

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={name}>
          {required ? `${label}*` : label}
        </label>
      ) : null}
      <div className={wrapperClasses}>
        {icon ? (
          <div className={styles.icon}>
            {icon}
          </div>
        ) : null}

        <InputMask
          id={name}
          name={name}
          onChange={onChange}
          value={value}
          type={isPasswordVisible ? INPUT_TYPES.password : INPUT_TYPES.text}
          placeholder={placeholder}
          disabled={disabled}
          mask={mask}
        />

        {isPassword ? (
          <button
            className={styles.passwordIcon}
            type="button"
            onClick={onTextVisibility}
          >
            {isPasswordVisible ? <OpenedEye /> : <ClosedEye />}
          </button>
        ) : null}
      </div>
      {error ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
}

export default TextField;
