import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CURRENT_PASSWORD, PASSWORD, REPEAT_NEW_PASSWORD,
} from 'constants/formFields';
import { profilePasswordSchema } from 'utils/validators';

import type { IUseProfilePasswordForm } from '../interfaces/IUseProfilePasswordForm';

const useProfilePasswordForm = () => {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { isValid },
  } = useForm<IUseProfilePasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(profilePasswordSchema),
    defaultValues: {
      [CURRENT_PASSWORD]: '',
      [PASSWORD]: '',
      [REPEAT_NEW_PASSWORD]: '',
    },
  });

  const password = watch(PASSWORD);

  return {
    control,
    handleSubmit,
    password,
    setError,
    isValid,
  };
};

export default useProfilePasswordForm;
