import { forwardRef } from 'react';
import { HexColorPicker } from 'react-colorful';

import type { ForwardedRef } from 'react';
import type IColorPickerPopup from './interfaces/IColorPickerPopup';

import styles from './scss/ColorPickerPopup.module.scss';

function ColorPickerPopup(
  { color, onChange }: IColorPickerPopup,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div className={styles.wrapper} ref={ref}>
      <HexColorPicker color={color} onChange={onChange} />
    </div>
  );
}

export default forwardRef(ColorPickerPopup);
