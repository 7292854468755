import { clsx } from 'clsx';

import type IFieldWithCurrency from './interfaces/IFieldWithCurrency';

import styles from './scss/FieldWithCurrency.module.scss';

function FieldWithCurrency({
  label, name, className, children,
}: IFieldWithCurrency) {
  return (
    <div className={clsx(styles.root, className)}>
      <label htmlFor={name}>{label}</label>
      {children}
    </div>
  );
}

export default FieldWithCurrency;
