import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import IconButton from 'components/IconButton/IconButton';
import Subtitle from 'components/Subtitle/Subtitle';

import type IInfoPanelHeader from './interfaces/IInfoPanelHeader';

import styles from './scss/InfoPanelHeader.module.scss';

function InfoPanelHeader({
  title, icon, onEdit, isActionsVisible = true,
}: IInfoPanelHeader) {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {icon}
        <Subtitle
          className={styles.title}
          text={title}
        />
      </div>
      {isActionsVisible ? (
        <IconButton
          icon={<PenIcon />}
          onClick={onEdit}
        />
      ) : null}
    </div>
  );
}

export default InfoPanelHeader;
