import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

import type { NoticeProps } from 'react-select';

function NoOptionsMessage(props: NoticeProps) {
  const { t } = useTranslation();
  return (
    <components.NoOptionsMessage {...props}>
      <span>{t('common.notFound')}</span>
    </components.NoOptionsMessage>
  );
}

export default NoOptionsMessage;
