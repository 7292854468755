import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select/Select';
import Textarea from 'components/Textarea/Textarea';
import TextField from 'components/TextField/TextField';
import { DESCRIPTION, NAME, TYPE } from 'constants/formFields';
import { CLIENT_TYPES, DESCRIPTION_LENGTH, REPLACE_DOUBLE_SPACE_REGEX } from 'constants/general';

import type ICompanyInfoForm from '../../interfaces/ICompanyInfoForm';

import styles from './scss/CompanyInfoForm.module.scss';

function CompanyInfoForm({ control }: ICompanyInfoForm) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          <Controller
            control={control}
            name={NAME}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.name')}
                placeholder={t('common.name')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' ').trimStart())}
                required
              />
            )}
          />
        </div>
        <div className={styles.item}>
          <Controller
            control={control}
            name={TYPE}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                label={t('common.type')}
                placeholder={t('common.type')}
                options={CLIENT_TYPES}
                value={value}
                onChange={onChange}
                error={error?.message}
                required
              />
            )}
          />
        </div>
      </div>
      <div>
        <Controller
          control={control}
          name={DESCRIPTION}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <Textarea
              name={name}
              value={value}
              label={t('common.description')}
              placeholder={t('common.typeYourMessage')}
              onChange={(e) => onChange(e.target.value.slice(0, DESCRIPTION_LENGTH))}
              error={error?.message}
              charactersLimit={DESCRIPTION_LENGTH}
            />
          )}
        />
      </div>
    </div>
  );
}

export default CompanyInfoForm;
