import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import Separator from 'components/Separator/Separator';
import { BUTTON_VARIANT } from 'constants/interfaces';

import PillarSelection
  from './PillarSelection';

import type { IPermissionsForm } from '../../interfaces/IPermissionsForm';

import styles from './sass/PermissionsForm.module.scss';

function PermissionsForm({
  control, onCreateNewUserSubmit, onHandleClose, onPrevClick, pillars, isCreateFlow, isDisabled,
}: IPermissionsForm) {
  const { t } = useTranslation();

  const { userId = '' } = useParams();

  const isUserEdit = userId && !isCreateFlow;

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.subTitle}>{t('permissions.selectNeededPillars')}</div>
        {isDisabled ? (
          <div className={styles.warning}>
            <InfoIcon />
            <span>{t('permissions.companyAdminPillarsWarning')}</span>
          </div>
        ) : null}
        {pillars?.map(({ name, pillarModules }, i) => name && (
        <PillarSelection
          key={window.crypto.randomUUID()}
          pillarModules={pillarModules}
          pillarIndex={i}
          control={control}
          pillarName={name}
          isDisabled={isDisabled}
        />
        ))}
      </div>

      <Separator />
      <div
        className={clsx(styles.buttonsWrapper, {
          [styles.editButtonsWrapper]: isUserEdit,
        })}
      >
        {isUserEdit ? (
          <div className={styles.prevNextButtonsWrapper}>
            <Button
              className={styles.button}
              variant={BUTTON_VARIANT.cancel}
              title={t('common.cancel')}
              onClick={onHandleClose}
            />
            <Button
              className={styles.button}
              title={t('common.save')}
              onClick={onCreateNewUserSubmit}
              disabled={isDisabled}
            />
          </div>
        ) : (
          <>
            <Button
              className={styles.button}
              variant={BUTTON_VARIANT.cancel}
              title={t('common.cancel')}
              onClick={onHandleClose}
            />
            <div className={styles.prevNextButtonsWrapper}>
              <Button
                className={styles.button}
                variant="secondary"
                title={t('common.prev')}
                startIcon={<ArrowLeftIcon />}
                onClick={onPrevClick}
              />
              <Button
                className={styles.button}
                title={isDisabled ? t('common.sendInvite') : t('permissions.saveAndInvite')}
                onClick={onCreateNewUserSubmit}
              />
            </div>
          </>
        )}

      </div>
    </div>
  );
}

export default PermissionsForm;
