import { NavLink } from 'react-router-dom';
import { useUserMeQuery } from 'store/slices/user/apis/user';
import { selectPillarByKey } from 'store/slices/user/selectors';

import type ISidebarItem from './interfaces/ISidebarItem';

import styles from '../../sass/Sidebar.module.scss';

function SidebarItem({
  to, icon, pillarKey, isSuperAdmin,
}: ISidebarItem) {
  const { pillar } = useUserMeQuery(
    undefined,
    {
      selectFromResult: (result) => ({
        ...result,
        pillar: selectPillarByKey(
          { data: result.data, pillarKey },
        ),
      }),
    },
  );
  const isLinkVisible = pillar?.canView || isSuperAdmin;

  return isLinkVisible ? (
    <NavLink to={to} className={({ isActive }) => (isActive ? styles.active : '')}>
      {icon}
    </NavLink>
  ) : null;
}

export default SidebarItem;
