import ImagePlaceholder from 'assets/icons/image.svg';
import { clsx } from 'clsx';

import type IAvatar from './interfaces/IAvatar';

import styles from './scss/Avatar.module.scss';

function Avatar({
  src, className,
  placeholder = ImagePlaceholder,
  alt = 'avatar',
}: IAvatar) {
  return (
    <div className={clsx(styles.root, className)}>
      <img src={src || placeholder} alt={alt} />
    </div>
  );
}

export default Avatar;
