import { useState } from 'react';
import {
  generatePath, useNavigate, useParams,
} from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import {
  AUTHENTICATION_GOOGLE, AUTHENTICATION_MICROSOFT, AUTHENTICATION_SETUP,
} from 'constants/formFields';
import { AUTHENTICATOR_SETUP_MODAL_STEPS } from 'constants/interfaces';
import { MFA_SETUP_TYPE } from 'constants/user';

import useAuthenticationSetupForm from './useAuthenticationSetupForm';

import type { TAuthenticatorSetupModalSteps } from 'constants/interfaces';
import type { IUseAuthenticationSetupModal } from '../interfaces/IUseAuthenticationSetupModal';

type AuthenticatorType = typeof AUTHENTICATION_GOOGLE | typeof AUTHENTICATION_MICROSOFT | typeof MFA_SETUP_TYPE;

const useAuthenticationSetupModal = ({ isProfile }: IUseAuthenticationSetupModal) => {
  const defaultType = isProfile ? MFA_SETUP_TYPE : AUTHENTICATION_GOOGLE;
  const { step, type = defaultType } = useParams();
  const [isSecretCodeVisible, setIsSecretCodeVisible] = useState(false);
  const navigate = useNavigate();

  const basePath = isProfile ? AppRoutes.profileSecurity : AppRoutes.authenticationSetup;

  const {
    control,
    handleSubmit,
  } = useAuthenticationSetupForm();

  const onAuthenticationSetupSubmit = handleSubmit((values) => {
    const setupType = values[AUTHENTICATION_SETUP] || AUTHENTICATION_GOOGLE;

    if (type) {
      navigate(generatePath(
        AppRoutes.profileSecurity,
        { step: AUTHENTICATOR_SETUP_MODAL_STEPS.downloadApp, type: setupType },
      ));
    }
  });

  const additionalProfileStep = {
    path: generatePath(
      AppRoutes.profileSecurity,
      { step: AUTHENTICATOR_SETUP_MODAL_STEPS.authenticationSetup, type: MFA_SETUP_TYPE },
    ),
    control,
    onSubmit: onAuthenticationSetupSubmit,
  };

  const allowedPaths: string[] = Object.values(AUTHENTICATOR_SETUP_MODAL_STEPS).reduce<string[]>(
    (acc, authenticatorStep) => {
      const authenticatorTypePaths = [AUTHENTICATION_GOOGLE, AUTHENTICATION_MICROSOFT, MFA_SETUP_TYPE].map(
        (authenticatorType) => generatePath(
          basePath,
          { step: authenticatorStep as TAuthenticatorSetupModalSteps, type: authenticatorType as AuthenticatorType },
        ),
      );

      return [...acc, ...authenticatorTypePaths];
    },
    [],
  );

  const steps = [
    {
      path: generatePath(
        basePath,
        { step: AUTHENTICATOR_SETUP_MODAL_STEPS.downloadApp, type },
      ),
    },
    {
      path: generatePath(
        basePath,
        { step: AUTHENTICATOR_SETUP_MODAL_STEPS.qrCode, type },
      ),
    },
    {
      path: generatePath(
        basePath,
        { step: AUTHENTICATOR_SETUP_MODAL_STEPS.secretCode, type },
      ),
      isSkipped: !isSecretCodeVisible,
    },
    {
      path: generatePath(
        basePath,
        { step: AUTHENTICATOR_SETUP_MODAL_STEPS.verificationCode, type },
      ),
    }];

  const isModalVisible = !!step;

  return {
    steps: isProfile ? [additionalProfileStep, ...steps] : steps,
    isModalVisible,
    setIsSecretCodeVisible,
    allowedPaths,
  };
};

export default useAuthenticationSetupModal;
