import { createSelector } from '@reduxjs/toolkit';
import { MAPPING_SOURCES, MAPPING_TARGET } from 'constants/formFields';

import type IOption from 'components/MappingModal/interfaces/IOption';
import type { RootState } from 'store/types/TStore';
import type IEnumResponse from './interfaces/IEnumResponse';
import type { INaceCodeTreeResponse } from './interfaces/INaceCodeTreeResponse';
import type { INaceData } from './interfaces/INaceData';
import type { INaceExtractedValue } from './interfaces/INaceExtractedValue';

export const selfDataSets = (state: RootState) => state.dataSets;

export const selectMappingColumns = createSelector(
  [selfDataSets],
  ({ mappingColumns }) => {
    const target = mappingColumns?.targets?.reduce((acc: { [key: string]: string }, item, index) => {
      acc[`${MAPPING_TARGET}_${index}`] = item;
      return acc;
    }, {}) || {};

    const matched = mappingColumns?.map?.filter((item) => item.source)?.length;

    const info = { total: mappingColumns?.map?.length, matched };
    const sources = mappingColumns?.targets?.reduce((acc: { [key: string]: IOption }, item, index) => {
      const isMatched = !!mappingColumns?.map?.find((mapItem) => mapItem.source === item);
      const value = `${MAPPING_SOURCES}_${index}`;
      acc[`${MAPPING_SOURCES}_${index}`] = {
        value, name: value, label: item, isMatched,
      };
      return acc;
    }, {}) || {};
    return { target, sources, info };
  },
);

export const selectSourcesOptions = createSelector(
  [selfDataSets],
  ({ mappingColumns }) => mappingColumns?.sources?.map(
    (item, index) => (({ value: `${MAPPING_SOURCES}_${index}`, label: item })),
  )
      || [],
);

export const selectEnums = createSelector(
  (data?:IEnumResponse) => data?.data,
  (data) => data?.reduce((acc, item) => {
    const transformedEnum = item?.enum?.map(({ value }) => ({
      label: value,
      value,
    }));
    return { ...acc, [item.name]: transformedEnum };
  }, {}),
);

export const selectUidNameTuples = createSelector(
  (data?:IEnumResponse) => data?.data,
  (data) => data?.reduce((acc, item) => {
    const transformedEnum = item?.enum?.map(({ value }) => ({
      label: value,
      value,
    }));
    return { ...acc, [item.name]: transformedEnum };
  }, {}),
);

const extractLabelsAndValues = (data: INaceData, level: number = 0): INaceExtractedValue[] => {
  if (level > 2) return [];

  return Object.keys(data).map((key) => {
    const item = data[key];
    const result: INaceExtractedValue = {
      label: `${key} ${item.label}`,
      value: key,
    };

    if (level < 1) {
      if (item.divisions) {
        result.divisions = extractLabelsAndValues(item.divisions, level + 1);
      } else if (item.groups) {
        result.divisions = extractLabelsAndValues(item.groups, level + 1);
      }
    }

    return result;
  });
};

export const selectNaceCodes = createSelector(
  (data?: INaceCodeTreeResponse) => data?.data,
  (data) => (data ? extractLabelsAndValues(data) : []),
);
export default selectMappingColumns;
