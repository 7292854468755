import { ReactComponent as AdminIcon } from 'assets/icons/user-settings.svg';
import { t } from 'i18next';

import i18n from '../i18n';
import { USER_PILLAR_ACCESS_TYPES, USER_ROLE_TYPES, USER_STATUS } from './interfaces';

import type { ReactNode } from 'react';

import { ReactComponent as AcrossIcon } from '../assets/icons/across.svg';
import { ReactComponent as CheckmarkIcon } from '../assets/icons/checkmark.svg';
import { ReactComponent as FacilitatorIcon } from '../assets/icons/microphone.svg';
import { ReactComponent as ContributorIcon } from '../assets/icons/pieBar.svg';
import { ReactComponent as AuditorIcon } from '../assets/icons/searchDoc.svg';
import { ReactComponent as SendIcon } from '../assets/icons/send.svg';

type TUserRoleItem = {
  label: string,
  icon: ReactNode,
};

export const USER_ROLE = [
  { value: USER_ROLE_TYPES.companyAdmin, label: i18n.t('common.administrator') },
  { value: USER_ROLE_TYPES.facilitator, label: i18n.t('common.facilitator') },
  { value: USER_ROLE_TYPES.contributor, label: i18n.t('common.contributor') },
  { value: USER_ROLE_TYPES.auditor, label: i18n.t('common.auditor') },
];

export const USER_ACCESS_TYPES = [
  { value: USER_PILLAR_ACCESS_TYPES.noAccess, label: i18n.t('permissions.accessTypes.noAccess'), color: '#DC2626' },
  { value: USER_PILLAR_ACCESS_TYPES.viewOnly, label: i18n.t('permissions.accessTypes.viewOnly'), color: '#08BF9E' },
  { value: USER_PILLAR_ACCESS_TYPES.viewEdit, label: i18n.t('permissions.accessTypes.viewEdit'), color: '#FFAD08' },
  { value: USER_PILLAR_ACCESS_TYPES.viewAudit, label: i18n.t('permissions.accessTypes.viewAudit'), color: '#FF76D0' },
  {
    value: USER_PILLAR_ACCESS_TYPES.dimmedDisabled,
    label: i18n.t('permissions.accessTypes.dimmedDisabled'),
    color: '#DBDDDE',
  },
];

export const FILTER_USER_STATUSES = [
  { value: '', label: i18n.t('common.all') },
  { value: USER_STATUS.draft, label: i18n.t('common.draft') },
  { value: USER_STATUS.invited, label: i18n.t('common.invited') },
  { value: USER_STATUS.active, label: i18n.t('common.active') },
  { value: USER_STATUS.deactivated, label: i18n.t('common.deactivated') },
];

const USER_ROLES: Record<string, TUserRoleItem> = {
  [USER_ROLE_TYPES.admin]: {
    label: t('common.administrator'),
    icon: <AdminIcon />,
  },
  [USER_ROLE_TYPES.companyAdmin]: {
    label: t('common.administrator'),
    icon: <AdminIcon />,
  },
  [USER_ROLE_TYPES.facilitator]: {
    label: t('common.facilitator'),
    icon: <FacilitatorIcon />,
  },
  [USER_ROLE_TYPES.contributor]: {
    label: t('common.contributor'),
    icon: <ContributorIcon />,
  },
  [USER_ROLE_TYPES.auditor]: {
    label: t('common.auditor'),
    icon: <AuditorIcon />,
  },
};

export default USER_ROLES;

export type TUserStatusItem = {
  label: string,
  color: string,
  textColor?: string,
  actionIcon: ReactNode,
  actionLabel: string,
};

export const USER_STATUSES: Record<string, TUserStatusItem> = {
  [USER_STATUS.draft]: {
    label: t('common.draft'),
    color: '#DBDDDE',
    textColor: '#5A5F60',
    actionIcon: <SendIcon />,
    actionLabel: t('common.sendInvite'),
  },
  [USER_STATUS.invited]: {
    label: t('common.invited'),
    color: '#0555F0',
    actionIcon: <SendIcon />,
    actionLabel: t('common.resendInvite'),
  },
  [USER_STATUS.active]: {
    label: t('common.active'),
    color: '#7A9A01',
    actionIcon: <AcrossIcon />,
    actionLabel: t('common.deactivate'),
  },
  [USER_STATUS.deactivated]: {
    label: t('common.deactivated'),
    color: '#DC2626',
    actionIcon: <CheckmarkIcon />,
    actionLabel: t('common.activate'),
  },
};

export const MFA_SETUP_TYPE = 'setup';

export const USER_MANAGEMENT_HEAD_CELLS = [
  {
    id: 'firstName',
    label: 'userManagement.user',
    withSort: true,
  },
  {
    id: 'role',
    label: 'userManagement.role',
    withSort: true,
  },
  {
    id: 'pillar',
    label: 'userManagement.pillar',
    withSort: false,
  },
  {
    id: 'status',
    label: 'userManagement.status',
    withSort: false,
  },
];
