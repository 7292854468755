import Panel from 'components/Panel/Panel';
import Separator from 'components/Separator/Separator';

import InfoPanelHeader from './InfoPanelHeader';

import type IInfoPanel from './interfaces/IInfoPanel';

import styles from './scss/InfoPanel.module.scss';

function InfoPanel({
  children, title, icon, onEdit, isActionsVisible = true,
}: IInfoPanel) {
  return (
    <Panel className={styles.root}>
      <InfoPanelHeader
        title={title}
        icon={icon}
        onEdit={onEdit}
        isActionsVisible={isActionsVisible}
      />
      <Separator />
      <div className={styles.content}>
        {children}
      </div>
    </Panel>
  );
}

export default InfoPanel;
