import {
  FINISHED_GOOD_STOCK_AMOUNT, FINISHED_GOOD_STOCK_AMOUNT_CURRENCY,
  GROSS_MARGIN_ROLLING_AMOUNT, GROSS_MARGIN_ROLLING_CURRENCY,
  GROSS_MARGIN_ROLLING_PERCENTS,
  PROFIT_CENTRE_NAME, PROFIT_CENTRE_UNIQUE_IDENTIFIER,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
} from 'constants/formFields';

import type { IGetProfitCentreDefaultValues } from './interfaces/IGetProfitCentreDefaultValues';

function getProfitCentreDefaultValues({ values }: IGetProfitCentreDefaultValues) {
  const {
    name, uniqueIdentifier, totalRevenueRolling12MActual, totalRevenueRolling12MPercents,
    totalGrossMarginRolling12MActual, totalGrossMarginRolling12MPercents, wipFinishedGoodsStock,
  } = values || {};

  const defaultOption = { value: '', label: '' };

  return {
    [PROFIT_CENTRE_NAME]: name || '',
    [PROFIT_CENTRE_UNIQUE_IDENTIFIER]: uniqueIdentifier || '',
    [REVENUE_ROLLING_PERCENTS]: totalRevenueRolling12MPercents ? String(totalRevenueRolling12MPercents) : '',
    [GROSS_MARGIN_ROLLING_PERCENTS]: totalGrossMarginRolling12MPercents
      ? String(totalGrossMarginRolling12MPercents) : '',
    [REVENUE_ROLLING_AMOUNT]: totalRevenueRolling12MActual?.amount ? String(totalRevenueRolling12MActual.amount) : '',
    [GROSS_MARGIN_ROLLING_AMOUNT]: totalGrossMarginRolling12MActual?.amount
      ? String(totalGrossMarginRolling12MActual?.amount) : '',
    [FINISHED_GOOD_STOCK_AMOUNT]: wipFinishedGoodsStock?.amount ? String(wipFinishedGoodsStock?.amount) : '',
    [REVENUE_ROLLING_CURRENCY]: totalRevenueRolling12MActual?.currency ? {
      value: totalRevenueRolling12MActual.currency, label: totalRevenueRolling12MActual.currency,
    } : defaultOption,
    [GROSS_MARGIN_ROLLING_CURRENCY]: totalGrossMarginRolling12MActual?.currency ? {
      value: totalGrossMarginRolling12MActual.currency, label: totalGrossMarginRolling12MActual.currency,
    } : defaultOption,
    [FINISHED_GOOD_STOCK_AMOUNT_CURRENCY]: wipFinishedGoodsStock?.currency ? {
      value: wipFinishedGoodsStock.currency,
      label: wipFinishedGoodsStock.currency,
    } : defaultOption,
  };
}

export default getProfitCentreDefaultValues;
