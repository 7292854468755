import { useTranslation } from 'react-i18next';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import Button from 'components/Button/Button';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type { ISaveDraftButton } from './interfaces/ISaveDraftButton';

import styles
  from './scss/SaveDraftButton.module.scss';

function SaveDraftButton({
  onDraftSave,
  isDisabled,
}: ISaveDraftButton) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Button
        startIcon={<SaveIcon />}
        variant={BUTTON_VARIANT.secondary}
        title={t('common.saveDraft')}
        onClick={onDraftSave}
        disabled={isDisabled}
      />
    </div>
  );
}

export default SaveDraftButton;
