import { ReactComponent as RuleIcon } from 'assets/icons/rule.svg';

import type { IRule } from './interfaces/IRule';

import styles from './scss/Rule.module.scss';

function Rule({ label }: IRule) {
  return (
    <div className={styles.root}>
      <RuleIcon />
      <span>{label}</span>
    </div>
  );
}

export default Rule;
