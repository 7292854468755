import type { NodeModel } from '@minoru/react-dnd-treeview';
import type INodeModelData from '../pages/UserPages/OwnOperations/components/FiltersBar/interfaces/INodeModelData';

const createTreeData = (input: string[]): NodeModel<INodeModelData>[] => {
  const pathMap: { [key: string]: string | number } = { '': 0 };
  let idCounter = 1;
  return input.reduce((tree: NodeModel<INodeModelData>[], path) => {
    const parts = path.split('/').filter((part) => part.trim() !== '').map((part) => part.trim());
    let parentId = 0;
    parts.forEach((part, index) => {
      const currentPath = parts.slice(0, index + 1).join(' / ');
      const isHaveChilds = input.some((item) => item.startsWith(currentPath) && item !== currentPath);
      const isHaveNesting = index < parts.length - 1 || isHaveChilds;

      if (!pathMap[currentPath]) {
        idCounter += 1;
        const node: NodeModel<INodeModelData> = {
          id: idCounter,
          parent: parentId,
          text: part,
          droppable: true,
          data: { path: currentPath, isHaveNesting },
        };
        tree.push(node);
        pathMap[currentPath] = node.id;
      }
      parentId = Number(pathMap[currentPath]);
    });

    return tree;
  }, []);
};

export default createTreeData;
