import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { USER_PILLAR_ACCESS_TYPES } from 'constants/interfaces';
import { USER_ACCESS_TYPES } from 'constants/user';

import type IPermissionItem from './interfaces/IPermissionItem';

import styles from './scss/PermissionsSection.module.scss';

function PermissionItem({
  permissionId, access, pilarModuleName,
}: IPermissionItem) {
  const { t } = useTranslation();

  const accessType = USER_ACCESS_TYPES.filter((item) => item.value === access)?.[0];
  const { color, label } = accessType || {};

  return (
    <div className={styles.item} key={permissionId}>
      <p>{t(`pillarsAndModules.${pilarModuleName}`)}</p>
      {access ? (
        <div>
          <p
            className={clsx(styles.access, {
              [styles.accessDisabled]: access === USER_PILLAR_ACCESS_TYPES.dimmedDisabled,
            })}
            style={{ backgroundColor: color }}
          >
            {label}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default PermissionItem;
