import { Trans, useTranslation } from 'react-i18next';
import EmptyList from 'components/EmptyList/EmptyList';
import Panel from 'components/Panel/Panel';
import Table from 'components/Table/Table';
import TableBody from 'components/Table/TableBody';

import UsersTableHead from '../UsersTableHead/UsersTableHead';
import UsersTableRow from '../UsersTableRow/UsersTableRow';

import type { IUsersTable } from '../../interfaces/IUsersTable';

import styles from './scss/UserTable.module.scss';

function UsersTable({
  users, isActionsVisible, control, onSortChange,
}: IUsersTable) {
  const { t } = useTranslation();
  const emptyListText = (
    <Trans
      i18nKey="common.noResultsWithFilters"
      t={t}
      components={{
        br: <br />,
      }}
    />
  );
  return (
    <Panel className={styles.root}>
      {users?.length ? (
        <Table>
          <UsersTableHead isActionsVisible={isActionsVisible} control={control} onSortChange={onSortChange} />
          <TableBody>
            {users?.map((user) => (<UsersTableRow key={user.id} user={user} />))}
          </TableBody>
        </Table>
      ) : (<EmptyList text={emptyListText} />)}

    </Panel>
  );
}

export default UsersTable;
