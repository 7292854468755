import AppRoutes from 'constants/AppRoutes';

export default {
  [AppRoutes.profile]: 'Profile',
  [AppRoutes.personalInfo]: 'Personal Info',
  [AppRoutes.clients]: 'Clients',
  [AppRoutes.companyInfo]: 'Company Info',
  [AppRoutes.multiLingual]: 'Multi-Lingual',
  [AppRoutes.companyAdmin]: 'Company Admin',
  [AppRoutes.uiConfiguration]: 'UI Configuration',
  [AppRoutes.moduleManagement]: 'Module Management',
  [AppRoutes.security]: 'Security',
  [AppRoutes.profilePassword]: 'Login & Security',
  [AppRoutes.profileSecurity]: 'Login & Security',
  [AppRoutes.launchpad]: 'Launchpad',
  [AppRoutes.launchpadSettings]: 'Settings',
  [AppRoutes.userManagement]: 'User Management',
  [AppRoutes.createNewUser]: 'Create New User',
  [AppRoutes.createUserPermissions]: 'Create New User',
  [AppRoutes.editUserInfo]: 'Edit User Info',
  [AppRoutes.editUserPermissions]: 'Edit User Info',
  [AppRoutes.clientUsers]: 'List of Users',
  [AppRoutes.clientUsersSecurity]: 'Edit User Info',
  [AppRoutes.companyUiConfiguration]: 'UI Configuration',
  [AppRoutes.ownOperations]: 'Data Sets Management',
  [AppRoutes.suppliers]: 'Data Sets Management',
  [AppRoutes.customers]: 'Data Sets Management',
  [AppRoutes.products]: 'Data Sets Management',
  [AppRoutes.profitCentres]: 'Data Sets Management',
  [AppRoutes.profitCentresCreate]: 'Create New Profit Centre',
  [AppRoutes.profitCentresEdit]: 'Edit Profit Centre',
  [AppRoutes.profileLanguage]: 'Language',
  [AppRoutes.ownOperationsCreate]: 'Add New Own Operation',
  [AppRoutes.ownOperationsEdit]: 'Edit Own Operation',
  [AppRoutes.supplierCreate]: 'Add New Supplier',
  [AppRoutes.supplierEdit]: 'Edit Supplier',
  [AppRoutes.productsCreate]: 'Add New Input Product',
  [AppRoutes.productsEdit]: 'Edit Input Product',
  [AppRoutes.customerCreate]: 'Add New Customer',
  [AppRoutes.customerEdit]: 'Edit Customer',
};
