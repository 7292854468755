import {
  ADDRESS_BUILDING,
  ADDRESS_CITY, ADDRESS_COORDINATES,
  ADDRESS_COUNTRY, ADDRESS_POST_CODE, ADDRESS_STATE, ADDRESS_STREET,
  BUSINESS_UNIT_TYPE,
  CODE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING,
  EU_TAXONOMY_CODE,
  FINANCIAL_CONTROL, KEY_SUPPLIER_TIER_1_TOTAL,
  KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY,
  KEY_SUPPLIER_TIER_10_OF_TOTAL,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  OWNERSHIP,
  SPEND,
  SPEND_CURRENCY,
  UNIQUE_IDENTIFIER, UPSTREAM_TITLE,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT, DATE_FORMAT_ISO } from 'constants/general';
import dayjs from 'dayjs';

import type { IGetRequestDataForSupplier } from './interfaces/IGetRequestDataForSupplier';

function getRequestDataForSupplier({ values, recordId }: IGetRequestDataForSupplier) {
  return {
    [NAME]: values?.[NAME] || null,
    [CODE]: values?.[CODE] || null,
    [UNIQUE_IDENTIFIER]: values?.[UNIQUE_IDENTIFIER] || null,
    [OWNERSHIP]: values[OWNERSHIP]?.value || null,
    [EQUITY_SHAREHOLDING]: values?.[EQUITY_SHAREHOLDING] ? Number(values?.[EQUITY_SHAREHOLDING]) : null,
    [VALUE_CHAIN_SECTION]: values?.[VALUE_CHAIN_SECTION]?.value || null,
    [FINANCIAL_CONTROL]: values?.[FINANCIAL_CONTROL] ? Number(values?.[FINANCIAL_CONTROL]) : null,
    [BUSINESS_UNIT_TYPE]: values?.[BUSINESS_UNIT_TYPE]?.value || null,
    [DATE_OPENED]: values?.[DATE_OPENED] ? dayjs(values?.[DATE_OPENED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [DATE_CLOSED]: values?.[DATE_CLOSED] ? dayjs(values?.[DATE_CLOSED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [NACE_SECTION]: values?.[NACE_SECTION]?.value || null,
    [NACE_CODE]: values[NACE_CODE]?.value || null,
    [EU_TAXONOMY_CODE]: values?.[EU_TAXONOMY_CODE] || null,
    [SPEND]: values?.[SPEND] ? {
      amount: Number(values?.[SPEND]),
      currency: values?.[SPEND_CURRENCY].value,
    } : null,
    [KEY_SUPPLIER_TIER_1_TOTAL]: values?.[KEY_SUPPLIER_TIER_1_TOTAL] ? {
      amount: Number(values?.[KEY_SUPPLIER_TIER_1_TOTAL]),
      currency: values?.[KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY]?.value || '',
    } : null,
    [KEY_SUPPLIER_TIER_10_OF_TOTAL]: values[KEY_SUPPLIER_TIER_10_OF_TOTAL]
      ? Number(values[KEY_SUPPLIER_TIER_10_OF_TOTAL]) : null,
    [ADDRESS_COUNTRY]: values?.[ADDRESS_COUNTRY]?.label || null,
    [ADDRESS_STATE]: values?.[ADDRESS_STATE]?.label || null,
    [ADDRESS_POST_CODE]: values?.[ADDRESS_POST_CODE] || null,
    [ADDRESS_CITY]: values?.[ADDRESS_CITY]?.label || null,
    [ADDRESS_STREET]: values?.[ADDRESS_STREET] || null,
    [ADDRESS_BUILDING]: values?.[ADDRESS_BUILDING] || null,
    [ADDRESS_COORDINATES]: values?.[ADDRESS_COORDINATES] || null,
    [UPSTREAM_TITLE]: values?.[UPSTREAM_TITLE]?.value || null,
    ...(recordId && { id: Number(recordId) }),
  };
}

export default getRequestDataForSupplier;
