import { useLayoutEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import { useAppSelector } from '../store/hooks/useApp';
import { authTokensSelector } from '../store/slices/auth/selectors';
import selectCurrentTheme from '../store/slices/theme/selectors';
import { useUserMeQuery } from '../store/slices/user/apis/user';

import type IUseTheme from './interfaces/IUseTheme';

function useTheme(): IUseTheme {
  const theme = useAppSelector(selectCurrentTheme);

  const { accessToken } = useAppSelector(authTokensSelector);

  const queryParams = accessToken ? undefined : skipToken;
  const { data: me } = useUserMeQuery(queryParams);

  const { colorPalette } = me?.data?.company?.uiConfiguration || {};

  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    if (colorPalette) {
      const {
        primary, secondary, headings1, headings2, subtitle,
      } = colorPalette;
      document.documentElement.style.setProperty('--primary', primary); // TODO - Refactor this to use a loop
      document.documentElement.style.setProperty('--secondary', secondary);
      document.documentElement.style.setProperty('--headings1', headings1);
      document.documentElement.style.setProperty('--headings2', headings2);
      document.documentElement.style.setProperty('--subtitle', subtitle);
    }
  }, [theme, me, colorPalette]);

  const resetToDefaultTheme = () => {
    if (colorPalette) {
      Object.keys(colorPalette).forEach((key) => {
        document.documentElement.style.removeProperty(`--${key}`);
      });
    }
  };

  return {
    theme, resetToDefaultTheme,
  };
}

export default useTheme;
