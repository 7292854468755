import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EMPTY_SYMBOL, MAPPING_FIELDS_PREFIX } from 'constants/general';

import type IUseMappingForm from '../interfaces/IUseMappingForm';
import type { TMappingSchema } from '../interfaces/TMappingSchema';

const useMappingForm = ({ mappingColumns }: IUseMappingForm) => {
  const {
    control,
    handleSubmit,
    formState: {
      isValid,
      errors,
    },
    reset,
    watch,
    setError,
    setValue,
  } = useForm<TMappingSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { target: {}, sources: {} },
  });

  useEffect(() => {
    if (mappingColumns.target) {
      const newValues: TMappingSchema = { target: mappingColumns.target, sources: mappingColumns.sources };
      reset(newValues);

      Object.keys(mappingColumns.sources)?.forEach((sourceKey) => {
        const currentSource = mappingColumns?.sources?.[sourceKey];
        if (!currentSource?.isMatched) {
          setError(`${MAPPING_FIELDS_PREFIX.source}${currentSource.value}`, { message: EMPTY_SYMBOL });
          setValue(`${MAPPING_FIELDS_PREFIX.source}${currentSource.value}`, { ...currentSource, ...{ value: '' } });
        }
      });
    }
  }, [reset, mappingColumns, setError, setValue]);

  return {
    control,
    handleSubmit,
    isValid,
    reset,
    watch,
    setError,
    setValue,
    errors,
  };
};

export default useMappingForm;
