import { useTranslation } from 'react-i18next';
import {
  matchPath, Outlet, useLocation, useMatch,
} from 'react-router-dom';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import NavSidebar from 'components/NavSidebar/NavSidebar';
import Title from 'components/Title/Title';
import { AppRoutes } from 'constants/AppRoutes';
import { loginSecurityNav } from 'constants/navigationLists';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useUserMeQuery } from 'store/slices/user/apis/user';

import type { TAppRoutes } from 'constants/AppRoutes';

import styles from './scss/ProfileLayout.module.scss';

function ProfileLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const { breadcrumbs, isBreadcrumbsInVisible } = useBreadcrumbs({});
  const { pathname } = location;

  const { data, isLoading } = useUserMeQuery();

  const { security } = data?.data?.company || {};

  const ifMfaCompanyDisabled = (security?.mfaApp === 'disabled' && security?.mfaSms === 'disabled') || isLoading;

  const isNavMenuVisible = !ifMfaCompanyDisabled && [
    AppRoutes.profilePassword,
    AppRoutes.profileSecurity,
  ].some((path) => matchPath(path, pathname));

  const navList = loginSecurityNav();

  const useMatchPageTitle = (page: TAppRoutes): string => useMatch(page)?.pathname || '';

  const pageTitle: { [key in TAppRoutes]?: string } = {
    [useMatchPageTitle(AppRoutes.profile)]: t('profile.title'),
    [useMatchPageTitle(AppRoutes.personalInfo)]: t('profile.personalInfo'),
    [useMatchPageTitle(AppRoutes.profilePassword)]: t('profile.loginSecurity'),
    [useMatchPageTitle(AppRoutes.profileSecurity)]: t('profile.loginSecurity'),
    [useMatchPageTitle(AppRoutes.profileLanguage)]: t('profile.language'),
  };

  const pageTitleText = pageTitle[pathname as TAppRoutes];

  return (
    <div className={styles.root}>
      {!isBreadcrumbsInVisible ? <BreadcrumbsNav breadcrumbs={breadcrumbs} className={styles.breadcrumbs} /> : null }
      { pageTitleText ? (
        <Title
          text={pageTitleText}
          className={styles.title}
        />
      ) : null}
      <div className={styles.wrapper}>
        {isNavMenuVisible ? (
          <div className={styles.sidebar}>
            <NavSidebar
              navList={navList}
            />
          </div>
        ) : null}
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default ProfileLayout;
