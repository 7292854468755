import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as FingerprintIcon } from 'assets/icons/fingerprint.svg';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import Separator from 'components/Separator/Separator';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import AppRoutes from 'constants/AppRoutes';

import type ISecuritySection from './interfaces/ISecuritySection';

import styles from './scss/SecuritySection.module.scss';

function SecuritySection({
  security,
}: ISecuritySection) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId = '' } = useParams();

  const onHandleEdit = () => {
    const redirectPath = generatePath(AppRoutes.clientProfile, { clientId });
    navigate(generatePath(AppRoutes.security, { clientId }), { state: { redirectPath } });
  };

  return (
    <InfoPanel
      title={t('clients.security')}
      icon={<FingerprintIcon />}
      onEdit={onHandleEdit}
    >
      <div className={styles.item}>
        <h3>{t('clients.mfaAuthentication')}</h3>
        <div>
          {security?.mfaApp ? <StatusBadge status={security?.mfaApp} /> : '-'}
        </div>
      </div>
      <Separator />
      <div className={styles.item}>
        <h3>{t('clients.mfaSms')}</h3>
        <div>
          {security?.mfaSms ? <StatusBadge status={security?.mfaSms} /> : '-'}
        </div>
      </div>
    </InfoPanel>
  );
}

export default SecuritySection;
