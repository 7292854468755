import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FILTER_BY_STATUS, ORDER_KEY, ORDER_TYPE, SEARCH, SORT,
} from 'constants/formFields';
import { ORDERS } from 'constants/interfaces';
import { FILTER_USER_STATUSES } from 'constants/user';
import { userManagementSchema } from 'utils/validators';

import type { TUserManagementSchema } from 'utils/validators';

const useUserManagementForm = () => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<TUserManagementSchema>({
    mode: 'onChange',
    resolver: yupResolver(userManagementSchema),
    defaultValues: {
      [SEARCH]: '',
      [FILTER_BY_STATUS]: { value: FILTER_USER_STATUSES[0].value, label: FILTER_USER_STATUSES[0].label },
      [SORT]: {
        [ORDER_KEY]: '',
        [ORDER_TYPE]: ORDERS.asc,
      },
    },
  });

  return {
    control,
    handleSubmit,
    getValues,
    setValue,
  };
};

export default useUserManagementForm;
