export default {
  title: 'Profit Centres',
  subTitle: 'Lorem ipsum dolor',
  totalPages: '{{offset}}-{{totalPages}} of {{total}}',
  archivingReasonTitle: 'Specify reason for archiving',
  archivingReasonDescription: 'Please provide a reason for archiving the selected record(s). You can restore '
      + 'them anytime.',
  archiveDetails: 'Archived record details',
  editInformation: 'Edit Information',
  fieldsHeaders: {
    profitCentreName: 'Profit Centre Name (Output Product)',
    uniqueIdentifier: 'Unique Identifier',
    totalRevenue: '% of Total Revenue (rolling 12 months)',
    actualRevenue: 'Actual Revenue (rolling 12 months) ',
    totalGross: '% of Total Gross Margin (rolling 12 months)',
    actualGross: 'Actual Gross Margin (rolling 12 months) ',
    goodsStock: 'WIP/Finished Goods Stock',
    actions: 'Actions',
  },
  createProfitCentre: {
    title: 'Create New Profit Centre',
    editTitle: 'Edit Profit Centre',
    generalPanel: 'General',
    financialsPanel: 'Financials',
    fields: {
      name: 'Profit Centre Name (Output Product)',
      uniqueIdentifier: 'Unique Identifier',
      totalRevenue: '% of Total Revenue (rolling 12 months)',
      actualRevenue: 'Actual Revenue (rolling 12 months) ',
      totalGross: '% of Total Gross Margin (rolling 12 months)',
      actualGross: 'Actual Gross Margin (rolling 12 months) ',
      goodsStock: 'WIP/Finished Goods Stock',
    },
  },
};
