import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useMatches } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';

import type { UIMatch } from '@remix-run/router';
import type { IBreadcrumb } from '../components/Breadcrumbs/interfaces/IBreadcrumbs';
import type { IUseBreadcrumbs, THandle } from './interfaces/IUseBreadcrumbs';

const useBreadcrumbs = ({ mathCrumbs = [] }: IUseBreadcrumbs) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;

  const matches = useMatches() as UIMatch<unknown, THandle>[];

  const getPathPattern = (path: string) => (
    Object.values(AppRoutes).find((route) => matchPath(route, path))
  );

  const breadcrumbs: IBreadcrumb[] = matches
    .filter((match) => match.pathname !== '/' && !match.handle?.skipBreadcrumbs)
    .map((match) => {
      const newCrumb = mathCrumbs?.find((crumb) => crumb.pathname === match.pathname);
      const label = getPathPattern(match.pathname);

      return newCrumb || { path: match.pathname, label: t(`breadcrumbs.${label}`) };
    });

  const lastCrumb = breadcrumbs.at(-1);

  if (lastCrumb?.path) {
    delete lastCrumb.path;
  }

  const isBreadcrumbsInVisible = [
    AppRoutes.profile,
  ].some((path) => matchPath(path, pathname));

  return {
    breadcrumbs,
    isBreadcrumbsInVisible,
  };
};

export default useBreadcrumbs;
