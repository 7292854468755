import { useUserMeQuery } from '../../../store/slices/user/apis/user';
import ProfileView from './ProfileView';

import type { ReactElement } from 'react';

function Profile(): ReactElement {
  const { data } = useUserMeQuery();
  const companyLanguages = data?.data?.company?.localization?.languages || [];
  return (
    <ProfileView companyLanguages={companyLanguages} />
  );
}

export default Profile;
