export default {
  firstNameRequired: 'First name is required',
  lastNameRequired: 'Last name is required',
  emailRequired: 'Email is required',
  emailNotValid: 'Email is not valid',
  nameRequired: 'Name is required',
  typeRequired: 'Type is required',
  passwordMismatch: 'Password mismatch',
  companyLogoRequired: 'Company logo is required',
  reasonRequired: 'Reason is required',
  linkWithTokenExpired: 'The link is invalid or has expired. Please request a new link and try again.',
  codeHasBeenSent: 'The code has been sent',
  minCharactersLength: '{{name}} must be at least {{length}} characters',
  maxCharactersLength: '{{name}} must be at most {{length}} characters',
  nameContainLettersAndNumbers: 'Must contain only letters, numbers or hyphens',
  nameContainLettersAndHyphens: 'Must contain only letters, spaces or hyphens',
  nameContainLettersAndSpecialSymbols: 'Must contain only letters, special symbols or hyphens',
  requiredField: '{{name}} is required',
};
