import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import Button from 'components/Button/Button';
import Backdrop from 'components/Modal/Backdrop';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type {
  Crop,
} from 'react-image-crop';
import type ICropper from './interfaces/ICropper';

import styles from './scss/Cropper.module.scss';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

function Cropper({
  title, file, onSave, onCancel,
}: ICropper) {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const imgRef = useRef<HTMLImageElement>(null);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  const getCroppedImg = async (image: HTMLImageElement) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Failed to get canvas context');
    }

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise<Blob | null>((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, file.type, 1);
    });
  };

  const onHandleSave = async () => {
    try {
      if (imgRef.current && crop.width && crop.height) {
        const croppedImgBlob = await getCroppedImg(imgRef.current);
        if (croppedImgBlob) {
          const croppedImage = new File([croppedImgBlob], file.name, {
            type: file.type,
          });
          onSave(croppedImage);
        }
      }
    } catch (error) {
      onCancel();
    }
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(file);
    }
  }, [file]);

  return (
    <Backdrop>
      <div className={styles.wrapper}>
        {title ? <h2 className={styles.title}>{title}</h2> : null}
        <div className={styles.content}>
          <ReactCrop
            className={styles.cropper}
            crop={crop}
            onChange={(pixelCrop) => setCrop(pixelCrop)}
          >
            <img
              className={styles.image}
              ref={imgRef}
              alt="preview"
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
        <div className={styles.actions}>
          <Button
            title={t('common.cancel')}
            onClick={onCancel}
            variant={BUTTON_VARIANT.cancel}
          />
          <Button
            title={t('common.cropAndSave')}
            onClick={onHandleSave}
          />
        </div>
      </div>
    </Backdrop>
  );
}

export default Cropper;
