import {
  ADMIN_USERS_DEACTIVATE_SECURITY,
  COMPANY_ADMIN_USERS_ACTIVATE, COMPANY_ADMIN_USERS_DEACTIVATE, COMPANY_ADMIN_USERS_DELETE,
  COMPANY_ADMIN_USERS_LIST, COMPANY_ADMIN_USERS_USER_DETAILS,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';
import COMPANY_TAG, { COMPANY_USER } from 'store/tags/companies';

import type IUser from 'store/slices/user/interfaces/IUser';
import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type IUserActivationRequest from '../interfaces/IUserActivationRequest';
import type IUserDeactivationRequest from '../interfaces/IUserDeactivationRequest';
import type IUserDeleteRequest from '../interfaces/IUserDeleteRequest';
import type IUserDetailsRequest from '../interfaces/IUserDetailsRequest';
import type IUserSecurityDeactivation from '../interfaces/IUserSecurityDeactivation';
import type IUsersListRequest from '../interfaces/IUsersListRequest';
import type IUsersListResponse from '../interfaces/IUsersListResponse';

export const adminUsersApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminUsersList: builder.query<IBaseQueryResponse<IUsersListResponse>, IUsersListRequest>({
      query: ({ search, ...rest }) => ({
        url: COMPANY_ADMIN_USERS_LIST,
        params: {
          keyword: search,
          ...rest,
        },
      }),
    }),

    getAdminUserDetails: builder.query<IBaseQueryResponse<IUser>, IUserDetailsRequest>({
      query: ({ userId, companyId }) => ({
        url: COMPANY_ADMIN_USERS_USER_DETAILS.replace('{userId}', `${userId}`),
        params: {
          companyId,
        },
      }),
      providesTags: [COMPANY_USER],
    }),

    activateAdminUser: builder.mutation<void, IUserActivationRequest>({
      query: ({ userId, companyId }) => ({
        url: COMPANY_ADMIN_USERS_ACTIVATE.replace('{userId}', `${userId}`),
        method: 'PATCH',
        params: {
          companyId,
        },
      }),
      invalidatesTags: [COMPANY_TAG],
    }),

    deactivateAdminUser: builder.mutation<void, IUserDeactivationRequest>({
      query: ({ userId, reason, companyId }) => ({
        url: COMPANY_ADMIN_USERS_DEACTIVATE.replace('{userId}', `${userId}`),
        method: 'DELETE',
        body: {
          reason,
          companyId,
        },
      }),
      invalidatesTags: [COMPANY_TAG],
    }),

    deleteAdminUser: builder.mutation<void, IUserDeleteRequest>({
      query: ({ userId, companyId }) => ({
        url: COMPANY_ADMIN_USERS_DELETE.replace('{userId}', `${userId}`),
        method: 'DELETE',
        params: {
          companyId,
        },
      }),
      invalidatesTags: [COMPANY_TAG],
    }),

    deactivateSecurityAdminUser: builder.mutation<void, IUserSecurityDeactivation>({
      query: ({ userId, companyId, ...rest }) => ({
        url: ADMIN_USERS_DEACTIVATE_SECURITY.replace('{userId}', `${userId}`),
        method: 'PATCH',
        body: {
          companyId,
          ...rest,
        },
      }),
      invalidatesTags: [COMPANY_USER],
    }),
  }),
});

export const {
  useGetAdminUsersListQuery,
  useGetAdminUserDetailsQuery,
  useActivateAdminUserMutation,
  useDeactivateAdminUserMutation,
  useDeleteAdminUserMutation,
  useDeactivateSecurityAdminUserMutation,
} = adminUsersApi;
