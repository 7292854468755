import { clsx } from 'clsx';

import type ICheckbox from './interfaces/ICheckbox';

import styles from './scss/Checkbox.module.scss';

function Checkbox({
  value,
  checked,
  onChange,
  name,
  label,
  disabled,
  type = 'checkbox',
  isLineMark,
}: ICheckbox) {
  const isRadioButton = type === 'radio';

  return (
    <label className={styles.root} htmlFor={name}>
      {label}
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <span className={isRadioButton ? styles.radio : clsx(styles.checkmark, {
        [styles.lineMark]: isLineMark,
      })}
      />
    </label>
  );
}

export default Checkbox;
