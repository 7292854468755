import { useTranslation } from 'react-i18next';
import EmptyList from 'components/EmptyList/EmptyList';
import Modal from 'components/Modal/Modal';
import Panel from 'components/Panel/Panel';
import Separator from 'components/Separator/Separator';

import CompanyUserCard from './components/CompanyUserCard/CompanyUserCard';
import CompanyUsersHeading from './components/CompanyUsersHeading/CompanyUsersHeading';

import type ICompanyUsersView from './interfaces/ICompanyUsersView';

import styles from './scss/CompanyUsersView.module.scss';

function CompanyUsersView({
  users, control, onSearch, isConfirmationModalOpen, onHandleCloseConfirmation,
  onConfirm, setIsConfirmationModalOpen,
}: ICompanyUsersView) {
  const { t } = useTranslation();

  return (
    <Panel className={styles.root}>
      <CompanyUsersHeading
        control={control}
        onSearch={onSearch}
      />
      {users.length ? (
        <ul className={styles.list}>
          {users.map((user, index) => (
            <>
              <li key={user.id}>
                <CompanyUserCard user={user} setIsConfirmationModalOpen={setIsConfirmationModalOpen} />
                {users.length - 1 !== index ? <Separator /> : null}
              </li>
              <Modal
                open={isConfirmationModalOpen}
                title={t('companyUsers.impersonationModeTitle')}
                description={t(
                  'companyUsers.impersonationModeDescription',
                  { userName: `${user.firstName} ${user.lastName}` },
                )}
                confirmTitle={t('common.confirm')}
                closeTitle={t('common.cancel')}
                confirmVariant="primary"
                onReject={() => onHandleCloseConfirmation(false)}
                onConfirm={() => onConfirm(user.email)}
              >
                <ul className={styles.modalList}>
                  <li>{t('companyUsers.impersonationModeNotice')}</li>
                  <li>{t('companyUsers.impersonationModeReturnInstruction')}</li>
                </ul>

                <p className={styles.modalText}>{t('companyUsers.impersonationModeConfirmationQuestion')}</p>
              </Modal>
            </>
          ))}
        </ul>
      ) : (
        <EmptyList />
      )}
    </Panel>
  );
}

export default CompanyUsersView;
