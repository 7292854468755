import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AUTHENTICATION_GOOGLE } from 'constants/formFields';
import { useStartSyncWithAppQuery } from 'store/slices/auth/apis/auth';

import styles from './sass/SecretCode.module.scss';

function SecretCode() {
  const { type = AUTHENTICATION_GOOGLE } = useParams();
  const { data } = useStartSyncWithAppQuery();
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <ul>
        <li>
          <Trans
            i18nKey="authenticationModal.secretCode.inTheAuthenticator"
            t={t}
            components={{
              authenticatorType: <span>{type}</span>,
              b: <b />,
            }}
          />
        </li>
        <li>
          <Trans
            i18nKey="authenticationModal.secretCode.includeYour"
            t={t}
            values={{ secretCode: data?.data.secret }}
            components={{
              b: <b />,
            }}
          />
        </li>
        <li>
          <Trans
            i18nKey="authenticationModal.secretCode.makeSure"
            t={t}
            components={{
              b: <b />,
            }}
          />
        </li>
        <li>
          <Trans
            i18nKey="authenticationModal.secretCode.toComplete"
            t={t}
            components={{
              b: <b />,
            }}
          />
        </li>
      </ul>

    </div>
  );
}

export default SecretCode;
