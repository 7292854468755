import { type Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import Table from 'components/Table/Table';
import TableBody from 'components/Table/TableBody';
import TableCell from 'components/Table/TableCell';
import TableHead from 'components/Table/TableHead';
import TableSort from 'components/Table/TableSort';
import { SELECT_ALL, SORT } from 'constants/formFields';

import type { TDataSetsListSchema } from 'utils/validators';
import type IDataSetsItems from './interfaces/IDataSetsItems';

import styles from './sass/DataSetsItems.module.scss';

function DataSetsItems<T extends Control<TDataSetsListSchema>>({
  control, onSortChange, onSelectAll, isLineMark,
  isActionsVisible, children, headCells,
}: IDataSetsItems<T>) {
  const { t } = useTranslation();

  const cells = headCells.filter(({ id }) => id !== 'actions'
      || (id === 'actions' && isActionsVisible));

  return (
    <div className={styles.root}>
      <Table className={styles.table}>
        <TableHead className={clsx(styles.header, { [styles.headerWithoutActions]: !isActionsVisible })}>
          {cells.map(({
            id, label, withSort, isFirstCell,
          }) => (withSort ? (
            <Controller
              key={id}
              name={SORT}
              control={control}
              render={({
                field: { value },
              }) => (
                <TableCell head className={styles.tableCell}>
                  <TableSort
                    active={id === value.orderKey}
                    onClickSort={() => onSortChange(id)}
                  >
                    {isFirstCell && isActionsVisible ? (
                      <Controller
                        control={control}
                        render={({
                          field: {
                            onChange, value: selectValue, disabled, name,
                          },
                        }) => (
                          <Checkbox
                            key={id}
                            name={name}
                            checked={!!selectValue}
                            value={selectValue || ''}
                            disabled={disabled}
                            onChange={(event) => {
                              onChange(event.target.checked);
                              onSelectAll();
                            }}
                            label={false}
                            isLineMark={isLineMark}
                          />
                        )}
                        name={SELECT_ALL}
                      />
                    ) : null}
                    <span>{t(label)}</span>
                  </TableSort>
                </TableCell>
              )}
            />
          ) : (
            <TableCell head className={styles.tableCell} key={id}>
              <div>
                <span>{t(label)}</span>
              </div>
            </TableCell>
          )))}
        </TableHead>
        <TableBody className={clsx(styles.body, { [styles.bodyWithoutActions]: !isActionsVisible })}>
          {children}
        </TableBody>
      </Table>
    </div>
  );
}

export default DataSetsItems;
