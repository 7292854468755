import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import Separator from 'components/Separator/Separator';
import Subtitle from 'components/Subtitle/Subtitle';
import TextField from 'components/TextField/TextField';
import { SEARCH } from 'constants/formFields';

import type ICompanyUsersHeading from '../../interfaces/ICompanyUsersHeading';

import styles from './scss/CompanyUsersHeading.module.scss';

function CompanyUsersHeading({ control, onSearch }: ICompanyUsersHeading) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <UsersIcon />
          <Subtitle
            className={styles.title}
            text={t('companyUsers.title')}
          />
        </div>
        <Controller
          control={control}
          name={SEARCH}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              className={styles.input}
              name={name}
              value={value}
              placeholder={t('companyUsers.inputPlaceholder')}
              icon={<SearchIcon />}
              onChange={({ target }) => onSearch({ search: target.value, onChange })}
              error={error?.message}
            />
          )}
        />
      </div>
      <Separator />
    </>
  );
}

export default CompanyUsersHeading;
