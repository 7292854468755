import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import AppRoutes from 'constants/AppRoutes';
import { SEARCH } from 'constants/formFields';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { useAppDispatch } from 'store/hooks/useApp';
import { useGetAdminUsersListQuery } from 'store/slices/adminUsers/apis/adminUsersApi';
import { useLoginAdminAsUserMutation } from 'store/slices/auth/apis/auth';
import { setAuthState } from 'store/slices/auth/slice';
import { useUserMeQuery } from 'store/slices/user/apis/user';
import { usersListSchema } from 'utils/validators';

import CompanyUsersView from './CompanyUsersView';

import type { ISearchInputHandler } from 'components/TextField/interfaces/ITextField';
import type { TUsersListSchema } from 'utils/validators';
import type IParams from './interfaces/IParams';

function CompanyUsers() {
  const navigate = useNavigate();
  const { clientId = '' } = useParams();
  const [params, setParams] = useState<IParams>({
    search: '',
  });

  const fetchQuery = clientId ? {
    companyId: +clientId,
    search: params.search || undefined,
  } : skipToken;

  const { data, refetch } = useGetAdminUsersListQuery(fetchQuery);

  const { items: users = [] } = data?.data || {};

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const {
    control,
    getValues,
    reset,
  } = useForm<TUsersListSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(usersListSchema),
    defaultValues: {
      [SEARCH]: params.search,
    },
  });

  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    setParams((prev) => ({ ...prev, search }));
  });

  const onSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    reset();
  }, [reset]);

  const onHandleCloseConfirmation = (isOpen = false) => {
    setIsConfirmationModalOpen(isOpen);
  };

  const [loginAdminAsUser] = useLoginAdminAsUserMutation();

  const { refetch: refetchMe, isLoading } = useUserMeQuery();

  const dispatch = useAppDispatch();

  const handleBecomeUser = async (userEmail: string) => {
    setIsConfirmationModalOpen(true);
    const response = await loginAdminAsUser({ email: userEmail }).unwrap();
    const { data: accessToken } = response;
    dispatch(setAuthState({
      userAccessToken: accessToken?.accessToken || '',
      userRefreshToken: accessToken?.refreshToken || '',
    }));
    refetchMe();
    navigate(AppRoutes.launchpad, { replace: true });
  };

  return (
    <CompanyUsersView
      control={control}
      users={users}
      onSearch={onSearchChange}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onHandleCloseConfirmation={onHandleCloseConfirmation}
      onConfirm={handleBecomeUser}
      setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      isLoading={isLoading}
    />
  );
}

export default CompanyUsers;
