export default {
  title: 'Set up authentication application',
  invalidCode: 'Invalid Code',
  downloadApp: {
    description: 'Download the authenticator from the links below:',
    appStore: 'App Store',
    googlePlay: 'Google play',
  },
  qrCode: {
    inTheAuthenticator: 'In the <authenticatorType /> Authenticator app, click the + icon.',
    selectScan: 'Select Scan QR Code.',
    linkText: 'Can\'t scan the QR code?',
    imgAlt: 'QR code',
  },
  secretCode: {
    inTheAuthenticator: 'In the <authenticatorType /> Authenticator app, click the + icon, and then select '
        + '<b>Enter Setup Key</b>',
    includeYour: 'Include your e-mail address and this key (spaces are not counted): <b>{{secretCode}}</b>',
    makeSure: 'Make sure the <b>Time based</b> option is selected.',
    toComplete: 'To complete the configuration, click <b>Add</b>.',
  },
  verificationCode: {
    description: 'Enter the 6-digit code from the application.',
    verificationCode: 'Verification Code',
  },
  authenticationSetup: {
    description: 'Select the app you would like to proceed with:',
  },
};
