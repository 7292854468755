import Subtitle from '../Subtitle/Subtitle';

import type IClientPanelHeader from './interfaces/IClientPanelHeader';

import styles from './scss/ClientPanelHeader.module.scss';

function ClientPanelHeader({ title, text }: IClientPanelHeader) {
  return (
    <div className={styles.root}>
      <Subtitle
        className={styles.title}
        text={title}
      />
      <p className={styles.text}>
        {text}
      </p>
    </div>
  );
}

export default ClientPanelHeader;
