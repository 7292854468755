import { useEffect, useState } from 'react';
import {
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/query';
import AppRoutes from 'constants/AppRoutes';
import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from 'constants/dataSets';
import { BLOCKER_STATES } from 'constants/general';
import useFieldsErrors from 'hooks/useFieldsErrors';
import i18n from 'i18n';
import {
  useEnumQuery,
  useProductCreateMutation, useProductRecordQuery,
  useProductRecordUpdateMutation,
  useUidNameTuplesQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums, selectUidNameTuples } from 'store/slices/dataSets/selectors';
import getRequestDataForProduct from 'utils/formHelpers/getRequestDataForProduct';

import useCreateProductForm from './hooks/useCreateProductForm';
import CreateProductView from './CreateProductView';

import type { ReactElement } from 'react';
import type IProductRecordRequest from 'store/slices/dataSets/interfaces/IProductRecordRequest';
import type { TCreateProductSchema } from 'utils/validators';

function CreateProduct(): ReactElement {
  const navigate = useNavigate();
  const { recordId = '' } = useParams();

  const { data: uidNameTuples, isLoading: isNaceUidNameTuplesLoading } = useUidNameTuplesQuery({
    types: [UID_NAME_TUPLES.profitCenter, UID_NAME_TUPLES.supplier],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectUidNameTuples(result.data) }),
  });

  const [productCreate] = useProductCreateMutation();

  const [productRecordUpdate] = useProductRecordUpdateMutation();
  const { setFieldsErrors } = useFieldsErrors();

  const { data: enumData, isLoading: isEnumLoading } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency, DATA_SETS_ENUM_TYPES.volumeQtyUnit,
      DATA_SETS_ENUM_TYPES.relianceOnFreeNaturalResources, DATA_SETS_ENUM_TYPES.relianceOnFreeSocialResources,
      DATA_SETS_ENUM_TYPES.criticalityRating],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });

  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { isLoading: isProductRecordLoading } = useProductRecordQuery(fetchQuery);

  const isPageLoading = isEnumLoading || isNaceUidNameTuplesLoading
      || isProductRecordLoading;

  const productEndpoint = recordId ? productRecordUpdate : productCreate;
  const {
    control,
    handleSubmit,
    isDirty,
    reset,
    setError,
    fieldsName,
  } = useCreateProductForm({ recordId, isPageLoading });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty
      && currentLocation.pathname !== nextLocation.pathname
      && !isConfirmationModalOpen);

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      if (isDirty) {
        setIsConfirmationModalOpen(true);
      } else {
        blocker.proceed?.();
      }
    }
  }, [blocker, isDirty]);

  const onProductSubmit = handleSubmit((values) => {
    const requestData: IProductRecordRequest = getRequestDataForProduct({ values, recordId });

    productEndpoint(requestData).unwrap()
      .then(
        () => {
          toast.success(i18n.t(
            recordId ? 'notifications.recordHasBeenUpdated'
              : 'notifications.recordHasBeenAdded',
          ));
          reset(undefined, { keepValues: true });
          navigate(AppRoutes.products);
        },
      ).catch((errors) => {
        setFieldsErrors<TCreateProductSchema>({ errors, setError, fieldsName });
      });
  });

  const onHandleCloseConfirmation = (isOpen = false) => {
    if (!isConfirmationModalOpen && !isDirty) {
      navigate(-1);
    } else {
      setIsConfirmationModalOpen(isOpen);
    }
  };

  const onDiscardChangesConfirm = () => {
    navigate(-1);
    reset(undefined, { keepValues: true });
  };

  return (
    <CreateProductView
      control={control}
      onProductSubmit={onProductSubmit}
      onHandleCloseConfirmation={onHandleCloseConfirmation}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onDiscardChangesConfirm={onDiscardChangesConfirm}
      enumData={enumData}
      uidNameTuples={uidNameTuples}
      isPageLoading={isPageLoading}
    />
  );
}

export default CreateProduct;
