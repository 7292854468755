import { createSelector } from '@reduxjs/toolkit';

import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type { RootState } from '../../types/TStore';
import type ICompany from './interfaces/ICompany';
import type IPermissions from './interfaces/IPermissions';

const selfCompanies = (state: RootState) => state.companies;

export const createFlowCompanyIdSelector = createSelector(
  [selfCompanies],
  ({ createFlowCompanyId }) => createFlowCompanyId,
);

export const clientsQueryParamsSelector = createSelector(
  [selfCompanies],
  ({ clientsQueryParams }) => clientsQueryParams,
);

export const selectCompanyInfo = createSelector(
  (data?: IBaseQueryResponse<ICompany>) => data?.data,
  (company) => company,
);

export const selectCompanyLanguages = createSelector(
  (data?: IBaseQueryResponse<ICompany>) => data?.data,
  (company) => ({
    languages: company?.localization?.languages || ['en'],
  }),
);

export const selectCompanyAdmins = createSelector(
  (data?: IBaseQueryResponse<ICompany>) => data?.data,
  (company) => company?.companyAdminUsers.map(({ latestDeactivation, ...rest }) => rest) || [],
);

export const selectUiConfiguration = createSelector(
  (data?: IBaseQueryResponse<ICompany>) => data?.data,
  (company) => company?.uiConfiguration,
);

export const selectCompanyPermissions = createSelector(
  (data?: IBaseQueryResponse<IPermissions>) => data?.data,
  (data) => data?.pillars.map(({ id, name, canView }) => ({
    id,
    name,
    canView,
    pillarModules: data?.pillarModules.filter(({ pillarId }) => pillarId === id),
  })),
);

export const selectCompanySecurity = createSelector(
  (data?: IBaseQueryResponse<ICompany>) => data?.data,
  (company) => ({
    sso: company?.security?.sso,
    mfaApp: company?.security?.mfaApp,
    mfaSms: company?.security?.mfaSms,
  }),
);

export default createFlowCompanyIdSelector;
