import { useTranslation } from 'react-i18next';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';

import AdminsSection from './components/AdminsSection/AdminsSection';
import CompanyInfoSection from './components/CompanyInfoSection/CompanyInfoSection';
import ModuleManagementSection from './components/ModuleManagmentSection/ModuleManagementSection';
import MultilingualSection from './components/MultilingualSection/MultilingualSection';
import SecuritySection from './components/SecuritySection/SecuritySection';
import UIConfigurationSection from './components/UIConfigurationSection/UIConfigurationSection';

import type ICompanyProfileView from './interfaces/ICompanyProfileView';

import styles from './scss/CompanyProfileView.module.scss';

function CompanyProfileView({
  company, permissions, onChangeCompanyStatus,
  isDeactivateCompanyModalOpen, onCloseDeactivateCompanyModal,
  onConfirmDeactivateCompanyModal,
}: ICompanyProfileView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <CompanyInfoSection
        title={company?.name}
        type={company?.type}
        description={company?.description}
        status={company?.status}
        deactivationInfo={company?.latestDeactivation}
        onChangeCompanyStatus={onChangeCompanyStatus}
      />
      <MultilingualSection
        languages={company?.localization?.languages || []}
      />
      <AdminsSection
        admins={company?.companyAdminUsers || []}
      />
      <UIConfigurationSection
        uiConfiguration={company?.uiConfiguration}
      />
      <ModuleManagementSection
        pillars={permissions?.pillars || []}
        pillarModules={permissions?.pillarModules || []}
      />
      <SecuritySection
        security={company?.security}
      />
      <DeactivationModal
        open={isDeactivateCompanyModalOpen}
        text={t('common.clientActivity')}
        onReject={onCloseDeactivateCompanyModal}
        onSubmit={onConfirmDeactivateCompanyModal}
      />
    </div>
  );
}

export default CompanyProfileView;
