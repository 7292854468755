export default {
  title: 'Suppliers',
  subTitle: 'Lorem ipsum dolor',
  totalPages: '{{offset}}-{{totalPages}} of {{total}}',
  archivingReasonTitle: 'Specify reason for archiving',
  archivingReasonDescription: 'Please provide a reason for archiving the selected record(s). You can restore '
        + 'them anytime.',
  archiveDetails: 'Archived record details',
  editInformation: 'Edit Information',
  addNewSupplier: 'Add New Supplier',
  editSupplier: 'Edit Supplier',
  fields: {
    supplierName: 'Supplier Name',
    supplierCode: 'Supplier Code',
    uniqueIdentifier: 'Unique Identifier',
    valueChain: 'Value Chain Section',
    upstreamTier: 'Upstream Tier',
    ownership: 'Ownership',
    equityShareholding: 'Equity Shareholding %',
    financialControl: 'Financial Control %',
    physicalOrLogical: 'Physical (site block) or Logical (business unit) Entity',
    dateOpened: 'Date Opened',
    dateClosed: 'Date Closed',
    industry: 'Industry (NACE)',
    sector: 'Sector (NACE)',
    taxonomy: 'EU Taxonomy Activity Code',
    spend: 'Spend (rolling 12 months)',
    keySuppliers: 'Key Suppliers Tier 1 Total Cost of Sales Spend (rolling 12 months)',
    keySuppliersInPercentage: 'Key Suppliers Tier 1 % of Total Cost of Sales Spend (rolling 12 months)',
    country: 'Country of Operation/Incorporation',
    state: 'State / Region / County',
    post: 'Post Code',
    town: 'Town / City',
    street: 'Street',
    building: 'Building Name or Number',
    coordinates: 'Coordinates',
    actions: 'Actions',
  },
  selections: {
    general: 'General',
    details: 'Details',
    financials: 'Financials',
    address: 'Address',
  },
};
