import { useEffect, useState } from 'react';

interface TUseTimerProps {
  storageKey: string,
  timeLeft: number,
}

const useTimer = ({ storageKey, timeLeft }: TUseTimerProps) => {
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem(storageKey);
    return savedTime !== null ? parseInt(savedTime, 10) : timeLeft;
  });
  const [isRunning, setIsRunning] = useState(true);
  const [isResetResetTimer, setIsResetTimer] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning && time > 0) {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0 && isRunning) {
      setIsRunning(false);
    }

    if (isResetResetTimer) {
      setTime(timeLeft);
      setIsRunning(true);
      setIsResetTimer(false);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [time, isRunning, isResetResetTimer, timeLeft]);

  useEffect(() => {
    localStorage.setItem(storageKey, time.toString());
  }, [time, storageKey]);

  const resetTimer = () => {
    setIsResetTimer(true);
  };

  const isTimeUp = time === 0;

  return { time, isTimeUp, resetTimer };
};

export default useTimer;
