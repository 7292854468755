import { clsx } from 'clsx';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type IButton from './interfaces/IButton';

import styles from './sass/Button.module.scss';

function Button({
  title,
  onClick,
  className,
  startIcon,
  endIcon,
  disabled,
  variant = BUTTON_VARIANT.primary,
  buttonStyles,
}: IButton) {
  const classes = clsx(styles.button, styles[variant], className);

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
      type="button"
      style={buttonStyles}
    >
      {startIcon ? <span>{startIcon}</span> : null}
      <span>{title}</span>
      {endIcon ? <span>{endIcon}</span> : null}
    </button>
  );
}

export default Button;
