import type { ICreateGeoLocationsOptionsOptions } from './interfaces/ICreateGeoLocationsOptionsOptions';

function createGeoLocationsOptions({ data, isState }: ICreateGeoLocationsOptionsOptions) {
  if (data?.length === 0) {
    return [];
  }

  return data?.map(({ longName, shortName, placeId }) => {
    const value = isState ? placeId : shortName;
    return { value, label: longName };
  });
}

export default createGeoLocationsOptions;
