import type { FieldValues } from 'react-hook-form';
import type IErrorMessage from '../store/types/IErrorMessage';
import type { IUseFieldsErrors } from './interfaces/IUseFieldsErrors';

const useFieldsErrors = () => {
  const setFieldsErrors = <T extends FieldValues>({ errors, setError, fieldsName }: IUseFieldsErrors<T>) => {
    errors?.data?.message?.forEach((fieldErrors: IErrorMessage<T>[]) => {
      fieldErrors?.forEach((error) => {
        const property = error.property.replace('.', '_');
        const isFieldNameExist = fieldsName?.includes(property);
        if (isFieldNameExist) {
          setError(property, { message: error.error });
        }
      });
    });
  };

  return { setFieldsErrors };
};

export default useFieldsErrors;
