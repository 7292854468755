import { t } from 'i18next';

import {
  AUTHENTICATION_GOOGLE, AUTHENTICATION_MICROSOFT,
} from './formFields';

import { ReactComponent as GoogleIcon } from '../assets/icons/google.svg';
import { ReactComponent as MicrosoftIcon } from '../assets/icons/microsoft.svg';

const AUTHENTICATION_TYPES = [
  {
    name: AUTHENTICATION_GOOGLE,
    value: AUTHENTICATION_GOOGLE,
    label: t('authenticationSetup.googleAuthenticator'),
    icon: <GoogleIcon />,
  },
  {
    name: AUTHENTICATION_MICROSOFT,
    value: AUTHENTICATION_MICROSOFT,
    label: t('authenticationSetup.microsoftAuthenticator'),
    icon: <MicrosoftIcon />,
  },
];

export default AUTHENTICATION_TYPES;
