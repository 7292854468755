import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import SelectDefaultLanguageModal from '../../components/SelectDefaultLanguageModal/SelectDefaultLanguageModal';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

import type { ReactElement } from 'react';
import type IPage from './interfaces/IPage';

import styles from './sass/Page.module.scss';

function Page({ isLanguageSelectorModalOpen = false }: IPage): ReactElement {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className={`page ${styles.root}`}>
      <Header />
      <div className={styles.content}>
        {isSidebarOpen ? <Sidebar /> : null}
        <div className={styles.main}>
          <Outlet context={{ setIsSidebarOpen }} />
        </div>
      </div>
      <SelectDefaultLanguageModal open={isLanguageSelectorModalOpen} />
    </div>
  );
}

export default Page;
