import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LogoPlaceholder from 'assets/icons/logo.svg';
import Avatar from 'components/Avatar/Avatar';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import {
  COMPANY_LOGO,
  HEADINGS_COLOR_1,
  HEADINGS_COLOR_2,
  PARTNER_LOGO,
  PRIMARY_COLOR,
  SECONDARY_COLOR, SUBTITLE_COLOR,
} from 'constants/formFields';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type IUIConfigurationPreview from '../../interfaces/IUIConfigurationPreview';

import styles from './scss/UIConfigurationPreview.module.scss';

function UIConfigurationPreview({ control, isSuperAdmin }: IUIConfigurationPreview) {
  const { t } = useTranslation();
  const companyLogo = useWatch({ control, name: COMPANY_LOGO });
  const partnerLogo = useWatch({ control, name: PARTNER_LOGO });

  const primaryColor = useWatch({ control, name: PRIMARY_COLOR });
  const secondaryColor = useWatch({ control, name: SECONDARY_COLOR });
  const headingsColor1 = useWatch({ control, name: HEADINGS_COLOR_1 });
  const headingsColor2 = useWatch({ control, name: HEADINGS_COLOR_2 });
  const subtitleColor = useWatch({ control, name: SUBTITLE_COLOR });

  const companyLogoSrc = companyLogo && typeof companyLogo === 'object'
    ? URL.createObjectURL(companyLogo) : companyLogo;
  const partnerLogoSrc = partnerLogo && typeof partnerLogo === 'object'
    ? URL.createObjectURL(partnerLogo) : partnerLogo;

  const previewColors = {
    header: secondaryColor,
    icon: primaryColor,
  };

  const primaryButtonStyles = {
    background: primaryColor,
    borderColor: primaryColor,
  };

  const secondaryButtonStyles = {
    color: primaryColor,
    borderColor: primaryColor,
  };

  return (
    <div>
      <h4 className={styles.previewTitle}>{t('clients.preview')}</h4>
      <div className={styles.root}>
        <div className={styles.logos}>
          <Avatar src={companyLogoSrc} placeholder={LogoPlaceholder} />
          {isSuperAdmin ? <Avatar src={partnerLogoSrc} placeholder={LogoPlaceholder} /> : null }
        </div>
        <div className={styles.texts}>
          <h1 className={styles.heading1} style={{ color: headingsColor1 }}>{t('common.loremIpsumTitle')}</h1>
          <h2 className={styles.heading2} style={{ color: headingsColor2 }}>{t('common.loremIpsumTitle')}</h2>
          <p className={styles.subtitle} style={{ color: subtitleColor }}>{t('common.loremIpsumTextError')}</p>
        </div>
        <p className={styles.body}>{t('common.loremIpsumText')}</p>
        <p className={styles.body}>{t('common.loremIpsumTextShort')}</p>
        <div className={styles.cards}>
          <Card className={styles.card} previewColors={previewColors}>
            <h4 className={styles.cardTitle} style={{ color: headingsColor1 }}>{t('clients.cardTitle')}</h4>
            <p className={styles.cardText} style={{ color: subtitleColor }}>{t('clients.cardText')}</p>
          </Card>
          <Card className={styles.card} previewColors={previewColors}>
            <h4 className={styles.cardTitle} style={{ color: headingsColor1 }}>{t('clients.cardTitle')}</h4>
            <p className={styles.cardText} style={{ color: subtitleColor }}>{t('clients.cardText')}</p>
          </Card>
        </div>
        <div className={styles.buttons}>
          <div className={styles.wrapper}>
            <Button
              title={t('common.cancel')}
              onClick={() => {}}
              variant={BUTTON_VARIANT.cancel}
            />
            <Button
              title={t('common.draft')}
              onClick={() => {}}
              variant={BUTTON_VARIANT.secondary}
              buttonStyles={secondaryButtonStyles}
            />
            <Button
              title={t('common.save')}
              onClick={() => {}}
              buttonStyles={primaryButtonStyles}
              className={styles.primaryButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UIConfigurationPreview;
