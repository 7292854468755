import { NumericFormat } from 'react-number-format';
import { clsx } from 'clsx';
import {
  DECIMAL_SEPARATOR, DEFAULT_DECIMAL_SCALE, PERCENT_SUFFIX, THOUSAND_SEPARATOR,
} from 'constants/general';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type INumberField from './interfaces/INumberField';

import 'react-international-phone/style.css';
import styles from './scss/TextField.module.scss';

function NumberField({
  name, label, onChange, value, type,
  className, error, placeholder, icon,
  required, suffix, decimalScale = DEFAULT_DECIMAL_SCALE,
}: INumberField) {
  const classes = clsx(styles.root, className, {
    [styles.rootError]: error,
  });

  const wrapperClasses = clsx(styles.wrapper, {
    [styles.withIcon]: icon,
  });

  const numberSuffix = type === NUMBER_FORMATS.percent ? PERCENT_SUFFIX : '';

  const thousandSeparator = type === NUMBER_FORMATS.percent ? '' : THOUSAND_SEPARATOR;

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={name}>
          {required ? `${label}*` : label}
        </label>
      ) : null}
      <div className={wrapperClasses}>
        {icon ? (
          <div className={styles.icon}>
            {icon}
          </div>
        ) : null}

        <NumericFormat
          value={value}
          onValueChange={onChange}
          thousandSeparator={thousandSeparator}
          decimalSeparator={DECIMAL_SEPARATOR}
          placeholder={placeholder}
          type="text"
          decimalScale={decimalScale}
          suffix={suffix || numberSuffix}
        />

      </div>
      {error ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
}

export default NumberField;
