import { useTranslation } from 'react-i18next';
import { ReactComponent as MaterialityCircleIcon } from 'assets/icons/materialityCircle.svg';
import { ReactComponent as SettingsCircleIcon } from 'assets/icons/settingsCircle.svg';
import { ReactComponent as ValueChainInfoCircleIcon } from 'assets/icons/valueChainCircle.svg';
import { AppRoutes } from 'constants/AppRoutes';
import { LAUNCHPAD_EMPTY_PILLARS_COUNT } from 'constants/general';
import { USER_PILLARS } from 'constants/interfaces';

import LaunchpadCard from './components/LaunchpadCard/LaunchpadCard';
import LaunchpadLoading from './LaunchpadLoading';

import type ILaunchpadView from './interfaces/ILaunchpadView';

import styles from './sass/LaunchpadView.module.scss';

function LaunchpadView({ isLoading }: ILaunchpadView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {isLoading ? <LaunchpadLoading /> : (
          <>
            <LaunchpadCard
              pillarKey={USER_PILLARS.valueChainAssessment}
              icon={<ValueChainInfoCircleIcon />}
              title={t('pillarsAndModules.valueChainAssessment')}
              navLink={AppRoutes.home}
            />
            <LaunchpadCard
              pillarKey={USER_PILLARS.materialityAssessment}
              icon={<MaterialityCircleIcon />}
              title={t('pillarsAndModules.materialityAssessment')}
              navLink={AppRoutes.home}
            />
            {Array.from({ length: LAUNCHPAD_EMPTY_PILLARS_COUNT }).map(() => (
              <LaunchpadCard key={window.crypto.randomUUID()} />
            ))}
            <LaunchpadCard
              pillarKey={USER_PILLARS.settings}
              icon={<SettingsCircleIcon />}
              title={t('pillarsAndModules.settings')}
              navLink={AppRoutes.launchpadSettings}
            />
          </>
        )}

      </div>
    </div>
  );
}

export default LaunchpadView;
