import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { clsx } from 'clsx';

import type ITableSort from './interfaces/ITableSort';

import styles from './sass/TableSort.module.scss';

function TableSort({
  children, onClickSort, active,
}: ITableSort) {
  const classes = clsx(styles.root, {
    [styles.active]: active,
  });

  return (
    <div className={classes}>
      {children}
      <button
        className={styles.button}
        type="button"
        tabIndex={-1}
        onClick={onClickSort}
      >
        <SortIcon />
      </button>
    </div>
  );
}

export default TableSort;
