import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TableCell from 'components/Table/TableCell';
import TableHead from 'components/Table/TableHead';
import TableSort from 'components/Table/TableSort';
import { SORT } from 'constants/formFields';
import { USER_MANAGEMENT_HEAD_CELLS } from 'constants/user';

import type { IUsersTableHead } from '../../interfaces/IUsersTableHead';

import styles from './sass/UsersTableHead.module.scss';

function UsersTableHead({ isActionsVisible, control, onSortChange } : IUsersTableHead) {
  const { t } = useTranslation();

  return (
    <TableHead>
      {USER_MANAGEMENT_HEAD_CELLS.map(({ id, label, withSort }) => (withSort ? (
        <Controller
          key={id}
          name={SORT}
          control={control}
          render={({
            field: { value },
          }) => (
            <TableCell head className={styles.date}>
              <TableSort
                active={id === value.orderKey}
                onClickSort={() => onSortChange(id)}
              >
                {t(label)}
              </TableSort>
            </TableCell>
          )}
        />
      ) : (
        <TableCell head className={styles.actions} key={id}>
          {t(label)}
        </TableCell>
      )))}
      { isActionsVisible ? (
        <TableCell head className={styles.actions}>
          {t('userManagement.actions')}
        </TableCell>
      ) : null}
    </TableHead>
  );
}

export default UsersTableHead;
