import type { NodeModel } from '@minoru/react-dnd-treeview';

function getParentIds(currentItem: NodeModel, treeData: NodeModel[]) {
  const parentIds = [];
  let currentNode: NodeModel | undefined = currentItem;

  while (currentNode && currentNode.parent !== 0) {
    parentIds.push(currentNode.parent);
    const parentId: number | string = currentNode.parent;
    currentNode = treeData.find((node) => node.id === parentId);
  }

  return parentIds;
}

export default getParentIds;
