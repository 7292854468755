import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type ICompanyAdminUsersStore from './interfaces/ICompanyAdminUsersStore';

export const initialState: ICompanyAdminUsersStore = {
  createFlowUserId: undefined,
};

export const companyAdminUsersSlice = createSlice({
  name: 'companyAdminUsers',
  initialState,
  reducers: {
    setCompanyAdminUsersState: (state, { payload }:
    PayloadAction<Partial<ICompanyAdminUsersStore>>) => ({ ...state, ...payload }),
    resetState: () => initialState,
  },
  extraReducers: () => {},
});

export const {
  resetState, setCompanyAdminUsersState,
} = companyAdminUsersSlice.actions;

export default companyAdminUsersSlice.reducer;
