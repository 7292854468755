import { useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import IconButton from 'components/IconButton/IconButton';

import type IUserStatusBadge from './interfaces/IUserStatusBadge';

import styles from './scss/UserStatusBadge.module.scss';

function UserStatusBadge({
  title, children, showInfoButton, deactivationInfo, modalTitle,
}: IUserStatusBadge) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className={styles.root}>
        {title ? <p>{title}</p> : null}
        <div className={styles.status}>
          {children}
          {showInfoButton && deactivationInfo ? (
            <IconButton
              className={styles.details}
              size="small"
              icon={<InfoIcon color="#F89603" />}
              onClick={() => setIsModalOpen(true)}
            />
          ) : null}
        </div>
      </div>
      <DeactivationInfoModal
        open={isModalOpen}
        title={modalTitle}
        deactivationInfo={deactivationInfo}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

export default UserStatusBadge;
