import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { DATA_SET_PAGINATION_COUNT } from 'constants/dataSets';
import PaginationComponent from 'rc-pagination';

import 'rc-pagination/assets/index.css';

import './sass/pagination.scss';

interface PaginationComponentProps {
  count: number,
  offset: number,
  limit: number,
  onPageChange: (page: { selected: number }) => void,
}

function Pagination({
  count, offset, limit, onPageChange,
}: PaginationComponentProps) {
  const current = offset ? offset / DATA_SET_PAGINATION_COUNT + 1 : 1;
  return (
    <PaginationComponent
      align="center"
      total={count}
      current={current}
      pageSize={limit}
      onChange={(page) => onPageChange({ selected: page - 1 })}
      showTitle={false}
      prevIcon={<ArrowLeftIcon />}
      nextIcon={<ArrowRightIcon />}
    />
  );
}

export default Pagination;
