import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  EMAIL, FIRST_NAME, LAST_NAME, ROLE,
} from 'constants/formFields';
import { USER_ROLE } from 'constants/user';
import { useUserDetailsQuery } from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';
import { createNewUserSchema } from 'utils/validators';

import type { TCreateNewUserSchema } from 'utils/validators';
import type { IUseCreateNewUserForm } from '../interfaces/IUseCreateNewUserForm';

const useCreateNewUserForm = ({ currentUserId }: IUseCreateNewUserForm) => {
  const fetchQuery = currentUserId ? {
    userId: +currentUserId,
  } : skipToken;
  const { data } = useUserDetailsQuery(fetchQuery);

  const {
    firstName, lastName, email, role,
  } = data?.data || {};

  const userRole = USER_ROLE.filter(({ value }) => value === role)?.[0] || undefined;

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
  } = useForm<TCreateNewUserSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createNewUserSchema),
    values: {
      [FIRST_NAME]: firstName || '',
      [ROLE]: userRole,
      [LAST_NAME]: lastName || '',
      [EMAIL]: email || '',
    },
  });

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
    isLoading,
  };
};

export default useCreateNewUserForm;
