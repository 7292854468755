import about from './about';
import authenticationModal from './authenticationModal';
import authenticationSetup from './authenticationSetup';
import authenticationType from './authenticationType';
import breadcrumbs from './breadcrumbs';
import clients from './clients';
import common from './common';
import companyUsers from './companyUsers';
import createNewUser from './createNewUser';
import customers from './customers';
import dataSetsManagement from './dataSetsManagement';
import deactivatedUser from './deactivatedUser';
import editUserInfo from './editUserInfo';
import errors from './errors';
import home from './home';
import languages from './languages';
import login from './login';
import mfaPhoneSetup from './mfaPhoneSetup';
import multilingual from './multilingual';
import newPassword from './newPassword';
import notifications from './notifications';
import ownOperations from './ownOperations';
import permissions from './permissions';
import pillarsAndModules from './pillarsAndModules';
import products from './products';
import profile from './profile';
import profilePassword from './profilePassword';
import profileSecurity from './profileSecurity';
import profitCentres from './profitCentres';
import recoverPassword from './recoverPassword';
import recoverPasswordSuccess from './recoverPasswordSuccess';
import staticTranslates from './staticTranslates';
import suppliers from './suppliers';
import toast from './toast';
import userInfo from './userInfo';
import userManagement from './userManagement';
import verificationCode from './verificationCode';
import verificationSms from './verificationSms';

export default {
  common,
  home,
  about,
  login,
  clients,
  languages,
  errors,
  authenticationType,
  verificationSms,
  verificationCode,
  recoverPassword,
  recoverPasswordSuccess,
  newPassword,
  profile,
  profilePassword,
  breadcrumbs,
  pillarsAndModules,
  userManagement,
  notifications,
  createNewUser,
  permissions,
  userInfo,
  companyUsers,
  authenticationSetup,
  authenticationModal,
  editUserInfo,
  deactivatedUser,
  profileSecurity,
  mfaPhoneSetup,
  dataSetsManagement,
  profitCentres,
  ownOperations,
  suppliers,
  customers,
  products,
  toast,
  multilingual,
  staticTranslates,
};
