import { useTranslation } from 'react-i18next';

import PillarCard from './PillarCard';

import type IModuleManagementForm from '../../interfaces/IModuleManagementForm';

import styles from './scss/ModuleManagementForm.module.scss';

function ModuleManagementForm({
  control,
  pillars,
  onResetPillarModules,
}: IModuleManagementForm) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>
        {t('clients.selectPillars')}
      </h3>
      <div className={styles.wrapper}>
        {pillars?.map(({ id, name, pillarModules }, i) => name && (
          <PillarCard
            key={id}
            control={control}
            pillarIndex={i}
            pillarName={name}
            pillarModules={pillarModules}
            onResetPillarModules={onResetPillarModules}
          />
        ))}
      </div>
    </div>
  );
}

export default ModuleManagementForm;
