import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { clsx } from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import DataSetsItemButtons from 'components/DataSetsItemButtons/DataSetsItemButtons';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import { SELECTED_ITEMS } from 'constants/formFields';
import { NUMBER_FORMATS } from 'constants/interfaces';
import formatNumber from 'utils/formatNumber';
import getNaceLabel from 'utils/getNaceLabel';

import type { ICustomersItem } from './interface/ICustomersItem';

import styles from './scss/CustomersItem.module.scss';

function CustomersItem({
  item, control, onArchiveModalInfoOpen, onMoveToArchive, onDeArchive, onEditRecord, isActionsVisible, naceCodeTreeData,
}: ICustomersItem): React.ReactElement {
  const { t } = useTranslation();
  const {
    id, name, code, profitCenterId, addressBuilding, addressCity, addressCountry, addressPostCode, addressState,
    addressStreet, archived, businessUnitType, dateClosed, priorityRating, dateOpened, naceCode, equityShareholding,
    financialControl, totalRevenueRolling12MActual, totalRevenueRolling12MPercents,
    ownership, uniqueIdentifier, valueChainSection, latestArchivation,
    naceSection, addressCoordinates, expectedGrowthNext12M,
  } = item;

  const { naceCodeLabel, naceSectionLabel } = getNaceLabel(
    { naceCode, naceSection, naceCodeTreeData },
  );

  const hyphen = t('common.hyphen');

  return (
    <TableRow className={clsx(undefined, { [styles.archived]: archived })}>
      <TableCell>
        <div className={styles.staticCell}>
          {archived ? (
            <span>
              {name}
              {latestArchivation ? (
                <InfoIcon
                  onClick={() => onArchiveModalInfoOpen(latestArchivation)}
                />
              ) : null}
            </span>
          ) : null}

          {!archived && (
            isActionsVisible ? (
              <Controller
                control={control}
                render={({ field: { onChange, value, disabled } }) => (
                  <Checkbox
                    key={id}
                    name={`${SELECTED_ITEMS}-${id}`}
                    checked={!!value.includes(id)}
                    value={id}
                    disabled={disabled}
                    onChange={(event) => {
                      onChange(event.target.checked
                        ? [...value, id]
                        : value.filter((selectedItem) => selectedItem !== id));
                    }}
                    label={(<span>{name}</span>)}
                  />
                )}
                name={SELECTED_ITEMS}
              />
            ) : (<span>{name}</span>)
          )}
        </div>
      </TableCell>
      <TableCell>
        {code || hyphen}
      </TableCell>
      <TableCell>
        {uniqueIdentifier || hyphen}
      </TableCell>
      <TableCell>
        {profitCenterId || hyphen}
      </TableCell>
      <TableCell>
        {valueChainSection || hyphen}
      </TableCell>
      <TableCell>
        {ownership || hyphen}
      </TableCell>
      <TableCell>
        {equityShareholding ? formatNumber(
          {
            value: equityShareholding,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {financialControl ? formatNumber(
          {
            value: financialControl,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {businessUnitType || hyphen}
      </TableCell>
      <TableCell>
        {dateOpened || hyphen}
      </TableCell>
      <TableCell>
        {dateClosed || hyphen}
      </TableCell>
      <TableCell>
        {priorityRating || hyphen}
      </TableCell>
      <TableCell>
        {naceCodeLabel || hyphen}
      </TableCell>
      <TableCell>
        {naceSectionLabel || hyphen}
      </TableCell>
      <TableCell>
        {expectedGrowthNext12M ? formatNumber(
          {
            value: expectedGrowthNext12M,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {totalRevenueRolling12MPercents ? formatNumber(
          {
            value: totalRevenueRolling12MPercents,
            type: NUMBER_FORMATS.percent,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {totalRevenueRolling12MActual?.amount ? formatNumber(
          {
            value: totalRevenueRolling12MActual.amount,
            currency: totalRevenueRolling12MActual.currency,
          },
        ) : hyphen}
      </TableCell>
      <TableCell>
        {addressCountry || hyphen}
      </TableCell>
      <TableCell>
        {addressState || hyphen}
      </TableCell>
      <TableCell>
        {addressPostCode || hyphen}
      </TableCell>
      <TableCell>
        {addressCity || hyphen}
      </TableCell>
      <TableCell>
        {addressStreet || hyphen}
      </TableCell>
      <TableCell>
        {addressBuilding || hyphen}
      </TableCell>
      <TableCell>
        {addressCoordinates || hyphen}
      </TableCell>

      {isActionsVisible ? (
        <TableCell>
          <DataSetsItemButtons
            id={id}
            archived={archived}
            onEditRecord={onEditRecord}
            onMoveToArchive={onMoveToArchive}
            onDeArchive={onDeArchive}
          />
        </TableCell>
      ) : null}
    </TableRow>
  );
}

export default CustomersItem;
