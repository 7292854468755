import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'assets/icons/toast-error.svg';

import type IErrorItem from '../interfaces/IErrorItem';

import styles from '../scss/ErrorItem.module.scss';

function ErrorItem({
  error,
}: IErrorItem) {
  const { t } = useTranslation();
  const { rowNumber, column, error: errorMessage } = error || {};

  return (
    <div className={styles.root}>
      <div className={styles.errorPlace}>
        <div>
          <Trans
            i18nKey="dataSetsManagement.importErrorsModal.errorPlaceColumn"
            t={t}
            components={{
              b: <b />,
            }}
            values={{
              column,
            }}
            tOptions={{ interpolation: { escapeValue: false } }}
          />
        </div>
        <div>
          <Trans
            i18nKey="dataSetsManagement.importErrorsModal.errorPlaceRow"
            t={t}
            components={{
              b: <b />,
            }}
            values={{
              row: rowNumber,
            }}
            tOptions={{ interpolation: { escapeValue: false } }}
          />
        </div>

      </div>
      <div className={styles.description}>
        <div><ErrorIcon /></div>
        <span>{errorMessage}</span>
      </div>

    </div>
  );
}

export default ErrorItem;
