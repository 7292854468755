import type { IGetNaceCodeLabelProps } from './interfaces/IGetNaceCodeLabelProps';
import type { INaceLabelResult } from './interfaces/INaceLabelResult';

function getNaceLabel({ naceCode, naceSection, naceCodeTreeData }: IGetNaceCodeLabelProps): INaceLabelResult {
  if (!naceSection || !naceCodeTreeData) {
    return {
      naceCodeLabel: '',
      naceSectionLabel: '',
      naceDvisions: [],
    };
  }

  const section = naceCodeTreeData?.data[naceSection] || {};
  const naceSectionLabel = section?.label ? `${naceSection} ${section?.label}` : '';

  const naceDvisions = section?.divisions
    ? Object.keys(section.divisions).map((key) => {
      const division = section.divisions[key];
      return {
        label: `${key} ${division.label}`,
        value: key,
        classes: division.groups
          ? Object.keys(division.groups).map((groupKey) => {
            const group = division.groups[groupKey];
            return {
              label: `${groupKey} ${group.label}`,
              value: groupKey,
            };
          })
          : [],
      };
    })
    : [];

  const naceCodeOption = Object.values(naceCodeTreeData.data)
    .map((item) => item.divisions)
    .flatMap((divisions) => Object.entries(divisions))
    .find(([code]) => code === naceCode);

  const naceCodeLabel = naceCodeOption?.[0] && naceCodeOption?.[1]?.label
    ? `${naceCodeOption?.[0]} ${naceCodeOption?.[1]?.label}` : '';

  return {
    naceCodeLabel: naceCodeLabel || '',
    naceSectionLabel,
    naceDvisions,
  };
}

export default getNaceLabel;
