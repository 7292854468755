import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';

import RecoverPasswordSuccessView from './RecoverPasswordSuccessView';

import type { ReactElement } from 'react';

function RecoverPasswordSuccess(): ReactElement {
  const navigate = useNavigate();
  const onRecoverPasswordSuccessSubmit = () => {
    navigate(AppRoutes.login);
  };

  return (
    <RecoverPasswordSuccessView onRecoverPasswordSuccessSubmit={onRecoverPasswordSuccessSubmit} />
  );
}

export default RecoverPasswordSuccess;
