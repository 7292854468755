import { FILES_PRE_SIGNED } from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';

import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type IPresignedFileRequest from '../interfaces/IPresignedFileRequest';
import type { IPresignedData } from '../interfaces/IPresignedFileResponse';

const filesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getPresignedPost: builder.mutation<IBaseQueryResponse<IPresignedData>, IPresignedFileRequest>({
      query: (data) => ({
        url: FILES_PRE_SIGNED,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetPresignedPostMutation,
} = filesApi;

export default filesApi;
